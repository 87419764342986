import {
  InitialProjects,
  CATEGORY,
  SOURCE_TYPE,
} from '../util/type/projectType';

export const initialProjects: InitialProjects[] = [
  // {
  //   id: 32,
  //   name: "디지털 트윈 기반 관제 / 시뮬레이션",
  //   category: [CATEGORY.DT],
  //   period: "2024.01.01. - 01.01.",
  //   image: "/images/project/img_thumnail_oss.png",
  //   playLink: null,
  //   detail: null,
  // },
  {
    id: 38,
    name: 'KISTI 가상실험',
    category: [CATEGORY.META],
    period: '2024.08.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_kisti_2024.png',
    playLink: 'https://vlabon.re.kr/',
    detail: {
      title: 'KISTI 가상실험',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_kisti_2024_1.png',
        '/images/project/img_highlight_kisti_2024_2.png',
        '/images/project/img_highlight_kisti_2024_3.png',
      ],
      source: '/images/project/img_thumnail_kisti_2024.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 37,
    name: '통일교육원 가상현실 VR 통일교육 콘텐츠 고도화',
    category: [CATEGORY.META],
    period: '2024.07.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_uniedu.png',
    playLink: null,
    detail: {
      title: '통일교육원',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_uniedu1.png',
        '/images/project/img_highlight_uniedu2.png',
        '/images/project/img_highlight_uniedu3.png',
      ],
      source: '/images/project/img_thumnail_uniedu.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  // {
  //   id: 36,
  //   name: '금연치료',
  //   category: [CATEGORY.META],
  //   period: '2024.05.',
  //   // period: "2023.12. - 2024.02.",
  //   image: '/images/project/img_thumnail_pnu.png',
  //   playLink: null,
  //   detail: {
  //     title: '금연치료',
  //     subTitle: 'Metaverse',
  //     HighlightImg: [
  //       '/images/project/img_highlight_pnu1.png',
  //       '/images/project/img_highlight_pnu2.png',
  //       '/images/project/img_highlight_pnu3.png',
  //     ],
  //     source: '/images/project/img_thumnail_pnu.png',
  //     sourceType: SOURCE_TYPE.IMG,
  //   },
  // },
  {
    id: 36,
    name: '인하대 실시간 강의 통번역 서비스',
    category: [CATEGORY.ETC],
    period: '2024.06.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_rtst.png',
    playLink: null,
    detail: {
      title: '실시간 통번역',
      subTitle: '번역 시스템',
      HighlightImg: [
        '/images/project/img_highlight_rtst1.png',
        '/images/project/img_highlight_rtst2.png',
        '/images/project/img_highlight_rtst3.png',
      ],
      source: '/images/project/img_thumnail_rtst.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 35,
    name: '국립 중앙 의료원 인공호흡기 시뮬레이션 콘텐츠 개발',
    category: [CATEGORY.META],
    period: '2024.05.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_ventilator.png',
    playLink: null,
    detail: {
      title: '인공호흡기',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_ventilator1.png',
        '/images/project/img_highlight_ventilator2.png',
        '/images/project/img_highlight_ventilator3.png',
      ],
      source: '/images/project/img_thumnail_ventilator.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 34,
    name: '산림교육원 2024 VR 콘텐츠 제작',
    category: [CATEGORY.META],
    period: '2024.04.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_forest_2024.png',
    playLink: null,
    detail: {
      title: '산림교육원',
      subTitle: 'Metaverse',
      HighlightImg: null,
      source: '/images/project/img_thumnail_forest_2024.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 33,
    name: '법무부 VR 심리치료 시스템',
    category: [CATEGORY.META],
    period: '2024.04.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_psychology.png',
    playLink: null,
    detail: {
      title: '심리치료 시스템',
      subTitle: 'Metaverse',
      HighlightImg: null,
      source: '/images/project/img_thumnail_psychology.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 32,
    name: '부산대 원스톱플랫폼',
    category: [CATEGORY.ETC],
    period: '2023.12.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_pnu.png',
    playLink: 'https://sanhakdb.pusan.ac.kr/',
    detail: {
      title: '부산대 원스톱 플랫폼',
      subTitle: '부산대 웹 고도화',
      HighlightImg: [
        '/images/project/img_highlight_pnu1.png',
        '/images/project/img_highlight_pnu2.png',
        '/images/project/img_highlight_pnu3.png',
      ],
      source: '/images/project/img_thumnail_pnu.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 31,
    name: '2024년 북한정보 인공지능·빅데이터 분석시스템 운영 및 유지관리',
    category: [CATEGORY.ETC],
    period: '2023.11.',
    // period: "2023.12. - 2024.02.",
    image: '/images/project/img_thumnail_unipotal.png',
    playLink: 'https://nkinfo.unikorea.go.kr/nkp/main/portalMain.do',
    detail: {
      title: '북한정보 인공지능·빅데이터 분석시스템 운영 및 유지관리',
      subTitle: '북한정보 분석시스템',
      HighlightImg: [
        '/images/project/img_highlight_unipotal1.png',
        '/images/project/img_highlight_unipotal2.png',
        '/images/project/img_highlight_unipotal3.png',
      ],
      source: '/images/project/img_thumnail_unipotal.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 30,
    name: '2023 사라진 마을',
    category: [CATEGORY.META],
    period: '2023.06.',
    // period: "2023.06. - 2023.12.",
    image: '/images/project/img_thumnail_losttown.png',
    playLink: 'https://www.universe.go.kr/main',
    detail: {
      title: '2023 사라진 마을',
      subTitle: '사라진 마을 메타버스',
      HighlightImg: [
        '/images/project/img_highlight_2023losttown1.png',
        '/images/project/img_highlight_2023losttown2.png',
        '/images/project/img_highlight_2023losttown3.png',
        '/images/project/img_highlight_2023losttown4.png',
        '/images/project/img_highlight_2023losttown5.png',
        '/images/project/img_highlight_2023losttown6.png',
      ],
      source:
        'https://igi-homepage.s3.ap-northeast-2.amazonaws.com/blog/video/OnTwins_%E1%84%90%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B5%E1%86%AF%E1%84%87%E1%85%AE_%E1%84%89%E1%85%A1%E1%84%85%E1%85%A1%E1%84%8C%E1%85%B5%E1%86%AB%E1%84%86%E1%85%A1%E1%84%8B%E1%85%B3%E1%86%AF(2023).mp4',
      sourceType: SOURCE_TYPE.VIDEO,
    },
  },
  {
    id: 29,
    name: 'KISTI 가상실험 - HPC',
    category: [CATEGORY.META],
    period: '2023.03.',
    // period: "2023.03. - 2023.11.",
    image: '/images/project/img_thumnail_kisti_hpc.png',
    playLink: 'https://vlabon.re.kr/',
    detail: {
      title: 'KISTI 가상실험 - HPC',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_kisti_hpc1.png',
        '/images/project/img_highlight_kisti_hpc2.png',
        '/images/project/img_highlight_hpc3.png',
        '/images/project/img_highlight_kisti_hpc4.png',
        '/images/project/img_highlight_kisti_hpc5.png',
        '/images/project/img_highlight_kisti_hpc6.png',
      ],
      source:
        'https://igi-homepage.s3.ap-northeast-2.amazonaws.com/blog/video/OnTwins_KISTI_HPC.mp4',
      sourceType: SOURCE_TYPE.VIDEO,
    },
  },
  {
    id: 28,
    name: 'KISTI 가상실험 - 화학·생물',
    category: [CATEGORY.META],
    period: '2023.03.',
    // period: "2023.03. - 2023.11.",
    image: '/images/project/img_thumnail_kisti_chemistry.png',
    playLink: 'https://vlabon.re.kr/',
    detail: {
      title: 'KISTI 가상실험 - 화학·생물',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_kisti_chemistry1.png',
        '/images/project/img_highlight_kisti_chemistry2.png',
        '/images/project/img_highlight_kisti_chemistry3.png',
        '/images/project/img_highlight_kisti_chemistry4.png',
        '/images/project/img_highlight_kisti_chemistry5.png',
        '/images/project/img_highlight_kisti_chemistry6.png',
      ],
      source:
        'https://igi-homepage.s3.ap-northeast-2.amazonaws.com/blog/video/OnTwins_KISTI_%E1%84%92%E1%85%AA%E1%84%92%E1%85%A1%E1%86%A8%E1%84%89%E1%85%A2%E1%86%BC%E1%84%86%E1%85%AE%E1%86%AF%E1%84%89%E1%85%AE%E1%84%92%E1%85%A1%E1%86%A8.mp4',
      sourceType: SOURCE_TYPE.VIDEO,
    },
  },
  {
    id: 27,
    name: 'KISTI 가상실험 - 물리·지구과학',
    category: [CATEGORY.META],
    period: '2023.03.',
    // period: "2023.03. - 2023.11.",
    image: '/images/project/img_thumnail_kisti_physics.png',
    playLink: 'https://vlabon.re.kr/',
    detail: {
      title: 'KISTI 가상실험 - 물리·지구과학',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_kisti_physics1.png',
        '/images/project/img_highlight_kisti_physics2.png',
        '/images/project/img_highlight_kisti_physics3.png',
        '/images/project/img_highlight_kisti_physics4.png',
        '/images/project/img_highlight_kisti_physics5.png',
        '/images/project/img_highlight_kisti_physics6.png',
      ],
      source:
        'https://igi-homepage.s3.ap-northeast-2.amazonaws.com/blog/video/OnTwins_KISTI_%E1%84%86%E1%85%AE%E1%86%AF%E1%84%85%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%80%E1%85%AE%E1%84%80%E1%85%AA%E1%84%92%E1%85%A1%E1%86%A8.mp4',
      sourceType: SOURCE_TYPE.VIDEO,
    },
  },
  {
    id: 26,
    name: '산림교육원',
    category: [CATEGORY.META],
    period: '2023.03.',
    // period: "2023.03. - 2023. 09.",
    image: '/images/project/img_thumnail_foresttraining.png',
    playLink: null,
    detail: {
      title: '산림교육원',
      subTitle: 'Metaverse',
      HighlightImg: null,
      // HighlightImg: [
      //   '/images/project/img_highlight_foresttraining1.png',
      //   '/images/project/img_highlight_foresttraining2.png',
      //   '/images/project/img_highlight_foresttraining3.png',
      //   '/images/project/img_highlight_foresttraining4.png',
      //   '/images/project/img_highlight_foresttraining5.png',
      //   '/images/project/img_highlight_foresttraining6.png',
      // ],
      source: '/images/project/img_thumnail_foresttraining.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 24,
    name: '문화관광축제 - 통영',
    category: [CATEGORY.META],
    period: '2022. 12.',
    // period: "2022. 12. - 2023. 02.",
    image: '/images/project/img_thumnail_tourism_hs.png',
    playLink: 'https://www.hansanfmeta.org/main',
    detail: {
      title: '문화관광축제 - 통영',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_tourism7.png',
        '/images/project/img_highlight_tourism8.png',
        '/images/project/img_highlight_tourism9.png',
      ],
      source:
        'https://igi-homepage.s3.ap-northeast-2.amazonaws.com/blog/video/OnTwins_%E1%84%92%E1%85%A1%E1%86%AB%E1%84%80%E1%85%AE%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9%E1%86%BC%E1%84%89%E1%85%A1_%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC%E1%84%8E%E1%85%AE%E1%86%A8%E1%84%8C%E1%85%A6(%E1%84%90%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%A7%E1%86%BC).mp4',
      sourceType: SOURCE_TYPE.VIDEO,
    },
  },
  {
    id: 23,
    name: '문화관광축제 - 포항',
    category: [CATEGORY.META],
    period: '2022. 12.',
    // period: "2022. 12. - 2023. 02.",
    image: '/images/project/img_thumnail_tourism_ph.png',
    playLink: 'https://www.phff.kr/main',
    detail: {
      title: '문화관광축제 - 포항',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_tourism4.png',
        '/images/project/img_highlight_tourism5.png',
        '/images/project/img_highlight_tourism6.png',
      ],
      source:
        'https://igi-homepage.s3.ap-northeast-2.amazonaws.com/blog/video/OnTwins_%E1%84%92%E1%85%A1%E1%86%AB%E1%84%80%E1%85%AE%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9%E1%86%BC%E1%84%89%E1%85%A1_%E1%84%86%E1%85%AE%E1%86%AB%E1%84%92%E1%85%AA%E1%84%80%E1%85%AA%E1%86%AB%E1%84%80%E1%85%AA%E1%86%BC%E1%84%8E%E1%85%AE%E1%86%A8%E1%84%8C%E1%85%A6(%E1%84%91%E1%85%A9%E1%84%92%E1%85%A1%E1%86%BC).mp4',
      sourceType: SOURCE_TYPE.VIDEO,
    },
  },
  {
    id: 22,
    name: '문화관광축제 - 대구',
    category: [CATEGORY.META],
    period: '2022. 12.',
    // period: "2022. 12. - 2023. 02.",
    image: '/images/project/img_thumnail_tourism_dg.png',
    playLink: 'https://deagu-release.d2oxbdsbmlpe8z.amplifyapp.com/main',
    detail: {
      title: '문화관광축제 - 대구',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_tourism1.png',
        '/images/project/img_highlight_tourism2.png',
        '/images/project/img_highlight_tourism3.png',
      ],
      source: '/images/project/img_thumnail_tourism_dg.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 21,
    name: '통일교육원',
    category: [CATEGORY.META],
    period: '2022.06.',
    // period: "2022.06. - 2022.12.",
    image: '/images/project/img_thumnail_unificationeducation.png',
    playLink: 'https://www.roblox.com/ko/games/10762392256/uniedu',
    detail: {
      title: '통일교육원',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_unificationeducation1.png',
        '/images/project/img_highlight_unificationeducation2.png',
        '/images/project/img_highlight_unificationeducation3.png',
        '/images/project/img_highlight_unificationeducation4.png',
        '/images/project/img_highlight_unificationeducation5.png',
        '/images/project/img_highlight_unificationeducation6.png',
      ],
      source: '/images/project/img_thumnail_unificationeducation.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 20,
    name: '2022 사라진 마을',
    category: [CATEGORY.META],
    period: '2022.04.',
    // period: "2022.04. - 2022.12.",
    image: '/images/project/img_thumnail_losttown.png',
    playLink: 'https://www.universe.go.kr/main',
    detail: {
      title: '2022 사라진 마을',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_losttown1.png',
        '/images/project/img_highlight_losttown2.png',
        '/images/project/img_highlight_losttown3.png',
        '/images/project/img_highlight_losttown4.png',
        '/images/project/img_highlight_losttown5.png',
        '/images/project/img_highlight_losttown6.png',
      ],
      source: '/images/project/img_thumnail_losttown.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 18,
    name: 'Mirom',
    category: [CATEGORY.VE],
    period: '2022.04.',
    // period: "2022.04. - 2022.08.",
    image: '/images/project/img_thumnail_mirom.png',
    playLink: null,
    detail: {
      title: 'Mirom',
      subTitle: 'Virtual Exhibition',
      HighlightImg: [
        '/images/project/img_highlight_mirom1.png',
        '/images/project/img_highlight_mirom2.png',
        '/images/project/img_highlight_mirom3.png',
        '/images/project/img_highlight_mirom4.png',
        '/images/project/img_highlight_mirom5.png',
        '/images/project/img_highlight_mirom6.png',
      ],
      source: '/images/project/img_thumnail_mirom.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 16,
    name: '평화지도',
    category: [CATEGORY.META],
    period: '2021.12.',
    // period: "2021.12. - 2022.04.",
    image: '/images/project/img_thumnail_peacemap.png',
    playLink: 'https://peacemap.universe.go.kr/main.html',
    detail: {
      title: '평화지도',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_peacemap1.png',
        '/images/project/img_highlight_peacemap2.png',
        '/images/project/img_highlight_peacemap3.png',
        '/images/project/img_highlight_peacemap4.png',
        '/images/project/img_highlight_peacemap5.png',
        '/images/project/img_highlight_peacemap6.png',
      ],
      source: '/images/project/img_thumnail_peacemap.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 15,
    name: 'CJ Logistics',
    category: [CATEGORY.DT],
    period: '2021.08.',
    image: '/images/project/img_thumnail_cj.png',
    playLink: null,
    detail: {
      title: 'CJ Logistics',
      subTitle: 'Smart Fatory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_cj.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 14,
    name: 'DMZ Universe',
    category: [CATEGORY.META],
    period: '2021.08.',
    // period: "2021.08. - 2022.02.",
    image: '/images/project/img_thumnail_dmzuniverse.png',
    playLink: 'https://universe.go.kr/main',
    detail: {
      title: 'DMZ Universe',
      subTitle: 'Metaverse',
      HighlightImg: [
        '/images/project/img_highlight_universe1.png',
        '/images/project/img_highlight_universe2.png',
        '/images/project/img_highlight_universe3.png',
        '/images/project/img_highlight_universe4.png',
        '/images/project/img_highlight_universe5.png',
        '/images/project/img_highlight_universe6.png',
      ],
      source: '/images/project/img_thumnail_dmzuniverse.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 13,
    name: 'K-water',
    category: [CATEGORY.DT],
    period: '2021.07.',
    image: '/images/project/img_thumnail_kwater.png',
    playLink: null,
    detail: {
      title: 'K-water',
      subTitle: 'Smart Fatory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_kwater.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 25,
    name: 'Shinsung ENG',
    category: [CATEGORY.DT],
    period: '2023.01.',
    image: '/images/project/img_thumnail_shinsung.png',
    playLink: null,
    detail: {
      title: 'Shinsung ENG',
      subTitle: 'Smart Fatory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_shinsung.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 19,
    name: 'Winnercom',
    category: [CATEGORY.DT],
    period: '2022.04.',
    image: '/images/project/img_thumnail_winnercom.png',
    playLink: null,
    detail: {
      title: 'Winnercom',
      subTitle: 'Smart Fatory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_winnercom.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 17,
    name: 'Neuromeka',
    category: [CATEGORY.DT],
    period: '2022.01.',
    image: '/images/project/img_thumnail_neuromeka.png',
    playLink: null,
    detail: {
      title: 'Neuromeka',
      subTitle: 'Smart Fatory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_neuromeka.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 12,
    name: 'Korea Electric Terminal',
    category: [CATEGORY.DT],
    period: '2021.06.',
    image: '/images/project/img_thumnail_ket.png',
    playLink: null,
    detail: {
      title: 'Korea Electric Terminal',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_ket.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 11,
    name: '강남역',
    category: [CATEGORY.DT],
    period: '2021.03.',
    image: '/images/project/img_thumnail_gangnam.png',
    playLink: null,
    detail: {
      title: '강남역',
      subTitle: 'Smart City',
      HighlightImg: [
        '/images/project/img_highlight_gangnam1.png',
        '/images/project/img_highlight_gangnam2.png',
        '/images/project/img_highlight_gangnam3.png',
      ],
      source: '/images/project/img_thumnail_gangnam.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 10,
    name: 'LG Electronics Production engineering Research Institute',
    category: [CATEGORY.DT],
    period: '2020.12.',
    image: '/images/project/img_thumnail_lg_elc.png',
    playLink: null,
    detail: {
      title: 'LG Electronics Production engineering Research Institute',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_lg_elc.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 9,
    name: '상명대학교',
    category: [CATEGORY.DT],
    period: '2020.10.',
    image: '/images/project/img_thumnail_smu.png',
    playLink: null,
    detail: {
      title: '상명대학교',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_smu.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 8,
    name: 'Samsung Electro-Mechanics',
    category: [CATEGORY.DT],
    period: '2020.08.',
    image: '/images/project/img_thumnail_samsung_elc.png',
    playLink: null,
    detail: {
      title: 'Samsung Electro-Mechanics',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_samsung_elc.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 7,
    name: 'Shinwoo Costec',
    category: [CATEGORY.DT],
    period: '2020.08.',
    image: '/images/project/img_thumnail_shinwoo.png',
    playLink: null,
    detail: {
      title: 'Shinwoo Costec',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_shinwoo.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 6,
    name: 'Nestfield',
    category: [CATEGORY.DT],
    period: '2020.08.',
    image: '/images/project/img_thumnail_nestfield.png',
    playLink: null,
    detail: {
      title: 'Nestfield',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_nestfield.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 5,
    name: '주차장',
    category: [CATEGORY.DT],
    period: '',
    image: '/images/project/img_thumnail_parking.png',
    playLink: null,
    detail: {
      title: '주차장',
      subTitle: 'Smart City',
      HighlightImg: [
        '/images/project/img_highlight_parking1.png',
        '/images/project/img_highlight_parking2.png',
        '/images/project/img_highlight_parking3.png',
      ],
      source: '/images/project/img_thumnail_parking.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 4,
    name: 'Daewoo Shipbuilding & Marine Engineering Co., Ltd.',
    category: [CATEGORY.DT],
    period: '2020.06.',
    image: '/images/project/img_thumnail_dsme.png',
    playLink: null,
    detail: {
      title: 'Daewoo Shipbuilding & Marine Engineering Co., Ltd.',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_dsme.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 3,
    name: 'Korens',
    category: [CATEGORY.DT],
    period: '2020.06.',
    image: '/images/project/img_thumnail_korens.png',
    playLink: null,
    detail: {
      title: 'Korens',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_korens.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 2,
    name: 'Samsung Display',
    category: [CATEGORY.DT],
    period: '2020.04.',
    image: '/images/project/img_thumnail_samsung_display.png',
    playLink: null,
    detail: {
      title: 'Samsung Display',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_samsung_display.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 1,
    name: 'NEDEC',
    category: [CATEGORY.DT],
    period: '2019.10.',
    image: '/images/project/img_thumnail_nedec.png',
    playLink: null,
    detail: {
      title: 'NEDEC',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_nedec.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
  {
    id: 0,
    name: '한국생산기술연구원 (KITECH)',
    category: [CATEGORY.DT],
    period: '2019.07.',
    image: '/images/project/img_thumnail_kitech.png',
    playLink: null,
    detail: {
      title: '한국생산기술연구원 (KITECH)',
      subTitle: 'Smart Factory',
      HighlightImg: null,
      source: '/images/project/img_thumnail_kitech.png',
      sourceType: SOURCE_TYPE.IMG,
    },
  },
];
