import { Blog, BlogImg, BlogCategory } from '../util/type/blogType';

export const initialBlogs: Blog[] = [
  // {
  //   id: 'blog_smart_manufacturing',
  //   title: 'Smart Manufacturing',
  //   content: `

  //           <img src="images/blog/post-1.png">

  //           <p >
  //             With the advent of the fourth industry revolution, global manufacturing
  //             companies are developing smart manufacturing systems based on Information
  //             and Communication Technology (ICT) and reporting various best practices.
  //           </p>
  //           <p >
  //             The competition is fierce for preoccupying the manufacturing advantage.
  //             In Germany, the manufacturing powerhouse, the government is leading
  //             the Public Private Partnerships (PPP) project called Industry 4.0.
  //             Through connected factories, it is making a leading attempt to create
  //             value and business models that have not existed until now by converging
  //             Internet of Things (IoT) and Internet of Services (IoS) with the manufacturing
  //             industry. The final result of Industry 4.0 is implemented as a smart factory.
  //             In the United States, the Advanced Manufacturing Partnership (AMP) has been
  //             initiated with recommendations of the President’s Council of Advisors
  //             on Science and Technology (PCAST). Smart Manufacturing Leadership Coalition
  //             (SMLC) and National Institute of Standards and Technology (NIST) are conducting
  //             active research on smart manufacturing.
  //           </p>
  //           <p >
  //             The Japanese government has announced Connected Industries as a new concept
  //             framework to achieve Society 5.0. The main policies in the manufacturing
  //             sector are composed of four types: smart factory pilot project, Industrial
  //             Internet of Things (IIoT) platform connection, startup factory construction
  //             support, and test bed pilot project. China aims to advance its economic
  //             competitiveness by advancing China’s position in the global manufacturing
  //             value chain through three phases over next thirty years with a broad umbrella
  //             industrial plan called Made in China 2025 (MIC 2025). In Korea, the Manufacturing
  //             Industry Innovation 3.0 strategy was introduced, and the Korean government is
  //             promoting business of developing core technology for smart manufacturing and
  //             expending smart factories.
  //           </p>
  //           <p >
  //             The SMLC definition states, “smart manufacturing is the intensified application
  //             of advanced intelligence system to enable rapid manufacturing of new product,
  //             dynamic response to product demand, and real-time optimization of manufacturing
  //             production and supply chain network.” NIST defines smart manufacturing as
  //             “fully-integrated, collaborative manufacturing systems that respond in real time
  //             to meet changing demands and conditions in the factory, in the supply network,
  //             and in customer needs.”
  //           </p>

  //               <img src="images/blog/post-1-2.svg">

  //           <p >
  //             The smart manufacturing system is built on the latest ICT technologies and standards.
  //             Figure 1 shows the concept of smart manufacturing system that all manufacturing company
  //             functions including raw material management, production, logistics, services, and products
  //             are connected to the network via an integrated system, and production is controlled
  //             through the Cyber-Physical Product System (CPPS). CPPS consists of a virtual factory
  //             model connected to a real production site that continuously exchanges information with
  //             enterprise software through the Internet of Service (IoS), distributed devices, and
  //             equipment (IoT). The analysis function of CPPS can generate dynamic plans to optimally
  //             control the entire production process.
  //           </p>
  //           <p >
  //             Many standards for smart manufacturing system are being developed around IoS and IoT.
  //           </p>
  //           <p >
  //             In the IoS sector, standard development is in progress centering on representative
  //             systems used by manufacturing companies, and the maturity of related standards is high
  //             because the utilization is already very high in manufacturing companies. STEP 242 is
  //             a representative standard for Product and Enterprise Resource Planning (ERP), and ISA-95
  //             is representative for Manufacturing Execution System (MES).
  //           </p>
  //           <p >
  //             Table 1 summarizes the representative functions of PLM, ERP, SCM, and MES related to IoS.
  //           </p>

  //               <img src="images/blog/post-1-3.svg">

  //               <p class="blog-page-text-explain">
  //                 <a href="https://www.nist.gov/publications/analysis-technologies-and-standards-designing-smart-manufacturing-systems" target="_blank" style="color: blue;">
  //                   https://www.nist.gov/publications/analysis-technologies-and-standards-designing-smart-manufacturing-systems
  //                 </a>
  //               </p>

  //           <p >
  //              In the IoT sector, there is ISO/IEC 30141, which defines the IoT reference structure
  //              proposed by China, and IEEE 802.24 is also a representative standard activity.
  //              The “IEC SG8 Industry 4.0- Smart Manufacturing” strategic group compose mainly of
  //              European countries and the United States is actively promoting international
  //              standardization activities for the Internet of Things (IoT) architecture framework
  //              IEEE P2413-Standard for an Architectural Framework for the Internet of Things (IoT)
  //              and mutual cooperation. oneM2M has been formed to improve the reality that products
  //              are not compatible internationally due to the jagged M2M standards by region.
  //              At Supervisory Control And Data Acquisition (SCADA) and device level, standards such as
  //              OPC UA, MTConnect, PackML, and BatchML are being used.
  //           </p>
  //           <p >
  //             IEC 62264 is an international standard for enterprise control system integration that is
  //             based on ISA 95. IEC 62264 defines an activity model, a functional model, and an object
  //             model belonging to the Manufacturing Operations Management (MOM) domain. ISO 22400 defines
  //             key performance indicators (KPIs) used in manufacturing management.
  //           </p>

  //           <p >
  //             4차 산업혁명 시대의 도래로 글로벌 기업들은 최신 정보통신기술(Information and Communication Technology: ICT)을 기반으로 스마트 제조 시스템을 개발하고 있으며, 다양한 베스트 프랙티스들을 발표하고 있다.
  //           </p>
  //           <p >
  //             제조 우위를 선점하기 위한 국가의 경쟁 또한 치열하다. 제조 강국 독일에서는 인더스트리 4.0이라 불리는 민-관-학 프로젝트를 정부주도로 추진하고 있다. 연결된 공장을 통해 사물 인터넷과 인터넷 서비스를 제조업과 융합하여 지금까지는 없었던 가치와 비즈니스 모델을 창출하기 위한 선도적 시도를 하고 있다. 인더스트리 4.0의 최종 결과물은 스마트 팩토리 (Smart Factory)로 구현된다. 미국은 대통령 과학기술자문위원회(President’s Council of Advisors on Science and Technology: PCAST)의 권고로 첨단 제조 파트너십 프로그램을 발족시켰다. 스마트 제조 리더십 연합체(Smart Manufacturing Leadership Coalition: SMLC)와 미국표준기술연구소(National Institute of Standards and Technology: NIST)를 중심으로 스마트 제조에 대한 활발한 연구를 진행하고 있다.
  //           </p>
  //           <p >
  //             일본은 소사이어티 5.0, 커넥티드 인더스트리즈 (Connected Industries) 정책을 발표하였다. 제조 분야의 주요 정책은 스마트 팩토리 시범사업, IIoT(Industrial Internet of Things) 플랫폼 연계, 스타트업 팩토리 구축 지원, 테스트베드 시범사업 등 4가지로 구성된다. 중국은 제조업 활성화와 제조 강국 건설을 위하여 향후 30년간 3단계 제조업 혁신을 통해 세계 제조업 선도국가 지위 확립하는 것이 목표로 제조 2025를 추진하고 있다. 한국도 제조 3.0을 추진하며 정부 주도로 스마트 제조 원천 기술 개발, 스마트 팩토리 보급 확산 사업을 추진하고 있다.
  //           </p>
  //           <p >
  //             SMLC는 스마트 제조를 ‘신제품의 신속한 제조, 제품 수요의 적극적 대응, 생산 및 공급사슬망의 실시간 최적화를 가능하게 하는 첨단 지능형 시스템들의 심화 적용 (intensified application)’이라 정의했고, NIST는 ‘공장, 공급 네트워크 및 고객 요구의 변화하는 요구와 조건을 충족하기 위해 실시간으로 대응하는 완전 통합되어 있는 협업적인 제조 시스템’ 이라고 정의했다.
  //           </p>
  //           <p >
  //             스마트 제조 시스템은 최신의 ICT 기술과 표준들을 기반으로 구축된다. [그림 1]은 원자재 관리, 생산, 물류, 서비스 및 제품을 포함한 모든 제조 기업 기능이 통합 시스템으로 네트워크에 연결되고, 생산이 사이버-물리 생산 시스템(Cyber-Physical Production System: CPPS)을 통해 제어되는 스마트 제조 시스템의 개념을 보여준다. CPPS는 분산 소프트웨어 서비스 (Internet of Service: IoS) 및 분산 장치 및 장비 (IoT)를 통해 엔터프라이즈 소프트웨어와 지속적으로 정보를 교환하는 실제 생산 현장과 연결된 가상의 팩토리 모델로 구성된다. CPPS의 분석 기능은 전체 생산 프로세스를 최적으로 제어하기 위한 동적 계획을 생성할 수 있다.
  //           </p>
  //           <p >
  //             스마트 제조 시스템을 위한 많은 표준들이 IoS와 IoT를 중심으로 개발되고 있다.
  //           </p>
  //           <p >
  //             IoS 영역에서는 제조기업에서 활용되는 대표 시스템들을 중심으로 표준 개발이 진행되고 있으며, 이미 제조 기업에서 그 활용도가 매우 높기에 관련 표준의 성숙도 또한 높은 편이다. PLM(Product Lifecycle Management)은 STEP 242이 대표적 표준이고, SCM(Supply Chain Management), ERP(Enterprise Resource Planning)와 관련해서는 OAGIS, SCOR가 있으며, MES(Manufacturing Execution System)와 관련해서는 ISA-95가 대표적이다.
  //           </p>
  //           <p >
  //             [표 1]은 IoS와 관련한 PLM, ERP, SCM, MES에 대한 대표적인 기능들을 정리한 것이다.
  //           </p>
  //           <p >
  //             IoT 영역에서는 중국에서 제안한 사물인터넷 참조구조를 정의하는 ISO/IEC 30141가 있으며, IEEE 802.24도 대표적인 표준 활동이다. 유럽과 미국을 중심으로 구성된 “IEC SG8 Industry 4.0-Smart Manufacturing” 전략그룹은 현재 활발하게 사물인터넷(IoT) 아키텍처 프레임워크 국제 표준화 활동을 추진하고 있는 IEEE P2413- Standard for an Architectural Framework for the Internet of Things(IoT)와 상호협력을 추진하고 있다. oneM2M은 지역별로 들쭉날쭉한 M2M 표준 때문에 국제적으로 제품을 호환할 수 없는 현실을 개선하기 위해 결성했다. SCADA(Supervisory Control And Data Acquisition) 및 장치 레벨에서는 OPC UA, MTConnect, PackML 및 BatchML과 같은 표준들이 활용되고 있다.
  //           </p>
  //           <p >
  //             IEC 62264는 기업 제어 시스템 통합을 위한 국제 표준이며 ISA 95를 토대로 한다. IEC 62264는 MOM(Manufacturing Operations Management) 도메인에 속한 활동 모델과 기능 모델, 객체 모델을 정의한다. ISO 22400 제조업 관리에 사용되는 핵심 성능 지표(KPI)를 정의하고 있다.
  //           </p>
  //           <p class="blog-page-writer">
  //             Written by Dr. SangSu Choi
  //           </p>

  //       `,
  //   createDate: 'Posted on May 3, 2021',
  //   lastModifiedDate: '2024-07-04T06:19:39.879Z',
  //   thumbnailUrl: null,
  //   category: BlogCategory.TECH,
  //   imgUrls: [],
  // },
  {
    id: '85',
    title: 'CES 2025 전시 참가!',
    content: `
              <img src="/images/blog/post85/post85_1.png" />
              <p>안녕하세요.</p>
              <p>지난 베트남 전시 후기에서 ‘다음은 OnTwins가 어디로 진출할지 기대해 주세요~’라는 멘트를 남겼는데,</p>
              <p>그다음이 미국일 거라고는 생각도 못 했습니다!!</p><br/>
              <p>이번에 좋은 기회를 얻어 라스베가스로 날아가 CES 2025에 참가하게 되었답니다.😊</p><br/>
              <p>CES(Consumer Electronics Show)는 전자기기 및 혁신적인 기술을 소개하는 전 세계에서 가장 큰 박람회 중 하나로 매년 1월 미국 라스베가스에서 열려요.</p>
              <p>이번 CES 2025는 ‘DIVE IN’을 테마로 AI, 로보틱스, 모빌리티, 스마트홈, 디지털 헬스 등을 핵심 키워드로 160개 국가에서 참여한다고 해요.</p><br/>
              <p>CES 2025에서 IGI Korea의 여정, 시작합니다!</p>
              <img src="/images/blog/post85/post85_2.png" />
              <img src="/images/blog/post85/post85_3.png" />
              <p>전시 첫날 셋팅 부스 셋팅하고 난 뒤의 인증샷!</p>
              <p>이번 출장에는 대표님, 부사장님, Y이사님, A프로가 함께했습니다ㅎㅎ</p><br/>
              <p>CES 2025전시장은 크게 라스베가스 컨벤션센터(LVCC)와 Venetian Expo에서 진행되는데요.</p>
              <p>LVCC에는 자율 주행, AI, 스마트홈 등 다양한 최신 기술을 선보이는 주요 기업들의 부스가 있고, Venetian Expo에는 스타트업 및 국가관 위주로 이루어져 있습니다.</p><br/>
              <p>저희는 서울경제진흥원이 구성한 서울통합관에서 서강대 소속으로 참석하게 되었어요!</p>
              <p>서울 통합관은 이번에 역대 최대 규모로 총 16개의 기관에서 104개사 기업, 그리고 103명의 서포터즈들이 참여하였다고 해요.</p><br/>
              <p>저희 부스에도 서포터즈 한 분이 배정되어 전시기간 동안 함께했는데요.</p>
              <p>저희 담당 서포터즈 분은 영어도 잘하시고 매우 유능한 인재였어요! 덕분에 전시가 원활하게 진행될 수 있었고 많은 도움이 되었어요ㅎㅎ</p>
              <img src="/images/blog/post85/post85_4.png" />
              <img src="/images/blog/post85/post85_5.png" />
              <p>최대 규모의 박람회답게 정말 많은 방문객이 몰려와 현장의 열기를 실감할 수 있었어요!</p>
              <p>이번 CES에는 총 14만 1천 명 이상이 방문했고, 지난해보다 5%가량 늘어난 수준이라고 해요.</p><br/>
              <p>첫날에는 메인 홀인 LVCC로 관람객들이 집중되었지만, 2일 차부터는 유레카 파크(Venetian Expo)에도 많은 사람들이 방문했어요.</p><br/>
              <p>저희는 이번 전시에서 디지털트윈, 메타버스, VR 콘텐츠를 준비하여 관람객들에게 저희 솔루션을 소개했어요!</p>
              <p>디지털 트윈 분야에서는 ‘OSS’라는 디지털트윈 저작 스튜디오를 활용하여 쉽게 레이아웃을 배치하고 데이터 연동하는 과정을 보여드리고 실시간 데이터를 모니터링할 수 있는 데모를 선보였어요.</p>
              <p>그리고 실제 구축된 사례들을 통해 저희 솔루션이 다양한 산업에 적용 및 활용되고 있는지 설명해 드렸답니다.</p><br/>
              <p>메타버스 분야에서는 초중고 학생들을 대상으로 한 물리, 지구과학 등의 교육 콘텐츠를 준비했어요.</p>
              <p>메타버스 교육 콘텐츠는 가상공간에서 미션과 미니게임을 통해 학생들이 재밌고 쉽게 이론을 학습할 수 있도록 구성되어 실제로 선생님들이 많이 활용하고 계신답니다.</p><br/>
              <p>VR 콘텐츠는 이번에 통일부에서 ‘업무 유공 장관상’을 수상한 프로젝트로 북한 학생의 하루를 체험할 수 있는 특별한 데모를 준비했어요.</p>
              <p>관람객들은 VR을 통해 북한 학생이 되어 북한의 일상적인 환경과 학교생활을 직접 경험하며, 북한의 교육과 문화를 생생하게 체험할 수 있었어요! 많은 분이 신기해하며 흥미를 가지셨어요.</p>
              <img src="/images/blog/post85/post85_6.png" />
              <img src="/images/blog/post85/post85_7.png" />              
              <p>부스에서 활동뿐만 아니라, 서울통합관에서 주최하는 IR 피칭에도 참여하여 OnTwins를 더 많은 사람들에게 알릴 수 있는 기회를 가졌어요!</p>
              <p>발표는 Y이사님께서 준비해주셨는데, 영어로 발표를 진행해야 해서 조금 걱정도 되었지만 실제로 발표를 시작하니 떨지 않으시고 너무나 잘하시더라구요.</p>
              <p>역시역시 믿고있었다구요 이사님!👍👍👍</p><br/>
              <p>발표 시간이 10분 이내로 길지 않았기 때문에, OnTwins의 핵심 포인트를 잘 강조할 수 있도록 준비했습니다.</p>
              <p>특히 M프로님의 뛰어난 디자인과 Y이사님의 매끄러운 발표 덕분에 짧은 시간 안에 OnTwins의 강점을 효과적으로 전달할 수 있었던 것 같아요.</p>
              <p>두 분 덕분에 발표가 성공적으로 마무리되었고, 많은 분이 관심을 가져주셔서 정말 뜻깊은 시간이었어요.</p>
              <p>이번 발표를 통해 OnTwins를 더 널리 알릴 수 있어 정말 기뻤습니다!</p>
              <img src="/images/blog/post85/post85_8.png" />
              <img src="/images/blog/post85/post85_9.png" />   
              <img src="/images/blog/post85/post85_10.png" />
              <img src="/images/blog/post85/post85_11.png" />   
              <p>전시 일정을 소화하면서 지친 몸은 저녁 메뉴로 싸악 풀렸습니다..!</p>
              <p>요즘 미국 환율도 높고 물가도 정말 비쌌지만… 먹은 음식들이 너무 맛있었어요.</p>
              <p>베트남쌀국수는 비싼 만큼 푸짐한 양.. 고기가 먹어도 먹어도 사라지지 않더라구요.</p>
              <p>한식을 먹을 때는 한국이 전혀 그립지 않을 정도로 만족감이 있었어요ㅎㅎㅎ</p><br/>
              <p>갔던 고깃집은 고기뷔페로 먹었는데, 요즘 고물가 시대에 가성비 최고최고!!</p>
              <p>고기도 맛있었지만, 특히 곱창이 한국에서도 보기 힘든 곱이 꽉 찬 곱창이더라구요. 찐 맛집이었어요.</p><br/>
              <p횟집도 한국에서 먹는 것처럼 신선하고 푸짐해서 감탄이 절로 나왔네요. 기본 반찬도 다양하고 고퀄..! 사장님 손맛이 좋으시더라구요. 여기도 정말 찐맛집 인정입니다.</p>
              <p>매일 저녁에 맛있는 음식을 먹으면서 더 열심히! 힘낼 수 있었던 거 같아요.</p>  
              <img src="/images/blog/post85/post85_12.png" />              
              <p>세계 최대 규모의 전시인 만큼, 부스에만 있을 순 없죠!</p>  
              <p>다행히 서포터즈 분이 계셔서 Y이사님과 함께 로테이션으로 돌아가며 CES 전시를 구경할 수 있었어요.</p>  
              <p>그렇게 둘러보다가 우연히 고객사 부스에서 저희가 개발한 정수장 관리 디지털트윈 이미지가 전시된 걸 발견하게 되었어요. 정말 뿌듯하더라구요!</p>              
              <p>우리가 참여했던 프로젝트가 CES 2025라는 큰 전시에서 소개되다니…. 감격스러운 순간이었어요.ㅎㅎ</p>  
              <img src="/images/blog/post85/post85_13.png" />   
              <img src="/images/blog/post85/post85_14.png" />   
              <img src="/images/blog/post85/post85_15.png" />   
              <img src="/images/blog/post85/post85_16.png" />   
              <p>LVCC의 Central Hall에서 가장 눈에 띄었던 두 부스는 바로 삼성과 LG!!</p>  
              <p>개인적으로 두 부스 모두 내용과 구성이 좋았어요.</p>  
              <p>두 브랜드 모두 세계적인 가전 브랜드지만, 제품을 홍보하고 풀어내는 방식에서 차이가 있었는데요, 그 점도 흥미로웠어요.</p><br/>
              <p>삼성은 차분한 톤으로 SmartThings라는 기술을 중심으로 다양한 스마트 기기들이 어떻게 하나로 연결되는지, 내 삶을 어떻게 편리하게 하는지를 보여주는데</p>
              <p>저도 순간 혹해서 모든 전자제품을 삼성으로 맞춰야 하나..? 라는 생각을 잠시 했어요ㅎㅎㅎ</p><br/>
              <p>반면, LG는 훨씬 친근한 톤으로 고객들에게 다가가는 느낌이었어요. 아기자기한 캐릭터들로 집을 구경하는 컨셉이었는데, 그중 가장 인상 깊은 건 신제품인 AI TV를 소개하는 부분이었어요.</p>
              <p>TV가 전시된 벽면도 디스플레이로 활용해서 AI TV의 기능을 설명하는데 화려하고 집중하게 되더라구요!</p>
              <img src="/images/blog/post85/post85_17.png" />   
              <img src="/images/blog/post85/post85_18.png" />   
              <img src="/images/blog/post85/post85_19.png" />   
              <img src="/images/blog/post85/post85_20.png" />               
              <p>LVCC Central Hall 한쪽 편에는 VR/AR존이 따로 있을 정도로 VR 기기를 활용한 다양한 콘텐츠들이 많이 전시되었어요.</p>
              <p>반면에 메타버스 관련 콘텐츠는 롯데의 칼리버스를 제외하고는 거의 보지 못했어요.</p><br/>
              <p>소니에서는 디지털트윈을 결합한 차량용 프로세싱 시스템을 선보였는데, 저희는 주로 제조업체를 대상으로 공장 등을 디지털트윈화하여 모니터링, 분석하는데 소니에서는 차량을 디지털트윈화 하여 가상환경에서 차량을 촬영하는 데 중점을 둔 부분이 색달랐어요.</p>
              <p>그 외 새로운 헤드셋과 콘텐츠 제작 시스템을 선보였다고 해서 한번 착용해봤습니다ㅎㅎ</p><br/>
              <p>칼리버스에서는 3D 영상관과 VR 기기를 활용해 콘텐츠를 체험할 수 있었는데, 실사와 3D 그래픽을 혼합해 더 몰입감 있는 경험을 제공하려는 시도가 인상 깊었어요.</p>
              <p>특히, K-POP과 투모로우랜드 콘서트로 일반 사람들도 쉽게 즐길 수 있는 콘텐츠를 준비했다는 점도 눈여겨볼 만한것 같아요.</p>
              <img src="/images/blog/post85/post85_21.png" />   
              <img src="/images/blog/post85/post85_22.png" />  
              <p>이번 CES 2025의 핵심 키워드 중 AI, 로보틱스, 모빌리티가 있었는데 로봇, 자동차, 자율주행 관련 부스들도 눈에 많이 띄었어요.</p><br/>
              <p>그중에서도 인공지능(AI)은 이제 어디서든 빠질 수 없는 핵심 키워드가 된 것 같아요.전시를 둘러보면서 AI가 적용되지 않은 분야를 찾는 게 더 어려울 정도였어요.</p>                     
              <img src="/images/blog/post85/post85_23.png" />   
              <img src="/images/blog/post85/post85_24.png" />                
              <p>확실히 세계 최대 규모 박람회답게 수많은 기업과 기술들이 관람객들의 주목을 받기 위해 경쟁하고 있었는데, 이 속에서 저희도 OnTwins의 강점을 효과적으로 전달하기 위한 전략이 필요하다는 것을 느꼈어요.</p>
              <p>관람객의 눈길을 끌 수 있는 시각적 임팩트와 고객이 흥미를 느낄만한 데모 시나리오가 중요할 것 같아요.</p><br/>
              <p>그리고 AI는 이제 빠질 수 없는 요소인 것 같아요. 다음에는 AI를 활용한 OnTwins의 기능과 사례들을 더 어필해야겠다는 생각이 들었습니다!</p>
              <img src="/images/blog/post85/post85_25.png" />   
              <img src="/images/blog/post85/post85_26.png" />  
              <p>라스베가스는 저 멀리 보이는 돌산과 사막 특유의 황량함이 고요하면서 차분한 멋을 느끼게 해주는 반면, 호텔 내부는 화려하고 꿈속에 있는 듯한 분위기가 매력적이었어요.</p>
              <p>이처럼 대조적인 매력이 어우러진 라스베가스에서 열린 CES 2025는 저희에게도 특별한 경험을 선사했는데요.</p><br/>
              <p>이번 CES 2025 참가를 통해 글로벌 무대에서 OnTwins의 가능성을 확인할 수 있었고, 앞으로 더 많은 도전을 할 수 있는 자신감을 얻을 수 있었습니다!</p>
              <p>다음 도전도 많이 기대해 주시고 응원해 주세요!</p>
              `,
    createDate: 'Posted on Jan 24, 2025',
    lastModifiedDate: '2024-07-04T06:19:39.879Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '84',
    title: '2025 신년 인사',
    content: `
              <img src="/images/blog/post84/post84_1.png" />
              <p>(주)아이지아이 코리아와 관계된 고객님들, 주주님들, 그리고 임직원 여러분 모두 2024년 한 해 정말 감사했습니다.</p>
              <p>2025년 을사년 새해 복 많이 받으시고, 건강하시고, 계획하시는 모든 일 이루시길 기원합니다.</p><br/>
              <p>저희 회사는 글로벌 경제 침체 속에서도 디지털 트윈, 메타버스 시장에서 자체 솔루션인 OnTwins를 기반으로 성장해 나가고 있으며,</p>
              <p>올해는 디지털 트윈은 호주와 미국으로 진출하고, 메타버스는 교육 분야로 진출하여 최대 매출 달성이 기대되고 있습니다.</p><br/>
              <p>2027년 IPO를 위해 열심히 노력하고 있습니다. 앞으로도 많은 관심과 응원 부탁 드리겠습니다.</p>
              <p>감사합니다.</p><br/>
              <p>대표이사 최상수</p>
              `,
    createDate: 'Posted on Jan 3, 2025',
    lastModifiedDate: '2024-07-04T06:19:39.879Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '83',
    title: 'IGI Korea 2024 PTV 2부',
    content: `
              <img src="/images/blog/post83/post83_1.png" />
              <p>PTV Day 후기 2부 시작합니다!</p>
              <p>팀 발표가 끝나고, 이어서 프로젝트 발표가 진행되었어요.</p></br>
              <p>2024년 한 해 많은 프로젝트를 진행 했었는데요, 그 중 5개의 프로젝트들에 참여했던 직원분들의 발표가 있었어요.</p>
              <img src="/images/blog/post83/post83_2.png" />
              <p>먼저 인공호흡기 프로젝트 발표입니다. 이 프로젝트는 인공호흡기 관련 교육을 웹 가상공간에서 진행할 수 있도록 하는 교육 콘텐츠예요.</p></br>
              <p>개발을 맡으신 J프로님이 발표를 해주셨어요.</p>
              <p>이번년도 입사하셨는데, 프로젝트를 수행하면서 배운점과 개발쪽에서 어려움을 겪었던 부분, 3D파트 쪽에서 어려움을 겪었던 부분을 공유해주셨어요.</p>
              <p>J프로님은 특히 3D팀과 긴밀하게 협업하면서 3D 쪽 경험을 많이 쌓으셨다고 해요.</p></br>
              <p>3D 쪽 경험을 많이 얻으신 J프로님, 마스터가 되는 그날까지.. 응원합니다ㅎㅎ!</p>
              <img src="/images/blog/post83/post83_3.png" />
              <p>다음은 심리치료 프로젝트입니다.</p>
              <p>이 프로젝트는 VR을 활용하여 가상공간속에서 심리치료를 진행하는 프로그램이에요.</p></br>
              <p>발표는 3D 디자이너신 Y프로님이 맡아주셨는데, 귀여운 이미지를 사용해서 마치 하나에 이야기를 듣는 듯한 느낌이었어요.</p>
              <p>이 프로젝트는 개발팀, 3D팀에겐 여러 방면으로 새로운 도전이었는데, Y프로님이 그 여정을 잘 설명해 주셔서 하나의 웅장한 서사를 듣는 듯 했어요ㅎㅎ</p><br/>
              <p>프로젝트 참여한 다른 분들도 그렇지만 Y프로님은 특히 고객들을 대하는 자세를 배울 수 있었고 기술적으로도 많은 도움이 되었다고 합니다.</p>
              <img src="/images/blog/post83/post83_4.png"/>
              <p>다음은 금연치료 프로젝트입니다.</p>
              <p>이 프로젝트는 모바일 앱과 메타버스를 활용하여 금연치료를 지원하는 프로그램이에요.</p><br/>
              <p>발표는 개발자이신 H프로님이 맡아주셨어요.</p>
              <p>프로젝트 설명을 들으면서 방대한 양에 놀랐습니다..!</p>
              <p>짧은 기간동안 이 많은 양을 작업하신 프로젝트 참여 직원 분들 정말 대단해요..!</p>
              <p>공간, 기능, 디자인 뭐하나 빠지는 부분이 없는 프로젝트인 것 같습니다.</p><br/>
              <p>H프로님이 참여하신 분들을 병아리로 비유하셨었는데, 프로젝트를 진행하면서 모두 레벨 업업! 슈퍼병아리가 되셨다고 하네요ㅎㅎ</p>
              <img src="/images/blog/post83/post83_5.png"/>
              <p>프로젝트 마지막 순서인 S프로님의 발표입니다!</p>
              <p>이 프로젝트에서는 AI vision을 활용하여 객체를 인식하고 인식한 객체를 디지털 트윈에서 실시간으로 화물들을 수치화, 시각화 하는 과제예요.</p><br/>
              <p>PTV 당시 개발 중이라 결과물보다는 S프로님이 직접 수행하신 과정들을 설명해주셨는데, 네트워크 연결부터 AI vision까지..! 발표로 들었을 땐 간단해 보이지만 실제로 얼마나 힘든 여정을 헤쳐오셨을지..</p>
              <p>AI vision 내용은 개발자 분들에게도 도움이 되었을 것 같아요.</p>
              <img src="/images/blog/post83/post83_6.png"/>
              <p>프로젝트 발표가 끝나고, 상조회 활동에 대한 발표가 있었습니다.</p>
              <p>상조회에서는 경조사는 물론 직원들을 위한 다양한 활동들을 수행하고 있어요.</p><br/>
              <p>특히 PTV Day도 상조회 활동의 일환으로 진행되었는데요.</p>
              <p>저희 블로그를 보신 분은 아시겠지만, 최근에 기부 활동도 진행했었습니다.</p>
              <p>이번 년도 초에는 특히 대학 졸업생분들이 많아 졸업 선물도 증정했었어요.</p>
              <p>앞으로도 더 다양한 활동으로 직원 여러분과 함께할 예정이니 많은 기대 부탁드립니다!</p>
              <img src="/images/blog/post83/post83_7.png"/>
              <img src="/images/blog/post83/post83_8.png"/>
              <p>팀 발표,프로젝트 발표 모두 마치고 부사장님과 대표님의 발표시간입니다..!</p>
              <p>먼저 부사장님께서 발표를 해주셨는데요. 발표 주제가 ‘Cell’ 입니다.</p><br/>
              <p>부사장님께서 단백질, 세포에 대해서 설명해주시는데, 어라… 지금 과학시간인가.. 너무나 오랜만에 들어보는 단백질의 구조와 세포 이름들.. 잠깐동안 학생으로 돌아가 수업을 듣는 듯 했어요.</p><br/>
              <p>근데 세포에 대해 설명해 주시는 이유가 있었습니다..!!</p>
              <p>단백질 합성 시, 핵에서 DNA정보를 받아 각 세포가 업무를 수행하는 과정을 비유로 들어 조직 내에서도 경영 철학이 공유되고 같은 방향을 바라보며 나아가야 한다는 말씀을 하셨습니다.</p>
              <p>모든 팀이 각자 맡은 역할을 수행하고, 이들이 유기적으로 연결되어 하나의 목표를 향해 나아가야한다는 메시지를 전달하신거죠..!</p><br/>
              <p>세포로 비유한 발표는 정말 흥미로웠고, 하나의 목표를 향해 나아갈 수 있도록 모든 팀들이 힘을 모아야 겠죠?!</p>
              <p>앞으로 더욱 성장하는 IGI Korea 기대해 주세요.</p>
              <img src="/images/blog/post83/post83_9.png"/>
              <img src="/images/blog/post83/post83_10.png"/>
              <p>마지막으로 대표님의 발표가 있었습니다.</p>
              <p>두가지 세션으로 진행됐었는데요, 첫번째는 온트윈즈의 과거, 현재, 그리고 미래입니다</p><br/>
              <p>새로 입사하신 분들도 계셨고, 입사했지만 저희 솔루션인 온트윈즈가 탄생된 계기나 목표에 대해 잘 모르시는 직원분들도 많으셨을거라 생각되는데요.</p>
              <p>이번 발표에서 온트윈즈가 어떻게 성장해왔고, 앞으로 어떤 방향으로 나아갈 것 인지에 대해 설명해 주셨습니다.</p>
              <p>이로 인해 직원들이 저희 솔루션인 온트윈즈를 더욱 깊이 이해할 수 있었을 것 같아요.</p><br/>
              <p>그 다음 세션에서는 IGI Korea 2025에 대한 발표가 이어졌습니다.</p>
              <p>이번 발표에서는 올해 회사의 상황과 내년의 계획에 대해 상세히 설명해 주셨고, 결정된 사항들 계획된 사항들에 대해서도 함께 공유해 주셨습니다.</p>
              <p>이를 통해 앞으로 회사가 나아갈 방향과 비전, 목표에 대해서도 다시 한 번 강조해 주셨어요.</p><br/><br/>
              <p>부사장님과 대표님의 발표를 통해 큰 동기부여가 되고, 앞으로의 여정에 대한 기대감이 더욱 커집니다!</p>
              <img src="/images/blog/post83/post83_11.png"/>
              <p>이렇게 모든 발표가 마무리되고…….</p>
              <p>마지막으로 경품추첨 및 시상식이 있었습니다.</p><br/>
              <p>상조회장, 부사장님, 대표님을 이겨라! 가위바위보! 가 있었구요.</p>
              <p>그 다음은 제비뽑기를 통해 경품 증정식이 있었습니다.</p><br/>
              <p>가위바위보 할 때 직원분들의 너무나도 활기찬 모습들에 웃음이 났었어요.</p>
              <p>(여러분들 발표할 땐 굉장히 차분하셨었잖아요..?)</p><br/>
              <p>운이좋은 M이사님은 연속으로 경품을 타가시기도하고..! 럭키럭키</p>
              <p>그리고 지금 이 글을 쓰면서 S프로님의 경품 받으로 방방 앞으로 뛰어오는 귀여운 모습이 다시금 떠오르네요…ㅎㅎㅎㅎ</p>
              <img src="/images/blog/post83/post83_12.png"/>
              <p>매년 PTV Day에서 올해의 직원 시상이 진행됩니다.</p><br/>
              <p>간단히 설명드리자면, 매월 전 직원들의 투표로 이달의 사원을 선정하고, 선정된 이달의 사원들은 올해의 직원 후보로 올라갑니다.</p>
              <p>그리고 PTV 전날에 투표를 통해 최종 후보를 결정하게 돼요!</p><br/>
              <p>2024년 올해의 직원은 A프로님 이었습니다!</p>
              <p>A프로님은 올 한해 많은 업무를 혼자 맡아가며 잘 수행해 주셨어요~</p>
              <p>올해의 직원으로 선정되면 1일 특별휴가와 70만원이 포상금이 주어지는데요, 정말 축하드립니다!</p>
              <img src="/images/blog/post83/post83_13.png"/>
              <p>각 팀끼리 올해의 일들과 내년 비전을 얘기하면서 더욱 단단하고 성장할 수 있는 계기가 된 것 같습니다.</p>
              <p>내년에는 각 팀에서 설정한 목표를 향해 모두 달려갑시다!</p>
              <p>모두 PTV Day를 위해 준비하시느라 고생 많으셨습니다~~</p>
              <img src="/images/blog/post83/post83_14.png"/>
              <p>모든 발표를 마치고… 이젠 허기진 배를 채우는 일이 남아있죠!!</p>
              <p>매우매우 기대하던 저녁식사 장소, 랍스터 무한리필 뷔페 ‘바이킹스워프’!! 영등포 타임스케워점을 방문했어요.</p><br/>
              <p>밖에서 내부가 보이는데 벌써부터 설레는거 있죠..</p>
              <p>싱싱한 회, 신선한 과일들, 랍스타 랍스타 랍스타!!</p>
              <img src="/images/blog/post83/post83_15.png"/>
              <img src="/images/blog/post83/post83_16.png"/>
              <p>영등포 타임스퀘어에 조금 일찍 도착했는데, 바이킹 스워프는 딱 입장시간에 들어갈 수 있더라구요.</p>
              <p>그래서 잠시 기다리면서 인생네컷도 찍고, 바이킹 스워프 옆 가재와도 인증샷 찰칵!</p>
              <img src="/images/blog/post83/post83_17.png"/>
              <p>바이킹 스워프는 예약제라서 모두 예약하기때문에 선착순으로 입장하게돼요.</p>
              <p>발빠른 M이사님 덕분에 맨 앞에 서서 기다리다 1빠로 입장했습니다!>,<</p>
              <img src="/images/blog/post83/post83_18.png"/>
              <img src="/images/blog/post83/post83_19.png"/>
              <img src="/images/blog/post83/post83_20.png"/>
              <p>크게 랍스타존, 해산물존, 그릴존 이렇게 있는데요!(제가 마음대로 이름을 붙였어요)</p><br/>
              <p>그릴존에서는 꼭꼭 치즈랍스타, 갈비를 먹어야 한다고 하더라구요.</p>
              <p>그 외 추가로 전복, 새우  중에 하나 더 고를 수 있어요.</p><br/>
              <p>저희는 입장하자마자 짐을 내려놓고 바로 그릴존 가서 한 접시 떠오고,</p>
              <p>그다음 랍스타존가서 랍스타 한 접시 받아오고</p>
              <p>그다음 해산물존에서 스시, 회, 조개류 등등을 가져왔어요.</p>
              <img src="/images/blog/post83/post83_21.png"/>
              <p>그렇게 완성된 한상차림..!</p>
              <p>진짜 먹을게 너어어어무 많아서 뭐부터 먹어야 할지 결정장애가 오더라구요.!</p>
              <p>일단 랍스타 뷔페니까 랍스타 한입 먹고, 고기도 한입 먹고, 회도 한입 먹고, 하나씩 맛만봐도 금방 배가차오르는…</p><br/>
              <p>사실 메인메뉴들 외에 파스타, 전복죽, 등 반찬이나 다른 뷔페에서도 볼 수 있는 메뉴들도 있었는데 메인메뉴들만 먹어도 금방 배가 차더라구요.</p>
              <img src="/images/blog/post83/post83_22.png"/>
              <p>회 뿐만아니라 가리비, 전복도 회로 먹을 수 있어요. 가서 테이블 번호 말하면 자리에 가져다주더라구요.</p><br/>
              <p>바이킹 스워프는 $110로 꽤 가격이 나가는 곳인데.. 가격이 비싼이유가 있어요.</p>
              <p>랍스터가 무한리필이기도 하고 그 외 다른 해산물들이 다 싱싱하고 맛있더라구요.</p>
              <img src="/images/blog/post83/post83_23.png"/>
              <img src="/images/blog/post83/post83_24.png"/>
              <p>요리만 맛있냐구요? 아니요???? 디저트도 진짜 맛있어요.</p>
              <p>안에 폴바셋이 들어와 있어서 무료로 커피도 마실 수 있구요.</p>
              <p>케이크와 젤라또 아이스크림도 있어 디저트 종류가 풍성합니다.</p><br/>
              <p>배가 너무 불러서 젤라또를 한 컵밖에 못먹은게 아직도 아쉽고 생각이 나네요..</p>
              <img src="/images/blog/post83/post83_25.png"/>
              <p>디저트류 뿐만아니라 착즙쥬스와 과일들이 있는데</p>
              <p>그 중 망고가 진짜.. 베트남에 온 줄 착각하게 할정도로 잘 익었더라구요. </p>
              <p>저희 테이블에서는 망고를 몇 접시를 먹었는지 모를정도입니다ㅎㅎ</p><br/>
              <p>이렇게 맛있는 음식들로 배를 채우며 행복하게 하루를 마무리한 IGI Korea 임직원들이었습니다ㅎㅎ</p>
              <p>1부 맨 처음에도 언급했듯이, 이렇게 모든 직원들이 모여 한 해를 돌아보고 의미 있는 시간을 보낼 수 있어 정말 좋았습니다.</p><br/>
              <p>앞으로도 서로 응원하고 함께 나아가는 한해가 되기를 바라며 2024년도 모두 수고하셨습니다!</p>
              `,
    createDate: 'Posted on Dec 27, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.879Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '82',
    title: 'IGI Korea 2024 PTV 1부',
    content: `
              <img src="/images/blog/post82/post82_1.jpg" />
              <p>매년 IGI Korea에서는 연말에 모든 직원이 모여 1년을 돌아보면서 수행했던 프로젝트, 활용했던 기술들, 그리고 내년 비전을 공유하는 시간을 가져요!</p>
              <p>저희는 Project, Technology, Vision을 축약해 PTV Day로 부르는데요!</p>
              <p>매번 행사 진행 방식이 조금씩 다른데, 이번에는 팀별 발표, 프로젝트 발표, 부사장님과 대표님 발표, 마지막으로 경품 이벤트로 구성되었어요.</p>
              <p>평소에는 통일부로 파견 나가 있는 직원분들도 계셔서 이런 행사가 아니면 모두 모이기 어려운데 PTV행사를 통해서 서로 한해를 어떻게 보냈는지 나누며 의미 있는 시간을 가질 수 있어요.</p></br>
              <p>한 해가 지날수록 시간이 더욱 빠르게 흘러가는 것 같은데 2024년의 IGI Korea는 어땠는지 후기를 남겨 보겠습니다!</p>
              <img src="/images/blog/post82/post82_2.png" />
              <p>먼저 회사에서 1D로 부르고 있는 기획팀의 발표가 있었습니다!</p>
              <p>새로 들어오신 직원분들도 계시고 또, 일을 하다 보면 직접적으로 연관되어 있지 않으면 다른 팀에서 어떤 업무를 하고 있는지 알기가 어려운데, 기획팀의 업무와 내년 목표에 관해 설명해 주셨어요!</p></br>
              <p>사업 기획, 제안서 작성, 콘텐츠 기획까지..! 모든 과제는 기획팀으로부터 시작된다고 볼 수 있는데요.</p>
              <p>업무 효율성을 높이기 위한 프로세스 정립 및 내년 과제 수주 목표까지 당찬 포부가 느껴지는 발표였습니다ㅎㅎ</p>
              <img src="/images/blog/post82/post82_3.png"/>
              <p>2D팀에서는 2D 디자인, 퍼블리싱을 맡아주고 계시는데요.</p>
              <p>각 팀원들의 능력치를 육각형으로 분석한 부분이 재밌었습니다.</p>
              <p>특히 흥미로운 부분은 2D 팀원분들 각자 부족한 부분이 있지만, 모두가 뭉쳤을 때 서로를 보완하며 완벽한 육각형을 이룬다는 사실!! 크으…</p></br>
              <p>PTV행사 끝부분에 대표님께서 베스트 팀을 2D팀으로 선정하셨는데, 선정된 이유가 있었어요!!</p>
              <p>2D팀은 앞으로 더 완전한 육각형을 위해 피드백 Day를 가진다고 하는데 기대가 됩니다!</p>
              <img src="/images/blog/post82/post82_4.png"/>
              <img src="/images/blog/post82/post82_5.png"/>
              <p>3D팀은 3D 모델링 업무를 맡아주고 계십니다.</p>
              <p>3D팀은 각 팀원의 능력치, 성향을 메이플 스토리 캐릭터에 빗대어 표현하신 게 재밌었어요~</p>
              <p>발표도 집중되게 잘 해주셔서 흥미롭게 봤습니다!</p></br>
              <p>3D팀에서는 5가지 비전을 제시했는데요.</p>
              <p>올해 새로 사용해 본 언리얼을 포함한 기술적 향상, 미적 감각 향상, 개별적으로 자신 있는 분야에 집중하며 팀워크를 향상시키기.</p>
              <p>마지막으로 기술 및 팀워크뿐만아니라 언어 능력까지 챙기는 3D팀은 욕심쟁이 우후훗..!(옛날 옛적 유행어….)</p></br>
              <p>팀과 개인 모두를 발전시키는 비전이 돋보이는 3D팀이었습니다.</p>
              <img src="/images/blog/post82/post82_6.png"/>
              <img src="/images/blog/post82/post82_7.png"/>
              <p>다음은 통일부AI팀의 발표입니다.</p>
              <p>통일부AI팀은 파견 나가 있어 평소 뵙기 어려운데, 오랜만에 만나서 무척 반가웠답니다.</p>
              <p>작년에 이어 이번 연도, 그리고 내년 과제까지 수주하여 북한정보포털 유지보수를 수행하는 팀입니다!</p></br>
              <p>먼저 J프로님이 이번 연도 과제에 대한 발표를 들으면서 얼마나 고군분투했는지를 알 수 있었어요.</p>
              <p>특히 올해 여러 이슈가 있었지만, 이를 잘 해결해 내고 내년 과제까지 이어질 수 있도록 한 통일부 AI팀 분들께 박수를 드리고 싶습니다….!</p></br>
              <p>J프로님에 이어 M이사님의 내년도 과제에 대한 발표도 있었습니다.</p>
              <p>이번 연도보다 인원이 더 투입되고 시스템 안정성을 높이면서 다양한 기능들이 추가 될 예정인데요, 앞으로 더욱 발전할 북한정보포털이 기대되며, 응원합니다!</p>
              <img src="/images/blog/post82/post82_8.png"/>
              <p>다음은 유니버스팀입니다.</p>
              <p>유니버스 팀도 파견 나가 있어 만나 뵙기 어려운 분들이에요,,</p>
              <p>저는 이번 연도 초에 종종 출장 가서 뵈었는데, 본사에서 뵈니 더욱 반갑더라구요.</p></br>
              <p>2024년 유니버스 팀은 DMZ메타버스의 UI/UX를 대대적으로 리뉴얼하여 더욱 편리하고 아름다운 메타버스 공간으로 탈바꿈했어요!</p>
              <p>거기에 신규공간 및 기능들을 추가해서 사용자가 다채롭게 메타버스 공간을 즐길 수 있도록 개선했답니다.</p></br>
              <p>발표하신 J프로님이 내년 PM으로 계속해서 DMZ메타버스 유지관리를 수행할 예정이에요.</p>
              <p>계속해서 DMZ메타버스 공간 업데이트와 이벤트 등이 진행될거라고 하니 다들 기대해 주세요!</p>
              <img src="/images/blog/post82/post82_9.png"/>
              <img src="/images/blog/post82/post82_10.png"/>
              <p>다음은 M&H팀입니다! 모바일과 HMD 등에 사용되는 애플리케이션을 개발하는 팀입니다.</p>
              <p>평소 과묵한 P프로님이 발표를 맡으셨는데, P프로님을 응원차 다른 팀원분들이 모두 나와 계시더라구요. 의리의리!</p>
              <p>입사 일주일 차인 신입 S프로님도 중간에 밝게 자기소개 해주셔서 분위기가 한층 환해졌어요!</p></br>
              <p>M&H팀은 각자 각개전투로 다양한 프로젝트를 수행하면서 넓은 스펙트럼을 가지게 됐다고 합니다. </p>
              <p>하지만 팀원 간의 파편화된 기술 스택으로 접점이 부족한 것이 아쉬워 내년에는 다양한 협업툴이라던가 지식 공유 세션 등을 시도해 볼 계획이라고 해요.</p></br>
              <p>그리고 마지막 P프로님의 주먹다짐이 인상 깊었어요^0^</p>
              <p>내년에도 M&H팀이 활약할 과제들이 많이 대기하고 있는데, 2025년에도 화이팅 입니다!</p>
              <img src="/images/blog/post82/post82_11.png"/>
              <p>다음은 솔루션팀의 B이사님의 발표입니다.</p>
              <p>솔루션팀은 OSS솔루션 및 관련 프로젝트에서 개발을 맡고 있습니다.</p></br>
              <p>솔루션 리팩토링을 진행하면서 솔루션 팀에게는 한 단계 나아가는 뜻깊은 한 해가 아니었나 싶습니다!</p>
              <p>솔루션의 과거/현재/미래를 이해하기 쉽게 정리해 설명해 주셔서 쉽게 이해가 됐어요.</p></br>
              <p>B이사님의 발표 중 Lost Technology에 대한 이야기가 기억에 남는데요~</p>
              <p>솔루션의 업데이트 과정에서 사라진 기능들이 있지만, 사실 이는 잃어버린 것이 아닌 앞으로 나아가야 할 길이라는 것! 럭키비키 원영적 사고로 힘을 주는 발표였습니다.</p>
              <p>솔루션 방향성의 중요한 점을 짚어주어서 앞으로 어떻게 발전할지 더욱 기대가 되네요!</p>
              <img src="/images/blog/post82/post82_12.png"/>
              <p>팀 발표 중 마지막 BigData/AI팀의 K이사님의 발표입니다!</p>
              <p>올 한 해 가장 도전적인 업무를 수행한 팀이 아닌가 싶습니다. </p></br>
              <p>강의에서 사용될 수 있는 AI를 활용한 실시간 통번역 서비스를 개발하고 다국어 서비스, RAG챗봇, AI vision을 활용해 데이터 수집 등 다양한 기능들을 구현해서 많은 프로젝트에 도움을 주었던 AI팀입니다. </p>
              <p>처음으로 시도하다 보니 여러 시행착오를 겪었지만 그럼에도 불구하고 내년의 도전 과제와 해결해야 하는 부분들을 정리해 주신 부분이 기억에 남습니다.</p></br>
              <p>AI를 활용한 기능의 필요성이 점점 더 커지고 있는 만큼, 내년에도 BigData/AI팀이 많은 성과를 이루어내길 응원합니다!</p></br></br>
              <p>이렇게 팀 발표 세션이 마무리되었습니다~!</p>
              <p>생각보다 내용이 길어져서 PTV의 남은 이야기는 2부에서 뵐게요.</p>
              <p>프로젝트 발표, 부사장님과 대표님의 발표, 경품 추첨, 그리고 마지막으로 저녁 회식 바이킹 스워프 후기!! 기대해 주세요!!</p>
        `,
    createDate: 'Posted on Dec 18, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.879Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '81',
    title:
      '[CES 2025 서울통합관] 아이지아이코리아 ‘온트윈즈’ - 디지털 트윈과 메타버스 결합 혁신 솔루션',
    content: `
            <img src="/images/blog/post81/post81_1.png">
            <p ><strong>[스타트업엔]</strong></p></br>
            <p >'아이지아이코리아는 현실 세계의 정보를 디지털화해 새로운 가치를 창출하는 디지털 트랜스포메이션 솔루션 ‘온트윈즈(OnTwins)’를 이번 CES에서 공개한다. 이 솔루션은 디지털 트윈, 메타버스, 메타버스 기반의 에듀테크 콘텐츠, 가상 전시 등 다양한 활용 사례를 지원하며, 사용자 친화적인 웹 기반 플랫폼으로 누구나 쉽게 디지털 트윈을 생성할 수 있다.'</p>
            <br/>
            <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
            <a href="https://www.startupn.kr/news/articleView.html?idxno=49704" target="_blank">link: https://www.startupn.kr/news/articleView.html?idxno=49704</a>
            `,
    createDate: 'Posted on Dec 12, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.856Z',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '80',
    title: 'IGI Korea 상조회 세번째 기부',
    content: `
              <img src="/images/blog/post80/post80_1.jpg" />
              <p>안녕하세요, IGI Korea 상조회입니다!</p>
              <p>시작하기에 앞서 요즘 너무 추워지고 눈도 펑펑 오는데 다들 감기 조심하세요! 🤧❄️</p><br>
              <p>오늘은 IGI Korea 상조회 기부 관련 소식을 가져왔는데요~</p>
              <p>저희의 기부 소식을 보시고 너무나도 추운 여러분들의 연말이 따뜻해졌으면 합니다 ㅎㅎ</p><br>
              <p>저희가 작년에 이어서 또다시 서울 관악구에 위치한 "동명아동복지센터"에 물품 기부를 하게 되었는데요!</p>
              <p>벌써 작년이라니.. 시간이 정말 빠르구나라고 다시한번 새삼 느끼게 되네요..</p><br>
              <p>첫 번째에 이어 이번 연말에도 같은 곳에 기부할 수 있게 되어 더욱 뜻깊고 뿌듯한 거 같아요~~</p>
              <p>매년 기부에 한마음으로 동참해 주는 직원들한테도 감사한 마음이고, 다 같이 이런 뜻깊은 기부에 참여할 수 있어서 영광이고 감사한 마음입니다.</p>
              <img src="/images/blog/post80/post80_3.jpg" />
              <img src="/images/blog/post80/post80_2.jpg" />
              <img src="/images/blog/post80/post80_4.jpg" />
              <p>많은 고민 끝에 이번에는 더욱 아동들에게 필요한 물품을 지원해 주고 싶어서 에어프라이기, 토스터기, 청소기, 매트리스 커버, 실내용 사다리를 구매했습니다.</p>
              <p>너무 감사하게도 물품을 받으시고 복지사님께서 감사 인사와 실사용하는 모습을 손수 사진을 다 찍어서 보내주셨더라고요!</p><br>
              <p>사진을 너무 정성스럽게 찍어 보내주셔서 살짝 블로그에 올려봅니다ㅎㅎ</p><br>
              <p>그리고 동명아동복지센터의 소식지 사진첩에도 기부 소식이 올라갔어요 🤗</p>
              <p>연말이 다가오는데 저희의 기부 내용이 사이트에 올라간 걸 보니 괜히 막 뿌듯하고 마음이 더 뭉클해지는 거 같네요!</p>
              <img src="/images/blog/post80/post80_5.jpg" />
              <p>동명아동복지센터가 연말에는 좀 더 따뜻하고 편히 지낼 수 있는 시설이 되길 바라며
              다들 따듯한 연말 보내시고 미리 메리 크리스마스입니다 ㅎㅎ
              </p><br>
              <p>그럼, 다음에도 따뜻한 소식들을 가지고 돌아오겠습니다.</p>
              <p>앞으로도 상조회의 많은 활약을 기대해 주세요~~!!</p><br>
              <p>동명아동복지센터 소식지 : <a href="http://dmchild.or.kr/%EC%82%AC%EC%A7%84%EC%B2%A9/12486621" target="_blank">http://dmchild.or.kr/%EC%82%AC%EC%A7%84%EC%B2%A9/12486621</a></p>
            `,
    createDate: 'Posted on December 4, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '79',
    title: '오토메이션월드 베트남 2024 참가!',
    content: `
              <img src="/images/blog/post79/post79_1.jpg" />
              <p>안녕하세요!</p>
              <p>지난 9월, 저희 IGI Korea가 베트남에서 개최된 ‘오토메이션월드 베트남 2024’에 참가했습니다~! 👏👏</p><br>
              <p>오토메이션월드 베트남 2024는 지난 9월 25일~27일까지 베트남 빈증에서 처음 개최된 전시회입니다!</p>
              <p>공장 자동화, 머신 비전, 스마트 팩토리라는 3가지 주요 분야를 중심으로 108개의 업체들이 참가했는데요.</p><br>
              <p>오토메이션월드 베트남 전시회 후기 시작해볼까요~!?</p>
              <img src="/images/blog/post79/post79_2.jpg" />
              <img src="/images/blog/post79/post79_3.jpg" />
              <p>전시참가를 위해 A프로와 C프로가 베트남에 다녀왔답니다~~</p>
              <p>부스 세팅을 위해 전시 시작 전날 베트남으로 떠났는데요!</p>
              <p>새벽부터 공항에 도착해 아침 비행기를 타고 베트남으로, 베트남에 도착해서는 호텔에 짐만 놓고 바로 전시장으로 이동해서 부스 세팅하고 정신없던 하루였어요..ㅜㅜ..</p>
              <img src="/images/blog/post79/post79_4.jpg" />
              <img src="/images/blog/post79/post79_5.jpg" />
              <p>지친 저희를 위해 대표님께서 너무나도 맛있는 저녁을 사주셨습니다...! (대표님 감사합니다 +_+)</p>
              <p>아~~~주 신선한 해산물파티를 제대로 즐기고 왔는데요!!!!</p>
              <p>정말 제 손바닥보다 큰 랍스터, 오동통통한 새우, 조개 그리고 베트남 가면 빼먹을 수 없는 필수 사이드 모닝글로리까지!</p><br>
              <p>랍스터 집게발은 정말 제 손만 하더라구요…!! 그 안에 살은 또 어찌나 꽉 차 있던지 먹어도 먹어도 줄지 않는 매직..</p><br>
              <p>해산물들이 정말 싱싱하고 양도 많아서 푸짐하게 즐기고 왔어요!</p>
              <p>새벽부터 움직이고 부스 세팅으로 생긴 피로들이 싹~~ 가시는 저녁식사였어요ㅎㅎ 최고!! 👍</p>
              <img src="/images/blog/post79/post79_6.jpg" />
              <img src="/images/blog/post79/post79_7.jpg" />
              <p>출장기간동안 묵은 숙소는 전시장에서 차로 20분정도 떨어진 곳으로 5성급 호텔인 THE MIRA HOTEL 이에요! </p>
              <p>방도 넓고 조식은 메뉴가 빵, 밥, 쌀국수 다양하게 나오는데 쌀국수가 진~~짜 맛있었어요!! </p>
              <p>매일 쌀국수에 들어가는 재료들이 달라져서 조금씩 맛이 달라서 좋더라구요ㅎㅎ</p>
              <img src="/images/blog/post79/post79_8.jpg" />
              <img src="/images/blog/post79/post79_9.jpg" />
              <p>짜잔-! 완성된 저희 부스입니다ㅎㅎ!!</p>
              <p>전날 부스 세팅하러 갔었는데, 여전히 공사중인 부스들이 많아서 먼지가 너무 날리더라구요ㅜㅜ</p>
              <p>그래서 첫날 일찍 부스에 방문해서 깔끔하게 먼지 같은 것들 싹~~~ 청소하고 다시 세팅을 했어요!</p><br>
              <p>굿즈는 지난 일본 전시회, 메타버스 엑스포에서도 사용했던 브로슈어, 단무지 포스트잇과 볼펜을 챙겨갔답니다~!</p>
              <p>저희의 목표는 챙겨간 굿즈 모두 나눠드리기!!</p>
              <p>( 미리 스포하자면, 포스트잇이 조금 남긴 했지만 브로슈어와 볼펜은 모두 소진하고 왔답니다ㅎㅎ!! 뿌듯 🤗 )</p><br>
              <p>지난 메타버스 엑스포때도 저희 굿즈, 족자봉 디자인이 이쁘다는 소리를 들었었는데 베트남에서도 이쁘다는 칭찬을 들었어요.</p> 
              <p>역시 예쁜 거 알아보시는 눈은 다 같은가 봐요ㅎㅎㅎ</p>
              <p>특히 단무지 포스트잇은 디자인이 특이하셨는지 많이들 물어보셨답니다~!</p>
              <img src="/images/blog/post79/post79_10.jpg" />
              <p>전시 첫날 업체들과의 미팅이 있었는데, 갑자기 온라인 미팅으로 진행되기도하고....</p>
              <p>일정이 조금 변경돼서 갑자기 미팅을 시작하게 되는 등 정신이 없었지만, 저희 OnTwins에 관심 가져 주시고 찾아 주셔서 감사하더라구요!!</p><br>
              <p>열심히 저희가 준비한 데모와 영상들을 보여드리면서 소개해드렸답니다!!ㅎㅎ</p>
              <img src="/images/blog/post79/post79_11.jpg" />
              <img src="/images/blog/post79/post79_12.jpg" />
              <p>이번 전시회에서는 5가지 데모를 준비해갔어요!</p>
              <p>디지털 트윈 분야에서는 OSS(OnTwins Scene Studio)를 통해 직접 디지털 트윈을 구축해볼 수 있도록 준비했는데,</p>
              <p>레이아웃 배치 뿐만 아니라, 데이터를 정의하여 연동하는 시나리오로 준비했어요!</p><br>
              <p>메타버스 분야와 가상전시 분야에서는 지난번과 동일하게 암호화 이론을 학습하는 교육 콘텐츠와 전자제품 쇼룸을 준비해갔어요.</p><br>
              <p>추가로 OSS로 디지털 트윈을 만들면, 해당 디지털 트윈을 VR과 모바일로도 체험할 수 있도록 준비해갔답니다~!</p><br>
              <p>VR체험은 특히 우리 C프로님이 맡아서 진행해 주셨는데 매우 든든했어요!!</p>
              <img src="/images/blog/post79/post79_13.jpg" />
              <p>베트남에서 개최된 전시이다 보니, 주 고객층은 베트남 기업들이어서 전시회 준비할 때, 통역사를 신청해 두었어요!</p>
              <p>그래서 부스에는 통역사님이 상주해 계셔서 문제없이 미팅을 할 수 있었어요!</p>
              <p>통역사님은 베트남 분이신데 한국어를 어찌나 잘하시던지..! 찐 한국인 느낌이셨어요ㅎㅎ</p>
              <p>저희 회사 홍보도 너무 잘해주시고 전시기간 동안 너무 감사했습니다!</p>
              <img src="/images/blog/post79/post79_14.jpg" />
              <p>부스 운영도 중요하지만.. 개인적으로 매우 중요하게 여기는 것 중 하나인 밥!!!</p>
              <p>전시회 가기 전에 점심을 어떻게 할지 고민이었는데요! 우리나라의 배달의 민족처럼, 베트남에서는 그랩으로 쉽게 음식을 배달시킬 수 있더라구요~!!</p>
              <p>그래서 점심은 그랩으로 주문해서 분짜, 반미, 등 베트남 음식을 양~~~껏 즐겼어요 🤤 </p><br>
              <p>마지막 날에는 베트남 통역사님이 망고를 가져다주셔서 엄청 달달한 망고도 배터지게 먹었어요!!</p>
              <p>베트남 와서 맛있는 과일 꼭 먹어야지! 했는데, 이렇게 맛있는 망고를 먹네요ㅎㅎㅎ</p>
              <img src="/images/blog/post79/post79_15.jpg" />
              <p>저녁에는 그랩으로 택시를 타고 로컬 식당가서 쌀국수, 분짜 실~~컷 먹고</p>
              <p>마사지샵 가서 하루동안 부스에서 서있으면서 찌뿌둥해진 몸을 싸악 풀어주었어요ㅎㅎ</p>
              <p>물가가 저렴하다보니 마사지도 받고.. 출장 와서 이렇게 행복해도 되나요!?</p>
              <img src="/images/blog/post79/post79_16.jpg" />
              <img src="/images/blog/post79/post79_17.jpg" />
              <p>잠시 여유 있을 때 둘러보니, 스마트 팩토리 솔루션, 자동화 기계 등이 많이 눈에 띄었어요! </p>   
              <p>특히 자동화 기계들은 규모가 있다 보니 설치하는 게 만만치 않았을 텐데 기업들이 열심히 준비해 온 것이 느껴지더라구요~!!</p><br>
              <p>그리고 베트남 총리님이 방문하셔서 전시를 둘러보고 가셨는데, 아쉽게도 저희 부스 앞쪽이 아닌 뒤쪽으로 지나가셔서 제대로 보지 못했다는 슬픈 사실ㅜㅜ</p><br>
              <p>이번이 1회 전시회지만, 베트남 총리님도 관심을 가지시고 방문해 주셔서 앞으로 기대가 되네요!!</p>
              <img src="/images/blog/post79/post79_18.jpg" />
              <img src="/images/blog/post79/post79_19.jpg" />
              <p>전시준비 1일, 전시기간 3일 총 4일간의 베트남 전시 여정이 막을 내렸어요!!</p><br>
              <p>지난 메타버스 엑스포에 참가했을 때는 디지털 트윈, 메타버스 각각 관심을 가져 주셨었는데,</p>
              <p>이번 오토메이션월드는 주제가 공장 자동화, 머신 비전, 스마트 팩토리라 그런지 확실히 방문해주시는 분들의 관심이 디지털 트윈에 집중되어 있었네요!</p><br>   
              <p>베트남에서 OnTwins의 이름을 알릴 수 있어 의미 있는 시간이었습니다!!</p>
              <p>다음은 OnTwins가 어디로 진출할지 기대해주세요~~👋 </p> 
            `,
    createDate: 'Posted on October 18, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '78',
    title: 'IGI Korea, 2024년도 Metaverse Expo에 참가',
    content: `
              <img src="/images/blog/post78/post78_1.png" />
              <p>안녕하세요! A 프로입니다~~!</p>
              <p>아이지아이 코리아의 블로그 애독자분들은 아시겠지만, 작년 Metaverse Expo에 참가했었는데요!</p>
              <p>이번년도에도 Metaverse Expo에 참가하게 되었어요! 짝짝짝~! 👏👏</p>
              <p>저는 작년에 출장 중이라 엑스포 준비에 많은 도움을 못 드렸었는데요ㅜㅜ</p>
              <p>이번에는 도움이 될 수 있어서 뜻깊은 전시회였답니다~☺️</p>
              <img src="/images/blog/post78/post78_2.png" />
              <img src="/images/blog/post78/post78_3.png" />
              <p>저는 전시회 부스에는 처음 상주하다 보니 처음에는 어떻게 해야 할지 막막했는데,</p>
              <p>10시 땡 오픈하자마자 저희 부스에 많은 분이 방문해주시고!</p>
              <p>또 M 이사님의 폭풍 상담하시는 모습을 보고 힘 받아 으쌰으쌰 했어요👍</p>
              <p>사실… M 이사님은 부스 지원이 아니셨는데, 잠시 들리셨다가 저희에게 붙잡혀서 목이 쉬도록 일하셨다는… (M 이사님 감사해요ㅠㅠ하트)</p>
              <p>보내 드리고 싶지 않았지만, 눈물을 머금고 오후에 보내 드렸답니다 ^.ㅠ</p>
              <img src="/images/blog/post78/post78_4.png" />
              <img src="/images/blog/post78/post78_5.png" />
              <p>이번 2024 Metaverse Expo에도 IGI Korea 굿즈를 준비했어요!!</p>
              <p>리유저블 텀블러와 귀여운 단무지 포스트잇 그리고 볼펜!</p>
              <p>회사 내에서도 갖고 싶어하신 분들이 많았답니다?</p>
              <p>저희 부스 방문해주신 분들도 물론 모두 만족하며 가져가셨어요!!</p>
              <p>그만큼 많은 분들이 탐내신 알찬 구성!</p>
              <p>그리고 회사소개 자료인 브로슈어와 족자봉, 고객용 명함을 준비했어요!</p><br>
              <p>우리 C 프로님이 직접 디자인한 브로슈어와 족자봉, 투명 명함까지 너무 이쁘지 않나요~~~?</p>
              <p>저는 디자인 알못이지만.. 제 눈에도 감각적으로 보이는..! 최고최고</p>
              <p>이건 여담이지만 고객분들에게 저희 부스 디자인이 예쁘다는 칭찬도 많이 받으셨답니다!!ㅎㅎ</p>
              <img src="/images/blog/post78/post78_6.png" />
              <img src="/images/blog/post78/post78_7.png" />
              <p>이번 전시회에서는 4가지 데모를 준비했어요!</p><br>
              <p>디지털 트윈 분야에서는 OSS(Ontwins Scene Studio)를 통해 직접 디지털 트윈을 구축해볼 수 있도록 준비했구요~</p>
              <p>디자인도 디자인이지만 초보자들도 손쉽게 사용할 수 있도록 구현해서 사용성 면도 뛰어난 OSS!</p>
              <p>가장 많은 관심과 문의가 많았었는데요~</p>
              <p>그래서 첫날 이후에는 OSS를 직접 개발한 개발자분들에게 지원 요청해서 현장에서 직접 고객들과 소통할 수 있는 기회를 마련했어요!</p><br>
              <p>그 다음 메타버스 기반의 교육 콘텐츠를 준비했어요~</p>
              <p>학생들을 대상으로 암호에 대해 배울 수 있는 콘텐츠인데, 생각보다 난이도가 있어서 처음엔 저도 애를 먹었지만...</p>
              <p>게임요소들과 구성이 워낙 잘 짜여 있어서 나도 모르게 빠져들어 풀게 되는 매직..!!!</p><br>
              <p>그리고 가상전시 프로젝트로 전자제품 쇼룸을 구현하여 아바타로 직접 쇼룸을 둘러볼 수 있는 콘텐츠를 준비했어요.</p><br>
              <p>마지막으로 심리치료에 활용되는 VR 콘텐츠를 준비하였는데요, 저희 3D 디자이너분들이 온 힘을 다해 만들어서 퀄리티가 어마어마합니다~!</p>
              <p>VR도 정말 인기가 많았어요!</p>
              <p>여러 명이 줄을 서서 체험하고 가셨던…! 인기 체험 콘텐츠!</p>
              <p>기억에 남는 건 학생분들이 오셔서 체험하셨는데, 엄청 신기해하시면서 꺄르르 웃으셔서 옆에서 보는 제가 다 기쁘더라구요~~</p>
              <img src="/images/blog/post78/post78_8.png" />
              <img src="/images/blog/post78/post78_9.png" />
              <p>총 3일간의 메타버스 엑스포에서 저희가 만든 솔루션을 소개하면서 고객들의 이야기를 들을 수 있어서 정말 의미 있는 시간이었어요!</p>
              <p>엑스포 준비에 도움을 준 IGI Korea 모든 직원 여러분 고생 많으셨습니다~~~~</p><br>
              <p>메타버스 엑스포 후기! 끝!</p>
            `,
    createDate: 'Posted on July 15, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '77',
    title: '도쿄 시티투어',
    content: `
              <img src="/images/blog/post77/post77_1.png" />
              <p>안녕하세요 아이지아이 코리아의 D 프로입니다!</p> 
              <p>이전 포스팅처럼 저와 C 프로님이 전시회 참가를 위해 도쿄로 4박 5일간 출장을 다녀왔는데요.</p> 
              <p>도쿄까지 갔는데 투어를 빼먹을 순 없겠죠?!?!</p> 
              <p>사간과 체력이 부족할 정도로 알차고 재밌게 돌아다녔답니다!!</p> 
              <p>함께 보시죠~!</p> 
              <img src="/images/blog/post77/post77_2.png" />
              <img src="/images/blog/post77/post77_3.png" />
              <p><strong>첫째 날</strong></p>
              <p>4박 5일 동안 저희가 묶은 숙소는 호텔 트러스티 도쿄 베이사이드입니다!</p> 
              <p>빅사이트와 도보 10분 거리로 아주 가깝고 직원분들도 굉장히 친절하셨어요~</p> 
              <p>청결은 덤! 호텔이 아주 깔끔하죠?</p> 
              <p>C프로님과 함께 고심하여 고른 호텔이었답니다.^_______^</p> 
              <img src="/images/blog/post77/post77_4.png" />
              <img src="/images/blog/post77/post77_5.png" />
              <p>첫째 날에는 비행기 타고… 이동하고… 부스 설치하고….!</p> 
              <p>지칠대로 지쳐서 관광까지는 못하고 대신 숙소 근처를 돌아다녔답니다~</p> 
              <p>편털(편의점 털이)은 덤!</p> 
              <p>근처에 대학교가 있길래 구경해보자~ 해서 들어갔는데 벽에 그림도 그려져 있고 굉장히 화려하더라구요?!</p> 
              <p>알고보니까 이곳은 미대였어요!</p> 
              <p>미대 출신 C 프로님~ 감흥이 남다르셨겠어요~!</p> 
              <img src="/images/blog/post77/post77_6.png" />
              <img src="/images/blog/post77/post77_7.png" />
              <p>가볍게 산책을 마치고 편의점에서 사온 음식들로 늦은 저녁을 먹었어요!</p> 
              <p>일본 편의점 음식 유명한 거 다들 알고 계시죠??</p> 
              <p>배고픔에 허덕이던 저희는 세븐일레븐에 들어가자마자 눈이 돌아가.. 엄청난 지출을 하고 말았답니다.ㅎㅎ</p> 
              <p>참고로 저 라면은 정말 짰어요..^^ 계란말이는 황홀 그 잡채…!</p> 
              <p>첫째 날은 가볍게 편의점만 맛보고 내일 전시회를 위해서 마무리!</p> 
              <img src="/images/blog/post77/post77_8.png" />
              <img src="/images/blog/post77/post77_9.png" />
              <p><strong>두 번째 날!</strong></p> 
              <p>전시회에서 열심히 저희 OnTwins를 소개하고 저녁엔 긴자를 갔어요!</p> 
              <p>빅사이트는 관광지랑 꽤 떨어져 있어서 무조건 지하철을 타고 나가야 한답니다~</p> 
              <p>그 와중에 지하철표랑 영수증 넘 귀엽지 않나요..?</p> 
              <p>가는 노선마다 영수증 색깔이 다르더라구요!</p> 
              <p>저는 다이어리에 붙이려고 주섬주섬 모아서 왔답니다.ㅎㅎ</p> 
              <img src="/images/blog/post77/post77_10.png" />
              <p>그렇게 저희가 도착한 곳은 바로 긴자에 위치한 이타마에스시!</p> 
              <p>요기 아주 맛집이라고 소문난 곳인데요~</p> 
              <p>저희가 갔을 때 웨이팅이 있긴 했지만 기다릴만했답니다.</p> 
              <p>도쿄에서 먹는 제대로 된 첫 식사라서 두근..세근..네근…</p> 
              <img src="/images/blog/post77/post77_11.png" />
              <img src="/images/blog/post77/post77_12.png" />
              <p>여러분… 영롱하지 않나요…..?</p> 
              <p>가격이 꽤 사악했지만 지금 그런 게 중요한가요..</p> 
              <p>한 피스 한 피스 입에 넣을 때마다 녹아 없어지더라고요!! 솜사탕이야 뭐야~</p> 
              <p>제 픽은 참다랑어 세트에서 저 김에 쌓여있는 친구들이었어요!</p> 
              <p>같이 시킨 계란말이는 쏘쏘~</p> 
              <p>새우튀김은 새우가 통통한 게 씹는 맛이 있더라구요~</p> 
              <p>진짜 너~무 만족했던 저녁 식사였답니다!</p> 
              <img src="/images/blog/post77/post77_13.png" />
              <img src="/images/blog/post77/post77_14.png" />
              <p>웨이팅 후 먹은 저녁이라 다 먹고 나니 어딜 가기엔 시간이 애매해서 가볍게 돈키호테 구경해줬어요~</p> 
              <p>다들 아시죠? 돈키호테는 마지막 날 캐리어 정리하기 전에 털어줘야 하는 거^^…</p> 
              <p>하지만 저희는 그 전에 미리 쇼핑 답사 겸 가봤답니다.ㅋㅋㅋㅋ</p> 
              <p>귀여운 친구들 구경 좀 해주고 오늘 고생한 다리에 붙여줄 파스 하나씩 사서 귀가~</p> 
              <img src="/images/blog/post77/post77_15.png" />
              <p>패션은 버리고 편함을 선택한 우리..</p> 
              <p>한국은 길거리에서 눈만 돌려도 크록스 신은 사람들이 엄청나게 많은데 일본은 저 밖에 없더라구요?? 하지만 당당하게 돌아다니기~</p> 
              <img src="/images/blog/post77/post77_16.png" />
              <img src="/images/blog/post77/post77_17.png" />
              <img src="/images/blog/post77/post77_18.png" />
              <p>오늘도 어김없이 돌아온 편의점 털이 시간~</p> 
              <p>일본 세븐일레븐에는 이렇게 과일이 든 컵을 고르면 기계에 넣어서 갈아주는 머신이 있더라구요~!</p> 
              <p>양은 적었지만^^ 상큼하고 시원하게 먹기 딱이었답니다ㅎㅎ</p> 
              <img src="/images/blog/post77/post77_19.png" />  
              <img src="/images/blog/post77/post77_20.png" />
              <p>제가 좋아하는 이로하스 복숭아 물을 발견해서 바로 구매!!</p> 
              <p>자판기까지 야무지게 털고 호텔로 돌아왔어요~</p> 
              <p>숙소에 아주 큰 전신 거울이 있어서 전시회 혹은 투어를 나갈 때, 들어올 때마다 이렇게 거울 셀카를 찍었답니다.</p> 
              <p>하지만 매번 무척이나 지친 모습….ㅎ</p> 
              <p>두번째 날은 이렇게 마무리~!</p> 
              <img src="/images/blog/post77/post77_21.png" />
              <p><strong>세 번째 날 투어 시작!</strong></p> 
              <p>오늘은 무려 시부야에 갔답니다~</p> 
              <p>어제 긴자가는 건 쉬웠는데 시부야는 중간에 환승을 해야 해서 어려웠어요.ㅠㅠ</p> 
              <p>심지어 잘못 탐..(부들) 서울 지하철 N 년 차 자존심에 스크래치 났어요…</p> 
              <img src="/images/blog/post77/post77_22.png" />
              <img src="/images/blog/post77/post77_23.png" />
              <p>고생 끝에 도착한 시부야!</p> 
              <p> 혹시 다들 슈퍼갤즈 라는 애니메이션을 아시나요?</p> 
              <p>아신다면 당신은 나의 동년배~</p> 
              <p>제가 초딩 때 엄청나게 인기 많았던 투니버스 만화였는데요!</p> 
              <p>거기 주인공들이 시부야 핫걸들이거든요.</p> 
              <p>그 당시에 시부야에 대한 로망이 있었는데 드디어 와보네요.</p> 
              <p>시부야 첫 소감은 정말... 글로벌 인싸 집합소구나..! 싶었어요.ㅋㅋㅋㅋㅋ</p> 
              <p>세계 각국에서 모인 관광객들과 반짝이는 불빛들, 화려한 건물까지!</p> 
              <p>저도 나름 MBTI E인 사람인데 여기는 좀 기가 빨리더라구요…</p> 
              <img src="/images/blog/post77/post77_24.png" />
              <img src="/images/blog/post77/post77_25.png" />
              <p>아무 계획 없이 온 저희는 엄청난 인파에 당황하다가 호객행위를 하고 있는 이자카야에 들어갔어요!</p> 
              <p>가볍게 레몬사와 하나씩 시켜주고~</p> 
              <p>메뉴판을 보며 안주를 고르는데 영어로 쓰여 있어도 뭐가 뭔지 잘 모르겠더라구요.^^ 그래서 그냥 땡기는거 하나씩 시켜줬답니다.</p> 
              <p>저~기 보이는 정체불명의 음식은 유자 맛 곱창이에요.</p> 
              <p> 호불호가 확실하게 갈릴 맛?! 저희는 둘 다 호호였답니다.</p> 
              <img src="/images/blog/post77/post77_26.png" />
              <img src="/images/blog/post77/post77_27.png" />
              <p>그리고 또 돈키호테 갔어요…^^</p> 
              <p>사실 갈 생각 없었거든요?!</p> 
              <p>근데 여기는 메가 돈키호테라는 거에요!!</p> 
              <p>“메가” 라는데 어떻게 지나칠 수가 있나요???</p> 
              <p>그리고 저 시바견이 먼저 꼬셨어요.ㅠ</p> 
              <p>들어가서 뭐가 다른가 봐주고~</p> 
              <p> 거울 셀카를 또 찍어주고 나왔답니다.ㅋㅋㅋㅋㅋㅋ</p> 
              <img src="/images/blog/post77/post77_28.png" /> 
              <img src="/images/blog/post77/post77_29.png" />
              <p>그리고 가챠샵에 갔어요!</p> 
              <p>일본 가챠샵은 처음이었는데 정말 돈 잡아먹는 귀신이더라구요.ㅋㅋㅋ</p> 
              <p>각자 2천 엔씩 동전 교환하고 흩어져서 취향대로 뽑아줬답니다.</p> 
              <p>저와 C 프로님이 어떤 가챠를 뽑았는지 궁금하시죠?! 커밍 순~~~~~</p> 
              <img src="/images/blog/post77/post77_30.png" />
              <p>시부야를 떠나기 전에 하치와 한 컷!</p> 
              <p>하치야 잘 지내지…? 초딩 때 넌 내 눈물버튼이었어….</p> 
              <img src="/images/blog/post77/post77_31.png" /> 
              <img src="/images/blog/post77/post77_32.png" />
              <p>숙소로 돌아가는 다리에서 야경과 함께 한 컷~</p> 
              <p>C프로님이 찍어주셨는데 지금 제 카톡 프사랍니다!</p> 
              <p>사진 참 많이 찍으시던데 제 사진을 찍는 거였네요…</p> 
              <p>C 프로님의 짝사랑 못 말려... 고백하세요... 받아줄게요...</p> 
              <img src="/images/blog/post77/post77_33.png" />
              <p>마지막으로 저희가 뽑은 가챠 대공개!!!!</p> 
              <p>위가 저 D 프로가 뽑은 가챠, 밑이 C 프로님이 뽑은 가챠인데 취향이 정말 다르죠?</p> 
              <p>여러분의 취향은 어느 쪽이신가요????</p> 
              <p>이거 SNS에서 투표했었는데 제가 완승했답니다 쿠하하~</p> 
              <p>세번째 날도 열심히 놀고 기절~!</p> 
              <img src="/images/blog/post77/post77_34.png" />
              <p><strong>넷째 날이 밝았습니다~</strong></p> 
              <p>이 날은 전시회 마지막 날이었어요!</p> 
              <p>부스를 철거하고 숙소로 이동하는 길이에요.</p> 
              <p>다행히 비는 안 왔지만 습해서 땀을 정말 많이 흘렸답니다...(눈물)</p> 
              <img src="/images/blog/post77/post77_35.png" /> 
              <img src="/images/blog/post77/post77_36.png" />
              <p>모든 임무를 완수하고 한결 가벼운 마음으로 저희는 긴자로 향합니다!</p> 
              <p>지난 번에 저녁만 먹고 구경을 못해서 많이 아쉬웠거든요~</p> 
              <p>명품관을 지나 저희가 도착한 곳은 바로!</p> 
              <img src="/images/blog/post77/post77_37.png" />
              <img src="/images/blog/post77/post77_38.png" />
              <p>로프트랍니다.ㅎㅎ</p> 
              <p>우리나라에 핫트랙스가 있다면 일본엔 로프트가 있다!</p> 
              <p>규모가 엄청나게 큰 문구점이라고 보시면 돼요.</p> 
              <p>저랑 C 프로님 둘 다 귀여운 거에 엔도르핀 도는 사람들이거든요.</p> 
              <p>제가 가자고 꼬신 건데 C 프로님의 소비력… 저 못지않았답니다.ㅋㅋㅋ</p> 
              <p>저녁 예약이 있어서 다 돌아보진 못했지만 빠르게 문구 쇼핑 완료!</p> 
              <img src="/images/blog/post77/post77_39.png" />
              <img src="/images/blog/post77/post77_40.png" />
              <p>저녁을 먹기 전에 시간이 조금 남아서 카페를 왔어요.</p> 
              <p>여기는 직원분이 주문을 받으러 오고 음료도 가져다주는 곳인데요~</p>  
              <p>이렇게 오래된 느낌의 카페는 정말 오랜만이었어요!</p> 
              <p>저는 메론소다를, C 프로님은 밀크티를 주문했답니다. C 프로님이 사주셔서 더 맛있었어요~~!</p> 
              <img src="/images/blog/post77/post77_41.png" />
              <img src="/images/blog/post77/post77_42.png" />
              <p>대망의 마지막 일정은 야키니쿠집입니다!</p> 
              <p>도라지 라는 야키니쿠집인데요.</p> 
              <p>사실 저희가 예약한 곳은 여기가 아니었어요! (첫번째 사진이 예약한 가게 사진)</p> 
              <p>원래 예약한 곳으로 갔는데 저희가 구글맵에서 봤던 메뉴판이랑 가격이 아예 다른 거에요…??</p> 
              <p>부담이 되는 금액이라 직원한테 설명 후 나가려고 하는데, 직원분이 너무 죄송하다며 같은 계열사(?)인데 조금 더 저렴한 야키니쿠집을 소개해주셨어요.</p> 
              <p>거기가 바로 여기 도라지!!</p> 
              <p>이름에서 티가 나듯 여기는 한국식 야키니쿠…? 느낌이었어요.</p> 
              <img src="/images/blog/post77/post77_43.png" />
              <img src="/images/blog/post77/post77_44.png" />
              <p>저희가 주문한 아이들…!</p> 
              <p> 너무 빛나서 화면을 쳐다볼 수가 없네요.ㅠ</p> 
              <p>우설과 여러 소고기가 포함된 세트를 주문했어요.</p> 
              <p>처음엔 양이 적나~ 했는데 배 터지는 줄 알았답니다.</p> 
              <p>우설이 정말… 너무 부드럽고 입에 넣으면 없어지던데요?</p> 
              <p>중간에 살짝 퍽퍽한 고기도 있었지만, 전체적으로 진!짜! 맛있게 먹었어요.</p> 
              <p>너무 만족했던 저녁 식사였답니다.</p> 
              <img src="/images/blog/post77/post77_45.png" />
              <img src="/images/blog/post77/post77_46.png" />
              <p>후식으로 냉면, 디저트로 푸딩이 나왔는데요.</p> 
              <p>한국식 냉면이라고 되어있었는데… 갑자기 웬 우동면^^? 싶었지만 그래도 맛있게 다 먹었다는 사실~ㅎ</p> 
              <p>푸딩도 완전 저희 스타일이었답니다.</p> 
              <img src="/images/blog/post77/post77_47.png" />
              <p>만찬을 마치고 사진은 없지만, 새벽 2시까지 돈키호테를 털고 귀가했답니다.ㅋㅋㅋ</p> 
              <p>정말 엄청나죠..?</p> 
              <p>내일 귀국이라 짐 정리까지 하니까 새벽 4시였는데 무려 해가 뜨더라구요?ㅎㅎ 열정 대다나다..</p> 
              <img src="/images/blog/post77/post77_48.png" />
              <img src="/images/blog/post77/post77_49.png" />
              <p><strong>한국으로 돌아가는 날!</strong></p> 
              <p>엄청난 짐을 호텔에 맡기고 체크아웃했어요.</p> 
              <p>저 도라에몽 타포린백 보이시나요?</p> 
              <p>돈키호테에서 어마 무시한 쇼핑을 하고 저기에 다 담아두었답니다.</p> 
              <p>한국에 가져가는 건… 미래의 제가 알아서 하겠죠?ㅎㅎ</p> 
              <img src="/images/blog/post77/post77_50.png" /> 
              <img src="/images/blog/post77/post77_51.png" />
              <p>그동안 낮에는 전시회 운영하느라 바깥에 나가보지 못했는데...</p> 
              <p>마지막 날이 되어서야 도쿄의 낮을 보게 되었네요!</p> 
              <p>저희의 마지막 선택은 시부야였습니당.ㅎㅎ</p> 
             <img src="/images/blog/post77/post77_52.png" />
             <img src="/images/blog/post77/post77_53.png" />
              <p>원래 가려던 식당에 웨이팅이 너무 심해서 어디 가지 하다가 이번에는 MBTI P인 C 프로님 의견으로 보이는 식당 아무 곳이나 들어가자! 했어요.(J인 제 인생에서는 처음이었답니다^^..)</p> 
              <p>밖에서 메뉴만 보고 들어간 곳인데 양도 많고 맛있었어요. 가성비 굿굿!</p> 
              <img src="/images/blog/post77/post77_54.png" />
              <img src="/images/blog/post77/post77_55.png" />
              <img src="/images/blog/post77/post77_56.png" />
              <p>지난번 긴자에서의 로프트 쇼핑이 너무 짧게 끝나 아쉬워서 이번엔 진짜 제대로 털어보자!! 하고 시부야의 로프트를 갔답니다.</p> 
              <p>건물이 통으로 로프트라서 거의 2시간 동안 쇼핑을 했어요.ㅎㅎ</p> 
              <p>또 돈을 엄청나게 쓴 소비왕 2인..</p> 
              <p>하지만 거의 1시간을 대기해서 택스 리펀까지 확실하게 받아주었습니다.ㅎㅎ</p> 
              <img src="/images/blog/post77/post77_57.png" />
              <p>돌아가기 전에 시부야의 명물인 스크럼블 교차로도 구경했어요.</p> 
              <p>주말이라 그런지 사람들이 더 바글바글하더라구요~</p> 
              <p>나도 얼마 전까진 저기에 있었지… 벌써 추억이 되어버렸어요.</p> 
              <img src="/images/blog/post77/post77_58.png" />
              <img src="/images/blog/post77/post77_59.png" />
              <img src="/images/blog/post77/post77_60.png" />
              <img src="/images/blog/post77/post77_61.png" />
              <p>이제는 한국에 돌아갈 시간!</p> 
              <p>엄청난 짐들을 싣고 하네다 공항에 다시 왔습니다.</p> 
              <p>한국에서 우리를 하염없이 기다리고 있을(?) 회사 직원분들 간식도 사고 마지막 식사까지!</p> 
              <p>4박 5일동안 야무지게 도쿄를 즐기고 저희는 돌아갑니다!</p> 
              <p>잘있어 도쿄~~~~ 또 올게~~</p> 
              <img src="/images/blog/post77/post77_62.png" />
              <img src="/images/blog/post77/post77_63.png" />
              <p>한국으로 돌아가는 비행기에서는 기내식으로 장어덮밥이 나왔는데 둘 다 싹싹 긁어먹었어요.</p> 
              <p>대한항공 최고시다~~~~~</p> 
              <img src="/images/blog/post77/post77_64.png" />
              <p>드디어 한국 도착! 묘하게 한국이 더 더운 기분…? 더 습한 기분...? ㅎ</p> 
              <br>
              <p>끝나지 않을 것 같았던 일본 출장이 이렇게 마무리되었습니다!</p> 
              <p>다들 랜선 도쿄여행 즐거우셨나요?</p> 
              <p>전시회 일정이 있어서 주로 저녁에만 투어를 했지만, 저와 C 프로님은 굉장히 만족스러웠어요~</p> 
              <p>내내 몸과 마음이 고되었지만, 긍정적인 마인드로 즐거운 경험을 하는 시간을 보내고 왔답니다!</p> 
              <br>
              <p>그럼 다음 포스팅으로 돌아올게요 안녕~!</p>
            `,
    createDate: 'Posted on July 15, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '76',
    title: '매뉴팩처링 월드 2024 Tokyo 참가!',
    content: `
              <img src="/images/blog/post76/post76_1.png" />
              <p>안녕하세요, 여러분!</p>
              <p>지난 6월, 저희 IGI Korea는 도쿄에서 개최된 '매뉴팩처링 월드 2024'에 참가했습니다!!!</p><br>
              <p>매뉴팩처링 월드 설명을 드리자면 전 세계의 기계 부품, 모션 제어, DX(디지털 트랜스포메이션) 솔루션, 적층 제조, 공장 설비, 계측 기기를 전시하고 저희가 다녀온 도쿄뿐만 아니라 오사카, 나고야 등에서도 개최되는 세계 최대 규모의 제조 전시회중 하나입니다!! 설명만 들어도 규모가 정말 크죠?</p>
              <p>이렇게 큰 전시회이기도 하고 해외 전시회 참가는 처음이라 설렘 반 두려움 반이었는데요~</p><br>
              <p>과연 성공적으로 마무리할 수 있었을지! 도쿄 출발부터 마무리까지 함께 보실까요?</p>
              <img src="/images/blog/post76/post76_2.png" />
              <p>전시회는 저 C 프로와 D 프로님 둘이 함께 다녀왔습니다~</p>
              <p>전시회 시작 전날에 부스 세팅을 위해 아침 9시 비행기로 새벽부터 준비 후 출발했는데요!</p>
              <p>짐이 아주 아주 많아서 택시를 타고 김포공항으로 슝슝~!</p>
              <img src="/images/blog/post76/post76_3.png" />
              <img src="/images/blog/post76/post76_4.png" />
              <p>공항에 도착하자마자 환전을 하고 인증샷도 찍고! 비행기에 탑승했습니다ㅎㅎ</p>
              <img src="/images/blog/post76/post76_5.png" />
              <p>MZ샷 도전해봤는데 이렇게 찍는 거… 맞나요?</p>
              <img src="/images/blog/post76/post76_6.png" />
              <img src="/images/blog/post76/post76_7.png" />
              <p>일본에 처음 온 건 아니지만, 대한항공을 타고 일본에 오는건 처음인데요!</p>
              <p>나름 한국과 가까운 거리인데 티비로 영화도 볼 수 있고 기내식도 제공해주더라구요~</p>
              <p>덕분에 아주아주 편하게 다녀올 수 있었어요! (대표님 감사합니다~+0+)</p>
              <img src="/images/blog/post76/post76_8.png" />
              <img src="/images/blog/post76/post76_9.png" />
              <p>일본에 도착하니 비가 오고 있더라구요~~</p>
              <p>모두가 이 시기 일본은 더울 거라고 경고해서 겁을 먹었는데요ㅜ</p>
              <p>다행히 전시회 기간 동안 비가 오기도 했고 전시장 위치가 바다 바로 근처여서 오히려 선선했어요! ㅎㅎ</p><br>
              <p>이번에도 택시를 타고 전시장으로 이동~~</p>
              <p>앗 참! 일본 택시는 처음 타봤는데 듣던 대로 문도 자동이고 기사님도 너무나 젠틀하셔서 (조금 비싸지만…) 감동의 시간이였어요ㅎㅎ</p>
              <img src="/images/blog/post76/post76_10.png" />
              <img src="/images/blog/post76/post76_11.png" />
              <img src="/images/blog/post76/post76_12.png" />
              <img src="/images/blog/post76/post76_13.png" />
              <p>전시장인 Big Sight에 도착했어요!</p>
              <p>일본의 최대 규모 전시장이라고 듣긴 했지만… 직접 실물로 보니…</p>
              <p>우와하고 저절로 감탄이 나올만큼 너무나 거대해서 길을 헤맬뻔했습니다!+0+</p>
              <p>저희 IGI Korea는 많은 분야 중 DX(디지털 트랜스포메이션) 분야로 참여했는데요~</p>
              <p>DX란 디지털 트랜스포메이션의 줄임말로 제조업 업무 디지털화, DX를 추진하는 IT 제품, 서비스 등을 제공하는 분야입니다~</p>
              <img src="/images/blog/post76/post76_14.png" />
              <img src="/images/blog/post76/post76_15.png" />
              <p>늦은 시간에 도착한 것도 아닌데 벌써 북적북적 사람이 아주 많고 공사가 한참 진행 중이더라구요~</p>
              <p>저희도 질세라 후다닥 저희 부스를 단장하였습니다!!</p>
              <img src="/images/blog/post76/post76_16.png" />
              <img src="/images/blog/post76/post76_17.png" />
              <p>짜잔! 완성된 저희 부스!! 정말 예쁘지 않나요?ㅎㅎ</p>
              <p>사실 제가 담당해서 디자인한 결과물이랍니다~</p>
              <p>머릿속에서 상상만 하며 진행했다가 직접 실물로 완성된 모습을 보니 정말 뿌듯하더라구요~(감격!)</p>
              <img src="/images/blog/post76/post76_18.png" />
              <p>이렇게 우리는 영어 OK! 데모도 시연해요! 라고 모두가 보실수 있도록 붙여놨습니다!</p>
              <p>이걸 보시면 더 부담 없이 오실 수 있겠죠?</p>
              <img src="/images/blog/post76/post76_19.png" />
              <p>밖을 나와보니 헉! 어느새 해가 뉘엿뉘엿 지고 있었어요~</p>
              <p>바로 전시회가 진행된 날로 넘어가 볼까요?</p>
              <img src="/images/blog/post76/post76_20.png" />
              <img src="/images/blog/post76/post76_21.png" />
              <p>전시회 화이팅!을 위해 전날에 사둔 일본 에너지 젤리를 먹고 출발했어요!</p>
              <p>숙소는 전시회장에서 약 도보 15분 거리에 위치해있는데요~</p>
              <p>전시회 시작이 10시라 9시에 출발해 바이어분들을 만날 준비를 했답니다~!</p>
              <img src="/images/blog/post76/post76_22.png" />
              <img src="/images/blog/post76/post76_23.png" />
              <p>숙소의 엘리베이터 앞에 인증샷 남기기 딱 좋게 거울이 있어서 매일 매일 인증샷을 남긴 저와 D프로님ㅎㅎ</p>
              <img src="/images/blog/post76/post76_24.png" />
              <img src="/images/blog/post76/post76_25.png" />
              <img src="/images/blog/post76/post76_26.png" />
              <img src="/images/blog/post76/post76_27.png" />
              <p>여러분.. 이 많은 사람들이 모두 오로지 이번 전시회 참여&방문객들이랍니다!</p>
              <p>최대 규모의 제조 전시회 중 하나! 이 사진들로 납득이 확 가시나요?</p>
              <p>저와 D프로님도 매순간 대규모 인파에 깜짝 놀랐어요~!</p>
              <p>전시회장이 저 멀리 보이는 순간부터 전시회장을 떠나는 순간까지 한산한 적이 없었던것 같아요ㅎㅎ</p>
              <p>그리고 특히 대부분의 사람들이 정장을 입고 있다는 사실도 신기하더라구요~</p>
              <img src="/images/blog/post76/post76_28.png" />
              <img src="/images/blog/post76/post76_29.png" />
              <p>부스에 도착해서 또 한 번 놀란 점~!</p>
              <p>일본 전시회 분위기는 국내와는 다르게 호객행위가 당연스럽게 존재하더라구요~</p>
              <p>어느 정도였나면 부스에서 사람이 부르지 않으면 관심 없이 지나치는 정도…?</p>
              <p>알고보니 적극적인 응대 방식이 일본 전시회 특징이었던 점!!</p>
              <p>하지만 상대방을 억지로 끌고 들어가거나 불편하게는 하지 않고 보고 가세요~ 굿즈받아가세요~를 적극적으로 외치는 분위기였어요!</p>
              <img src="/images/blog/post76/post76_31.png" />
              <img src="/images/blog/post76/post76_30.png" />
              <img src="/images/blog/post76/post76_51.png" />
              <p>특징을 파악한 후 저희도 이번에 준비한 귀여운 굿즈들과 브로슈어를 들고 곤니치와~ 도죠~를 열심히 외쳤답니다!</p>
              <p>내향인인 전 처음엔 아주 약간 부끄러웠지만 하다 보니 분위기 자체가 모두가 호객행위를 하고 있고 또 주변 부스들과 경쟁 심리가 생겨서 점점 더 열심히 외치게 되더라구요ㅎㅎ</p>
              <p>언제나 프로다운 D 프로님은 처음부터 잘하셔서 제가 보고 많이 배웠어요!</p>
              <p>덕분에 바이어분도 많이 들리셨답니다~ D 프로님 최고~!</p>
              <img src="/images/blog/post76/post76_32.png" />
              <img src="/images/blog/post76/post76_33.png" />
              <img src="/images/blog/post76/post76_34.png" />
              <img src="/images/blog/post76/post76_35.png" />
              <p>응대 인원이 두명이라 사람들이 몰릴 때의 사진은 없지만..</p>
              <p>생각보다 많은 분들이 저희의 솔루션에 관심을 가져주셨어요!</p>
              <p>여러분께도 저희가 어떤 솔루션을 준비했는지 소개해 드릴게요.</p>
              <img src="/images/blog/post76/post76_36.png" />
              <img src="/images/blog/post76/post76_37.png" />
              <p><strong>첫째, 디지털 트윈 솔루션!</strong></p>
              <p>이번 년도에 출시된 ‘OSS’입니다~!</p>
              <p>초보자들도 게임처럼 쉽고 빠르게 시설물을 구축할 수 있는 저작도구인데요!</p>
              <p>실시간으로 모니터링과 AI 분석을 할 수 있어서 많은 분이 특히나 관심을 가져주셨어요!</p>
              <p>어플을 받으시면 데모도 체험하실 수 있으니 여러분도 많은 관심 부탁드려요~</p><br>
              <p><strong>둘째, 메타버스기반 교육 콘텐츠예요.</strong></p>
              <p>가상현실 속에서 실감 나는 교육을 받을 수 있답니다.</p>
              <p>중, 고등학생 뿐만 아니라 다양한 주제로 안전하고 효과적인 훈련이 가능하답니다~</p>
              <p>두 번째로 관심을 많이 가져주신 솔루션이에요~!</p>
              <img src="/images/blog/post76/post76_38.png" />
              <p><strong>셋째, 가상 전시관 솔루션</strong></p>
              <p>실제 전시장처럼 고품질의 가상 전시장을 구현해 실감 나는 비대면 전시가 가능하죠.</p>
              <p>전 세계 어디서든 전시회를 관람할 수 있습니다.!</p>
              <p>마지막으로, AI 다국어 통번역 솔루션입니다.</p>
              <p>현장에서 실시간으로 여러 언어를 번역해주니 저희의 의사소통이 한결 수월해졌죠!</p>
              <p>사용자 최적화된 챗봇 기능도 있답니다~!</p>
              <img src="/images/blog/post76/post76_39.png" />
              <img src="/images/blog/post76/post76_40.png" />
              <img src="/images/blog/post76/post76_41.png" />
              <img src="/images/blog/post76/post76_42.png" />
              <p>현장에서는 위 내용을 직관적으로 이해할 수 있는 영문 영상과 직접 솔루션을 체험해 보실 수 있는 체험 사이트 그리고 VR로 OSS를 체험 해보실수 있도록 구성하였답니다!</p>
              <p>저희가 열심히 준비한 만큼 많은 분들이 관심을 가져주셔서 뿌듯했어요~</p>
              <img src="/images/blog/post76/post76_43.png" />
              <img src="/images/blog/post76/post76_44.png" />
              <img src="/images/blog/post76/post76_45.png" />
              <img src="/images/blog/post76/post76_46.png" />
              <p>전시장을 나오면 앞에 로손과 세븐일레븐이 있고 또 전시장 내에 벤또를 저렴한 가격(850엔)에 판매하고 있어서 점심을 잘 챙겨 먹을 수 있었는데요ㅎㅎ</p>
              <p>바로 앞이 바다라 좋은 경치에 또 이 도시락들이 보기에는 한국 음식과 비슷해 보여도 일본만의 다른 맛이 느껴져서 즐겁게 먹었어요!</p>
              <p>그리고 곳곳에 자판기가 배치되어 있어 목마를 일도 없었답니다!</p>
              <img src="/images/blog/post76/post76_47.png" />
              <img src="/images/blog/post76/post76_48.png" />
              <p>이렇게 많은 사람이 다녀가시고 정신없이 응대하다 어느순간 정신차리니 전시회가 마무리되더라구요~ 아쉬워라ㅠ</p>
              <img src="/images/blog/post76/post76_49.png" />
              <img src="/images/blog/post76/post76_50.png" />
              <p>여러분~ 이번 포스팅을 통해 여러분도 뜨거웠던 전시회의 분위기를 조금이나마 느끼셨을까요~?</p>
              <p>다음 포스팅은 함께 출장을 떠났던 D 프로님이 도와주실 예정인데요!! 짝짝짝~</p>
              <p>그럼 도쿄 시티 투어 포스팅으로 돌아올게요~ 안녕~!👋</p>
            `,
    createDate: 'Posted on July 10, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '75',
    title: '2024 스마트공장·자동화산업전 방문!',
    content: `
              <img src="/images/blog/post75/post75_1.png" />
              <p>안녕하세요, 여러분! C 프로입니다~</p>
              <p>여러분과 함께 '2024 스마트공장·자동화산업전'의 방문 후기를 나누고자 해요.</p>
              <p>이번 전시회는 코엑스에서 열렸으며, 저희는 업계의 최신 트렌드를 파악하고 미래 기술의 방향성을 탐색하기 위해 참관했어요~!</p>
              <p>저희 회사와 깊은 연관이 있는 주제로, 정말 기대가 되는데요!! 같이 둘러볼까요?! &#x1F3ED; &#x1F4BB;</p><br>
              <p>'2024 스마트공장 • 자동화산업전'은 스마트 제조 및 자동화 기술의 최전선을 보여주는 자리였습니다~</p>
              <p>저희 아이지아이코리아는 디지털 트윈과 메타버스 솔루션을 개발하는 기업으로, 이 분야의 최신 기술과 시장 동향을 파악하는 것이 매우 중요해요!</p>
              <p>2024 스마트공장·자동화산업전은 2024년 3월 27일부터 29일까지 코엑스 전관(A, B, C, D홀)에서 열렸고 9개국, 500개 사, 2000 부스 참가로 역대 최대 규모였습니다.</p>
              <p>그래서 그런지 저희가 방문한 시간이 오픈 시간인 오전 10시였는데, 이미 굉장히 많은 사람들로 북적였어요!</p>
              <img src="/images/blog/post75/post75_2.png" />
              <p>저희는 핸드폰으로 미리 사전 정보등록을 완료한 후 입장 바코드를 발급해 놓았어요~</p>
              <p>그 후 현장 등록대에서 입장 바코드를 스캔하면 출입증이 인쇄되는데요~!</p>
              <p>미리 사전등록을 해놓으면 이렇게 현장에서 빠르고 쉽게 수령하실 수 있답니다!</p>
              <img src="/images/blog/post75/post75_3.png" />
              <img src="/images/blog/post75/post75_4.png" />
              <p>H 프로님과 함께 입구에서 가이드북을 챙겨 부스 배치도를 살펴보았는데 부스가 너무나도 많았어요!</p>
              <p>과연 어떤 경험을 하게 될까 벌써부터 두근두근!!!</p>
              <img src="/images/blog/post75/post75_5.png" />
              <img src="/images/blog/post75/post75_6.png" />
              <p>우와…입구에서부터 사람이 많아서 각오는 했지만, 내부에도 이렇게나 많다니…</p>
              <p>가지각색 활기찬 분위기에 두리번 두리번거리던 그때! 한 로봇이 저희의 시선을 이끌었어요.</p>
              <p>이렇게 사람도 많은 길 한복판에서 돌아다니다가 치이진 않을까 걱정이 되면서도 미래도시 같아서 감탄이 나오네요!</p>
              <p>사족보행을 하는 형태가 강아지 같아서 정말 귀엽지 않나요?? &#40;H 프로님은 귀엽진 않다고 하시네요ㅎㅎ…&#41;</p>
              <img src="/images/blog/post75/post75_7.png" />
              <img src="/images/blog/post75/post75_8.png" />
              <p>A홀과 B홀에는 Aimex, Korea Vision Show로 구성되어 로봇이 정말 정말 많이 전시되어 있었어요~!</p>
              <p>기계와 관련해서 포장, 배송, 조립, 제조라는 단어만 들었을 때에는 어떻게 자동화가 되어있는지 예상이 가질 않았는데요...</p>
              <p>직접 보니 아~ 저렇게 쓰이는구나, 유용하고 똑똑하게 기술이 발전해 나가고 있구나 라는 생각과 함께 입이 다물어지지 않더라구요.</p>
              <p>이렇게 다양한 종류의 자동화 로봇을 가까이에서 디테일하게 확인해 볼 수 있었어요~!</p>
              <p>저는 포장 기계가 제일 기억에 남았어요. 사람을 감지하면 멈추는 모습이 듬직해보였거든요ㅎㅎ</p>
              <p>H 프로님도 과거에 물류 알바를 하며 직접 랩핑하시던 시절 이야기를 꺼내시며 자동화된 포장 기계에 감탄을 하셨답니다!</p>
              <img src="/images/blog/post75/post75_9.png" />
              <img src="/images/blog/post75/post75_10.png" />
              <img src="/images/blog/post75/post75_11.png" />
              <img src="/images/blog/post75/post75_12.png" />
              <p>잠시 점심을 먹고 다음 전시로 넘어가 볼까요?</p>
              <p>사람이 붐비는 점심시간이라 어느 곳을 가든 오랫동안 기다려야 했는데요..ㅠㅠ</p>
              <p>코엑스의 수많은 식당 중 저희는 일식으로 선택!!</p>
              <img src="/images/blog/post75/post75_13.png" />
              <img src="/images/blog/post75/post75_14.png" />
              <p>줄도 섰고 많이 걸어 다녀 힘든 상태에서 식사를 하니 더욱 맛있었던 점심이었어요!</p><br>
              <p>든든하게 식사를 마치고 다시 전시회를 탐방했습니다~!</p>
              <p>C홀과 D홀로 돌아왔는데요. 이곳은 Smart Factory Expo로 구성되어 있었습니다.</p>
              <p>디지털 팩토리, 빅데이터, AI, 클라우드 컴퓨팅, 스마트 공장솔루션 등이 주제인 전시였어요.</p>
              <p>저희 디지털 트윈 파트의 개발을 맡고 계신 H프로님이 눈을 반짝이며 관심을 갖고 둘러보셨답니다~!</p>
              <img src="/images/blog/post75/post75_15.png" />
              <img src="/images/blog/post75/post75_16.png" />
              <p>여러분은 스마트팩토리에 대해 알고 계시나요?</p>
              <p>스마트팩토리는 단순히 공장에 기계 몇 대를 더하는 것이 아니라, 제조 과정 전체를 혁신하는 대단한 개념이에요.</p>
              <p>정보통신기술(ICT)을 기반으로 한 지능형 공장이며 사물인터넷(IoT), 빅데이터, 클라우드 컴퓨팅, 인공지능(AI) 등의 기술이 결합되어, 고객 맞춤형 제품을 최소 비용과 시간으로 생산할 수 있는 혁신적인 제조 시스템입니다.</p>
              <p>저희 아이지아이코리아에서도 스마트 팩토리를 구축할 수 있는 디지털 트윈 솔루션인 OnTwins가 있죠~</p>
              <p>전시회 관람을 통해 다양한 솔루션을 확인하면서, 우리 OnTwins에 어떤 요소를 더할 수 있을지 생각해 볼 수 있었어요ㅎㅎ</p>
              <img src="/images/blog/post75/post75_17.png" />
              <img src="/images/blog/post75/post75_18.png" />
              <img src="/images/blog/post75/post75_19.png" />
              <img src="/images/blog/post75/post75_20.png" />
              <p>국내외 500여 기업이 참여한 전시였기 때문에 하루로는 부족할 정도로 볼거리가 정말 많았는데요.</p>
              <p>저와 H 프로님에게 새로운 인사이트를 많이 준 소중한 경험이었어요~</p>
              <p>저희 아이지아이코리아의 기술이 더 많은 분야에서 활용될 수 있도록, 앞으로도 더 열심히 연구하고 개발해야겠다고 다짐하게 되었어요!</p><br>
              <p>내년에도 개최될 예정이라 하니 여러분도 기회가 된다면 방문해 보시고 아이지아이코리아의 OnTwins에도 많은 관심 부탁드려요! &#x1F31F;</p>
            `,
    createDate: 'Posted on April 4, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '74',
    title: '베트남 출장(2) - 성화비나 방문기',
    content: `
              <img src="/images/blog/post74/post74_1.png">              
              <p>베트남 출장 3일차의 아침이 밝았습니다~!!</p>
              <p>이날은 베트남 출장의 목적인 성화비나에 방문하는 날이에요!</p>
              <p>아이지아이코리아와 트윈 프로젝트를 진행하기로 한 성화비나의 공장 촬영 및 측량을 하기 위해 공장에 방문을 했는데요~</p>
              <p>성화비나 측에서 직접 차량을 보내주신 덕에 차로 숙소에서 한 시간 거리를 편하게 오갈 수 있었습니다!</p>
              <img src="/images/blog/post74/post74_2.png">
              <img src="/images/blog/post74/post74_3.png">
              <p>성화비나 내부에는 나무도 많고 심지어 연못도 있었는데요~</p>
              <p>전체적으로 깔끔하고 쾌적한 느낌을 받았습니다!</p>
              <p>촬영 날 비라도 오면 어쩌나 걱정했지만 다행히 날씨도 아주 화창했습니다~!</p>
              <img src="/images/blog/post74/post74_4.png">
              <p>촬영에 앞서 잠시 미팅 시간을 가졌는데요!</p>
              <p>화기애애한 분위기 속에 진행된 미팅에서는 아이지아이코리아에 대한 소개 및 프로젝트 설명과 현장 촬영에 대한 간단한 미팅이 이루어졌습니다!</p>
              <p>성화비나 측에서 다양한 사전 자료를 제공해 주시는 등 협조를 잘해주신 덕분에 수월하게 촬영 및 측량을 진행할 수 있었습니다~!</p>
              <img src="/images/blog/post74/post74_5.png">
              <p>오전 촬영을 마치고 점심시간을 가졌는데요~</p>
              <p>오늘 점심으로는 한식을 준비해 주셨습니다!!</p>
              <p>베트남에서 먹는 비빔밥이라니~</p>
              <p>심지어 취향에 따라 먹을 수 있게 간장 소스와 고추장 모두 준비해 주셨답니다!</p><br>
              <p>성화비나에서 식사를 담당해 주시는 이모님께서는 베트남 분이심에도 불구하고 한국 직원분들을 위해 한식을 준비해 주신다고 하네요~!</p>
              <p>유튜브를 보며 한식 레시피를 배우셨다고 하는데 정말 너무 맛있었어요! 한식당 차리셔도 될 듯합니다..!!</p>
              <img src="/images/blog/post74/post74_6.png">
              <p>점심 식사를 한 후 잠깐 쉬는 시간에 베트남의 더위에 익숙하지 않아 힘들어하던 저희를 위해 성화비나에서 코코넛을 준비해 주셨습니다!!</p>
              <p>실제 코코넛은 처음 먹어보는데 생각보다 너무 맛있더라구요~!</p>
              <p>덕분에 수분&amp;당분 보충을 하고 나머지 촬영도 빠르게 완료했습니다!</p>
              <img src="/images/blog/post74/post74_7.png">
              <p>모든 촬영 및 미팅을 마치고 단체 사진 한 컷 찍었습니다~!</p>
              <p>따뜻하게 맞이해주신 덕분에 이번 출장이 아주 좋은 기억으로 남았습니다!!</p>
              <img src="/images/blog/post74/post74_8.png">
              <p>무사히 성화비나 방문을 마치고 대표님께서 추천해 주신 분짜 맛집에 방문했습니다!</p>
              <p>바로 호치민 7군에 위치한 ‘분짜 하노이’인데요~</p>
              <p>저녁 7시에 문을 닫는 곳이기 때문에 촬영을 마치고 바로 식당으로 달려갔습니다!!</p>
              <img src="/images/blog/post74/post74_9.png">
              <img src="/images/blog/post74/post74_10.png">
               <img src="/images/blog/post74/post74_11.png">
              <p>드디어 음식이 나왔는데요..!</p>
              <p>와.. 충격.. 제가 그동안 먹은 건 분짜도 아닌 것 같았어요..!!</p>
              <p>한국에서 먹었던 분짜들은 따뜻한 국물도 아니었고 넴도 이렇게 바삭하지 않았었는데, 현지 분짜를 먹어보고 나서 비로소 분짜의 맛을 알게 되었습니다..!!</p>
              <p>대표님의 강력 추천으로 숯불구이를 추가했는데요~</p>
              <p>저 산더미 같은 쌀국수 두 접시를 모두 해치울 정도로 너무 맛있었습니다!!</p>
              <img src="/images/blog/post74/post74_12.png">
               <img src="/images/blog/post74/post74_13.png">
              <p>분짜를 배불리 먹고 숙소로 돌아가기 아쉬워서 카페를 가기로 했는데요~ (다들 후식 배는 따로 있잖아요 ㅎㅎㅎ)</p>
              <p>근처에 있는 콩카페로 왔습니다~!</p>
              <p>콩카페 역시 베트남에서 아주 유명한 커피 체인점인데 코코넛 커피가 메인 메뉴였어요!</p>
              <p>달달하고 시원한 코코넛 커피로 무더웠던 하루의 피로가 싹 날아가는 기분이었습니다~~</p>
              <img src="/images/blog/post74/post74_14.png">
              <p>숙소에 돌아와 아쉬운 마음에 마지막 밤의 야경을 담아봤습니다~!</p>
              <p>2월 호치민의 밤은 낮과 달리 선선한 편이었고, 건물들의 화려한 조명 덕에 야경이 매력적이었습니다!</p>
              <p>숙소에서 내려다본 풍경인데 너무 예쁘지 않나요~?</p>
              <img src="/images/blog/post74/post74_15.png">
              <img src="/images/blog/post74/post74_16.png">
              <p>마지막 날 아침 일찍 다 함께 쌀국수를 먹으러 갔는데요~</p>
              <p>가려던 식당이 아직 문 열기 전이라 베트남까지 와서 쌀국수 한 번 못 먹고 갈 뻔..</p>
              <p>하지만! 즉흥적으로 찾아간 근처 쌀국수 집 역시 엄청난 맛집이었어요!!</p>
              <p>고기가 듬뿍 들어간 거 보이시나요..!</p>
              <p>그리고 넴은 필수! 정말 어떻게 이렇게 바삭할 수가 있나요.. 한 끼도 빠짐없이 맛있게 먹다 왔습니다~~</p>
              <img src="/images/blog/post74/post74_18.png">
              <img src="/images/blog/post74/post74_19.png">
              <img src="/images/blog/post74/post74_20.png">
              <img src="/images/blog/post74/post74_21.png">
              <p>든든하게 아침식사를 마치고 점심 비행기를 타기 전까지 자유시간을 가졌는데요~</p>
              <p>대표님께선 베트남에서의 남은 일정이 있으셔서 아쉽지만 숙소로 가셔야 했습니다..</p>
              <p>그리고 베트남 마사지의 신세계를 경험하신 부사장님은 자유 시간에 마사지를 한 번 더 받으러 가셨답니다!!</p><br>
              <p>나머지 인원들은 쇼핑팀과 카페 투어팀으로 나누어 시간을 보냈습니다!</p>
              <p>쇼핑을 위해 찾아간 현지의 마트는 정말 신기한 게 많았는데요~</p>
              <p>생각보다 한국 제품들도 많았고 동남아답게 과일이 정~말 많았어요!!</p>
              <p>이곳에서 선물도 사고 다양한 물건들을 구경하며 시간을 보냈습니다~~</p><br>
              <p>카페 투어 팀은 카페를 두 군데나 다녀왔습니다!</p>
              <p>첫 번째로 방문한 카페는 로컬의 감성이 그대로 느껴지는 카페였는데요~</p>
              <p>카페 사장님께서 한국어가 유창하셔서 대화도 나눴답니다ㅎㅎ</p>
              <p>두 번째로 방문한 카페는 깔끔하고 세련된 느낌의 카페에 방문했습니다!</p>
              <p>첫 번째 카페에서 커피를 마셨기 때문에 소다에이드를 시켜봤어요~</p>
              <p>분위기가 상반된 두 카페에서 힐링하며 베트남에서의 마지막 추억을 쌓았습니다!</p>
              <img src="/images/blog/post74/post74_22.png">
              <img src="/images/blog/post74/post74_23.png">
              <img src="/images/blog/post74/post74_24.png">
              <img src="/images/blog/post74/post74_25.png">
              <p>공항에 도착해서 탑승수속을 마치고 면세점 구경을 하며 시간을 보냈습니다!</p>
              <p>출국장 내에 반미를 파는 곳이 많았는데요~</p>
              <p>반미를 한 번도 못 먹은 게 아쉬운 마음에 마지막으로 출국하기 전 반미를 나눠 먹으며 알차게 베트남 음식들을 경험했습니다!!</p>
              <img src="/images/blog/post74/post74_26.png">
              <img src="/images/blog/post74/post74_27.png">
              <p>6시간에 달하는 비행을 대하는 자세!! </p>
              <p>숙면을 위해 야무지게 담요를 두르고 기절한 3인방..ㅎㅎ </p>
              <p>그렇게 모두 무사히 한국으로 돌아왔습니다~!!</p><br>
              <p>이번 베트남 출장은 다양한 경험들을 해볼 수 있었던 좋은 기회였고, ‘맛잘알’이신 대표님 덕에 맛있는 것들을 잔뜩 먹으며 행복했던 출장이었습니다!!</p>
              <p>올해 좋은 시작을 발판 삼아 열심히 달려보겠습니다~!!</p><br>
              <p>이만 마치겠습니다~ 다시 즐거운 글로 돌아올게요~!!</p>
            `,
    createDate: 'Posted on Mar 15, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.691Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '73',
    title: '베트남 출장(1) - 시티 투어!',
    content: `
              <img src="/images/blog/post73/post73_01.png">
              <p>안녕하세요! 아이지아이코리아입니다~!</p>
              <p>이번 이야기는 베트남 출장 이야기예요!</p>
              <p>저희는 지난 2월 말에 ‘성화비나’와의 트윈 프로젝트를 위해 성화비나의 공장이 위치한 베트남 호치민에 방문을 했습니다!!</p>
              <p>4일간의 출장 기간 중에 성화비나는 3일차 일정에 방문했는데요~</p>
              <p>3박 4일간의 출장 동안 어떤 것들을 했는지 같이 보도록 할까요~?</p>
              <img src="/images/blog/post73/post73_02.png">
              <p>베트남행 비행기를 타기 위해 아침 일찍 모인 출장 인원들!</p>
              <p>출국 전에 마지막으로 다 함께 사진 한 컷 찍었어요~!</p>
              <p>대표님과 부사장님 두 분께서 나란히 양옆에 계시니까 마치 수학여행 인솔하는 선생님과 학생들 같네요 ㅎㅎㅎ</p>
              <p>출국 날 한국 날씨는 매우 춥고 심지어 눈까지 왔는데요~</p>
              <p>호치민은 2월에도 기온이 30도가 넘는다고 해요! </p>
              <p>오랜만에 느끼는 여름 날씨를 위해 여름 옷들을 잔뜩 챙겨갔답니다!</p>
              <img src="/images/blog/post73/post73_03.png">
              <p>6시간의 비행 끝에 무사히 호치민에 도착을 했습니다!!</p>
              <p>베트남은 우리나라보다 2시간이 느리기 때문에 6시간을 날아왔지만 아직 한낮이었어요!</p>
              <p>오랜만에 느끼는 한낮의 여름 날씨에 모두 더워했는데요~</p>
              <p>도착하자마자 체크인을 기다리며 근처에 있는 카페에서 아이스 커피부터 사 왔답니다 ㅎㅎㅎ</p>
              <img src="/images/blog/post73/post73_04.png">
              <img src="/images/blog/post73/post73_05.png">
              <img src="/images/blog/post73/post73_06.png">
              <img src="/images/blog/post73/post73_07.png">
              <img src="/images/blog/post73/post73_08.png">
              <img src="/images/blog/post73/post73_09.png">
              <p>숙소에 체크인을 하고 짐을 정리한 뒤에 저녁식사로 대표님께서 저희들을 위해 예약해 주신 호텔 뷔페에 갔어요.</p>
              <p>와!! 글로는 설명할 수 없는 정말 맛있는 음식들이 잔뜩 있더라구요!</p>
              <p>그리고 무려 랍스터나 굴 등 메인 요리의 주문이 무제한으로 가능했답니다!! 살면서 랍스터를 가장 많이 먹은 날이었어요 ㅎㅎㅎ</p>
              <p>그중에서도 크림치즈가 올라간 굴 요리가 저희 대표님의 ‘원픽’인데요~!</p>
              <p>완전 ‘고소, 담백’ 그 자체..!! 너무 부드러워서 한입 가득 넣어도 바로 녹는 거 있죠?! (사진 보니까 또 가고 싶어졌어요! 흑ㅜㅜ)</p>
              <p>여긴 다른 메뉴들도 맛있었지만 해산물 요리 천국이에요. 해산물 좋아하시는 분은 베트남 오면 꼭 가보기! 완전 강추!!</p>
              <img src="/images/blog/post73/post73_10.png">
              <img src="/images/blog/post73/post73_11.png">
              <p>2일차는 자유시간이 주어졌는데요! 자유시간을 즐기기 위해 다들 아침부터 일찍 일어나 일정을 시작했어요~</p>
              <p>첫 일정으로는 숙소에서 걸어서 5분 거리의 브런치 카페에 갔는데요. 이른 시간이라 그런지 손님이 저희 밖에 없더라구요~! 덕분에 분위기 좋은 브런치 타임을 가졌답니다~~</p>
              <p>저희는 몇 가지 다양한 브런치 메뉴들을 주문했는데요~ 에그 베네딕트와 잉글리시 브렉퍼스트 등의 메뉴와 함께 코코넛과 콜드브루도 한 잔~!</p>
              <p>든든하지만 부담 없는 아침을 먹고 하루의 시작을 기분 좋게 시작했답니다~~</p>
              <img src="/images/blog/post73/post73_13.png">
              <img src="/images/blog/post73/post73_14.png">
              <p>밥을 먹으며 저희 프로님들 사진을 찍어드렸어요!</p>
              <p>아니!! 다들 너무 해맑으신 거 아닌가요~~??</p>
              <p>모자이크를 뚫고 나오는 미소예요!</p>
              <p>세 분이서 해맑게 웃는 모습을 보니 저까지 미소가 지어져요!!^-^</p>
              <img src="/images/blog/post73/post73_15.png">
              <img src="/images/blog/post73/post73_16.png">
              <p>다음으로 간 장소는 호치민에 갔으면 꼭 한 번은 가봐야 한다는 ‘벤탄 시장’이에요!</p>
              <p>우리나라 같은 시장을 기대하고 갔는데 우리나라 시장보다 정리 정돈이 잘 되어있는 것 같아 깜짝 놀랐어요!</p>
              <p>걷다 보면 주변 상인분들께서 한국말로 호객행위를 하시는 모습을 볼 수 있어요~</p><br>
              <p>L프로님께서는 벤탄 시장에서 티셔츠를 한 장 사셨는데요!</p>
              <p>로고가 베트남 전통 모자를 쓰고 있는 스타벅스 로고인 거 있죠?ㅎㅎㅎ</p>
              <p>그 그림에 꽂히셔서 열심히 흥정까지 해가며 옷을 샀어요. 무려 반값 이상으로 깎았어요!!!! (흥정은 K 프로님이 열심히 해 주셨답니다~~)</p>
              <img src="/images/blog/post73/post73_17.png">
              <img src="/images/blog/post73/post73_18.png">
              <p>짜잔~~ 여기선 S프로님의 인생 샷도 건졌어요.</p>
              <p>벤탄 시장을 갔다가 더위를 식힐 겸 온 카페인데요~</p>
              <p>테라스 분위기가 좋아서 사진이 저엉말 잘 나와요!!</p>
              <p>이곳은 에그 커피로 유명한 카페였어요. 계란이 들어간 커피라니.. 맛이 예상되시나요?!</p>
              <p>커피 위에 올라간 크림이 계란으로 만든 크림이라 커스터드 크림 맛이 나는 커피였어요~!</p>
              <img src="/images/blog/post73/post73_19.png">
              <img src="/images/blog/post73/post73_20.png">
              <p>두둥. 다음 장소는 프로님들 사이에서 제일 극찬이었던 마사지 숍인 22SPA예요!</p>
              <p>대표님께서 추천해 주셔서 방문하게 되었어요~</p><br>
              <p>예약할 때까지만 해도 다들 큰 기대는 없으셨는데요, 그 생각은 90분 후…</p>
              <p>완!전! 180도 바뀌었답니다! 머리부터 발끝까지 강도를 적어서 제출하면</p>
              <p>그 강도에 맞게 마사지를 받을 수 있었어요.</p><br>
              <p>정말 엄~청 시원한 거 있죠?! 100년 치 담이 싹 사라진 기분이었어요~</p>
              <p>또 우리 디자이너, 개발자분들은 항상 거북목을 달고 살잖아요! 그치만 저흰 마사지 받고 거북이에서 기린이 된 건 안 비밀~⸜( 'ᵕ')⸝</p>
              <img src="/images/blog/post73/post73_21.png">
              <p>마사지를 받고 간 곳은 로컬 감성이 가득한 분팃느엉 가게인데요~</p>
              <p>마사지 숍에서 걸어서 1분 거리! 바로 근처에 K프로님이 찾아 놨던 분팃느엉(비빔쌀국수) 맛집이 있길래 이곳으로 향했어요!</p>
              <p>쌀국수에 짜조와 숯불고기가 들어가 있고 야채를 원하는 만큼 넣어 새콤달콤한 소스를 넣어 비며 먹는 음식이었어요~ 한국에서 먹는 분짜랑 비슷한 느낌!</p>
              <p>쌀국수도 무한 리필인데 한 그릇에 겨우 3만 동 (한화로 약 1500원)이라니!!</p>
              <p>가격도 착한데 엄청 맛있었던 거 있죠?! 어쩐지 구글맵이나 블로그에 한국인들의 후기가 많더라구요~ 여기도 강추!!</p>
              <img src="/images/blog/post73/post73_22.png">
              <img src="/images/blog/post73/post73_23.png">
              <img src="/images/blog/post73/post73_24.png">
              <img src="/images/blog/post73/post73_25.png">
              <p>배 든든하게 채우고! 이번에는 베트남의 역사를 엿볼 수 있는 호치민시 미술관에 왔어요. </p>
              <p>미술관은 ㅁ자 구조의 특이한 건물이었는데 건축양식이 정말 아름답죠? 예쁜 건물에서 다양한 작품들을 구경하며 시간을 보냈어요~</p>
              <p>사진 중에는 작품에 심취하신 Y프로님도 볼 수 있어요 ㅎㅎㅎ 분위기 있죠?</p>
              <img src="/images/blog/post73/post73_26.png">
              <p>영롱한 핑크빛의 떤딘 성당도 구경했어요.</p>
              <p>핑크 성당이라고도 불리는 떤딘 성당은 시내 투어 필수 코스로 유명하더라구요~ 안 가볼 수 없죠!</p>
              <p>파란 하늘과 잘 어울리는 핑크색 성당! 너무 예쁘지 않나요~?</p>
              <p>아쉽게도 내부에 들어가 보진 못했지만 화사한 건물을 구경하고 사진도 한 컷 찍어보았어요~!</p>
              <img src="/images/blog/post73/post73_27.png">
              <img src="/images/blog/post73/post73_28.png">
              <p>도시 구경이 끝나고 저녁때가 되어 저희는 대표님, 부사장님과 함께 꼬치구이 집에 방문했어요! </p>
              <p>새우, 문어, 닭, 소, 돼지고기 등의 다양한 꼬치류와 콜리플라워 샐러드까지 정말 맛있는 음식을 잔뜩 먹었어요~ </p>
              <p>새우가 얼마나 신선한지 꼬치에 꽂아져 있는데도 새우가 살아 움직였다니까요..!!</p>
              <p>베트남 맥주와 너무 잘 어울리는 꼬치들을 배불리 먹고 왔답니다~!</p>
              <img src="/images/blog/post73/post73_29.png">
              <img src="/images/blog/post73/post73_30.png">
              <p>2일차의 마무리는 숙소에서 맛있는 과일들과 함께 한 잔!</p>
              <p>대표님께서 저희를 위해 조니워커 블루라벨을 오픈해 주셨어요...! 대표님 덕에 처음 마셔봤답니다 ㅎㅎㅎ</p>
              <p>망고와 잭 프루트 등 과일들을 먹으며 무겁지 않은 분위기에서 다들 가볍게 한 잔씩 마시며 도란도란 대화를 나누는 시간이었는데요~</p>
              <p>대표님께서 앞으로 저희가 어떤 방향으로 나아가야 하는지,</p>
              <p>회사의 미래에 대한 이야기나 따뜻한 조언 등 다양한 이야기들을 서로 주고받는 시간을 가지며 2일차를 마무리했습니다~</p><br>
              <p>이상 아이지아이코리아의 베트남 출장 포스팅 1편이었습니다~!</p>
              <p>우리 2편에서 만나요~~</p>
            `,
    createDate: 'Posted on Mar 15, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.683Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '72',
    title: '아이지아이코리아 2024 한국 CDE 학회 참가!',
    content: `
              <img src="/images/blog/post72/post72_5.png">
              <p>안녕하세요~! 아이지아이코리아 J프로입니다!</p>
              <p>저는 얼마 전 2024 한국 CDE 학회 동계학술대회(1월29일-2월1일)에 다녀왔는데요!</p>
              <p>일부 기간 학회에 참가하여 다양한 경험과 지식을 습득하고 공유했던 시간을 함께 나눠보려 합니다.</p>
              <p>이번 CDE학회에는 “디지털 트윈 기반 스마트제조 응용 기술”이라는 세션 주제에 맞춰 저를 포함한 3명의 동료분이 각자의 주제로 발표를 진행했는데요!</p>
              <p>저희 아이지아이코리아가 추구하는 방향과 진행하고 있는 활동을 공유하고 논의하는 시간이기에 더욱 의미 있는 시간이었습니다.</p>
              <p>또한 비슷한 분야의 연구를 하고 있는 다른 전문가분들의 연구 결과와 연구 동향을 파악하고, 다양한 논의가 이뤄지는 시간들을 경험하고 왔습니다.</p>
              <p>그리고 발표가 끝난 뒤 주어지는 즐거운 보상 같은 시간들까지 있었는데요.</p>
              <p>자세한 내용 궁금하시죠?? 자 그럼! 1박 2일간의 CDE학회에서 어떤 일들이 있었는지 바로 확인해 보실까요?!</p><br>
              <p>시간을 돌려서... 1월 30일 오전 6시! 저희는 10시까지 CDE학회가 열리는 “평창 휘닉스 파크“로 가기 위해 모였습니다.</p>
              <p>오늘의 드라이버는 S프로님! 아침부터 저희 집앞부터 와주시고 그댄... 아마도 1004?</p>
              <p>아직 캄캄한 오전에 저희는 설렘 반 떨림 반으로 투머치 토킹을 하며 강원도로 달렸습니다.</p>
              <img src="/images/blog/post72/post72_1.png">
              <img src="/images/blog/post72/post72_2.png">
              <p>한 시간 정도 달리니 날이 밝아졌는데요!!</p>
              <p>순간 여행가나…? 라고 착각하게 할 만큼 산뜻한 기분과 멋있는 경치가 펼쳐지더라구요.</p>
              <p>이것이 바로 출장의 묘미죠. ㅎㅎ</p>
              </br>
              <p>9시쯤 되니 날이 화창하게 밝고 저희도 어느덧 평창 휘닉스 파크에 도착했습니다!!</p>
              <p>휘닉스 파크는 강원도 평창군에 위치한 대규모 리조트로, 다양한 숙박시설과 레저와 휴식을 누릴 수 있는 공간이 있는데요.</p>
              <p>이뿐 아니라 휘닉스 파크 호텔에는 학회, 회의, 워크숍 등 다양한 오프라인 이벤트를 개최할 수 있는 공간이 존재합니다.</p>
              <img src="/images/blog/post72/post72_3.png">
              <img src="/images/blog/post72/post72_4.png">
              <p>예상하셨다시피 저희의 목적지는 학회가 열리는 휘닉스 파크 호텔인데요.</p>
              <p>내부 2층으로 들어서니 바쁘게 현장 참가자 등록을 하고 있기에 저희도 부랴부랴 등록을 진행했습니다.</p>
              <img src="/images/blog/post72/post72_5.png">
              <p>그러자 이름표와 식권, 일정표를 챙겨주셨습니다.</p>
              <p>“연사”라고 적혀진 스티커를 이름표 하단에 붙이면서 더욱 오늘 발표에 대한 책임감과 설렘이 느껴졌는데요.</p>
              <p>저희 오늘 잘할 수 있겠죠??</p>
              </br>
              <p>사전 현장 등록을 마치고 오전 발표를 들으러 왔습니다! 발표는 약 6개 홀에서 여러 주제의 세션이 동시에 진행되는 식이었습니다.</p>
              <p>저희는 각자 관심 있는 주제의 세션이 진행되고 있는 홀로 흩어져서 발표를 듣고 오기로 하고 잠시 헤어졌는데요.</p>
              <p>저는 IoT/AI 세션을 듣기 위해 해당 세션이 열리고 있는 강연장으로 이동했습니다.</p>
              <p>마침 “오픈데이터셋 활용 제조 공정 작업자 손 끼임 인식 모델 연구” 주제에 대한 발표가 진행되고 있었는데요.</p>
              <p>해당 세션을 요약하면 다음과 같았답니다.</p>
              <p>다양한 제조 현장에서 센서를 통한 안전관리에 대한 필요성이 요구되지만, 현장에서 사용되는 안전 센서 기술은 매우 고가이고 사용하기 어려운 점과 함께, ICT 도입이 어려운 이유를 ICT 장점에 대한 인식 부족 그리고 기술 및 인력 부족 등을 원인으로 이야기하였습니다.</p>
              </br>
              <p>이에 연사께서 저비용으로 스마트 제조 구축 및 산업 재해 감소를 위한 안전 기술 연구가 필요성을 언급하며, 특히 중소기업에서 사용할 수 있는 손 끼임 방지 및 예방을 위한 손 끼임 인식 모델 구축에 대한 연구 내용을 이야기해 주셨습니다.</p>
              <p>세션을 듣고 다양한 중소기업의 제조 환경을 고려하기 위해 고민하는 부분이 저희의 고민과 비슷하다고 느꼈고, 제조업체의 대부분이 중소기업인 것을 고려하여 안전 센서 기술의 저비용 구축과 활용 방안을 연구 대상으로 설정하여 전달해 주신 전반적인 발표 내용들이 너무나 흥미롭게 다가왔습니다.</p>
              <img src="/images/blog/post72/post72_6.png">
              <p>이후 2개 정도의 세션을 듣고 오전 세션이 끝나자, 저희는 잠시 발표 준비를 하러 휴게실에 들렀습니다.</p>
              <p>사실 차 타고 오는 길은 별로 떨리지 않았는데 막상 강연장에 도착하고 다른 분들의 발표와 약간의 엄숙하고도 진지한 현장 분위기를 느끼자 살짝 긴장되더라구요...</p>
              <p>다들 각자의 발표 내용을 잘 전달하기 위해 마지막까지 점검하고 연습하며 열심히 노력했답니다.</p>
              <img src="/images/blog/post72/post72_7.png">
              <p>말 걸면 안 될 것 같은 초집중 상태의 뒷모습… 우리 오늘 잘해야 해요…</p>
              <p>입꼬리를 올리기 힘든 심리상태였지만 끝나면 추억이 될 것을 생각해서 셀카 한번 찍었구요.</p>
              <img src="/images/blog/post72/post72_8.png">
              <p>11시 30분부터 1시까지 점심시간이었기에 저희는 주어진 식권으로 점심식사를 하기위해 이동했습니다.</p>
              <p>점심은 정해진 메뉴가 나오는 형식이었는데 따듯한 가정식 느낌으로 특히 국이 시원하고 맛있더라구요!!</p>
              <img src="/images/blog/post72/post72_9.png">
              <p>다시 연회장... 이어지는 세션을 마저 듣고 드디어 저희 발표 차례가 다가왔습니다!</p>
              <p>저희 세션은 “디지털 트윈 기반 스마트제조 응용 기술”이라는 제목으로 총 5팀이 발표를 진행했는데요.</p><br>
              <p>한양대학교 팀에서 “홀로닉 팩토리를 위한 자산관리 쉘 기반 디지털 트윈 모델 설계”를 주제로,</p>
              <p>과학기술대학교 팀에서 “인간과 로봇 협업 공간 내의 충돌 감지 분석을 위한 디지털 트윈 기술 개발”이라는 주제로 저희 세션에서 함께 발표해 주셨습니다.</p><br>
              <p>그리고 다른 3팀이 저희 아이지아이코리아 프로님들의 발표였는데요!!</p><br>
              <p>먼저 P프로님이 “제조업의 보편적 디지털 트윈 구축을 위한 고려 사항” 이라는 주제로 발표를 진행하셨습니다.</p>
              <img src="/images/blog/post72/post72_10.png">
              <p>P프로님의 발표는 “제조업의 보편적 디지털트윈 구축을 위한 고려 사항” 이라는 주제였습니다.</p>
              <p>먼저 제조업에서 많은 관심을 받고 있는 개념인 디지털 트윈에 대한 설명에 이어서 보편적 디지털 트윈에 대해 설명해 주셨습니다.</p>
              <p>보편적 디지털 트윈은 다양한 산업에서 공통적으로 사용할 수 있는 디지털 트윈을 뜻하며 지속적으로 업데이트되는 디지털 트윈을 뜻하는데,</p>
              <p>이를 위한 선결 조건 및 고려 사항에 대해서 데이터 수집의 표준화, 지속적인 파이프라인, 지속적인 분석 모델, 클라우드 활용, 그리고 트윈 정의 언어 사용 등 다양한 요소를 적절히 조합하여 보편적 디지털트윈을 구축하고 유지보수를 용이하게 한다는 내용으로 발표를 진행해 주셨습니다.</p>
              <p>그 옆에는 저희 부사장님이 좌장의 역할로 옆에 앉아계셨는데요.</p>
              <p>저희 세션의 발표들을 진행하고 통제하는 역할로써 함께 해주셨습니다.</p>
              <p>여러 발표를 진행하고 질의응답 시간에서 부사장님이 어떤 질문이 오더라도 적절하고 질문자가 이해하기 쉽도록 답변을 하시는 모습을 보며 또 한 번 존경스러웠는데요!</p>
              <p>저희도 이런 모습을 닮아갔으면 좋겠다는 마음이 들었습니다.</p><br>
              <p>다음으로 저!! J프로의 발표 순서였습니다.</p>
              <img src="/images/blog/post72/post72_11.png">
              <p>저는 “제조 디지털 트윈 기반 도메인 주도 모바일 앱 개발 방법”이라는 주제로 발표하게 되었는데요!</p>
              <p>저는 제조 환경에서 스마트폰 및 태블릿과 같은 모바일 디바이스가 왜 필요한지와 제조 환경에서 모바일 디바이스를 어떻게 활용하고 어떤 장점을 얻을 수 있는지 제안하는 반면!</p>
              <p>제조 디지털 트윈 시장에서 모델, 제조사 등 다양한 모바일 디바이스, 분화되어 있는 모바일 디바이스의 운영체제, 공장별 기업별로 너무나 다양한 제조 환경 요구사항으로 인해 실제 모바일 디바이스를 도입하기 어려운 이유를 이야기하였습니다.</p>
              <p>때문에 메이저IT회사가 일관적이고 표준적인 서비스를 제공하기 어렵고, 이로 인해 앱 개발 전문가가 아닌 사용자 중심의 
              즉, 도메인 주도의 No Code / Low Code 앱 개발 플랫폼이 필요함을 주장하였고, no code 툴을 사용하여 디지털 트윈 모바일 앱의 기능의 구현을 수행할 수 있음을 보였습니다.</p><br>
              <p>마지막으로 S프로님의 발표는 “웹 기반 가상 전시 플랫폼의 개발 고려 사항 : 현실적 오브젝트 제작과 성능 최적화”이었는데요.</p>
              <img src="/images/blog/post72/post72_12.png">
              <p>S프로님은 먼저 가상 전시에 대한 정의를 소개해 주셨습니다.</p>
              <p>가상 전시는 현실에서의 제품이나 작품 전시를 네트워크 기반으로 가상환경에서 체험할 수 있는 플랫폼 기반 서비스인데요.</p>
              <p>서비스 방향 중 하나는 사용자가 웹을 통해 가상 전시를 편리하게 이용하고 접근할 수 있도록 웹 접근성을 개선하는 것이라고 말씀해 주셨습니다.</p>
              <p>그 후에는 사용자 경험에 직접적인 영향을 미치는 현실적 오브젝트 제작과 웹 환경에서의 최적화 작업에 대한 내용을 공유해주셨고, 
              마지막으로 다양한 전시물 유형과 표준에 대한 정의를 통해 발표를 마무리하셨습니다.</p><br>
              <p>이러한 3분의 발표는 아이지아이코리아가 추구하는 목표와 방향과 부합하기도 한데요!!</p>
              <p>이 기회를 발판 삼아 저희가 진행 중인 활동과 연구를 홍보하여 더 넓은 범위로 알릴 수 있으면 좋겠다는 마음입니다!</p><br>
              <p>이렇게 모든 세션이 끝나니 6시 정도 되었습니다... 이제야 긴장이 풀리면서 배도 고파지더라구요.</p>
              <p>오늘 함께 세션에 참가했던 과학기술대학교, 한양대학교 교수님 그리고 대학원생분들과 함께 식사를 하러 평창 “흔들바위” 식당으로 이동했습니다!</p>
              <img src="/images/blog/post72/post72_13.png">
              <img src="/images/blog/post72/post72_14.png">
              <p>흔들바위 식당은 정갈한 한정식 맛집이었는데요!</p>
              <p>흔들바위 식당 외부에서 바라본 스키장이 너무 아름답게 빛나고 있어서 한 컷 남겼습니다.</p>
              <p>내부로 들어가 조금 기다리니 사장님께서 반찬 세팅을 해주셨는데요!</p>
              <p>저희는 산채정식으로 메뉴를 통일하고 고등어구이를 추가했습니다!!!</p>
              <p>짜잔~ 너~~~무 맛있어 보이지 않나요??</p>
              <p>저는 한정식을 너무나 좋아하는데 오늘 저녁 메뉴는 완전히 제 취향을 저격했어요.</p>
              <img src="/images/blog/post72/post72_15.png">
              <img src="/images/blog/post72/post72_16.png">
              <p>황태구이 위에 올려진 파가 너무 먹음직스럽지 않나요?</p>
              <p>뒤에 보이는 튀김도 갓 튀겨주셔서 바삭바삭하고 부드러웠는데!!! 중요한 것은 리필을해주십니다...</p>
              <p>여러분 리필이 되어요...</p>
              <p>혹시나 나중에 학회나 일이 있어 평창 휘닉스파크에 오시게 되신다면 “흔들바위” 꼭 가보세요!!</p><br>
              <p>식사 자리를 함께한 교수님들과 학생분들과 아이지아이코리아 동료들과 한 컷!!</p>
              <p>이날 이 식사 자리에는 모든 테이블에서 웃음꽃이 피었답니다.</p>
              <p>특히나 과기대 학생분들과 CDE학회를 준비했던 과정과 열심히 공부 중인 현재의 모습들을 설명해 주시는 시간들은 저희에게 너무나 좋은 자극제가 되었습니다!!</p>
              <p>그리고 과기대 분들은 남녀노소 훈훈하시더라구요.. 하지만 아이지아이도 그에 못지않죠...(자신감)</p>
              <img src="/images/blog/post72/post72_17.png">
              <p>이렇게 CDE학회 일정은 마무리되었습니다.</p>
              <p>...일까요???</p>
              <p>그렇지 않죠!!!!! 부사장님이 과기대 분들과 볼링치고 오라며 한턱 쏘셨는데요!!</p>
              <p>그로 인하여... 아이지아이코리아 VS 과기대의 볼링 승부가 열렸습니다.</p>
              <p>그 어떤 때보다 강하게 느껴지는 연대감과..팀워크..결속력... 이 정도면 주기적으로 볼링 한번 해야겠는데요...ㅎㅎ</p>
              <img src="/images/blog/post72/post72_18.png">
              <p>한 시간전만 해도 피곤해 보이던 얼굴들이 생기로 가득 차기 시작하던데요.</p>
              <p>저는 이분들 텐션 이렇게 높은지 몰랐잖아요...ㅎㅎ</p>
              <img src="/images/blog/post72/post72_19.png">
              <img src="/images/blog/post72/post72_20.png">
              <p>장렬하게 도랑으로 가더라도 괜찮다고 박수쳐주는 모습... 우린 하나다!!!!</p>
              <p>여러분 그거 아시나요...? 저희 회사에 볼링 선수급에 준하는 프로님이 있는데요... 바로 S프로님이에요!!!</p>
              <p>프로가 혹시 볼링프로...(쿨럭)</p>
              <p>S프로님이 월등한 점수를 달렸지만, 나머지 저희 둘이 점수를 다 까먹는 바람에 이번 볼링 승부는 과기대 분들의 승리였습니다!!</p>
              <p>연습하고 올게요... S프로님... 미안해요…</p><br>
              <p>이렇게 하루가 마무리되었는데요... 새벽부터 달려온 하루가 그 어떤 때보다 고단하지만 더욱 뿌듯한 하루였습니다.</p><br>
              <p>다음날 저희는 체크아웃을 하러 숙소를 정리했는데요. 숙소 안보고 가기 너무 아쉽죠?</p>
              <img src="/images/blog/post72/post72_21.png">
              <img src="/images/blog/post72/post72_22.png">
              <p>저희는 휘닉스파크 콘도의 오렌지 동에 머물렀는데요.</p>
              <p>약 20평의 거실 + 미니방 + 부엌 + 화장실 구조입니다.</p>
              <p>온돌식 난방이라 저는 오랜만에 따숩게 지지고 왔습니다.^-^</p>
              <img src="/images/blog/post72/post72_23.png">
              <img src="/images/blog/post72/post72_24.png">
              <p>방 바깥으로 보이는 풍경도 너무 시원하고 멋있네요. 그립네요... 좋았는데...</p>
              <p>이렇게 체크아웃을 하고 저희는 학회를 마저 참가하기 위해 또 다시 이동했습니다.</p><br>
              <p>저희가 참가한 세션은 XR/Metaverse 관련 분야의 세션이 진행되고 있었습니다.</p>
              <p>“로터형 비행체의 XR 기반 시뮬레이션을 위한 고속 고정밀 로터형 비행체의 확장현실 과학 시뮬레이션 분석을 위한 고속/고정밀 유동 해석 및 가시화 모듈 개발”이란 주제로 발표해 주셨는데요.</p>
              <p>로터형 비행체는 회전하는 날개나 로터를 가진 비행체로 본 연구에서는 드론을 사용하신 것 같아요!</p>
              <p>이러한 비행체를 대상으로 확장현실(XR) 시뮬레이션을 개발 중이셨는데요.</p>
              <p>유체의 흐름을 수학적으로 모델링하고 이를 해석하는 과정인 유동 해석을 통해서 비행 중에 발생하는 고속 유동 상황에서의 로터의 동작 및 성능을 이해하고 최적화하는 것을 목표로,</p>
              <p>연구 결과를 시각적으로 표현하기 위한 가시화 모듈을 개발하여 연구자나 사용자가 쉽게 해석할 수 있도록 하는 데 주력하시고 계신 것 같았습니다.</p>
              <img src="/images/blog/post72/post72_25.png">
              <p>사실 본 연구에 대해서 물리 계산 식에 대한 설명이 이어졌는데요...</p>
              <p>저는 이해하기 어려운 부분이 있었습니다.</p>
              <p>사실 모든 연구발표들을 다 이해하기 어렵지만 디지털 트윈, 메타버스 ,XR 등 여러 분야에서 어떤 연구가 이뤄지고 있는지 최신 동향이 어떻게 흘러가는지 
              파악하는 것만으로도 이번 CDE학회는 저희에게 큰 수확이었습니다!!</p><br>
              <p>이렇게 모든 일정을 마무리하고 저희는 서울로 돌아오는 차에 탑승했는데요!</p>
              <p>서울로 돌아오는 길에 보이는 눈으로 뒤덮인 산과 자연 풍경이 그동안 건물 사이에서 익숙해졌던 저의 일상을 한번 시원하게 일깨워주었답니다.</p>
              <p>저희뿐 아니라 아이지아이코리아 다른 직원분들도 좋은 기회가 되어 꼭 한번 CDE학회에 발표도 해보고 다른 분들의 연구도 들으며 저희의 생각과 이해를 더욱 풍부하게 만들어 갔으면 좋겠습니다.</p>
              <p>이상으로 글을 마치겠습니다. 곧 다시 즐거운 내용으로 만나요!!!</p>
              <img src="/images/blog/post72/post72_26.png">
            `,
    createDate: 'Posted on Feb 6, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.711Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '71',
    title: '가상실험 콘텐츠 프로젝트 소개',
    content: `
              <img src="/images/blog/post71/post71_1.png">
              <p>안녕하세요! 가상실험 콘텐츠 프로젝트의 PM을 맡았던 Y 프로입니다.</p>
              <p>이번 블로그에서는 2023년에 진행한 프로젝트 중 하나를 소개해드릴게요~</p>
              <p>소개해 드릴 프로젝트는 오픈한 지 2달 만에 4,300여 번의 플레이를 기록한 콘텐츠 '눈으로 보는 소리실험실'입니다!</p><br>
              <p><strong>1.프로젝트 소개</strong></p>
              <p>학생들은 딱딱한 교과수업보다는 학생 중심의 탐구 수업과 정보화 기기를 활용한 수업을 더욱 선호하는 편입니다.</p>
              <p>KISTI에서는 이러한 선호도를 고려하여 다양한 과학 실험을 가상환경에서 진행할 수 있는 VlabON 사이트를 2022년 8월에 오픈하게 되었는데요!</p>
              <p>2023년에는 IGI Korea에서 총 11개의 콘텐츠를 제작하여 업로드하였습니다~!</p>
              <p>해당 프로젝트를 통해 학생들이 더 흥미롭게 학습할 수 있도록 가상 실험을 통한 학습 경험을 제공하며, 
              현실 실험에서 어려움을 겪을 수 있는 여러 가지 상황을 안전하게 체험하고 이해할 수 있는 기회를 제공하고자 하는 취지로 기획되었습니다.</p><br>
              <p><strong>2.VlabON 사이트 소개</strong></p>
              <p>'VlabON'은 과학기술정보통신부가 한국과학창의재단, 한국과학기술정보연구원(KISTI)와 협력하여 초중고 학생들이 언제 어디서나 이용할 수 있는 웹 기반 교육 플랫폼인데요!</p>
              <p>이는 3D 시뮬레이션을 활용한 가상 실험실로, 온라인에서 수학과 과학에 대해 다양한 탐구를 할 수 있는 곳이예요~</p>
              <p>2022년 8월부터 시범운영을 시작하였고, 2023년에는 본격적으로 이용할 수 있도록 IGI Korea에서 11개의 콘텐츠를 추가했답니다.</p>
              <p>한국과학창의재단과 한국과학기술정보연구원(KISTI)는 한국청소년상담복지개발원, 국립특수교육원, 한국교육개발원 등 3개 기관과 업무협약을 체결하여, 창의재단의 창의적 인재 양성 사업과 KISTI의 데이터 교육 사업을 통합하여 학교 밖 청소년 및 장애 학생 등을 대상으로 교육 소외 사각지대를 해소하는 계획을 추진하고 있어요!</p>
              <p>VlabON <a href="https://vlabon.re.kr/">(https://vlabon.re.kr/)</a> 사이트에서 다양한 실험을 만나 볼 수 있어요.</p>
              <p>저희가 개발한 콘텐츠들도 직접 체험해 보실 수 있답니다!</p>
              <img src="/images/blog/post71/post71_2.png">   
              <p><strong>3.'눈으로 보는 소리실험실'콘텐츠 소개</strong></p>    
              <p>‘눈으로 보는 소리실험실’은 귀로 들리는 소리를 그래프를 통해서 눈으로 확인하면서 소리의 3요소와 같은 이론을 학습하는 콘텐츠예요~</p>
              <p>따라서, 자신의 목소리 파형을 확인하거나, 자신의 목소리로 다양한 음을 만들어 목소리 연주를 진행할 수 있습니다!!</p>
              <p>또한, 소리 관련 게임을 2개나 개발하였는데요!</p>
              <p>소리의 크기로 우주선을 조작하여 행성을 피하는 게임과 소리의 음정으로 장애물을 통과하는 게임입니다.</p>
              <p>총 8개의 학습 콘텐츠를 포함하고 있는데 2023년에 새로 추가한 총 11개의 실험 콘텐츠 중에서 가장 많은 내용을 담았던 것 같아요~</p>
              <p>'눈으로 보는 소리 실험실'은 김정식 선생님과 함께 기획되었답니다~!</p>
              <p>김정식 선생님은 온라인 과학 실험계의 ★네임드★ 누적 천만 명, 일일방문자가 5천 명이나 되는 과학 실험 콘텐츠 사이트를 운영 중이세요.</p>
              <p>선생님이 운영하시는 사이트에 저희 콘텐츠에 대한 소개와 본 콘텐츠를 활용하여 진행할 수 있는 수업 활동지를 공유해주시고 계신데요.</p>
              <p>많은 내용이 있지만 한 장만 예시로 보여드릴게요~!</p>
              <img src="/images/blog/post71/post71_3.png">
              <p>선생님은 해당 콘텐츠를 활용하여 학생들 반응을 직접 현장에서 접하시기 때문에, 개발자들이 놓칠 수 있는 부분을 세심하게 알려주셨어요~</p>
              <p>콘텐츠 개발하고 끝이 아니라, 배포하고 나서 사용자들의 반응을 계속 확인하며 실험을 업데이트해 나갔는데요!</p>
              <p>덕분에 프로젝트가 더 발전하고, 아이들이 더 재밌게 배울 수 있게 되었다고 생각합니다!</p> 
              <p>김정식 선생님과 함께 작업하면서 기획자로서, 작업자로서 정말 많은 것을 배웠어요ㅎㅎ</p><br>
              <p><strong>4.제작 과정</strong></p> 
              <p>그럼 해당 콘텐츠의 개발 과정을 기획/3D/개발로 나누어 간략하게 소개해 드릴게요!</p>
              <img src="/images/blog/post71/post71_4.png">
              <p>교과과정에서 나오는 학습을 가상실험으로 만드는 실험인 만큼 KISTI에서 공모전으로 실험 시나리오를 정했어요~</p>
              <p>그다음에 IGI Korea쪽에서 해당 시나리오들을 웹 표준 플랫폼의 상황에 맞게 다듬는 작업을 합니다.</p>
              <p>그 결과! 자문 선생님과 KISTI와 수많은 회의를 거친 뒤 개발에 착수하게 됩니다.</p>
              <img src="/images/blog/post71/post71_5.png">
              <p>실험이 진행되기 위한 가상실험실을 제작 방법은 이미지를 통해 보여드릴게요.</p>
              <p>해당 이미지는 PTV발표 때 개발자분들께 3D 작업자들의 업무를 간단하게 설명하기 위해 작성한 자료입니다!</p>
              <p>3D 가상실험실은 일반적인 방법으로 디자인되었어요~</p><br>
              <p>위에서 언급했지만 소리 실험 안에는 총 8개의 콘텐츠가 있어요!</p>
              <p>그중에서도 저희가 재미있게 기획했던 소리탈출게임 개발 과정을 소개해 드릴게요!</p>
              <p>탈출게임은 사용자의 마이크를 연결하여(Web Audio Api), 사용자의 마이크 음성데이터를 받아 자동 상관 분석 알고리즘을 이용하여 주파수 특성(Hz)을 추출합니다.</p>
              <img src="/images/blog/post71/post71_6.png">
              <p>이 주파수의 Hz를 유추하여 게임을 만드는 것인데 첫 번째로 A3(라 : 220Hz) 음을 내어 게이지 박스의 게이지를 채워야 합니다!</p>
              <p>게이지 박스에 알맞은 음을 내게 되면 게이지 박스의 게이지가 올라가는 애니메이션이 실행되어서 게이지가 점점 차오릅니다.</p>
              <p>특정 횟수만큼 음을 내게 되면 완료하게 되는데 이때 특정 횟수는 1프레임 별로 음의 Hz를 받게 되면서 누적됩니다.</p>         
              <img src="/images/blog/post71/post71_7.png">
              <p>기존 게이지 애니메이션과 비슷한 로직으로 G3(솔 : 193Hz)음 을 내게 되면 상자를 움직일 수 있어요.</p>
              <p>해당 박스를 발판 삼으면 맵 오른쪽 끝에 있는 게이지를 채울 수 있습니다!</p>
              <img src="/images/blog/post71/post71_8.png">
              <img src="/images/blog/post71/post71_9.png">
              <p>마지막으로 투명한 다리가 있는데요.</p>
              <p>처음에는 아예 안 보이지만 D4(레) 음을 내게 되면 다리가 점점 보이게 됩니다~</p>
              <p>계속 음정을 유지해서 불투명했던 다리가 완전히 보이게 되면 완료를 알려주는 파티클이 생성됩니다.</p>
              <p>이제 다리를 건널 수 있게 됩니다!!</p>           
              <img src="/images/blog/post71/post71_10.png">
              <p>다리를 건너고 우주선에 도착하면 게임 성공!</p>
              <p>저는 43초가 나왔는데요~</p>
              <p>누가 누가 더 빨리 가는지 친구들과 내기도 해볼 수 있겠죠?</p><br>   
              <p><strong>5.마무리</strong></p> 
              <p>‘눈으로 보는 소리실험실’이 힘들기도 했지만 그만큼 팀원들끼리 성장할 수 있었던 개발 과제였어요~</p>
              <p>VlabON 사이트에 실험 오픈한지 2달 만에 벌써 4,300 이상의 플레이 수를 기록하고 후기 반응도 긍정적인 것을 확인할 수 있는데요!!</p>
              <p>저희가 만든 콘텐츠에 대한 반응을 이렇게 바로 볼 수 있으니 너무 뿌듯하네요!</p>
              <p>아이지아이 코리아가 궁금한 분들을 위해 프로젝트 소개를 통해서 저희가 하는 업무 중에서 일부를 (매우x100 ) 간단하게 알려드렸는데요~</p>
              <p>도움이 되셨으면 좋겠습니다ㅎㅎ</p>
              <p>마지막으로 글을 마치며 콘텐츠 제작에 도움 주셨던 여러 자문 선생님들과 KISTI 연구원님들,</p>
              <p>약 1달이라는 짧은 기간동안 저희와 거의 한 팀처럼 콘텐츠를 같이 개발해 주신 김정식 선생님께 감사 인사드립니다.</p>
              <p>많은 선생님들이 해당 콘텐츠를 수업 시간에 잘 활용하셨으면 좋겠습니다!</p>
              <img src="/images/blog/post71/post71_11.png">
              <p>📍 해당 실험 콘텐츠가 궁금하다면? 아래의 링크에서 직접 플레이해볼 수 있어요!</p>
              <a href="https://vlabon.re.kr/contents/6b99849af3acdc35d8d18cd19cc79c4a">https://vlabon.re.kr/</a>
            `,
    createDate: 'Posted on Jan 9, 2024',
    lastModifiedDate: '2024-07-04T06:19:39.827Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '70',
    title: 'IGI Korea 2023 PTV 2일차',
    content: `
              <img src="/images/blog/post70/post70_1.png" />
              <p>안녕하세요, 여러분! PTV 2일 차의 날이 밝았습니다!</p>
              <p>오늘의 PTV 행사 내용은 우리 회사 OSS Solution의 기술 공유와 소개, 그리고 각 프로젝트에 대한 경영진의 리뷰, 외부 인사 강연 및 IGI 활동 내용과 시상식으로 구성 되어있습니다.</p>
              <p>하나씩 소개해 드릴게요~!</p>
              <img src="/images/blog/post70/post70_2.png">
              <img src="/images/blog/post70/post70_3.png">
              <p>사진 담당자들은 2일 차에도 열일할 준비가 되었습니다~~~</p>
              <img src="/images/blog/post70/post70_4.png">
              <img src="/images/blog/post70/post70_5.png">
              <p>오프닝 발표로 채 연구원님이 (창백한 표정으로) 열심히 논문 발표 준비를 하고 있습니다.</p>
              <p>가녀린 어깨가 발표 전 긴장감을 보여주고 있군요.</p>
              <img src="/images/blog/post70/post70_6.png">
              <p>하지만 멋지게 발표 해버렸거든요~</p>
              <p>저기 채 연구원님!! 많이 무거워 보이는데 짐 좀 내려놓으세요. 멋짐.</p>
              <img src="/images/blog/post70/post70_7.png">
              <p>논문 주제는 “애자일 기반 모바일 앱 태그 메커니즘 활용 방법론”으로 일반적인 웹 개발과는 다르게 모바일 앱 개발에서는 어떤 점들을 고려해서 개발해야 하는지 설명해 주셨답니다!</p>
              <p>회사 직원분들도 다들 집중해서 듣고 계십니다.</p>
              <p>회사 생활하면서 논문 쓰는 게 쉽지 않은데... 
              <p>채 연구원은 또 해내버렸쟈나...채구원님 좀 징하네요... ‘어메이징~~~’</p>
              <img src="/images/blog/post70/post70_8.png">
              <img src="/images/blog/post70/post70_9.png">
              <img src="/images/blog/post70/post70_10.png">
              <img src="/images/blog/post70/post70_11.png">
              <img src="/images/blog/post70/post70_12.png">
              <img src="/images/blog/post70/post70_13.png">
              <p>이어서 OSS 솔루션의 기술 공유입니다.</p>
              <p>다들 각자의 기술과 업무에서 느꼈던 점들을 공유해주었어요!</p>
              <p>열정 넘치는 JK 프로는 특히 사진 2장을 넣었어요.</p>
              <p>설명 속에서 느껴지는 열정이 정말 멋졌습니다~</p>
              <p>다른 분들도 각자의 업무에 대한 발표에서 열정이 넘쳤어요~!</p>
              <p>SS 프로가 치열하게 토론하며 개발한 경험을 공유하는데, 들으면서 스타트업을 소재로 한 열정 넘치는 청춘들의 드라마가 뚝딱 그려지더라구요~</p></br>
              <p>다음은 외부 인사분들의 강연입니다.</p>
              <p>대표님과 부사장님의 초청을 통해 오신 전문가분들이에요.</p>
              <p>회사 내부 사람들끼리 서로 소통하는 것도 좋지만, 외부 인사분들의 전문 지식과 경험을 공유받고, 새로운 시각을 경험해 볼 수 있어요!</p>
              <p>어떤 강연이 기다리고 있는지, 함께 알아보러 가볼까요? 🌟🎤</p>
              <img src="/images/blog/post70/post70_14.png" />
              <img src="/images/blog/post70/post70_15.png" />
              <p>먼저 코트라에서 오신 K 과장님입니다.</p>
              <p>코트라는 간단하게 정리하자면 한국 기업들이 국제시장에서 성공적으로 활동할 수 있도록 지원하고, 외국 기업들이 한국으로 투자 및 무역을 증진하도록 도와주는 곳이라고 할 수 있어요~</p>
              <p>우리의 목표인 CES를 향해 달려가다 보면 만나게 될 아주 중요한 곳이죠!!</p>
              <p>CES가 뭐냐구요?</p>
              <p>CES는 매년 미국 라스베이거스에서 열리는 세계 최대 규모의 전자제품 및 기술 전시회로, 새로운 기술과 혁신적인 제품들이 소개되는 장으로 유명하답니다!!</p>
              <p>쉽게 말하면 짱 좋은 해외 전시 !</p>
              <p>과장님께서 상하이에서 주재원으로 거주하신 경험을 잠깐 풀어주셨는데, 그때가 코로나가 한참 심할 때라 영화 같았던 이야기를 많이 해주셨어요~</p>
              <p>다른 이야기 더 많다고 하시던데 시간이 짧아서 아쉬웠던 강연이었어요.</p>
              <p>그리고 문학소녀 같은 면이 있으셔서 인연에 대해서 말씀 해주셨는데 같은 문학소녀로서 감동 받았어요...</p>
              <p>이 문장을 요즘 말로 하면 완전 F 그 잡채셔서 F 감성 제대로 느껴지는 발표였어요.</p>
              <p>이 넓은 우주 속에서 티끌만 한 우리가 만난 것을 인연이란 단어가 아니면 뭐라 표현할 수 있을까요~</p>
              <p>꼭 다시 만나요. 과장님~</p>
              <img src="/images/blog/post70/post70_16.png" />
              <img src="/images/blog/post70/post70_17.png" />
              <p>두 번째로 만난 분은 투자 분야에서 활동 중인 C 상무님이셨어요.</p>
              <p>부사장님과는 포스텍 동기이자 미국에서 함께 공부한 동료이신데요.</p>
              <p>저는 상무님이 투자 분야로 이동하기 전에 쌓으셨던 개인적인 경험들이 인상적으로 다가왔어요~</p>
              <p>발표의 주 내용은 아니었기에, 짧게 말씀해 주셨는데도 저한테는 새로운 동기부여가 되었어요!</p>
              <p>상무님께서 네이버/라인에서 근무하시면서 자연스레 투자에 대해서 지식이 생겼다고 하셨는데 여러분 모두 자신에게 주어진 모든 일을 열심히 하다 보면 큰 성장으로 나아갈 수 있는 새로운 기회가 올 수 있지 않을까요?</p>
              <p>제가 다른데 꽂혀서 글을 썼는데요 ㅎㅎ</p>
              <p>상무님의 발표는 벤처 투자에 관한 내용이었는데 벤처 투자를 간단히 설명 드리자면</p>
              <p>"새로운 기업이나 스타트업에 대한 자금을 제공하는 투자 형태입니다.</p>
              <p>벤처 투자자들은 기업의 성장에 참여하며, 주로 전문성 있는 조언과 네트워크도 제공하고 실패할 경우 투자금 손실이 발생할 수 있습니다.</p>
              <p>벤처 투자는 새로운 기술이나 혁신에 투자하는 동시에 기업이 성공할 경우 투자자에게 높은 수익을 가져다 줄 수 있는 기회를 제공합니다.”</p>
              <p>벤처 투자에 대한 자세한 내용은 이번 강연으로 처음 알게 되었는데 무척이나 자세하고 흥미로운 설명에 많은 공부가 되었습니다!!</p><br>
              <p>IGI Korea에는 다음과 같은 복지 제도가 있는데요!</p>
              <img src="/images/blog/post70/post70_18.png" />
              <p>경영 지원 발표에서는 복지와 관련하여 어떤 활동들을 진행했는지, 1년간의 활동을 발표해주셨어요.</p>
              <p>D 프로님. 리스펙뜨...</p>
              <img src="/images/blog/post70/post70_19.png" />
              <img src="/images/blog/post70/post70_20.png" />
              <img src="/images/blog/post70/post70_21.png" />
              <p>연말 깜짝 이벤트로 대표님께서 자취하는 직원들을 위해서 소정의 자취 용품을 지원해 주셨어요!!!</p>
              <p>사진 외에도 중요한 복지를 말씀드리자면, 주택자금 대출도 진행해서 주거환경이 좋아져 개인 발표에서 엄청나게 만족하신다고 자랑하신 직원분도 있었고, 추천 인력/ 논문/ 방통대 등록금 지원받고 있는 분들은 말해 뭐해요.</p>
              <p>꽤 많은 직원분이 해당 복지를 이용하고 계신답니다.</p>
              <p>대표님은 저희를 은근히 섬세하게 잘 챙겨주신답니다~</p>
              <p>춥다 그러면 막 핫 팩 몇 박스씩 사다 주시구..</p>
              <p>저 막 블로그용 사진 찍고 있는데, 제가 못 낄까 봐 챙겨주시고… 감덩..</p>
              <p>이것이 츤데레…?! SO SWEET…╰(*°▽°*)╯</p>
              <p>그리고 수습 끝났다고 아이패드(무려 프로)와  애플 워치 주는 곳이 대체 어디 있나요!!</p>
              <p>제가 아이패드 병에 걸려서 몇 달을 돈 모아서 샀는데, 여기서 한방에 프로로 업그레이드 되었잖아요~</p>
              <p>그리고 저 같은 경우 갤럭시여서 워치 대신 아이패드 액세서리로 지급받았는데요, 애플펜슬과 (제 돈 주고는 절대 못살) 매직 키보드를 샀습니다.</p>
              <p>아이패드 세트 완성!! 외부 미팅에서 다른 분들 노트북 무겁게 주섬주섬 꺼낼 때 아이패드 하나로 PPT 및 미팅 회의록 작성까지!! po완전 직장인wer</p>
              <p>제가 이렇게까지 주접을 떨지 모르셨겠죠??</p>
              <p>이 글을 보고 계실 대표님께 다시 한번 감사 인사드립니다! ^^7777</p>
              <img src="/images/blog/post70/post70_22.png" />
              <p>이 모든 걸 담당해 주신 D 프로님께도 이 자릴 빌어 다시 한번 감사 인사드립니다!</p>
              <p>그리고 덧붙이자면 발표 ASMR 듣는 줄 알았잖아요.</p>
              <p>그거 기억나요? 사람들한테 D 프로님 목소리 좋다고 생각하는 사람 손 접어 했더니 지구가 반으로 접힌 거.</p>
              <p>그거 겨우겨우 되돌렸잖아요. 나 그때 내 눈앞에 우루과이 있어서 깜짝 놀랐잖아~</p>
              <img src="/images/blog/post70/post70_23.png" />
              <img src="/images/blog/post70/post70_24.png" />
              <img src="/images/blog/post70/post70_25.png" />
              <img src="/images/blog/post70/post70_26.png" />
              <p>그럼 이제 상조회 활동들을 살펴볼까요?</p>
              <p>이번 해에는 새로운 상조회장님과 여러 가지 활동을 많이 했어요~</p>
              <p>그중 일부만 소개해 드릴게요!</p>
              <p>이 외에도 PTV 행사까지 포함해서, 매달 진행된 이달의 사원, 복지센터의 기부, 장기 근속자 감사패, 각종 경조사 참석이 상조회에서 진행되었습니다.</p>
              <p>상조회의 이벤트들은 프로젝트에 지쳐 허덕이는 저희에게 단비와 같은 존재였는데요!</p>
              <p>본인 업무도 바쁘셨을 텐데, 이 많은 행사를 진행해 주신 2023년의 상조회장님.. 감사해요!</p>
              <p>덕분에 아이지아이의 한해가 풍성해졌네요~!</p>
              <p>상조회장님은 계란 사면 29개밖에 없겠어요… 상조회장님에게 ‘한 계란’ 없으니까요..</p>
              <p>아 그리고 저번에 보니까 상조회장님 망치를 쓰시지 않더라구요? 왜냐면 ‘못’하는 게 없으셔서 그런가...?</p>
              <img src="/images/blog/post70/post70_27.png" />
              <p>마지막 순서인 아이지아이의 비전과 시상식입니다.</p>
              <p>주접 더 떨고 싶은데 위에서 많이 떨기도 했고 자세한 내용을 적기도 힘드니 한마디만 하고 넘어가볼게요…!!!!</p>
              <p>20XX년 유니콘 기업 CEO 자서전 절찬 판매 중!! 제가 제일 먼저 예약할래요~~</p>
              <img src="/images/blog/post70/post70_28.png" />
              <img src="/images/blog/post70/post70_29.png" />
              <p>우리 회사에 새롭게 나타난 혜성~~! 두 분의 발표장인!! 베(스트) 프(리젠터) 축하드립니다~!!</p>
              <p>어디 발표학원이라도 다니셨나요??</p>
              <p>아니 이건 학원에서도 못 가르칠 것 같아요!!!!!!!!!!!! 두 분 다 타고 나셨나 봐요!!!</p>
              <p>다시 생각해도 처음 발표를 들었을 때의 신선함이 두고두고 생각날 것 같아요!!</p>
              <p>두 분 외에도 다른 분들도 발표 너무 잘하셨고, 새로운 모습 잘 보았어요.</p>
              <p>말을 잘하는 사람은 매력이 배가 되는 것 같아요~</p>
              <p>대표님이 발표도 능력이라고 항상 말씀하시는데, 발표는 업무적인 능력 외에도 사람을 멋있어 보이게 해주는 것 같아요.</p>
              <p>다들 내년의 발표 장인을 노려봅시다!!</p>
              <img src="/images/blog/post70/post70_30.png" />
              <img src="/images/blog/post70/post70_31.png" />
              <p>올해의 피플리더와 상조회장님도 새로 임명되었습니다.</p>
              <p>피플리더는 새로 생긴 직책인데요!!!</p>
              <p>경영진분들과 함께, 회사가 더 나은 방향을 향해 나아갈 수 있도록 같이 토론할 수 있는 권한을 갖고 있습니다!!</p>
              <p>설명을 듣자마자 모두의 머릿속에 떠오른 분이 임명 되셨어요~</p>
              <p>상조회장님은 ‘전’ 상조 부회장 에서 ‘현’ 상조회장님이 되셨네요!</p>
              <p>새로운 상조회장님 내년에도 잘 부탁드립니다!</p>
              <img src="/images/blog/post70/post70_32.png" />
              <p>다음은 올해의 직원 S 프로님입니다!</p>
              <p>S 프로님은 가상 실험 프로젝트의 PM으로 여러 실험을 기획하고, 솔루션 개발도 진행하셨으며, 상조회장으로 다양한 이벤트도 진행하셨죠~</p>
              <p>작년의 치열한 경쟁에 비해 이번 해는 S 프로님이 압도적인 투표수로 올해의 직원으로 선정되셨습니다!!</p>
              <img src="/images/blog/post70/post70_33.png" />
              <p>그럼 PTV를 마무리하겠습니다~ 다들 2024년에도 비상합시다 ~~</p>
              <p>(사진을 함께 찍지 못한 통일부 인원분들 심심한 사과의 인사를 전합니다.)</p>
              <img src="/images/blog/post70/post70_34.png" />
              <p>여기서 끝이 아니죠~!!!</p>
              <p>오늘 회식 무려 호.텔.뷔.페!</p>
              <p>라마다로 힘차게 입장하는 저희 직원분들입니다.</p>
              <p>발걸음에서 느껴지는 신남 ㅋㅋㅋ</p>
              <img src="/images/blog/post70/post70_35.png" />
              <img src="/images/blog/post70/post70_36.png" />
              <img src="/images/blog/post70/post70_37.png" />
              <img src="/images/blog/post70/post70_38.png" />
              <p>저희를 위한 자리도 세팅이 되어있었어요~!</p>
              <p>뷔페니까 야무지게 담아와볼까요?</p>
              <img src="/images/blog/post70/post70_39.png" />
              <img src="/images/blog/post70/post70_40.png" />
              <p>엄청 실한 새우가 있었어요~~</p>
              <p>새우찍는데 뒤에서 V중인 Support 프로님 잘생김이 모자이크를 뚫고 나오고 있네요.</p>
              <p>블러를 몇번을 칠했는지..</p>
              <img src="/images/blog/post70/post70_41.png" />
              <img src="/images/blog/post70/post70_42.png" />
              <p>저희 스테이크도 먹었지요~~</p>
              <p>디저트도 망고 무스케이크와 샤인머스켓!</p>
              <p>요즘은 이런 확대샷이 유행이라면서요??</p>
              <img src="/images/blog/post70/post70_43.png" />
              <p>2023 올해 모두 수고하셨습니다~~</p>
              <p>이번 PTV를 통해 새로운 동기부여를 얻을 수 있었고, 다가오는 한 해를 더욱 기대하게 되었습니다.</p>
              <p>모두가 함께 만들어가는 이 특별한 경험에 참여할 수 있어 행복했어요!!</p>
              <p>PTV를 통해 우리 팀이 더 성장하고 발전해 나가기를 기대합니다!</p>
              <p>내년에도 함께 훌륭한 경험을 만들어가요!</p>
        `,
    createDate: 'Posted on Dec 18, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.879Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '69',
    title: 'IGI Korea 2023 PTV 1일차',
    content: `
              <img src="/images/blog/post69/post69_1.png" />
              <p>시간이 어떻게 흘렀는지 모를 2023년의 마지막 날이 다가오고 있습니다~</p>
              <p>올해 IGI Korea에서 함께한 소중한 한 해의 마지막 달이 되었는데요!!!</p>
              <p>IGI Korea에서는 매년 연말에 이 특별한 시간을 기념하고, 더 나아가 새로운 한 해에 대비하기 위해 PTV라는 행사를 진행합니다!!</p>
              <p>간단하게 설명 드리자면! 프로젝트 및 기술 공유, 그리고 소중한 경험을 공유하는 자리에요.</p>
              <p>한 해를 돌아보며 그동안의 추억과 경험을 함께 공유해보았답니다.</p>
              <p>PTV 행사에서는 전 직원이 발표할 기회를 갖게 되는데요~ 모두가 참여하고 함께 만들어가는 행사라서, 서로 다양한 경험과 아이디어를 공유하는 것이 가능하다는 점에서 더욱 특별하다고 생각해요!</p>
              <p>준비하시는 분들은 힘들었겠지만, 작년에 이 행사로 인해 얻은 좋은 기억 때문에 올해도 많이 기대하고 있었거든요.</p>
              <p>모두가 함께 참여하고 나누는 이 순간이, 다시 한 해 동안의 성장과 발전을 위한 소중한 계기가 되지 않을까요?</p>
              <p>블로그를 시작하기에 앞서, 이 글을 작성하는 자의 특권으로 개인적인 소감을 같이 말해보자면, 작년에 처음 참가해 봤을 때, 한 해를 돌아보고 다음 해를 준비하는 시간을 갖게 된 것이 정말 의미있었어요!</p>
              <p>다른 회사에서 경험했던 종무식과는 다르게 다가왔었던 것 같아요. 이 행사 덕분에 공식적으로 자신을 돌아보기 때문인데요!</p>
              <p>지난 한 해 동안의 경험을 되짚어보며, 그동안의 성취와 도전에 대한 생각을 나누는 것은 생각보다 버라이어티한 동기부여가 되었어요~ 행사 준비를 하면서 한 해를 돌아보니 좋았더라, 아쉬웠더라 등등 직원분들 다들 발표의 시작 말을 이렇게 시작하시더라고요.</p>
              <p>그럼 이제 PTV 행사가 어떻게 진행되었는지 후기를 남겨보도록 하겠습니다!</p>
              <img src="/images/blog/post69/post69_2.png" />
              <p>이번 PTV는 작년과 다르게 멀리 가지 않고 저희 사무실에서 진행했어요!</p>
              <p>사무실이 굉장히 넓고 의자도 편해서 행사를 편하게 즐길 수 있었어요.</p>
              <img src="/images/blog/post69/post69_3.png" />
              <img src="/images/blog/post69/post69_4.png" />
              <p>일찍와서 준비해주신 분들 감사합니당!</p>
              <img src="/images/blog/post69/post69_6.png" />
              <img src="/images/blog/post69/post69_7.png" /> 
              <img src="/images/blog/post69/post69_5.png" />
              <p>첫 번째 순서는 대표님의 개회사입니다.</p>
              <p>대표님 화끈하게 짧게 말씀하시고 행사 바로 시작했어요!</p>
              <p>두 번째 순서는 프로젝트 발표인데요.</p>
              <p>총 10개의 프로젝트 발표가 진행되었습니다!!</p>
              <img src="/images/blog/post69/post69_8.png" />
              <img src="/images/blog/post69/post69_9.png" />
              <img src="/images/blog/post69/post69_10.png" />
              <img src="/images/blog/post69/post69_11.png" />
              <p>프로젝트 발표 끝난 후에는 대표님의 리뷰 및 코멘트 시간이었어요~</p>
              <p>대표님이 칭찬해 주시기도 하고, 아쉬운 점에 있어서는 개선이 필요한 부분도 함께 알려주셨어요!</p>
              <p>역시… 경력에서 나오는 짬이 있더라구요.</p>
              <p>프로젝트 리뷰는 주로 긍정적으로 말씀해 주셔서, 프로젝트 때에 힘들었던 기억들이 다 뿌듯함으로 바뀌었어요.ㅎㅎ</p>
              <img src="/images/blog/post69/post69_12.png" />
              <img src="/images/blog/post69/post69_13.png" />
              <img src="/images/blog/post69/post69_14.png" />
              <p>세 번째 프로젝트 발표는 산림교육원이었어요~</p>
              <p>산림교육원에 맞춰 의상을 맞춰 입으신 M 프로님 ㅋㅋㅋㅋ</p>
              <p>대체 왜 대기 자세가 열중쉬어인건데요……!!!</p>
              <p>PTV가 순식간에 캠프로 바뀐 느낌이잖아요!!!!</p>
              <p>해당 프로젝트는 VR 중심의 과제로,</p>
              <p>개발자분이 게임 콘텐츠와 학습 콘텐츠의 개발 과정에서 자신의 생각 변화를 나눠주셨어요!</p>
              <p>각 콘텐츠에 따라 어떤 부분에 집중해야 할지에 관한 이야기였는데, 저는 생각해 보지 못했던 부분이라 꽤 흥미롭게 들었습니다~</p>
              <img src="/images/blog/post69/post69_15.png" />
              <img src="/images/blog/post69/post69_16.png" />
              <p>중간마다 힘들지 않도록 휴식시간도 가졌어요.</p>
              <p>상조회장님의 PTV 깔끔한 진행!! 정말 고생 많으셨어요.</p>
              <img src="/images/blog/post69/post69_17.png" />
              <p>쉬는 시간에는 발표 끝난 팀과 대기중인 자의 무리가 나눠지곤 했습니다... ㅋㅋㅋㅋ</p>
              <p>여유롭게 대화하는 팀과 발표 연습하고 있는 유니버스 팀의 대비…</p>
              <img src="/images/blog/post69/post69_18.png" />
              <p>다음 발표자들의 뒷모습을 확인하실 수 있습니다.</p>
              <p>발표 전에는 언제나 부담감 백배지만, 발표 끝난 후 오는 뿌듯함은 그만큼 달콤하죠!!</p>
              <img src="/images/blog/post69/post69_19.png" />
              <img src="/images/blog/post69/post69_20.png" />
              <p>다음으로 관광공사와 HPC 발표가 이어졌는데요~</p>
              <p>관광공사 같은 경우는 저도 참여했지만, 2D 디자인 쪽에서 어떤 작업이 있는지 몰랐었는데 이번 기회에 디자이너님의 고민과 작업 과정을 들을 수 있었습니다!</p>
              <p>HPC 프로젝트에서 J 프로님과 C 프로님의 성장 과정을 들려주셨는데, 저도 옆에서 그걸 직접 봤기 때문에 감회가 남다른 발표였어요~!</p>
              <img src="/images/blog/post69/post69_21.png" />
              <img src="/images/blog/post69/post69_22.png" />
              <p>다음은 KISTI 물리지구과학 발표인데요.</p>
              <p>끝없는 피드백 경험을 설명하고 있습니다...</p>
              <p>피드백 히스토리 보시면 첫 번째 실험에서는 피드백이 30개 이상 나왔었고, 마지막 실험에서는 10개 정도 나온 과정을 설명하면서 사용자의 관점을 어떻게 이해하게 되었는지 공유하였습니다.</p>
              <p>대단하지 않나요? 박수 (짝짝짝짝)</p>
              <img src="/images/blog/post69/post69_23.png" />
              <img src="/images/blog/post69/post69_24.png" />
              <p>발표 들으면서 당 떨어지면 간식도 냠냠 먹어요~</p>
              <img src="/images/blog/post69/post69_25.png" />
              <img src="/images/blog/post69/post69_26.png" />
              <p>곧이어 KISTI 화생수 발표입니다.</p>
              <p>해당 앞 발표와 겹치는 주제로 자칫 지루해질 수 있는 발표였지만... 아니 표지부터 심상치 않았습니다.</p>
              <p>이게 마케팅 시간에 말로만 듣던 스토리텔링!!!!!</p>
              <p>프로젝트 개발 과정을 섬을 향해 항해하는 컨셉을 적용하여 이야기해 주셔서 너무 재미있게 들었어요~</p>
              <p>PM을 선장에 비유해서 설명하시는데, 이해가 쏙쏙 잘 가더라구요!!!</p>
              <p>발표 시작 전에 뿌이뿌이 출항합니다~ 라고 외칠 계획이라고 하셨는데 너무 장난 같을까봐 안하셨다고 하네요.</p>
              <p>경험하셨듯이 우리 PTV는 아주 자유로운 분위기이니 내년 PTV 기대하겠습니다 ^^</p>
              <img src="/images/blog/post69/post69_27.png" />
              <img src="/images/blog/post69/post69_28.png" />
              <img src="/images/blog/post69/post69_29.png" />
              <p>점심시간 계속 발표 준비를 하던 유니버스 팀의 발표입니다!</p>
              <p>전혀 긴장한 티 나지 않고 조곤조곤 재미있게 발표 잘해 주셨어요~</p>
              <p>유니버스 유지보수 업무를 맡으면서 느꼈던 경험들, 그리고 개발자로서의 성장 과정도 공유해 주셨는데, 개발자는 아니지만 많이 공감했습니다ㅎㅎ</p>
              <img src="/images/blog/post69/post69_30.png" />
              <p>다음 발표는 사라진마을입니다!</p>
              <p>아니 근데 이게 뭐죠!??</p>
              <p>다들 검은 옷에 빨간 넥타이를 하고 활기찬 인사로 발표를 시작하여 분위기를 싹 전환시켜 주셨어요!</p>
              <p>아니 이런 아이디어는 다들 어디서 얻는건가요~~~</p>
              <img src="/images/blog/post69/post69_31.png" />
              <img src="/images/blog/post69/post69_32.png" />
              <p>그리고 이어지는 깜짝 퀴즈 이벤트 !!!</p>
              <p>냅다 이벤트가 진행되었어요!!</p>
              <p>“잠깐!!!! 당신도 사라진 마을 잘알이 될 수 있다!!!!!!!!!!”</p>
              <img src="/images/blog/post69/post69_33.png" />
              <p>상품은 사라진 마을의 옛 모습을 구현한 컨셉에 딱 어울리게, 어릴 적에 먹었던 간식들인데요!</p>
              <p>밭두렁을 진짜 오랜만에 먹어봤어요~</p>
              <p>이런 소소한 이벤트 너무 재밌어…! 짜릿해..!!!</p>
              <p>그리고 사진에는 안 보이지만 꾀돌이도 있었는데요.</p>
              <p>꾀돌이를 집에 가져갔더니 저희 엄마가 더 좋아하시더라구요ㅎㅎ😄</p>
              <img src="/images/blog/post69/post69_34.png" />
              <img src="/images/blog/post69/post69_35.png" />
              <img src="/images/blog/post69/post69_36.png" />
              <img src="/images/blog/post69/post69_37.png" />
              <p>마지막 프로젝트 발표입니다.</p>
              <p>봉구원님의 노트북 스티커가 귀여워서 사진 한 장 넣어보았어요~</p>
              <p>솔직히 프로젝트를 듣는 동안 졸릴까 봐 걱정하였는데 의외로 그렇지 않았습니다!</p>
              <p>각각의 프로젝트팀이 어떤 어려움(개발 용어로 이슈라고 하죠? ㅋㅋ)을 겪었는지 듣고, 이 팀들이 이를 어떻게 극복했는지 성장 스토리를 듣는 데 공감이 가는 부분이 정말 많았어요~</p>
              <p>게다가, 공통적인 이슈도 있었고, 프로젝트 특성상 발생하는 독특한 이슈도 있었죠.</p>
              <p>이런 간접 경험을 통해 한 걸음 더 나아가지 않았을까 기대돼요!</p><br>
              <p>이 다음은 개인 발표였는데요, 모두모두 너무 발표를 잘해 주셨지만 그 중에서도 베스트 프리젠터 두 분만 소개할게요!!!</p>
              <p>두 분 모두 신입분들이신데요!!</p>
              <img src="/images/blog/post69/post69_39.png" />
              <p>첫 번째 베스트 프리젠터님은 프로젝트 발표에서 보여주었던 스토리텔링 능력을 개인 발표에서도 보여주셨는데요!</p>
              <p>올해 본인의 성장 과정을 IGI를 삼행시 삼아 발표했습니다!</p>
              <p>짧고 굵게 요점만 말씀하셨어요~</p>
              <p>사실 이분은 모자이크에 가려서 안 보이시겠지만... 아이지아이 공식 귀염 담당이시거든요.</p>
              <p>장프님 MBTI 검사하면 CUTE나오신다면서요? 근데 발표 능력까지 갖추었다니…</p>
              <p>장프님을.. 팩트로 정리하자면 퍼펙트.</p>
              <p>장프님 발표 잘한다고 생각하는 사람 손들어! 했더니 지구가 성게 모양이 돼버렸다지요??</p>
              <img src="/images/blog/post69/post69_38.png" />
              <p>두번째 베스트 프리젠터님은 미누미누 프로님입니다~</p>
              <p>이분도 마찬가지로 짧고 굵게 이미지 한장으로 메시지를 주었는데요.</p>
              <p>디자인에 있어서의 디테일의 중요성과 자신의 포부를 밝혔어요.</p>
              <p>진짜 TMI죠? Too Much I LOVE 미누미누.</p>
              <p>저는 미누미누 프로님에게 벽이 느껴져요... 완벽...</p>
              <p>우리 3D 미누미누 좋을 때마다 이마 쳤더니 거북목 완치되어버렸잖아요~~</p>
              <p>발표하려고 각 잡고 입고 온 의상도 퍼펙트!</p>
              <img src="/images/blog/post69/post69_40.png" />
              <p>부사장님 발표시간에는 2024년에 적용되는 조직도, 사내 규정 등에 대해서 말씀해 주셨어요!</p>
              <img src="/images/blog/post69/post69_41.png" />
              <p>이 다음으로는 대표님의 마무리… 그리고 퀴즈 이벤트 !!!!</p>
              <p>문제 하나당 최소 5만 원 상품권이었어요.</p>
              <p>문제 난이도에 따라서 10~20만 원도 있었고요.</p>
              <p>호텔 스파 이용권까지 있었어요!!!!!!!!!!!!!</p>
              <p>근데 문제가 생각보다 많이 어려웠어요... ㅠㅠ</p>
              <img src="/images/blog/post69/post69_42.png" />
              <img src="/images/blog/post69/post69_43.png" />
              <p>왜냐하면 주관식도 있었거든요ㅋㅋㅋㅋㅋㅋㅋ</p>
              <p>주관식… 정말 쉽지 않아요.</p>
              <p>여러 답을 써야 하기에 채점 시간도 필요하답니다~</p>
              <p>좌절하는 우리에게 온 마지막 기회…!!!!!!!!</p>
              <p>가위바위보 !!!!!!!!! 무려 20만원 상품권이예요!!!!!!</p>
              <img src="/images/blog/post69/post69_44.png" />
              <p>현장감이 느껴지시나요? ㅋㅋㅋㅋ</p>
              <p>팀을 나눠 가위바위보 1등을 선정하게 되었습니다.</p>
              <p>이때 살짝 기대했죠… 제가 될 수도 있다는 기대감!!! 응 아니야~</p>
              <p>저는 1라운드에서 탈락하게 됩니다...</p>
              <img src="/images/blog/post69/post69_45.png" />
              <p>1등을 기대하고 있는 또다른 가위바위보 팀...</p>
              <img src="/images/blog/post69/post69_46.png" />
              <p>결승전 !!!</p>
              <p>세상에 1등이 나와버렸습니다ㅏㅏㅏ!!!!!!!!!!!!!!!!!</p>
              <p>행운의 찌!!!!!!!!!</p>
              <p>한번에 이겨버리셨네요~! 제가 사진에 빨간색으로 강조도 넣어봤어요...</p>
              <img src="/images/blog/post69/post69_47.png" />
              <p>…가위바위보로 20만원 상품권 획득…!</p>
              <p>진짜 부럽습니다.</p>
              <img src="/images/blog/post69/post69_48.png" />
              <p>아쉽지만 이렇게 1일차 PTV를 마무리합니다~</p>
              <p>마지막 순서는 회식입니다!!</p>
              <p>식당에 갔더니 미리 셋팅이 되어있어서 기다리지 않고 바로 먹을 수 있었어요!</p>
              <p>우리 상조회... 센스 넘쳐…</p>
              <img src="/images/blog/post69/post69_49.png" />
              <p>대표님의 테라 타워도 이용해 줍니다~!</p>
              <p>소맥을 기가 막히게 말아줘요ㅋㅋㅋ</p>
              <p>보시면 아시겠지만... 2대 1비율...! 이런 소맥 맛은 처음이었어요...!</p>
              <p>이 테라 타워의 장점으로 말하자면 주변 잔 따라줘야 하는 눈치 필요 없이 먹고 싶은 사람이 알아서 떠먹으면 돼요 ㅋㅋㅋ</p>
              <p>소맥 말아야 하는 수고도 필요없구요! 테라 타워 완전 짱 b</p>
              <img src="/images/blog/post69/post69_50.png" />
              <p>테이블을 돌아다니면서 직원 한 분 한 분 챙겨 주시는 대표님~!</p>
              <p>지금 대표님이 입고 계신 저 회색 후드티는 미국 출장에서 샀던 유펜 대학 굿즈네요!</p>
              <p>저도 하나 살 걸 그랬어요~ 너무 이뻐요!</p>
              <img src="/images/blog/post69/post69_51.png" />
              <p>즐겁게 저녁 식사를 하고 하루를 마무리하였습니다!</p>
              <p>그럼 PTV 2일 차에서 만나요~</p>

  
        `,
    createDate: 'Posted on May 3, 2021',
    lastModifiedDate: '2024-07-04T06:19:39.879Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '68',
    title: 'IGI Korea 상조회 두번째 기부',
    content: `
              <img src="/images/blog/post68/post68_1.png">
              <p>안녕하세요~ IGI Korea 상조회입니다!</p>
              <p>저희는 사내 상조회를 통해 직원들의 경조사비나, 사내 동호회 지원 등 다양한 행사를 진행하는데요!</p>
              <p>여러 행사 중 가장 의미가 있는 기부에 대한 내용을 소개할게요!</p><br>
              <p>직원들과 상조회비를 더욱 의미 있는 곳에 사용하고자 2022년도부터 기부를 진행하고 있는데요, 이번 기부는 서울 관악구에 위치한 “동명아동복지센터”에서 물품 기부의 형태로 진행하기로 하였습니다~!!</p><br>
              <p>동명아동복지센터는 1950년부터 아동보호를 목적으로 설립되어 아동복지에 힘쓰고 있는 시설이에요.</p>
              <p>후원 신청을 하기 위해 홈페이지의 후원 안내에 접속했어요.</p>
              <img src="/images/blog/post68/post68_2.png">
              <p>다양한 후원 방법이 보이는데요.</p>
              <p>물품 후원은 전화로 문의가 필요하여 연락드리니 친절하게 안내 해주셨어요.</p>
              <img src="/images/blog/post68/post68_3.png">
              <p>후원 절차에 대한 내용과 요청 사항을 정리하여 안 내주셨는데요,</p>
              <p>아이들의 생활에 꼭 필요한 물품을 후원한다는 생각에 너무 뿌듯했어요.</p>
              <img src="/images/blog/post68/post68_5.png">
              <p>마음을 담아 고민하며 후원 물품을 고르고 배송을 진행하였는데요.</p>
              <img src="/images/blog/post68/post68_4.png">
              <p>물품이 도착하니 복지사님께서 확인과, 감사하다는 인사까지 주셨어요.</p>
              <p>그리고 동명아동복지센터의 소식지 사진첩에도 저희의 기부 소식이 올라갔어요!</p><br>
              <p>저희의 기부 내용이 사이트에 올라간 걸 보니 기부를 한 실감이 나고 내용을 사내에 공유하니 직원분들 모두 너무 뿌듯해했어요.</p><br>
              <p>그럼, 다음에는 더욱 따뜻한 소식으로 돌아올게요~!</p>
              <p>앞으로도 상조회의 많은 활약을 기대해 주세요!!</p><br>
              <p>동명아동복지센터 소식지 : <a href="http://dmchild.or.kr/%EC%82%AC%EC%A7%84%EC%B2%A9/12067210" target="_blank">http://dmchild.or.kr/%EC%82%AC%EC%A7%84%EC%B2%A9/12067210</a></p>
            `,
    createDate: 'Posted on Nov 27, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.835Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '67',
    title: 'LA 시티 투어 - (2)',
    content: `
              <img src="/images/blog/post67/post67_12.png">
              <p>산타모니카, 게티 센터… 그다음 가야 할 곳은 어디?!?!?!</p>
              <p>LA 시티투어 2탄 시작합니다~~~</p>
              <p>아직도 LA에 가볼 만한 곳이 많이 남아있어요! 렛츠고우~!!</p>
              <img src="/images/blog/post67/post67_1.png" alt="인앤아웃 버거">
              <img src="/images/blog/post67/post67_2.png" alt="인앤아웃 버거">
              <p>퀴즈!! LA에 오면 꼭 먹어야 하는 음식은 무엇일까요???</p>
              <p>바로바로 인앤아웃 버거!!</p>
              <p>미국이라고 인앤아웃버거를 무조건 먹을 수 있는 건 아니에요!</p>
              <p>인앤아웃 매장은 미국 서부, 텍사스에서만 찾아볼 수 있거든요.</p>
              <p>그 이유는 인앤아웃은 얼리지 않은 생고기를 쓰는 것이 원칙으로 신선도 유지를 위해 직영 배급소 일정 거리 이내에만 매장을 열 수 있다고 해요!</p>
              <p>그리고 이번에 처음 알게 된 사실은 차를 탄 채로 메뉴를 주문하는 드라이브스루 서비스를 업계 최초로 도입한 곳이 인앤아웃이라고~~</p>
              <img src="/images/blog/post67/post67_3.png" alt="인앤아웃 버거">
              <img src="/images/blog/post67/post67_4.png" alt="인앤아웃 버거">
              <img src="/images/blog/post67/post67_5.png" alt="인앤아웃 버거">
              <p>짜자자잔!</p>
              <p>인앤아웃 메뉴는 굉장히 심플해요~! 햄버거, 치즈버거, 더블더블버거, 프렌치프라이 딱 4종입니다.</p>
              <p>근데…… 아는 사람만 주문할 수 있는 시크릿메뉴가 있어요...!</p>
              <p>양파, 토마토 등을 취향껏 맘껏 추가할 수 있고, 버거 빵 대신 양상추로 감싸는 프로틴 스타일, 프렌치프라이는 굽기를 extra crispy로 하면 바싹 튀겨준다는 것!!</p>
              <p>저는 더블더블버거에 생양파를 추가하고, 프렌치프라이를 extra crispy로 주문했어요~</p>
              <p>노 솔트라고 하면 감자튀김에 소금도 빼준답니다!</p>
              <p>햄버거의 맛은… 인앤아웃 버거의 경영 모토가 ‘Keep it Simple’인데 정말 기본을 잘 지킨 햄버거예요!</p>
              <p>심플한데 맛있다?! 과하지도 않고 그렇다고 덜하지도 않은… 이것은  Simple is the Best!!</p>
              <p>프렌치프라이는 바싹 구워서 뭔가 기름 잔뜩 먹은 감자칩일 것 같은데, 전혀 기름지지 않고 신선하고 건강한 감자칩을 먹는 것 같아서 먹으면서 죄책감이 들지 않은 감자튀김이었습니다!ㅎ</p>
              <p>코로나 이후로 미국 외식 물가가 많이 올랐는데, 가격도 상대적으로 저렴하고 다음에 기회가 된다면 또 먹고 싶은 버거입니다ㅎㅎ</p>
              <p>(근데 음식 사진에만 말이 길어지는 것 같네요... 먹는거에 진심인 편...)</p>
              <img src="/images/blog/post67/post67_6.png" alt="베버리힐스">
              <img src="/images/blog/post67/post67_7.png" alt="베버리힐스">
              <p>한때 베버리힐스~ 베버리힐스~ 가 울려 퍼지며 베버리힐스를 모티브로 한 힙합 노래가 유명했는데…</p>
              <p>이걸 쓰면서 자꾸 노래가 머릿속에 맴도네요~</p>
              <p>LA에 있는 부자 동네인 베버리힐스! 할리우드 톱스타들이 많이 살고 있어 유명해졌죠.</p>
              <p>그 베버리힐스….. 제가 잠시 찍고 왔습니다ㅎㅎㅎ </p>
              <p>아주 잠깐 들렸는데 베버리힐스 사인과 그 옆에 100살이 넘은 무화과 나무도 찍어봤어요!</p>
              <img src="/images/blog/post67/post67_8.png" alt="할리우드 스트리트">
              <img src="/images/blog/post67/post67_9.png" alt="할리우드 스트리트">
              <img src="/images/blog/post67/post67_10.png" alt="할리우드 스트리트">
              <img src="/images/blog/post67/post67_11.png" alt="할리우드 스트리트">
              <p>LA 하면 할리우드!!! 영화!!! 가 떠오르는데, 영화를 좋아하는 분들이라면 할리우드 스트리트 강추~</p>
              <p>먼저 위에 2장은 아카데미 시상식이 열리는 돌비 시네마인데, 기둥에 각 연도에 오스카상을 수상한 작품들이 적혀 있어요! </p>
              <p>2019년에는 한국 영화 ‘기생충’이 수상작으로 적혀있어요.</p>
              <p>개인적으로 기생충 좋아하는데 무척이나 뿌듯했답니다~~</p>
              <p>그다음 할리우드 스트리트를 거닐었는데요! </p>
              <p>다른 말로 명예의 거리(Walk of Fame)라고도 하죠. </p>
              <p>바닥에는 배우, 감독, 프로듀서, 음악가, 등 엔터테인먼트 산업의 업적에 대한 기념물로 별 모양 표시로 되어있어요!</p>
              <p>내가 좋아하는 영화감독 또는 영화배우는 어디 있나 찾아보는 재미가 있어요~!</p>
              <img src="/images/blog/post67/post67_12.png" alt="할리우드 사인">
              <p>그 다음 해가 지기 전에 할리우드 사인을 찍으러 부랴부랴 움직였어요!</p>
              <p>레이크할리우드공원 근처에서 할리우드 사인을 찍을 수 있는데, 예전에는 할리우드 사인 주변에 조명들이 있어서 해가 져도 사진을 찍을 수 있었다고 해요.</p>
              <p>근데 어느 날 누군가가 조명을 훔쳐 가서 다시 설치했는데 또다시 훔쳐 가서 조명은 영영 없어졌다고.. ㅜㅜ</p>
              <p>할리우드 사인은 저 멀리 있어서 카메라 3배 줌은 해야 하지만.. 실제로보니 신기했어요!</p>
              <img src="/images/blog/post67/post67_13.png" alt="그리피스 천문대">
              <img src="/images/blog/post67/post67_14.png" alt="그리피스 천문대">
              <img src="/images/blog/post67/post67_15.png" alt="그리피스 천문대">
              <p>투어의 마지막! 그리피스 천문대입니다!! </p>
              <p>그리피스 천문대는 광업으로 성공한 대부호 그리피스가 이 지역을 로스앤젤레스에 기증하면서 천문대를 지어달라는 유언으로 만들어졌다고 해요~</p>
              <p>최근에는 라라랜드에서도 나와 관광객들이 엄청나게 늘었다고 합니다!!</p>
              <p>저희도 차 타고 올라가는 데 한참 걸렸어요ㅠ</p>
              <p>그리피스 천문대는 천문대 자체도 꽤 커서 둘러볼 만하지만, 무엇보다도 여행의 꽃! 야경을 볼 수 있는 야경 맛집이에요!!</p>
              <p>LA의 야경을 쫘아아악 볼 수 있는…! </p>
              <p>제가 간 날은 살짝 흐렸지만 그래도 왼쪽에 높은 빌딩이 많은 곳이 다운타운임을 알 수 있었고 그 외 지역들은 대부분 낮은 건물들이라 작은 별들이 반짝이는 것처럼 보였어요.</p>
              <p>자유 여행이었다면 석양이 질 때쯤 와서 야경까지 볼 것 같아요.!</p>
              <p>예정된 시간보다 늦게 끝났지만 정말 알찬 일일 투어였습니다!</p>
              <img src="/images/blog/post67/post67_16.png" alt="판다 익스프레스">
              <img src="/images/blog/post67/post67_17.png" alt="판다 익스프레스">
              <img src="/images/blog/post67/post67_18.png" alt="판다 익스프레스">
              <img src="/images/blog/post67/post67_19.png" alt="판다 익스프레스">
              <p>어느새 귀국하는 날이 돌아왔어요…</p>
              <p>오후 12시 비행기라 아침에 호텔 체크아웃을 하고 공항에 도착했어요.</p>
              <p>미국에서 마지막 식사는 ‘판다 익스프레스’로 결정했습니다!</p>
              <p>미드에 보면 항상 외국인들이 어메리칸 차이니즈 푸드를 배달시켜 누들을 젓가락질하며 먹는 씬들이 많은데.. 그 음식의 대표 브랜드 판다익스프레스를 안 먹으면 섭섭하죠ㅎㅎ</p>
              <p>누들과 볶음밥 하프 하프로 하고, 사이드 음식을 골랐어요.</p>
              <p>으으음~~~ 역시 이맛이죠.. 배가 터지게 먹었습니다ㅎㅎ</p>
              <img src="/images/blog/post67/post67_20.png" alt="See’s CANDIES의 초콜릿">
              <img src="/images/blog/post67/post67_21.png" alt="See’s CANDIES의 초콜릿">
              <p>배부르게 먹고 빈손으로 돌아갈 수 없지요!! </p>
              <p>LA 기념품으로 See’s CANDIES의 초콜릿을 구매했어요.</p>
              <p>See’s CANDIES는 로스앤젤리스 1921년 LA에 첫 매장을 오픈하고 100년이 넘은 전통을 지닌 브랜드예요~</p>
              <p>캘리포니아의 가장 오래되고 유명한 캔디라고 해서 저도 맛볼 겸 사보았어요ㅎㅎ</p></br>
              <p>회사에서 직원들에게 좋은 기회들을 제공하려고 하는데, 제가 그 기회를 얻어 미롬 방문에서 시티투어까지 잊지 못할 경험을 하고 돌아왔어요!</p>
              <p>이 경험을 벗 삼아 남은 하반기도 으쌰으쌰 해야겠습니다ㅎㅎㅎ </p>
              <p>그럼 이만 마치겠습니다~~</p>
          `,
    createDate: 'Posted on Aug 8, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.722Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '66',
    title: 'LA 시티 투어 - (1)',
    content: `
              <img src="/images/blog/post66/post66_9.png">
              <p>출장 일정 중 하루는 LA 시티투어를 예약해 두었어요!</p>
              <p>그래서!!! 이번 블로그는 LA 시내 투어 후기입니다~~~!!</p>
              <p>LA 여행 어디를 가야 할지 고민이라면 주목해주세요!</p>
              <p>액기스만 모아서 다녀왔습니다!!</p>
              <img src="/images/blog/post66/post66_1.png" alt="BCD 북창동 순두부">
              <img src="/images/blog/post66/post66_2.png" alt="BCD 북창동 순두부">
              <img src="/images/blog/post66/post66_3.png" alt="BCD 북창동 순두부">
              <img src="/images/blog/post66/post66_4.png" alt="BCD 북창동 순두부">
              <img src="/images/blog/post66/post66_5.png" alt="BCD 북창동 순두부">
              <img src="/images/blog/post66/post66_6.png" alt="BCD 북창동 순두부">
              <p>11시 픽업이라 투어 시작 전 배를 든든하게 채우기 위해 아침 식사 장소로 BCD 북창동 순두부를 찾았습니다!</p>
              <p>북창동 순두부의 본점이… LA라는 것 알고 계셨나요?</p>
              <p>미국에서 한국으로 역수출한 BCD 북창동 순두부 본점에 다녀왔습니다! 저희 대표님도 인정하신 LA 맛집!!</p>
              <p>순두부에 LA 갈비 세트를 시켜 나눠 먹었는데... 와 여기 순두부도 맛있는데 LA갈비 맛집이에요! 짱짱!!~</p>
              <p>달달하면서 숯불 향이 나는데 LA갈비 장인이 여기 계셨네요~!</p>
              <p>밥 다 먹고 난 뒤 돌솥 밥 누룽지까지 풀코스로 즐긴 아침이었어요ㅎㅎㅎ</p>
              <img src="/images/blog/post66/post66_7.png" alt="산타모니카">
              <img src="/images/blog/post66/post66_8.png" alt="산타모니카">
              <p>LA 시티투어의 첫 번째 핫스팟은 산타모니카!</p>
              <p>코리아타운에서 차로 40분 정도 걸리는 거리예요~</p>
              <p>LA에 오면 산타모니카는 필수 코스!</p>
              <p>저 멀리 랜드마크인 관람차가 보이네요~~~</p>
              <img src="/images/blog/post66/post66_9.png" alt="산타모니카">
              <img src="/images/blog/post66/post66_10.png" alt="산타모니카">
              <img src="/images/blog/post66/post66_11.png" alt="산타모니카">
              <p>산타모니카 사진 스팟인 ROUTE 66에서 찰칵..!</p>
              <p>ROUTE 66은 미국 국도 제66호선으로 미국의 시카고에서 로스앤젤레스 산타모니카까지 이어지는 국도라고 해요~!</p>
              <p>현재는 폐쇄되었지만 66번 국도 끝을 표시하는 표지판이 산타모니카에 있어서 줄 서서 찍는 사진 맛집이랍니다!!</p>
              <p>그리고 영화 포레스트 검프를 모티브로 만들어진 해산물 맛집 부바 검프 옆에는 포레스트 검프에 빙의해서 찍을 수 있는 포토존이 있더라구요.</p>
              <p>인증샷 맛집 산타모니카입니다ㅎㅎ</p>
              <img src="/images/blog/post66/post66_12.png" alt="게티센터">
              <img src="/images/blog/post66/post66_13.png" alt="게티센터">
              <img src="/images/blog/post66/post66_14.png" alt="게티센터">
              <img src="/images/blog/post66/post66_15.png" alt="게티센터">
              <img src="/images/blog/post66/post66_16.png" alt="게티센터">
              <p>다음 장소는 게티 센터입니다!</p>
              <p>석유왕 진 폴 게티(J. Paul Getty)의 소장품과 기부금을 통해 조성된 박물관이에요!</p>
              <p>게티 센터는 작품들을 구경하는 재미도 있지만, 저명한 건축가 리처드 마이어가 설계한 건축물과 정원들을 구경하는 재미도 있어요~</p>
              <p>부지가 넓어서 하루 종일 구경해도 좋을 것 같아요ㅎㅎ</p>
              <p>그리고 게티 센터는 산타모니카 산맥 꼭대기 브렌트우드에 있는데, 위에서 바라보는 LA의 풍경도 굉장히 멋있어요!</p>
              <p>제가 구경한 날은 살짝 흐렸는데, 날씨가 맑으면 산타모니카도 보인다고 합니다~!!</p>
              <img src="/images/blog/post66/post66_17.png" alt="게티센터">
              <p>게티 센터를 들리면 꼭 봐야 하는 작품들이 있는데, 그중 하나는 값이 제일 나간다는 반 고흐의 아이리스…!!!</p>
              <p>하지만 저는 보기만 하고 찍지는 못했어요 흑흑...</p>
              <p>유명한 작품답게 앞에 사람들이 많이 서 있더라고요~</p>
              <p>반 고흐의 아이리스 외에도 무지한 저도 들어본 유명한 화가의 작품들도 많았어요! 렘브란트, 밀레, 세잔, 드가 등등</p>
              <p>그 중 개인적으로 좋았던 작품 2개 소개드립니다!!</p>
              <p>클로드 모네의 &lt;Sunrise&gt; 와 에두아르 마네의 &lt;The Rue Mosnier Dressed with Flags&gt;입니다.</p>
              <p>미술관 좋아하는 사람들에게는 천국일 듯해요ㅎㅎㅎ</p><br>
              <p>아직 2곳만 둘러봤는데도 길이 길어졌네요!</p>
              <p>남은 이야기는 2탄에서 이어 쓰도록 할게요! 2탄도 기대해 주세요~</p>

            `,
    createDate: 'Posted on Aug 8, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.719Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '65',
    title: 'LA 출장 미롬 방문기',
    content: `
            <img src="/images/blog/post65/post65_5.png">
            <p>안녕하세요! 아이지아이 코리아입니다~!</p>
            <p>이번 연도 초 글로벌 확장을 목표로 미국 동부로 출장을 다녀왔는데요!</p>
            <p>이번에는 가상전시 POC 프로젝트를 함께 진행했던 ‘미롬(Mirom Design)’ 방문을 위해 미국 서부 LA로 출장을 다녀왔습니다.</p>
            <p>먼저 저와 함께 출국길부터 여정을 떠나 보실까요~~~~?</p>
            <img src="/images/blog/post65/post65_1.png" alt="인천공항">
            <img src="/images/blog/post65/post65_2.png" alt="인천공항">
            <img src="/images/blog/post65/post65_3.png" alt="인천공항">
            <img src="/images/blog/post65/post65_4.png" alt="인천공항">
            <p>공항 가는 길은 언제나 설레네요~</p>
            <p>심지어 정말 오랜만에 가는 공항이라!! 두근두근 설레는 마음을 안고 인천공항 제2 여객터미널에 도착했어요ㅎㅎㅎ</p>
            <p>수속 밟고 보딩 시간 기다리며 화장실에서 사진 한 컷 찰칵!!</p>
            <p>저의 출장 룩을 소개해 드리면 편한 복장과 슬리퍼를 신고 노트북, 태블릿을 넣은 백팩과 지갑, 여권 등이 들어있는 미니 크로스백 구성에 면세 쇼핑백은 덤ㅎㅎㅎ</p>
             <img src="/images/blog/post65/post65_5.png">
            <p>약 12시간이 지나 드디어 미국에 도착!!</p>
            <p>도착해서 입국 수속하러 가는 길에 미국 국기와 Welcome to the United States를 보니 미국에 도착했구나… 실감이 나서 찰칵!</p>
            <p>미국의 입국 수속은 까다롭다고 들어서 조마조마했는데 무사히 잘 마쳐서 다행이었어요~</p>
            <img src="/images/blog/post65/post65_6.png" alt="미롬 사무실">
            <img src="/images/blog/post65/post65_7.png" alt="미롬 사무실">
            <img src="/images/blog/post65/post65_8.png" alt="미롬 사무실">
            <img src="/images/blog/post65/post65_9.png" alt="미롬 사무실">
            <p>가상전시 POC 프로젝트를 함께했던 미롬 대표님과 미팅을 위해 사무실에 방문했어요!</p>
            <p>먼저 회사에 대해 간략히 설명해 드리면, 미롬(Mirom Desgin)은 LA에 본사를 둔 실내/실외 디자인에 특화된 디자인 회사입니다.</p>
            <p>미롬의 뜻은 한국말로 미(美)+이롭다를 합친 ‘미이롭다’의 줄임말이라고 해요~~</p>
            <p>사무실은 회사하면 일반적으로 떠오르는 딱딱한 분위기와는 확연히 달랐어요!</p>
            <p>곳곳에 식물이 있어 자연 친화적이면서 멋스러웠습니다!</p>
            <p>영감이 마구마구 떠오를 것 같은 사무실이었는데.. 저도 일하고 싶다는 생각이 들더라구요ㅎㅎㅎ</p>
            <img src="/images/blog/post65/post65_10.png" alt="가상전시 쇼룸">
            <img src="/images/blog/post65/post65_11.png" alt="가상전시 쇼룸">
            <img src="/images/blog/post65/post65_12.png" alt="가상전시 쇼룸">
            <img src="/images/blog/post65/post65_13.png" alt="가상전시 쇼룸">
            <p>저희가 진행했던 프로젝트를 소개하자면,</p>
            <p>아이지아이 코리아의 온트윈즈 솔루션을 활용하여 3D 가상공간에서 가전제품 전시를 경험할 수 있도록 구현했어요!</p>
            <p>고객들은 직접 쇼룸을 방문하지 않아도!!</p>
            <p>자신의 집에서 원격으로 쇼룸을 둘러볼 수 있고 다른 사용자들과 온라인 채팅 및 화상미팅도 가능합니다.</p>
            <p>디지털 트윈 기술을 활용해 가전제품의 사실감과 퀄리티를 높이고 3D 애니메이션을 통해 제품이 실제 동작하는 모습들을 확인할 수 있어요!</p>
            <p>저도 가상전시 쇼룸을 직접 체험해 봤는데 쇼룸이 모니터 안에 있을 뿐이지 현실이랑 비슷하더라구요~~</p>
            <p>가전제품 중에 롤러블 TV가 있었는데, 클릭하면 롤러블 스크린이 올라오는 등, 실제 제품들의 기능을 3D로 확인하니 이해하기 쉬웠어요~</p>
            <p>현재 저희는 이 프로젝트를 기반으로 일회성 전시로 끝나지 않고 온오프라인 경계를 넘어 지속적으로 고객들과 이어질 수 있도록 가상전시 비즈니스 모델을 준비 중에 있습니다!</p>
            <img src="/images/blog/post65/post65_14.png" alt="Oyabun">
            <img src="/images/blog/post65/post65_15.png" alt="Oyabun">
            <img src="/images/blog/post65/post65_16.png" alt="Oyabun">
            <img src="/images/blog/post65/post65_17.png" alt="Oyabun">
            <p>미팅을 마친 후, 저녁 식사를 위해 식당으로 이동했습니다~</p>
            <p>코리아타운에 위치한 Oyabun라는 곳인데 한국어 메뉴판도 있고 점원들도 한국 분들이라 한국에 있는듯한 기분이었어요ㅎㅎ</p>
            <p>모둠회를 시켰는데 회 외에도 꼬치, 알밥, 튀김 등등 음식들이 계속 줄줄이 나와서.. 먹어도 먹어도 줄지 않더라구요?!?!</p>
            <p>식당 앞에 다녀간 유명인분들의 사진이 있었는데 한국 배우분들도 보이시고 현재 미국 대통령님과도 찍은 사진이 있더라구요…!</p>
            <p>굉장히 유명한 곳 같은데 미롬 대표님 덕분에 좋은 곳에서 저녁 식사를 마쳤습니다!! 짝짝짝~</p><br>
            <p>저는 미롬프로젝트에는 참여하진 않았지만, 이번 출장을 통해 가상전시에 대해 많은 것을 배우고 관심을 가지게 되는 계기가 되었습니다~</p>
            <p>가상전시를 위해 해결해야 하는 과제들이 남아 있지만, 이번 미팅에서 나온 내용들을 적용하고 디벨롭하여 가상전시 프로젝트에 참여할 수 있도록 노력해야겠어요!!</p>
            <p>LA 출장 미롬 방문기는 이만 마치며... 저는 LA 시티투어 후기로 다시 돌아오겠습니다~~~</p>
            `,
    createDate: 'Posted on Aug 8, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.714Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '64',
    title: '서울대학교 EMBA 방문',
    content: `
              <img src="/images/blog/post64/post64_5.png">
              <p>안녕하세요 아이지아이코리아 직원 Y양입니다~!</p>
              <p>저희 아이지아이코리아에 아주아주 특별한 게스트분들을 모셨습니다!</p>
              <p>서울대학교 EMBA (Executive MBA)분들이신데요!</p>
              <p>쉽게 말해 서울대학교 경영전문대학원입니다.</p>
              <img src="/images/blog/post64/post64_1.png">
              <p>^0^ Welcome! IGI Korea에 오신 것을 환영합니다~</p>
              <p>대표님의 SNU EMBA 원우님들이 회사를 방문하신 건데요~</p>
              <p>IGI Korea에 대한 소개와 개발한 프로그램들을 시연했어요!</p>
              <img src="/images/blog/post64/post64_2.png">
              <p>회의실에서 대표님이 열심히 PT를 하고 계십니다!</p>
              <p>대표님의 회사소개를 들은 적이 있는데요~</p>
              <p>제가 열심히 참여한 프로젝트가 발표내용에 나오면 기분이 좋습니다ㅎㅎㅎ</p>
              <p>특히 제가 작업한 부분이 메인 이미지로 쓰였다? ^^ 후후 기분이 좋그든요.</p>
              <img src="/images/blog/post64/post64_3.png">
              <img src="/images/blog/post64/post64_4.png">
              <p>데모 공간에서 VR과 AR 시연을 하였습니다.</p>
              <p>이거 저도 해봤는데 꽤 신기하고 재미있어요 ㅎㅎ</p>
              <p>하얀 옷을 입은 우리의 잘생긴 VR 개발자님이 열심히 설명하고 계신 모습이 보입니다.</p>
              <p>이건 조금 다른 이야기인데요.</p>
              <p>본인이 개발한 것을 직접 시연하는 경험도 중요한 것 같아요.</p>
              <p>제가 생각지도 못한 부분에서 재미를 느끼기도 하시고, 불편함을 느끼기도 하시거든요.</p>
              <p>이게 작업할 때 하나의 경험치로 쌓이는 것 같아요~~</p>
              <img src="/images/blog/post64/post64_5.png">
              <img src="/images/blog/post64/post64_6.png">
              <p>회사 입구에서 기념 샷을 안 찍을 수가 없겠죠?</p>
              <p>IGI Korea 방문을 마치고 손님들과 저녁 식사는 대표님의 최애식당 량에서 진행되었습니다~</p>
              <p>회사를 방문하신 SNU EMBA 원우님께서 몽지란 M6를 가지고 오셨네요! (영롱한 파란 술병+0+)</p>
              <p>몽지란은 중국 술이라고 하네요, 중식당에 중국 술이라니… 운명이네요~</p>
              <img src="/images/blog/post64/post64_7.png">
              <p>사실 저는 이 자리에 함께 있었던 건 아니에요!</p>
              <p>제가 어떻게 이 자리에 덜덜…</p>
              <p>하지만! 대표님이 가끔 량에 데려가 주시거든요.</p>
              <p>마침 블로그 쓰기 전날 같은 메뉴를 먹고 왔답니다~</p>
              <p>의도한 건 아니지만 아주 타이밍이 좋죠?</p>
              <p>짤막하게 음식사진도 보고 가시죠!</p>
              <p>량 진짜 맛있거든요. 보고 가세요!!</p>
              <img src="/images/blog/post64/post64_8.png">
              <p>첫 번째로 양장피입니다!</p>
              <p>저는 양장피를 여기서 처음 먹어봤어요~</p>
              <p>이게 여름철 별미인 듯..</p>
              <p>저 한입 와구와구 먹었다가 겨자 맛 엄청 세게 맛봤습니다. ㅎㅎ 코 찡~</p>
              <img src="/images/blog/post64/post64_9.png">
              <p>그거 아세요?</p>
              <p>전 팔보채도 량에서 처음 먹어봤답니다!</p>
              <p>해산물을 무척이나 좋아하는데 오징어랑 새우랑 여러 야채가 있어요~!</p>
              <img src="/images/blog/post64/post64_10.png">
              <p>마지막으로는 탕수육인데요.</p>
              <p>탕수육은 너무 맛있어서 항상 두 번 시켜 먹어요~!</p>
              <p>블로그 쓰기 전날, 대표님과 점심 미니 회식으로 총 6명이서 탕수육 2개, 팔보채, 양장피, 짬뽕 곱빼기, 짜장 곱빼기를 먹었어요!</p>
              <p>대표님의 SNU EMBA 원우님들도 마음에 드셨겠죠??</p>
              </br>
              <p>그럼 이상으로 블로그를 마치겠습니다.</p>

            `,
    createDate: 'Posted on Jul 7, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.887Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '63',
    title: 'IGI Korea 직원들의 여가 활동! 단체영화관람 후기',
    content: `
              <img src="/images/blog/post63/post63_14.png">
              <p>안녕하세요~ 아이지아이 코리아 직원 Y양입니다!!!!!!!!!!!!</p>
              <p>약간 한물갔지만, MBTI가 한동안 크게 유행했었죠??</p>
              <p> 제 MBTI는 바로 ENFP인데요!</p>
              <p>글 서두에 냅다 MBTI를 이야기하는 건, 다 이유가 있습니다. </p>
              <p>TMI를 많이 쓸 예정이니 영화관람 후기가 궁금하신 분들은 밑으로 내리시면 돼요!</p>
              <p>엘리멘탈 포스터 이미지를 넣어 놓았으니 거기서부터 읽으시면 됩니다 ㅎㅎㅎ</p>
              <p>다시 MBTI 이야기로 돌아가자면 저는 절 빼고 노는 걸 무척이나 서운해해요 ㅠ</p>
              <p>저만 그런 줄 알았는데, 알고 보니 ENFP 특징이었다죠...</p>
              <p>ENFP 시무10조라는밈도 있어요!</p>
              <img src="/images/blog/post63/post63_1.png">
              <p>아이지아이 코리아에는 개발팀에도 엔프피(S)양이 한 명 있고, 디자인팀에도 엔프피(저)와 새로 들어온 분이 엔프피이십니다.</p>
              <p>따라서 저희 회사에는 작은 문화가 하나 있어요… (저.. 저만의 작고 소중한 문화…)</p>
              <p>만약 직원들끼리 어딘가를 놀러 간다? 그럼 꼭 다른 사람들한테도 물어봐 주기!</p>
              <p>왜냐고요? 안 물어보면 서운하니까요~!!!!!</p>
              <p>이날 놀러 갑니다~ 함께하실 분 있나요? 하고 공개적으로 공지를 해가며 파티원들을 구하는데 꽤나 참여율이 좋은 편이랍니다!?!?!?</p>
              <p>가끔 진짜 안 모일 때도 있긴 하지만요 ㅎㅎㅎ 꼭.. 물어봐줘야해요..</p>
              <p>아! 그렇다고 E들의 회사라며 I분들 겁내지 않으셔도 돼요.</p>
              <p>말은 못 하지만 끼고 싶어 하는 눈치면 일대일로 물어봐 줍니다.</p>
              <p>확실히 거절하는 방법도 훈련하실 수 있어요!</p>
              <p>제가 거절당해도 까먹고 또 물어보거든요.</p><br>
              <p>하여튼 제가 영화 보러 가는 걸 너무너무 좋아해서 가끔 영화를 같이 관람하곤 했어요!</p>
              <p>이번에 픽사에서 새로 나온 영화인 엘리멘탈은 4월부터 개봉하기만을 기다리고 있었어요!!!</p>
              <p>그래서 개봉하자마자 보고 싶었는데, 회사에 메타버스 엑스포 행사가 있어서, 그 다음주인 6월 23일에 관람하기로 했습니다.</p>
              <p>그런데 이게 웬걸! 물어볼 때마다 사람들이 영화파티에 참여하시는 거예요!!!</p>
              <p>그래서 최종 12명의 엘리멘탈 파티원이 모집되었습니다~~!! (박수~~)</p>
              <img src="/images/blog/post63/post63_2.png">
              <img src="/images/blog/post63/post63_3.png">
              <p>후후후, 씨네큐라는 영화관이 자리가 많아요.</p>
              <p>CGV나 롯데시네마에서 볼 때는 각자 티켓팅을 하지만 씨네큐는 딱히 할인 수단이 없어서 그냥 제가 한 번에 예약하고 나눕니다.</p>
              <p>그런데! 이번엔! 영화 보러 가기 전날인 평일 저녁에 회식이 있었단 말이죠???</p>
              <p>회식 자리에서 영화 보러 가는 이야기가 나왔어요! 회식 자리에서도 파티원을 열심히 구하고 있었고요!!</p>
              <p>대표님이랑 같이 가도 재밌을 것 같아서 대표님께도 파티 초대를 하였지만! 단호하게 거절하셨습니다…</p>
              <p>그 대신에!!!!!!!!!!!! 영화와 팝콘 비용을 지원해 주겠다고 하셨씁니다!!!!!!!!!!!!!!!!!!!!!!!!!</p>
              <p>어머낭.. ㅎㅎㅎ 다음날 업무챗에서 공식화해주시더라고요.</p>
              <p>이왕 문화생활 하는 거, 회사 내 상조회에서 동아리 정책으로 직원들의 여가생활을 지원해 주는 게 있는데, 이 정책 활용해서 지원도 받으면 좋지 않겠냐고 하시며 말이죠~</p>
              <p>저희 대표님은 복지를 정말 많이 생각해 주신답니다. (어필하기 +_+ 저 잘하고 있나요? ㅎㅎㅎㅎㅎ)</p>
              <p>글솜씨가 비루하여.. 잘 떨지 못한 아부… 더 하면 싫어하실 것 같아서 이 정도만 하겠습니다.</p><br>
              <p>영화가 8시 40분으로 꽤 늦게 시작했기 때문에, 저희는 퇴근하고 여유롭게 뒷정리하고, 간단하게 저녁을 먹은 후 영화관으로 이동했어요.</p>
              <p>사실 영화 시간을 늦게 잡은 건 영화관이 회사랑 거리가 있어서 지하철 퇴근 시간을 조금 피하고 싶었거든요.</p>
              <p>영화관에 있는 게임장에서 시작 전까지 조금 놀았는데요~</p>
              <img src="/images/blog/post63/post63_4.png">
              <img src="/images/blog/post63/post63_5.png">
              <img src="/images/blog/post63/post63_6.png">
              <img src="/images/blog/post63/post63_7.png">
              <p>농구 게임도 하고~ 총도 쏘았어요!</p>
              <p>점수는.. ㅋㅋㅋㅋ 1,300점부터 사은품이 있는데 300점… ㅋㅋㅋㅋ</p>
              <p>이 와중에 M프로님은 혼자 카레이싱 즐기고 계시더라구요!?</p>
              <img src="/images/blog/post63/post63_8.png">
              <p>그리고 마지막으로 영화관 가기 전에 펀치 기계를 쳤는데요!</p>
              <p>보이시나요???????? 9,224점 ㅎㅎㅎ 제 점수예요. 불주먹입니당^^ /</p>
              <img src="/images/blog/post63/post63_9.png">
              <p>영화관을 입장하기 전에 팝콘을 주문해야 하는데!!!!!!!!</p>
              <p>이런ㅠ 팝콘을 먹는 사람이 없네요.</p>
              <p>이제 밤에 팝콘을 먹으면 소화하기 어려운 나이가 되었어요...</p>
              <p>각자 취향에 맞게 오렌지주스 커피 보리차^^를 주문해서 들어갔습니다.</p>
              <p>어우 이렇게 보니까 진짜 많네요. ㅎㅎ</p>
              <img src="/images/blog/post63/post63_13.png">
              <p>포토스팟에서도 요로콤 사진 한 장씩 남겨줍니다.</p>
              <img src="/images/blog/post63/post63_10.png">
              <p>후후 지난번에 범죄도시 봤을 때는 우리밖에 없었는데...</p>
              <p>우리 앞뒤로도 사람이 꽤 앉아있네요 ~~</p>
              <img src="/images/blog/post63/post63_11.png">
              <p>J군의 뒷모습에서 엘리멘탈 영화에 대한 설렘과 기대가 느껴지지 않나요? ㅎㅎㅎㅎ</p>
              <img src="/images/blog/post63/post63_12.png">
              <p>드디어 영화가 시작되었습니다.</p>
              <p>3D 그래픽이 진짜 장난 아니여서 우리 3D 디자이너분들 모두 눈을 반짝거리며 봤답니다. ㅎㅎㅎ</p>
              <p>아니 예전 다른 영화들은 그래도 어떻게 했겠거니 감이라도 오는데 이번에 물, 불, 구름 표현은 정말… 어떻게 구현한 거지?</p>
              <p>영화 보고 나서 직원들끼리 서서 영화에 대한 의견을 나눴어요.</p>
              <p>아쉬운 게 어디 카페 가서 신나게 토론 나눴으면 좋았겠지만 영화가 10시 반에 끝나서... ㅠ 짧게만 나누었어요</p>
              <p>제가 영화를 단체관람하는 걸 좋아하는 이유는, 영화가 끝나고 방금 본 영화에 관한 이야기를 나누는 걸 아주 좋아하기 때문입니다~</p>
              <p>각자의 의견과 시야가 달라서 신기해요!</p>
              <p>그리고 제가 생각하지 못했던 영화에 대한 해석? 같은 것들을 서로 나누면 그게 또 시간 순삭이거든요 ㅎㅎㅎ</p>
              <p>이후에 저희 영화파티 참여를 안 한 직원 B님도(연인과 보셔야 한다고^^...) 영화에 대한 이야기를 같이 나누었는데, 인생 영화라고 하시더라구요!</p>
              <p>그럼 포스팅을 마무리하며 우리 직원들의 엘리멘탈 영화관람 평도 같이 남겨놓겠습니다.</p>
              <br/>
              <p>😀 S양 - 일 끝나고 다 같이 영화 보니 더욱 돈독해지는 것 같고 영화 끝나고 같이 감상평도 함께 나누니 즐거웠어요. 다음에 또 이런 기회가 있으면 좋겠네요~</p>
              <p>🙂 C군 - 행복한 시간과 즐거운 사람들이 모여 기억 남는 시간을 보낸 것 같습니다. 앞으로도 이런 시간을 자주 보낼 수 있으면 좋을 거 같아요.</p>
              <p>😋 M양 - 내용은 아쉬웠지만 원소 연출이 재밌었다~</p>
              <p>😇 H군 - 아이들이 보기에 서로 반대되는 인물들이 화합을 할 수 있다는 좋은 내용을 전달해 주고 성인들에게는 차별과 관련된 메시지를 던져줘서 단순하지만, 좋은 영화를 본 것 같다.</p>
              <p>😉 K군 - 기대만큼은 아니었지만, 충분히 볼만한 영화였고, 특히 색감이 인상적이었다.</p>
              <p>😂 R양 - 일 끝나고 함께한 시간이 일주일간의 스트레스가 풀리는 것 같았고 영화관이 아늑하고 그래픽이 화려해서 편하게 쉬면서 즐기고 왔습니당~</p>
              <p>😲 H양 - 다양한 사람들이 부딪히며 서로 이해하는 메시지가 담긴 따뜻한 스토리와 다채로운 그래픽으로 여러모로 힐링 된 시간이었습니다-!</p>
              <p>😊 S양 - 기대를 많이 한 것에 비해 아쉬움이 조금 있긴 했지만, 디즈니 애니메이션 중에 Top5 안에는 드는 것 같습니다! 이민 가족들에 대한 이야기를 원소들로 녹여낸 것 같아서 신선했고 감동적인 요소들도 만족스러웠습니다~~</p>
              <p>😃 I군 - 픽사 영화답게 디자인 그리고 아이디어에 볼 거리가 많았습니다.</p>
              <br/>
              <p>그러면 7월에는 더 색다른 문화 활동과 구체적인 동아리가 되어 다시 돌아오겠습니다!</p>
              <p>COMING SOON!</p>

            `,
    createDate: 'Posted on Jun 26, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.830Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '62',
    title: '아이지아이 코리아 2023 Metaverse Expo 참가!',
    content: ` 
              <img src="/images/blog/post62/post62_1.png">
              <p>안녕하세요~ 아이지아이 코리아입니다!</p>
              <p>이번엔 아주 특별한 소식을 들고 왔는데요~</p>
              <p>아이지아이 코리아가 2023.06.14~2023.06.16까지 코엑스에서 열렸던 메타버스 엑스포에 참가하였습니다!</p>
              <p>이번 ‘2023 메타버스 엑스포’는 국내외 메타버스, 웹 3.0, AI 업계의 종사자, 전문가, 공공기관 등 다양한 관계자가 모이는 아시아 최대 규모의 메타버스 산업박람회입니다!</p>
              <p>저희 아이지아이 코리아도 열심히 준비해서 나가게 되었답니다~!</p>
              <img src="/images/blog/post62/post62_2.png">
              <img src="/images/blog/post62/post62_3.png">
              <p>행사 전날 부스 배치를 어떻게 할지 고민하고 계신 서 디자이너님과 열심히 준비하고 있는 개발자 김 씨!</p>
              <p>사람들에게 저희 홍보영상을 보여주기 위해 열심히 TV 거치대를 준비하고 있어요~~</p>
              <img src="/images/blog/post62/post62_4.png">
              <img src="/images/blog/post62/post62_5.png">
              <p>드디어 대망의 엑스포 첫날!</p>
              <p>엑스포가 시작되었습니다~ 짝짝짝짝!!</p>
              <p>출입증도 받고, 행사장 입구도 깔끔하게 찍어 주고요~</p>
              <img src="/images/blog/post62/post62_6.png">
              <img src="/images/blog/post62/post62_7.png">
              <p>부스 위치는 요기에요!</p>
              <p>카페테리아 바로 옆에 있어서 사람들이 아주아주 많이 오겠죠?</p>
              <img src="/images/blog/post62/post62_8.png">
              <p>저희 부스 모습 한번 보여드릴게요~!</p>
              <p>저희 회사 상징인 주황색 포인트가 들어간 부스 벽면에 누가 디자인했는지 모르지만 정말 정말 화사한 솔루션 설명 그래픽이 붙어서 정말 잘 어울리는 부스가 탄생했어요! (협박당했다면 당근을 들어주세요🥕)</p>
              <img src="/images/blog/post62/post62_9.png">
              <p>3일 동안 엑스포 부스를 지켜줄 상주 인원분들 찰칵!</p>
              <p>앞으로 행사 끝날 때까지 목이 쉴 때까지 OnTwins를 외칠 분들입니다^^</p>
              <img src="/images/blog/post62/post62_10.png">
              <img src="/images/blog/post62/post62_11.png">
              <p>개장하기 전 마지막 세팅을 하고, 본격 행사로 고고!</p>
              <p>행사에서 저희는 총 세 가지의 솔루션을 준비했는데요!!</p>
              <p>저희 OnTwins 솔루션에 대해 설명 잠깐 해드릴까요?</p>
              <p>저희 OnTwins 솔루션은 메타버스, 디지털 트윈, 가상전시 솔루션으로 이루어져 있습니다!</p>
               <img src="/images/blog/post62/post62_12.png">
              <img src="/images/blog/post62/post62_13.png">
              <p>먼저 OnTwins 메타버스 솔루션에 대해 설명해 드리자면, 저희 아이지아이 코리아의 뛰어난 3D 가시화 기술과 뛰어난 기획력으로 메타버스 플랫폼을 만들었습니다!</p>
              <p>통일부의 DMZ Universe, 한국관광공사의 축제 메타버스가 저희 솔루션을 바탕으로 제작된 메타버스인데요~</p>
              <p>DMZ Universe는 현재 universe.go.kr에 접속하셔서 즐기실 수 있답니다!</p>
              <img src="/images/blog/post62/post62_14.png">
              <img src="/images/blog/post62/post62_15.png">
              <p>다음으로 디지털 트윈 솔루션에 대해 설명드릴게요!</p>
              <p>아이지아이 코리아의 OnTwins 디지털 트윈 솔루션은, 현실의 물리적인 공간을 가상화하여 여러 가지 지표들을 대시보드 상에서 보여주는 역할을 하는데요!</p>
              <p>단순히 지표를 보여주기만 하는 게 아니라, 여러 가지 상황을 시뮬레이션하는 기능과, 직접 트윈 내부를 돌아다니는 워크스루 모드, 그리고 사용자가 직접 3D 오브젝트의 위치를 변경할 수 있는 빌더 기능이 있어요!</p>
              <p>저희 트윈 솔루션, 기능이 아주 실하게 꽉 차있죠?ㅎㅎ</p>
              <p>앞으로도 많이 기대해 주세요!</p>
              <img src="/images/blog/post62/post62_16.png">
              <img src="/images/blog/post62/post62_17.png">
              <p>마지막은 OnTwins 가상전시 솔루션에 대해 설명해 드릴게요~~</p>
              <p>저희 가상전시 솔루션은 3D 공간상에 사용자가 원하는 대로 전시장을 만들 수 있는 솔루션이에요!</p>
              <p>그림이면 그림! 모델하우스면 모델하우스! 가상공간 속에서 저희는 어떤 것이든 만들 수 있답니다~</p>
              <p>게다가 다수의 인원이 참가할 수 있는 컨퍼런스 기능까지!</p>
              <p>이 정도면 저희 가상전시 솔루션 혜자 아닌가요??</p>
              <img src="/images/blog/post62/post62_18.png">
              <img src="/images/blog/post62/post62_19.png">
              <p>부스에서 상주하고 계신 저희 인싸력 뿜뿜 직원들이 부스에 방문한 참관객을 대상으로 열심히 설명하고 있네요!</p>
              <p>크 역시 아나운서를 해도 될 정도의 정확한 딕션으로 참관객들의 귀에 쏙쏙 박히는 설명을 해주시네요~</p>
              <p>설명 들으신 모든 분께는 브로슈어, 부채, 리유저블 컵을 드리고 있습니다!</p>
              <p>얼른얼른 와서 설명 듣고 받아 가세요~</p>
              <img src="/images/blog/post62/post62_21.png">
              <p>일단 저희 브로슈어 먼저 보고 가시죠~</p>
              <p>저희 회사에는 금손 디자이너 분들이 정말 많답니다ㅎㅎ</p>
              <p>눈에 확 튀는 저희 브로슈어. 이쁘지 않나요?</p>
              <img src="/images/blog/post62/post62_22.png">
              <img src="/images/blog/post62/post62_23.png">
              <p>홍기들어! 백기들어!~~</p>
              <p>부채 앞 뒷면에 OnTwins 로고가 딱!</p>
              <p>요즘 같은 무더위에는 OnTwins 부채가 딱이죠.</p>
              <img src="/images/blog/post62/post62_24.png">
              <p>마지막으로 OnTwins 로고와 솔루션 설명이 간결하고 정확하게 박혀있는 환경 사랑 리유저블컵까지 받으시면 OnTwins 굿즈 수집 끝~~!</p>
              <img src="/images/blog/post62/post62_25.png">
              <img src="/images/blog/post62/post62_26.png">
              <p>회사의 다른 직원분들도 엑스포에 참여하셔서 부스 일도 도와주시고 다른 체험도 하셨답니다!</p>
              <img src="/images/blog/post62/post62_27.png">
              <img src="/images/blog/post62/post62_28.png">
              <p>마지막 부스 철거까지 도와주신 착한 직원분들!</p>
              <p>모든 분의 도움이 있어서 아이지아이 코리아의 첫 엑스포 출품이 성공적으로 끝났습니다!</p>
              <p>이번 엑스포를 통해서 생각보다 많은 사람이 저희 OnTwins 솔루션에 관심을 가져주신 것을 확인할 수 있었어요~</p>
              <p>몸은 힘든 시간이었지만 마음은 더 든든해지는 기분!!</p>
              <p>그러면 다음에 다른 소식으로 다시 올게요~ 안녕~!</p>
            `,
    createDate: 'Posted on Jun 21, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.698Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '61',
    title: '아이지아이 코리아의 새로운 사무실!',
    content: `
              <img src="/images/blog/post61/post61_1.png">
              <p>안녕하세요~!</p>
              <p>이번에 저희 아이지아이 코리아가 아주 넓~은 사무실로 이사를 했습니다! 짝짝짝짝!!</p>
              <p>이전에는 사무실이 2개로 나뉘어져 있어서 소통하기가 조금 불편했는데요~</p>
              <p>이제는 하나의 사무실에서 더 효율적으로 일할 수 있게 되었답니다~!ㅎㅎ</p>
              <p>새로운 사무실! 구경해보실까요~?</p>             
              <img src="/images/blog/post61/post61_2.png">
              <p>모두 모두 아이지아이 코리아에 오신 것을 환영합니다~!</p>
              <img src="/images/blog/post61/post61_3.png">
              <p>짜잔!!!!!!!!!!!!!!!</p>
              <p>이전 사무실에 비하면 훨씬 넓어진 거 느껴지시나요??</p>
              <p>구조가 살짝 특이한 곳이라 한곳씩 소개해 드릴게요~</p>
              <img src="/images/blog/post61/post61_4.png">
              <p>먼저 문 열고 들어오자마자 보이는 이곳은 데모룸이랍니다!</p>
              <p>VR기기와 AR글래스로 아이지아이 코리아의 메타버스와 디지털 트윈을 직접 체험해 보실 수 있게 공간을 만들어 두었어요~!</p>
              <p>여기에 서서 VR도 하고 유니버스도 들어가고~ 아주 즐거운 데모룸이랍니다!</p>
              <img src="/images/blog/post61/post61_5.png">
              <img src="/images/blog/post61/post61_6.png">             
              <p>데모룸 옆에는 개발그룹 분들의 자리를 배치했어요~~</p>
              <p>모두 열일하고 계시는 모습이 보이시나요!?</p>
              <p>사무실이 넓어지다 보니 이전보다 자리도 널널해져서 다들 만족해하신답니다~^0^</p>
              <img src="/images/blog/post61/post61_7.png">
              <img src="/images/blog/post61/post61_8.jpngpg">    
              <p>조금 더 안쪽으로 들어가면 나오는 디자인기획그룹!</p>
              <p>이렇게 개발그룹과 디자인기획그룹이 붙어있다보니 확실히 이전보다는 소통하기가 훨씬 수월하더라구요~</p>
              <img src="/images/blog/post61/post61_9.png">
              <img src="/images/blog/post61/post61_10.png">  
              <p>다음은 저희 아이지아이 코리아의 대표님이 쓰시는 곳이랍니다~</p>
              <p>손님들이 오시면 미팅을 하실 수 있게 소파를 배치하고 액자도 걸어두었어요!</p>
              <p>저기 보이는 갈색 의자는 조절하면 누울 수도 있어요~~</p>
              <p>제가 한 번 누워봤는데… 일어나고 싶지 않았습니다...! (내 집 같은 편안함..)</p>
              <img src="/images/blog/post61/post61_11.png">
              <img src="/images/blog/post61/post61_12.png">  
              <p>여기는 저희 부사장님이 쓰시는 공간이에요~</p>
              <p>부사장님 방에는 천장에 아주 예쁜 등이 달려있답니다~!!</p>
              <p>최대한 깔끔하고 업무에 불편함이 없으시게끔 가구를 배치했어요ㅎㅎ</p>
              <img src="/images/blog/post61/post61_13.png">
              <img src="/images/blog/post61/post61_14.png">  
              <p>저희 아이지아이 코리아에는 기업부설연구소가 있답니다!</p>
              <p>무려 LED등이 달린 공간…!</p>
              <p>가장 안쪽에 위치해있어 문을 닫고 있으면 방음이 아주 잘되는 공간이랍니다. bb</p>
              <img src="/images/blog/post61/post61_15.png">
              <img src="/images/blog/post61/post61_16.png">  
              <p>여기는 직원분들끼리 미팅도 하고 손님맞이도 하는 회의실이랍니다!</p>
              <p>주황색 의자를 이번에 새로 구매했는데 회사 로고와 색깔이 잘 맞아서 넘 만족만족~!!</p>
              <img src="/images/blog/post61/post61_17.png">
              <img src="/images/blog/post61/post61_18.png">  
              <p>마지막으로 저희 직원분들이 가장 많이 들락날락하시는 탕비실!</p>
              <p>이전 탕비실에 비해서 정말 쾌적해졌어요ㅎㅎ</p>
              <p>중앙의 테이블에서 다 같이 소소하게 담소를 나누기도 하고 미팅을 진행하기도 한답니다.</p><br>
              <p>이사를 오고 나서 여기저기 수리할 곳도 많고 데모룸도 구축하느라 포스팅이 조금 늦어졌네요. ㅜㅜ</p>
              <p>앞으로 조금씩 더 공간을 꾸며나갈 계획이랍니다!</p>

              `,
    createDate: '2021-09-10 12:48',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '60',
    title: '국립통일교육원 제11회통일교육주간 - 통일 한반도, 메타버스 경진대회',
    content: `
              <img src="/images/blog/post60/post60_2.png">
              <p>안녕하세요! 아이지아이코리아에 다니는 직원 "Y"입니다.</p>
              <p>작년에 국립통일교육원과 진행한 프로젝트의 PM을 맡았었습니다!</p>
              <p>이 프로젝트는 실제로 가지 못하는 북한을 가상의 세계에서 체험할 수 있는 재미있는 내용을 담고 있는데요~</p>
              <p>프로젝트명인 "통일 한반도, 또 하나의 세계"에서 보여주는 것처럼,</p>
              <p>우리가 통일된 한반도에서 어떤 모습일지 상상하며 구현하였습니다!!</p>
              <img src="/images/blog/post60/post60_1.png">
              <p>이 프로젝트는 초등학생들을 대상으로 하며, 인지도가 높은 메타버스 플랫폼인</p>
              <p>로블록스를 이용하여 평양, 개성, 원산, 나선, 신의주라는 북한의 다섯 공간을 구현하였습니다~!</p>
              <p>국립통일교육원의 교수님들과 함께 한 달 동안 기획 회의를 진행하고, 실제 북한의 지도를 참고하여 열심히 만들었습니다.</p>
              <p>제작 과정에서는 씁쓸한 감정도 들기도 했지만,</p>
              <p>오히려 북한에 대해 잘 몰랐다는 것을 깨닫고 더 많이 배운 시간이었습니다~~</p>
              <img src="/images/blog/post60/post60_5.png">
              <p>메타버스로 게임 대회를 진행하게 되었는데요~</p>
              <p>약 50명의 학생이 게임 대회에 참가하였습니다!</p>
              <p>예선전을 통과한 12명의 학생이 본선을 진행하고,</p>
              <p>그중에서 4명의 학생들이 결승을 진행하는 형식으로 대회가 구성되었습니다!!</p>
              <img src="/images/blog/post60/post60_3.png">
              <img src="/images/blog/post60/post60_4.png">
              <p>한 달 동안 틈틈이 대회 구성을 짜고, 대회 하루 전에는 리허설에도 참가했습니다~</p>
              <p>그중에서도 가장 특별한 경험은 아이들이 우리가 만든 메타버스를 즐기는 것을 직접 보는 것이었어요!</p>
              <img src="/images/blog/post60/post60_6.png">
              <img src="/images/blog/post60/post60_7.png">
              <p>대회 당일에는 체험 부스 존이 마련되어, 갤럭시 탭을 통해 메타버스를 체험할 수 있었어요~!</p>
              <p>그리고 대회에 참가하지 않은 친구들도 캐릭터를 꾸미며 즐겁게 놀고 있던 모습을 볼 수 있었습니다 &gt;&lt;</p>
              <p>덕분에 저도 정말 특별한 기억을 남겼네요~~!</p>
              <img src="/images/blog/post60/post60_8.png">
              <p>드디어 본선이 시작되었습니다!!</p>
              <p>와~!!!!!!!! 왼쪽에 하얀 옷 입은 사람이 저예요 여러분!!!</p>
              <p>오른쪽에는 개발자님이구요! 방송탔어요  &gt;________ &lt;</p>
              <p>왜 무대에 올라가 있냐구요 ???</p>
              <p>게임대회는 공정해야 하므로, 혹시 버그로 인해 불공정한 일이 생기면 안 되니까 선수들(?) 바로 옆에서 모니터링 진행하였어요~</p>
              <p>4명 학생의 화면을 전광판에 띄워놓고 유튜버인 조충현님이 게임을 중계하는 방식으로 진행되었는데요!!</p>
              <p>대회의 메인 MC는 개그맨이자 유튜버 조충현님과 북한 전문가 엄현숙 교수님, 탈북민 주찬양 쌤이 진행해 주셨습니다-!</p>
              <p>MC분들께서 게임 중간 중간 북한에 대한 정보를 설명해 주시거나 이벤트를 진행하여 대회를 더욱 풍성하고 알차게 만들어주셨어요ㅎㅎ</p>
              <img src="/images/blog/post60/post60_9.png">
              <p>중간 중간 치시는 개그 때문에 진행요원으로 있으면서 실컷웃었네요..ㅋㅋㅋㅋ</p>
              <img src="/images/blog/post60/post60_10.png">
              <p>결승전은 20분 동안 10개의 미션을 몇 개 깨는지 대결하는 거였는데요.</p>
              <p>결승전답게 잘하는 친구들이 올라와서 미션을 거의 다 깼어요…!  대단...!!!</p>
              <p>1위를 한 친구는 무려 갤럭시 탭을 타갔답니다!! ㅎㅎ 정말 부럽네요~</p>
              <p>2등 갤럭시 버즈 프로, 그 외에  인스탁스 카메라, 짐벌, 블루투스 스피커 무선충전기 등등 탐나는 상품들이 많았어요..</p>
              <p>버즈 프로라니!!</p>
              <img src="/images/blog/post60/post60_11.png">
              <p>모든 순서가 끝나고 빈 무대를 기념으로  한컷 찍어보았습니다~~!</p>
              <img src="/images/blog/post60/post60_12.png">
              <p>대회가 끝나고 나서는 국립통일교육원SNS에 관련 소식도 올라왔답니다~</p>
              <p>친구들이 깬 미션을… 여러분들도 도전하고 싶진않나요?????????</p>
              <p>아래의 링크로 들어오시면 직접 체험해 보실 수 있답니다!</p>
              <p>츄라이츄라이~</p><br>
              <p>&lt;메타버스 체험해보기&gt;</p>
              <a href="https://www.roblox.com/games/10762392256/uniedu">https://www.roblox.com/games/10762392256/uniedu</a><br><br>
              <p>&lt;통일 한반도 메타버스 경진대회 영상&gt;</p>
              <a href="https://www.youtube.com/watch?v=5FLELrIkJZs">https://www.youtube.com/watch?v=5FLELrIkJZs</a>

            `,
    createDate: 'Posted on May 26, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.783Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '59',
    title: 'IGI Korea 디자인기획그룹 워크숍',
    content: `
              <img src="/images/blog/post59/post59_17.png">
              <p>안녕하세요~</p>
              <p>따뜻한 봄날, 저희 아이지아이 코리아의 디자인기획그룹이 가평 &amp; 남이섬으로 1박2일 워크숍을 다녀왔습니다!</p>
              <p>긴말 필요 없이 바로 함께하시죠~^ㅇ^</p>
              <img src="/images/blog/post59/post59_1.png"></p>
              <p>인원이 많아 무려 4대의 차량으로 이동했답니다. (운전자분들bb)</p>
              <p>플레이리스트 장전하고 가평으로 출발! </p>
              <img src="/images/blog/post59/post59_2.png">
              <img src="/images/blog/post59/post59_3.png">
              <img src="/images/blog/post59/post59_4.png">
              <p>전 인원 무사히 도착!</p>
              <p>남이섬에 들어가기 전에 닭갈비로 배에 기름칠부터 해주었답니다.</p>
              <p>치즈퐁듀에도 찍어 먹고 막국수에 싸 먹기도 하고~ JMT!</p>
              <p>배부르게 먹고 옹기종기 모여 단체 사진까지!</p>
              <p>이제 남이섬 접수하러 갑시다~~~~~~~</p>
              <img src="/images/blog/post59/post59_5.png">
              <img src="/images/blog/post59/post59_6.png">
              <p>남이섬에 들어갈 땐 배 or 짚와이어 중 선택할 수 있는데요!</p>
              <p>대부분 배를 선택할 때.. 오늘만 사는 스릴러 4인방은 짚와이어로 1분 30초 만에!! 아주 빠르게 남이섬에 들어갔답니다!</p>
              <img src="/images/blog/post59/post59_7.png">
              <p>나미나라공화국에 오신 걸 환영합니다~!</p>
              <p>요새 날씨가 계속 우중충했었는데 하늘 맑은 거 보이시나요?</p>
              <p>날씨 요정 누구야! 칭찬해^ㅇ^~~~~~~</p>
              <img src="/images/blog/post59/post59_8.png">
              <img src="/images/blog/post59/post59_9.png">
              <img src="/images/blog/post59/post59_10.png">
              <p>북한강 길을 따라 걸으며 간만에 미세먼지 없는 공기와 함께 도란도란 이야기도 나누고 사진도 찍어 줬답니다~!</p>
              <p>초록초록한 풍경을 보며 걸으니까 정말 힐링이 따로 없더라구요~</p>
              <p>여유로움을 만끽하며 걷다가 그새 안드로메다로 가버린 체력을 살리기 위해!! 카페로 고고!</p>
              <img src="/images/blog/post59/post59_11.png">
              <p>…? 네가 왜 거기서 나와…..?</p>
              <p>찾아보니 남이섬에는 공작새, 다람쥐, 토끼 등 다양한 동물들을 방목해서 키운다고 해요~</p>
              <p>서울에서는 흔히 볼 수 없는 친구라서 모두가 신기해했답니다~!</p>
              <img src="/images/blog/post59/post59_13.png">
              <p>이른 아침부터 이동하느라 지쳐버린 디자인기획그룹.. 카페인 수혈이 필요한 순간이었습니다~</p>
              <p>크으 지금 보니 채광이 기가 막히네요.</p>
              <p>충분히 쉬어주고 다음 장소로 출발~!</p>
              <img src="/images/blog/post59/post59_15.png">
              <img src="/images/blog/post59/post59_16.png">
              <img src="/images/blog/post59/post59_14.png">
              <p>출발하려고 보니 카페 옆에 무민 전시관이 있었어요!</p>
              <p>무료입장이라고 해서 냉큼 들어가 보았는데요~</p>
              <p>포토존이 꽤 많아서 여기저기 자리 잡고 찰칵찰칵!!</p>
              <p>두번째 사진은 미국에서 건너온 막내 삼촌과 어색한 조카 컨셉이랍니다ㅎㅎ</p>
              <p>이제 메타세콰이어 길로 고고!</p>
              <img src="/images/blog/post59/post59_17.png">
              <p>보기만 해도 탁 트인 메타세콰이어 길에서 단체 사진 빡!</p>
              <p>여기가 포토존인지 관광객분들이 줄을 서서 사진을 찍고 계시더라구요.</p>
              <p>저희도 놓칠 수 없죠ㅎㅎ</p>
              <p>다시 봐도 날씨가 화창하네요!</p>
              <p>워낙 유명한 길이라서 포토타임을 조금 갖고 이제 타조 보러 가자~~</p>
              <img src="/images/blog/post59/post59_18.png">
              <p>타조 보러 가는 길에 발견한 평화의 방패!</p>
              <p>남이섬에는 평화를 기원하는 평화의 방패와 전쟁의 상징물인 탱크가 전시되어 있어요.</p>
              <p>혹시 저기 사람이.. 보이시나요?</p>
              <p>전시물 아니고 저희 디자인기획그룹의 임 프로입니다. (안 잡아먹어요.. 순한 사람이에요…)</p>
              <p>지금 당장 저 탱크를 몰아도 손색없을 비주얼이죠?</p>
              <img src="/images/blog/post59/post59_19.png">
              <img src="/images/blog/post59/post59_20.png">
              <p>여기는 남이섬의 타조를 볼 수 있는 깡타의 집입니다!</p>
              <p>다른 동물들은 다 방목해서 키우는데 타조들은 하도 말썽을 피워서 이렇게 우리 안에서 키운다고 해요.</p>
              <p>그래서 별명이 깡(패)타(조)…!</p>
              <img src="/images/blog/post59/post59_21.png">
              <p>그런 깡패와 눈 맞추는 남모군..</p> 
              <p>화가 많이 나 보이는 깡타였…</p>
              <p>무브무브! 빠르게 이동…!!</p>
              <p>이렇게 남이섬 투어를 마치고 해가 지기 전에 숙소로 출발!</p>
              <img src="/images/blog/post59/post59_22.png">
              <p>짜잔~ 하루 동안 우리 디자인기획그룹을 책임져 줄 가평 백낙제풀빌라입니다!</p>
              <p>산골짜기에 위치해서 편의점에 가려면 차를 타고 나가야 하지만 그만큼 자연 친화적인 곳이었어요.</p>
              <img src="/images/blog/post59/post59_23.png">
              <img src="/images/blog/post59/post59_25.png">
              <img src="/images/blog/post59/post59_24.png">
              <p>숙소 앞에 이렇게 예쁜 계곡이…! 모두 신나서 발도 담그고 사진도 찍어 줬어요~</p>
              <p>디자인기획그룹의 분위기메이커님의 산뜻한 주황색 아우터가 배경이랑 너무 잘 어울리지 않나요?</p>
              <img src="/images/blog/post59/post59_26.png">
              <p>일정을 마치고 광합성을 즐기는 우리 직원분들~~! 푸릇푸릇한 풍경과 함께 솔솔 부는 바람을 맞으며~</p>
              <p>몸도 마음도 편안해지는 시간을 보냈답니다.</p>
              <img src="/images/blog/post59/post59_27.png">
              <img src="/images/blog/post59/post59_28.png">
              <p>쉬다 보니 몰려오는 배고픔^^.. 모두가 모여서 바비큐를 준비했답니다.</p>
              <p>가장 단합력이 좋았던 순간이랄까요..!</p>
              <img src="/images/blog/post59/post59_29.png">
              <img src="/images/blog/post59/post59_30.png">
              <p>오늘의 주인공 모십니다!! 영롱 그 자체..</p>
              <p>우리의 쉐프님들이 불 쇼까지 해가시면서 열심히 고기를 구워주셨어요!</p>
              <p>저희는 그저 받아먹는 아기 새였답니다ㅎㅎ</p>
              <img src="/images/blog/post59/post59_31.png">
              <p>그룹장님의 건배사와 함께 파티가 벌어졌답니다.</p>
              <p>“우리 지금처럼 잘해봅시다~!”</p>
              <p>고기도 배불리 먹고 술도 한잔씩 하면서 업무 얘기, 이런저런 사는 얘기를 나누는 시간이었어요.</p>
              <img src="/images/blog/post59/post59_33.png">
              <img src="/images/blog/post59/post59_34.png">
              <p>술이 들어가서일까요…!</p>
              <p>밤늦게 갑자기 감성적으로 별을 보러 나갔답니다.</p>
              <p>아주 열정적으로 별을 사진에 담아내는 (구)쉐프님..^^</p>
              <p>나랑 별 보러 가지 않을래~ 를 외치며 길었던 하루를 마무리했답니다.</p>
              <img src="/images/blog/post59/post59_35.png">
              <img src="/images/blog/post59/post59_36.png">
              <p>2일차 아침이 밝았어요!</p>
              <p>역시 아침엔 라면이죠~~</p>
              <p>다들 몰골이 말이 아니었지만ㅎ 삼삼오오 모여 배를 채워주었답니다!</p>
              <p>후식으로 아이스크림까지 먹어주고 체크아웃합니다.</p>
              <p>잘 있어 백낙제야~!</p>
              <img src="/images/blog/post59/post59_37.png">
              <p>오늘의 첫 번째 일정인 레일바이크를 타러 왔어요~</p>
              <p>가평에는 여러 곳에서 레일바이크를 탈 수 있는데 김유정역에서 타면 볼거리들도 많고 풍경도 예쁘다고 하더라구요!</p>
              <p>단체 사진 찰칵!! 놓칠 수 없죠~!</p>
              <img src="/images/blog/post59/post59_39.png">
              <p>공정하게 사다리 타기를 통해서 자리를 정했답니다.</p>
              <p>언제 어디서나 수평적인 디자인기획그룹^^! (그룹장님 보고계신가요?)</p>
              <p>이제 출발합니다~!</p>
              <img src="/images/blog/post59/post59_40.png">
              <img src="/images/blog/post59/post59_42.png">
              <p>더울 줄 알았는데 시원한 바람이 솔솔 불어서 진짜 딱 좋았어요!!</p>
              <p>얼굴에 쌓인 미세먼지가 닦이는 기분~!</p>
              <p>셀카도 찍고 서로 사진도 찍어 주며 넘나 평화로운 시간을 보냈답니다.</p>
              <p>빨리 가라고 재촉하기도 했지만~~!ㅎㅎ</p>
              <img src="/images/blog/post59/post59_43.png">
              <img src="/images/blog/post59/post59_44.png">
              <p>이렇게 예쁜 빛이 가득한 동굴도 지나가고 붐바스틱 노래가 나오며 마치 클럽 같은 동굴도 있었는데요.</p>
              <p>우리 아버ㅈ.. 아니 그룹장님 많이 신나셨네요^^!</p>
              <img src="/images/blog/post59/post59_45.png">
              <img src="/images/blog/post59/post59_46.png">
              <p>30분 정도 가고 휴게소에 들렸어요.</p>
              <p>레일바이크를 타는 코스에 사진이 찍히는 구간이 있었는데 사이좋게 한 장씩 구매했답니다.</p>
              <p>물론 잘 나온 사람이 손에 꼽았지만..</p>
              <p>이런 것도 다 추억이니까요~!</p>
              <img src="/images/blog/post59/post59_47.png">
              <img src="/images/blog/post59/post59_48.png">
              <img src="/images/blog/post59/post59_49.png">
              <p>휴게소에서 강촌역까지는 열차를 타고 갔어요.</p>
              <p>가는 길 풍경도 정말 엄청났답니다~</p>
              <p>이렇게 놀다 보니 어느새 사라진 뱃속의 라면!!</p>
              <p>이제 점심을 먹으러 고고~!</p>
              
              <img src="/images/blog/post59/post59_50.png">
              <img src="/images/blog/post59/post59_51.png">
              <p>수많은 닭갈비집 사이에서 한 줄기 빛처럼 겨우 찾아낸 시루라는 한식집에 갔어요.</p>
              <p>코다리는 치즈 버터구이가 아주 맛있었어요!!</p>
              <p>오리 불고기도 살짝 달고 매콤하니 밥도둑이었답니다.</p>
              <img src="/images/blog/post59/post59_53.png">
              <img src="/images/blog/post59/post59_54.png">
              <p>워크숍 마지막 일정으로 기름진 배를 커피와 디저트로 씻어주러 근처 카페로 고고싱!</p>
              <p>저 옥수수 보이시나요?</p>
              <p>무려 옥수수 치즈케이크!!!</p>
              <p>진짜 감쪽같지 않나요?</p>
              <p>아메리카노랑 아주 잘 어울리는 맛이었답니다~</p>
              <img src="/images/blog/post59/post59_55.png">
              <img src="/images/blog/post59/post59_56.png">
              <p>평일 낮이라 그런지 2층 외부 자리에 저희밖에 없었어요. (아이지아이가 전세내따~!)</p>
              <p>날씨 운도 따라주고 너~무 유익했던 1박2일!</p>
              <p>금요일이라 집에 돌아가는 길이 꽤 험난했지만..</p>
              <p>모두가 재충전할 수 있었던 시간이었어요~!</p>
              <p>거의 300장에 다다르는 사진을 추리고 추리느라 조금 힘들었답니다.</p>
              <p>그럼 다음 포스팅에서 만나요~ 안녕!</p>
           `,
    createDate: 'Posted on May 9, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.891Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '58',
    title: 'IGI Korea 개발그룹 워크숍',
    content: `    
              <img src="/images/blog/post58/post58_7.png">
              <p>안녕하세요~ 오늘은 저희 IGI Korea의 이벤트 소식을 들고 왔습니다~!</p>
              <p>바로바로~ IGI Korea 개발그룹 워크숍!</p>
              <p>우리 개발그룹은 이번에 1박 2일로 용인에 다녀왔어요~</p>
              <p>그럼 지금부터 신나게 워크숍 이야기를 보러 가시죠!!</p>
              <img src="/images/blog/post58/post58_1.png">
              <img src="/images/blog/post58/post58_2.png">
              <p>개발그룹 모두 늦지 않게 도착했네요 bb</p>
              <p>1박 2일 동안 저희를 안전하게 데려다 줄 벤…!</p>
              <p>연예인들이 타는 벤이라 신기하고 두근거리더라고요~ㅎㅎ(지나가는 사람들이 가끔 손 흔들어 주셨다는ㅋㅋ)</p>
              <p>일정 동안 그룹장님이 운전을 맡아 수고해 주셨어요~(Best driver 그룹장님bb)</p>
              <p>자~ 그럼 다들 설레는 마음을 안고 용인으로 바로 출발!</p>
              <img src="/images/blog/post58/post58_3.png">

              <img src="/images/blog/post58/post58_4.png">
              <img src="/images/blog/post58/post58_5.png">
              <p>이른 아침부터 장거리 이동에 지친 몸과 마음을 충전하기 위해 맛집을 방문했습니다!</p>
              <p>이번에 방문한 곳은 용인시 처인구에 있는 고향보리밥이라는 곳이에요~</p>
              <p>건강한 나물과 보리밥을 쓱싹쓱싹 비벼 먹으니 정말 맛있더라구요~ㅎㅎ</p>
              <p>떡갈비도 정말 쫀득쫀득한 식감으로 감칠맛이 최고!</p>
              <img src="/images/blog/post58/post58_6.png">
              <p>식사를 마치고 만족스러운 표정이 느껴지시나요? ㅎㅎ</p>
              <p>그럼 모두 충전 완료했으니 본격적인 다음 장소로 출바알~!</p>
              <img src="/images/blog/post58/post58_7.png">
              <p>1일 차 하이라이트는 바로바로~ 용인 에버랜드!!</p>
              <p>오랜만에 온 놀이동산이라 마음이 부풀어서 두근두근하더라고요 ㅎㅎ</p>
              <p>누구인지 모를 거대한 토끼(?) 인형 앞에서 단체 샷 한 번 찰칵~</p>
              <img src="/images/blog/post58/post58_8.png">
              <img src="/images/blog/post58/post58_9.png">
              <p>소화도 시킬 겸 에버랜드 주토피아를 구경했답니다.</p>
              <p>오랜만에 동물들을 봐서 그런지 동심이 부풀어 오르더라고요~</p>
              <img src="/images/blog/post58/post58_10.png">
              <img src="/images/blog/post58/post58_11.png">
              <p>자 소화도 시켰으니 이제 본격적으로 즐겨야겠죠?</p>
              <p>첫 번째는 바로 아마존 익스프레스!!</p>
              <p>다~ 다~ 젖습니다~♪ 영상으로만 보던 퍼포먼스를 보게 되다니…</p>
              <p>춤추시는 에버랜드 직원분이 IGI 동료와 닮아서 다들 너무 웃었던…ㅋㅋㅋ</p>
              <img src="/images/blog/post58/post58_12.png">
              <img src="/images/blog/post58/post58_13.png">
              <p>물에 흠뻑 젖어서 즐기다 보니 어느새 내릴 때가 되었네요~</p>
              <p>그럼 바로 다음 코스로 고고~</p>
              <img src="/images/blog/post58/post58_14.png">
              <img src="/images/blog/post58/post58_15.png">
              <p>몸도 풀었으니, 다음은 바로바로~</p>
              <p>에버랜드의 마스코트…T 익스프레스입니다…!</p>
              <p>저기 보이는 웅장한 자태를 보자니 아직도 오금이 저리네요…! ㅎㅎㅎ</p>
              <p>무섭지만 정말 신났던 어트랙션이었습니다!</p>
              <p>기념사진도 한 장~ 찰칵!</p>
              <img src="/images/blog/post58/post58_16.png">
              <img src="/images/blog/post58/post58_17.png">
              <p>T 익스프레스로 흥분된 마음을 가라앉힐 겸 사파리를 갔어요~</p>
              <p>동물농장에서는 귀여워 보였는데 정말 거대한 동물들…</p><br>
              <p>사파리 구경을 하고 나니 어느새 저녁 시간이 가까워졌네요ㅠㅠ</p>
              <p>서둘러 다른 놀이기구를 탔답니다~</p>
              <img src="/images/blog/post58/post58_18.png">
              <img src="/images/blog/post58/post58_19.png">
              <p>렛츠 트위스트에 몸을 맡긴 IGI 분들…! ㅋㅋ</p>
              <p>힘 없이 축 늘어진 모습이 너무 재밌지 않나요? ㅋㅋㅋ</p>
              <img src="/images/blog/post58/post58_20.png">
              <p>시간이 없다…! 마지막은 더블 록 스핀!!!</p>
              <p>연속으로 360도 회전을 하는데…정말 혼이 나가는 줄 알았어요…</p>
              <p>덕분에 재밌는 표정들이 많이 나왔네요~bb</p>
              <p>자 그럼 신나게 놀았으니, 숙소로 가서 맛있는 저녁을 먹어야겠죠?</p>
              <p>GOGOGO~!!</p>
              <img src="/images/blog/post58/post58_21.png">
              <img src="/images/blog/post58/post58_22.png">
              <p>숙소로 오는 길에 장 본 음식들을 서둘러 세팅했어요.</p>
              <p>사장님이 세팅해 둔 숯불에 고기 올리고~ 라면도 끓이고~</p>
              <p>모두 배가 고팠는지 순식간에 식사 준비가 끝났네요~ bbb</p>
              <img src="/images/blog/post58/post58_23.png">
              <img src="/images/blog/post58/post58_24.png">
              <p>음식도 맛있었지만, 업무에 지쳤던 마음을 풀 수 있어서 더 좋은 시간이었어요~</p>
              <img src="/images/blog/post58/post58_25.png">
              <img src="/images/blog/post58/post58_26.png">
              <img src="/images/blog/post58/post58_27.png">
              <p>2일 차 아침이 밝았네요!</p>
              <p>어제는 저녁 늦게 와서 몰랐는데 숙소가 정말 예쁘더라고요~</p>
              <p>부지런하게 산책도 하고 족구를 하는 분들도 보이네요 bb</p>
              <img src="/images/blog/post58/post58_28.png">
              <p>숙소 체크아웃하기 전에 다 함께 기념사진~ 찰칵!</p>
              <img src="/images/blog/post58/post58_29.png">
              <img src="/images/blog/post58/post58_30.png">
              <img src="/images/blog/post58/post58_31.png">
              <p>2일 차에도 맛집 탐방은 놓칠 수 없죠!!</p>
              <p>이번에는 바로바로~ 용인시 처인구에 있는 차가네 손 두부!!</p>
              <p>담백한 두부가 정말 정말 맛있었다는…!</p>
              <img src="/images/blog/post58/post58_32.png">
              <img src="/images/blog/post58/post58_33.png">
              <img src="/images/blog/post58/post58_34.png">
              <p>아침을 든든하게 먹었으면 후식도 챙겨야죠~</p>
              <p>저희 개발그룹은 카페로 직진했습니다! ㅎㅎ</p>
              <p>용인시 처인구에 있는 카페다브라는 곳인데</p>
              <p>분위기도 평화롭고 마음이 편안해지는 곳이었어요~</p>
              <p>음료와 빵으로 당을 충전하고 이제는 휴식 타임~</p>
              <img src="/images/blog/post58/post58_35.png">
              <img src="/images/blog/post58/post58_36.png">
              <p>평소에 하지 못했던 광합성 몰아서 하기...!</p>
              <img src="/images/blog/post58/post58_37.png">
              <p>햇볕도 따스하고 바람도 솔솔 불어서 다들 행복한 단잠에 빠졌네요~ㅎㅎ</p>
              <img src="/images/blog/post58/post58_38.png">
              <p>카페에서 힐링 타임을 일정의 마지막으로 하고 서울로 향했습니다~</p>
              <p>이렇게 1박 2일의 개발그룹 워크숍이 무사히 마무리됐네요!</p>
              <p>정말 순식간에 시간이 지나가서 아쉬운 마음을 뒤로 하고 돌아왔는데요 ㅠㅠ</p>
              <p>블로그를 작성하면서 재밌는 순간들이 떠올라 저절로 미소 짓게 됐네요~</p>
              <p>그럼 다음에 더 즐거운 이벤트를 가지고 돌아올게요. 안녕~~</p>
            `,
    createDate: 'Posted on Apr 21, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.894Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '57',
    title: '아이지아이 코리아 창립기념 이벤트',
    content: `
              <img src="/images/blog/post57/post57_1.png">
              <p>안녕하세요~ 아이지아이 코리아입니다!!!</p>
              <p>이번 이야기는 아이지아이 코리아 창립기념일 맞이 이벤트에 대해 소개해드릴게요!</p><br>
              <p>이벤트를 기획하면서도 많은 일이 있었는데요!</p>
              <p>처음에는 작고 소소하게 찍기로 했던 단체 사진이 대표님의 강단으로!</p>
              <p>호리존을 빌려 멋진 사진을 찍게 되었습니다~~</p>
              <img src="/images/blog/post57/post57_2.png">
              <p>촬영 준비를 하며 모두 기다리고 있는데요!!</p>
              <p>정말 정신이 없네요... 하하하하하</p>
              <img src="/images/blog/post57/post57_3.png">
              <img src="/images/blog/post57/post57_4.png">
              <p>그리고 저희 37명이 전부 들어갈 스튜디오가 없어 홍대의 호리존을 빌렸는데요.</p>
              <p>그래서 촬영 준비부터 실제 촬영까지 전부 저희가 진행했답니다… 하하 ㅜㅜ</p>
              <p>(공간 섭외하기 너무 어려워요~~~)</p><br>
              <p>다행히 스튜디오 경력을 가지신 미 연구원님과 평소 사진에 대한 관심과 사랑이 깊은 재 연구원님의 도움으로 무사히 찍을 수 있었어요!</p>
              <p>(이렇게 아이지아이 코리아가 다재다능하답니다~~ *^_^*)</p>
              <img src="/images/blog/post57/post57_5.png">
              <img src="/images/blog/post57/post57_6.png">
              <p>특히 재 연구원님께서 엄지발가락에도 힘을 주시면서 촬영해 주시는 열정을 보여주셨는데요~!!</p>
              <p>고생해주신 미 연구원님과 재 연구원님께 감사드립니다~!! 짝짝짝~!!</p>
              <img src="/images/blog/post57/post57_7.png">
              <img src="/images/blog/post57/post57_8.png">
              <p>그리고 37명의 완벽한 순간을 촬영하기가 쉽지 않더라고요…</p>
              <p>그래도 여러 번의 시도로 단체 사진 촬영 완료!!!</p>
              <p>화이트 셔츠로 한층 더 화사하지 않나요???</p>
              <img src="/images/blog/post57/post57_9.png">
              <p>이어서 그룹별 사진 촬영이 시작되었습니다!!</p>
              <p>디자인그룹 사진에서는 14송이의 꽃이 피었더라고요~~</p>
              <img src="/images/blog/post57/post57_10.png">
              <p>다음으로 개발그룹 단체 사진 입니다!</p>
              <p>개발그룹에는 16명의 총잡이가 항시 대기 중입니다!!</p>
              <img src="/images/blog/post57/post57_11.png">
              <p>저희 회사의 능력자분들이 모인 연구소에서는 근엄과 진지함을 보여주셨습니다!!!</p><br>
              <p>이어서 파트별 촬영이 이어졌습니다!!</p>
              <p>어떤 컨셉이 있을지 벌써 기대가 됩니다~~~!!</p>
              <img src="/images/blog/post57/post57_12.png">
              <p>먼저 파트장을 끔찍하게 아끼는 메타버스 파트 사진이에요!</p>
              <p>파트장에 대한 파트원들의 애정과 존경이 느껴지지 않으시나요??</p>
              <p>해맑게 웃고 있는 파트장님도 만족해하시는 것 같아 다행이에요~!!</p>
              <img src="/images/blog/post57/post57_13.png">
              <p>디지털트윈 파트는 아이언맨 포즈로 토니 스타크에 대한 존경심과 개발자로서의 이상을 표현한 게 느껴지네요!!</p>
              <img src="/images/blog/post57/post57_14.png">
              <p>화목한 사랑이 넘치는 파트를 표현한 XR & 모바일 파트 단체 사진이에요~</p>
              <p>사이좋은 아이지아이 코리아를 너무 잘 표현해주셨어요!!</p>
              <img src="/images/blog/post57/post57_15.png">
              <p>저희 회사의 조물주 분들이신 3D 디자인 파트 단체 사진에서는</p>
              <p>사진을 촬영하는 중에도 입체적인 공간을 구성하려는 열정을 보여주셨어요!</p>
              <img src="/images/blog/post57/post57_16.png">
              <p>2D 디자인파트 단체 사진은 청일점이신 재 연구원님이 주인공이네요~!</p>
              <img src="/images/blog/post57/post57_17.png">
              <p>유지보수 파트원 분들은 뭐든 맡겨달라는 자신감을 보여주셨어요!</p>
              <img src="/images/blog/post57/post57_18.png">
              <p>문서를 담당해주시는 1D 파트 입니다!</p>
              <p>회계와 문서 등 어려운 작업을 도맡아 해주시는 에이스분들이에요!!</p>
              <img src="/images/blog/post57/post57_19.png">
              <p>마지막으로 저희에게 아이지아이 코리아라는 보금자리를 만들어주신</p>
              <p>경영진 분들입니다!!</p>
              <p>항상 직원을 생각해주시는 배려와 회사를 더 좋은 방향으로 이끌고자 고민하시는 대표님과 부사장님이십니다!!</p>
              <img src="/images/blog/post57/post57_20.png">
              <img src="/images/blog/post57/post57_21.png">
              <img src="/images/blog/post57/post57_22.png">
              <img src="/images/blog/post57/post57_23.png">
              <p>저희 상조회장님께서 이번 이벤트를 기획하면서 직원분들이 재미없어하면 어떻게 하나 하는 고민이 많으셨는데…</p>
              <p>다들 잘 즐겨 주셔서 너무 다행이에요!</p>
              <p>이제 사진도 다 찍었으니 회식하러 갑시다~~!!!</p>
              <img src="/images/blog/post57/post57_24.png">
              <p>회식을 하기 위해 합정동의 빕스로 이동했어요!</p>
              <p>단체 회식을 위하여 룸 공간을 빌렸습니다!</p>
              <img src="/images/blog/post57/post57_25.png">
              <img src="/images/blog/post57/post57_26.png">
              <p>모두 모여 스테이크와 와인을 주문하길 기다리는 직원분들입니다!</p>
              <p>다들 배가 고파서 그런지 표정이 좋지 않은데요…</p>
              <p>주문이 완료되어 바로 샐러드바로 달려 나갈 준비를 하고 있어요!</p>
              <img src="/images/blog/post57/post57_27.png">
              <img src="/images/blog/post57/post57_28.png">
              <img src="/images/blog/post57/post57_29.png">
              <img src="/images/blog/post57/post57_30.png">
              <p>식사가 시작되었습니다!</p>
              <p>바로~ 샐러드바로 달려 나갔는데요!</p>
              <p>폭립과 피자 등 다양한 음식이 저희를 기다리고 있었어요!</p>
              <p>그리고 마침 딸기 축제를 하고 있었는데요!!!!</p>
              <p>딱 기다려라! 밥 먹고 데리러 간다!!</p>
              <img src="/images/blog/post57/post57_31.png">
              <img src="/images/blog/post57/post57_32.png">
              <img src="/images/blog/post57/post57_33.png">
              <img src="/images/blog/post57/post57_34.png">
              <p>빕스에 처음 와보시는 분들도 있어 더 화기애애한 분위기의 회식이었는데요!</p>
              <p>대표님께서 스테이크까지 사주셔서 더욱 맛있고 화려한 회식이었답니다~~</p><br>
              <p>오랜만에 모두 모여 즐기다 보니 이벤트의 끝이 왔네요…</p>
              <p>다음에 더 재밌는 이야기로 돌아올게요!</p>
              <p>아. 디. 오. 스~!</p>
          
            `,
    createDate: 'Posted on Apr 5, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.762Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '56',
    title: '미국 메타버스 기획 출장 - (2)',
    content: `
              <img src="/images/blog/post56/post56_1.png">
              <p>2편에서는 펜실베이니아주의 필라델피아 방문기를 말씀드리려고 해요~</p>
              <p>필라델피아는 미국에서 굉장히 역사적인 도시예요!</p>
              <p>미 독립전쟁이 시작할 때 두 번의 대륙회의가 이곳 필라델피아에서 열렸고 헌법 회의가 열리고 합중국의 헌법이 만들어지게 된 곳입니다!</p>
              <p>미국 건국 역사에서 필라델피아는 중요한 도시이기에 미국 메타버스 기획할 때 학습할 요소가 많을 것으로 생각되어 방문했어요!</p>
              <img src="/images/blog/post56/post56_2.png">
              <img src="/images/blog/post56/post56_3.png">
              <p>1편에서 말씀드린 게티즈버그를 방문하고 필라델피아로 이동했어요~!</p>
              <p>필라델피아로 오니 또 느낌이 확 바뀌더라고요~</p>
              <p>차에서 필라델피아 풍경을 보며 왔는데 아기자기 예뻐서 눈이 행복했답니다~!</p>
              <img src="/images/blog/post56/post56_4.png">
              <p>필라델피아에서 처음으로 방문할 곳은 UPenn(펜실베이니아 대학교)입니다!</p>
              <p>펜실베이니아 대학은 아이비리그에 속하는 최상위 명문대학교예요!</p>
              <p>미국에서 건국의 아버지라 불리는 벤저민 프랭클린이 이 학교를 설립했고 최초 범용 전자 컴퓨터(애니악)이 1946년 펜실베이니아 대학교에서 개발되었다고 해요!</p>
              <p>그 외에도 많은 신기록을 보유한 학교예요.</p>
              <p>이런 대단한 업적과 역사를 지닌 학교에 방문한다는 것 자체로도 설레고 기대도 됐어요!~!</p>
              <img src="/images/blog/post56/post56_5.png">
              <p>입구에서 맵을 확인할 수 있었어요!</p>
              <p>맵으로만 봐도 학교가 굉장히 크죠?</p>
              <p>시간상 건물 전부를 구경할 수는 없었고 주요 건축물만 방문하기로 했어요.</p>
              <img src="/images/blog/post56/post56_6.png">
              <img src="/images/blog/post56/post56_7.png">
              <p>가는 길에 러브 동상 발견!!</p>
              <p>이런 조형물 놓칠 수 없죠~.</p>
              <p>러브 동상 앞에서 귀엽게 사진 찍고 다시 출발~!!</p>
              <img src="/images/blog/post56/post56_8.png">
              <img src="/images/blog/post56/post56_9.png">
              <p>처음 본 건물은 유펜의 본관이라고 할 수 있는 Collage Hall이에요~</p>
              <p>Collage Hall을 본 우리 직원분들은 다들 감탄하시더라고요.</p>
              <p>미국에서 사셨던 대표님, 미국에 살고 계신 우 전무님은 큰 감흥은 없어 보이셨지만 저희는 다 감탄했어요~ㅎㅎ</p>
              <p>녹색 고딕양식의 고풍스러운 건물이 너무 멋있었답니다!</p>
              <p>이런 곳에서 공부하면 얼마나 좋을까..</p>
              <p>캠퍼스에 대학생들이 돌아다니는데 멋있으면서 “나도 대학생 되고 싶다~” 생각하게 되더라고요.</p>
              <img src="/images/blog/post56/post56_10.png">
              <img src="/images/blog/post56/post56_11.png">
              <p>Collage Hall 앞에 벤저민 프랭클린 동상이 전시되어 있어요!</p>
              <p>Collage Hall 말고도 교정 곳곳에 벤저민 프랭클린 동상을 볼 수 있었어요~</p>
              <img src="/images/blog/post56/post56_12.png">
              <img src="/images/blog/post56/post56_13.png">
              <p>Collage Hall 반대편으로 가니 벤저민 프랭클린과 관련한 학교의 역사를 안내판으로 볼 수 있었으며 학교의 도면도 전시되어있었어요!</p>
              <p>학교 곳곳에 역사가 녹아 있어요~</p>
              <p>이 학교에 다니면 정말 자부심을 느끼고 다닐 만하다는 생각이 들었어요.</p>
              <img src="/images/blog/post56/post56_14.png">
              <img src="/images/blog/post56/post56_15.png">
              <p>이곳은 바로 와튼 스쿨인데요~</p>
              <p>조셉 와튼이 설립한 유펜의 와튼 스쿨은 미국에서 제일 오래된 상경대학이며 전 세계 최고 수준의 상경 계열 학부 과정과 경영전문대학원(MBA) 과정을 제공하고 있습니다.</p>
              <p>와튼 스쿨을 보니 저도 괜히 학구열이 샘솟는 기분이네요~</p>
              <p>일반인은 학교 내관으로 들어갈 수는 없었어요!</p>
              <p>학생분들이 카드를 찍고 들어가시더라고요~</p>
              <p>캠퍼스를 돌아다니면 유펜의 위상과 멋있는 기운이 뿜어져 나와 느끼는 게 많았던 것 같습니다!</p>
              <img src="/images/blog/post56/post56_16.png">
              <p>대학교 투어를 마치고 유펜 북스토어에 방문했어요~</p>
              <img src="/images/blog/post56/post56_17.png">
              <img src="/images/blog/post56/post56_18.png">
              <img src="/images/blog/post56/post56_19.png">
              <img src="/images/blog/post56/post56_20.png">
              <p>캠퍼스 투어를 마치고 북스토어를 들렀는데 북스토어는 정말 많은 굿즈들이 있었답니다!</p>
              <p>옷, 가방, 컵, 담요, 열쇠고리 등 정말 다양한 기념품들이 있었어요! </p>
              <p>유펜의 블루와 레드 색상의 조화로 디자인이 다 너무 예쁘더라고요..</p>
              <p>옷, 가방 사신 분들 계셨는데 나중에 저도 보니 나도 하나 살걸 그랬나 생각이 들더라고요..</p> 
              <p>여기서 겟하신 분들이 위너임다…!!</p>
              <img src="/images/blog/post56/post56_21.png">
              <p>이렇게 필라델피아에서 첫 번째 일정을 마치고 저희 직원분들한테는 영광스러운 시간이 기다리고 있었어요!</p>
              <p>바로 송 전무님을 뵙는 시간!!</p>
              <p>송 전무님은 미국에 살고 계셔 볼 수 있는 기회가 없었는데 이렇게 출장을 와서 기회가 생겨 뵐 수 있게 됐어요!</p>
              <p>미시간에서 필라델피아로 비행기 타고 오셔서 공항으로 모시러 갔어요~ 공항 가는 길 두근두근..</p>
              <img src="/images/blog/post56/post56_22.png">
              <p>뜨든..</p>
              <p>만났다..! 두 분..!</p>
              <p>아쉽게 공항에서 첫 만남을 갖는 영광스러운 사진은 남기지 못했네요…ㅠ</p>
              <p>VR 개발자로서 송 전무님과 함께 일하며 많이 배우고 성장하신 영모 파트장님!!</p>
              <p>항상 미팅으로만 뵙다가 드디어 만났습니다..!</p>
              <img src="/images/blog/post56/post56_23.png">
              <p>숙소에 와서 송 전무님의 세미나가 있었어요!</p>
              <p>송 전무님의 발표를 들으니 감히 제가 이런 말씀 드려도 되나 싶지만 너무 멋있으셨고 대단한 박사님 아래에서 함께 일하는 것 자체가 영광스럽더라고요..!</p>
              <img src="/images/blog/post56/post56_24.png">
              <img src="/images/blog/post56/post56_25.png">
              <p>그리고 선물 교환식이 이어졌어요!</p>
              <p>전무님께서 책과 쿠키를 주셨는데 좋은 선물 정말 감사드립니다ㅠㅠ</p>
              <p>이렇게 전무님을 뵙고 세미나도 듣고 선물 교환식도 하니 훨씬 가까워진 느낌이 들었어요!</p>
              <p>남은 필라델피아 일정은 쭉 송 전무님과 함께하기로 했어요~!</p>
              <img src="/images/blog/post56/post56_26.png">
              <p>필라델피아 2일 차의 해가 떴어요!</p>
              <p>숙소 옥상에서 본 풍경!!</p>
              <p>분명 한국에서 미국 날씨를 봤을 때는 계속 흐렸었는데 정말 거짓말 같이 계속 날씨가 좋았어요~!</p>
              <p>2일 차에는 필라델피아의 랜드마크인 필라델피아 시청, 미국의 역사적인 공간인 독립기념관을 방문합니다.</p>
              <img src="/images/blog/post56/post56_27.png">
              <img src="/images/blog/post56/post56_28.png">
              <p>아침 식사는 필라델피아에 오면 방문해야 한다는 리딩터미널 마켓에 방문했어요!</p>
              <p>최고의 역사를 자랑하는 도시 필라델피아답게 리딩 터미널 마켓은 미국에서 가장 오래된 공공 시장 중 하나라고 합니다.</p>
              <p>저희는 여기서 필라델피아의 명물 음식이라는 필리 치즈 스테이크를 먹었어요!</p>
              <img src="/images/blog/post56/post56_29.png">
              <img src="/images/blog/post56/post56_30.png">
              <p>이렇게 길게 나오는데 저희는 다 못 먹을 것 같아서 반반 나누어서 먹었답니다!</p>
              <p>진짜 너무 맛있었어요!</p>
              <p>고기양도 엄청 많고 안에 치즈와 함께 어우러져 환상의 조합…!!</p>
              <p>사진 보니 또 생각나네요ㅜ</p>
              <p>필라델피아에서 떠나는 마지막에 한 번 더 방문했다는..ㅎㅎ</p>
              <p>그 정도로 맛있었어요!!!</p>
              <p>맛있는 식사를 마치고 필라델피아 시청으로~~ 고고</p>
              <img src="/images/blog/post56/post56_31.png">
              <img src="/images/blog/post56/post56_32.png">
              <p>필라델피아 시청! 도착했어요!</p>
              <p>엄청 웅장하고 유럽에서 볼 법한 건축물이 있었어요~</p>
              <p>너무 멋있어서 감탄하면서 바라봤습니다.</p>
              <img src="/images/blog/post56/post56_33.png">
              <p>꼭대기에는 펜실베이니아 식민지를 창립한 윌리엄 펜 동상이 우뚝 서 있어요!</p>
              <p>너무 높이 있어 동상을 자세히 확인할 수는 없지만 굉장히 디테일하게 제작이 되었다고 해요.</p>
              <p>가까이 보면 어떨까 궁금하네요~</p>
              <p>그리고 과거에는 시내 중심부에 윌리엄 펜 동상보다 높은 건물을 건설하지 못하게 하는 규정이 있었다고 합니다!</p>
              <img src="/images/blog/post56/post56_34.png">
              <img src="/images/blog/post56/post56_35.png">
              <p>건물의 디테일이 정말 예술이더라고요..</p>
              <p>디자인적인 영감을 많이 얻을 수 있는 것 같아요! 감탄 난발… (글 작성자: 디자이너)  </p>
              <img src="/images/blog/post56/post56_36.png">
              <img src="/images/blog/post56/post56_37.png">
              <img src="/images/blog/post56/post56_38.png">
              <img src="/images/blog/post56/post56_39.png">
              <p>내부도 디테일하고 멋있었어요~</p>
              <p>채모 파트장님 거의 몸이 꺾이시며 열정적으로 사진 찍어주시는 모습입니다! </p>
              <p>높은 시계탑을 다 담기 위한 노력..</p>
              <img src="/images/blog/post56/post56_40.png">
              <p >그리고 시청 앞 Love Park에서 또 만난 러브 동상!</p>
              <p>로버트 인디애나의 작품이라고 하네요~</p>
              <p>이곳에서 단체 사진 안 찍고 갈 수 없죠~!!</p>
              <img src="/images/blog/post56/post56_41.png">
              <img src="/images/blog/post56/post56_42.png">
              <p >필라델피아 시청에서 약 15분 정도 걸어서 독립기념관(인디펜던스 홀)에 도착했어요! </p>
              <p>독립기념관은 1776년 7월 4일 제퍼슨이 독립선언서를 발표한 미국에서 역사적으로 굉장히 중요한 장소입니다.</p>
              <p>또한 제2차 대륙회의 집회 장소였습니다~</p>
              <p>저희는 투어를 듣기 위해 비지터센터에 방문해서 투어 시간을 확인했어요!</p>
              <p>투어는 무료이며 30분 간격으로 진행되었습니다.</p>
              <p>1월, 2월은 다른 예약 필요 없고 그냥 시간에 맞추어서 입장하면 됐어요! </p> 
              <p>다른 달은 예약이 필요하거나 늦게 가면 표가 없을 수 있어 미리 확인해 보는 게 좋을 것 같아요~!</p>
              <img src="/images/blog/post56/post56_43.png">
              <img src="/images/blog/post56/post56_44.png">
              <p>인디펜던스 홀 입구를 조금 헤맸는데요!</p>
              <p>비지터센터, 잔디밭 기준에서 왼쪽에 입구가 있어요~</p>
              <p>그리고 짐 검사를 하고 입장을 합니다!</p>
              <p>짐 검사를 하고 나오니까 아이지아이 코리아의 재롱둥이 준모 파트장님 재롱을 볼 수 있었어요!</p>
              <img src="/images/blog/post56/post56_46.png">
              <img src="/images/blog/post56/post56_47.png">
              <p>단아하면서 깔끔한 느낌을 풍기는 독립기념관입니다!</p>
              <p>붉은색 벽돌에 시계 쪽은 흰색으로 포인트를 줘서 굉장히 예쁜 것 같아요~</p>
              <p>정각에는 댕댕~~ 종이 울렸답니다!</p>
              <p>독립기념관 앞에는 조지 워싱턴 동상이 있었어요!</p>
              <p>미국 헌법 초안을 작성하기 위해 열린 필라델피아 헌법 제정 회의 때 워싱턴이 회의를 주재했다고 합니다.</p>
              <img src="/images/blog/post56/post56_48.png">
              <img src="/images/blog/post56/post56_49.png">
              <p>투어를 기다리면서 옆의 전시관에서 전시도 구경했어요~!</p>
              <p>사실 다 영어로 되어 있어서 읽기 어렵겠구나 싶었는데 한국어도 볼 수 있더라고요~!</p>
              <p>미국에서 한국어!! 반갑더라구요~</p>
              <img src="/images/blog/post56/post56_50.png">
              <img src="/images/blog/post56/post56_51.png">
              <p>투어 시간이 되어서 가이드분을 따라 출발~~</p>
              <img src="/images/blog/post56/post56_52.png">
              <img src="/images/blog/post56/post56_53.png">
              <p>홀 양쪽에 방이 하나씩 있었어요.</p>
              <p>첫 번째로 본 곳은 대법원 Supreme Court Room이에요!</p>
              <p>열정적인 가이드분의 설명을 듣고 다음 방으로 이동했어요~</p>
              <img src="/images/blog/post56/post56_54.png">
              <img src="/images/blog/post56/post56_55.png">
              <p>다음 방은 Assembly Room입니다!</p>
              <p>이곳이 13개 주의 대표가 모여 헌법을 만들고 미국의 독립을 선언한 곳입니다.</p>
              <p>이런 역사적인 장소에 와있다는 것 자체로도 의미 있었던 것 같아요~</p>
              <p>조지 워싱턴이 미국 독립선언서를 낭독했으며 2차 대륙회의 장소였다고 하네요.</p>
              <img src="/images/blog/post56/post56_56.png">
              <img src="/images/blog/post56/post56_57.png">
              <p>다음으로는 독립기념관에 있는 자유의 종을 보러 갔어요!</p>
              <p>독립선언문이 최종 승인되며 크게 울렸다는 전설이 있어요.</p>
              <p>하지만 실제로 종이 울리지 않았다고 하죠..</p>
              <p>재미있는 전설로만 이해해야겠네요!</p>
              <img src="/images/blog/post56/post56_58.png">
              <img src="/images/blog/post56/post56_59.png">
              <p>종에는 “땅 위의 모든 사람에게 자유를 공표하라”는 문자가 새겨있습니다.</p>
              <p>또한 첫 타종 이후로 금이 갔다고 해요.</p>
              <p>금이 생겨 종을 울리진 못하나 미국 독립 역사에서 중요한 상징이자 자유의 상징인 건 변함없는 사실이죠!</p>
              <p>미국 독립의 역사를 생생하게 느낄 수 있어서 기억에 남을 것 같네요~</p>
              <img src="/images/blog/post56/post56_60.png">
              <img src="/images/blog/post56/post56_61.png">
              <img src="/images/blog/post56/post56_62.png">
              <img src="/images/blog/post56/post56_63.png">
              <p>이외에도 필라델피아에서 미국의 역사적인 감옥, 역사박물관, 프랭클린 박물관, 필라델피아 미술관을 방문했습니다~!</p>
              <p>미국 메타버스 기획을 위해 방문한 미국에서 느끼는 감정이 많았던 것 같아요~</p>
              <p>현장 답사하니 인터넷으로 조사를 했던 것보다 배운 것도 많았고 영감을 얻어가는 것 같습니다!</p>
              <p>앞으로 기획만 잘하면 되겠군요~!</p>
              <p>미국에서 현장 답사한 것을 바탕으로 좋은 콘텐츠를 기획하여 성공적으로 프로젝트가 진행되길 기대해봅니다!</p>
        `,
    createDate: 'Posted on Feb 27, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.746Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '55',
    title: '미국 메타버스 기획 출장 - (1)',
    content: `
            <img src="/images/blog/post55/post55_1.png">
            <p>안녕하세요! 아이지아이 코리아입니다~</p>  
            <p>이번 블로그는 아이지아이 코리아가 글로벌 확장을 목표로 한 걸음씩 나아가고 있는 이야기를 담아보았습니다.</p>
            <p>저희가 자체적으로 진행하고 있는 프로젝트가 있는데요! <p>
            <p>교육 콘텐츠를 담은 미국 메타버스 프로젝트예요.</p>
            <p>해당 프로젝트를 기획하기 위해서는 미국의 역사를 알아볼 필요가 있었는데요.</p>
            <p>가상의 공간에 구현하고자 하는 현장을 직접 느끼기 위해서!!! 무려 미국으로 출장을 다녀왔습니다.</p>
            <p>총 7박 8일의 일정이었고, 첫날과 마지막날, 1일의 자유여행 총 3일을 빼고서 5일 동안 워크숍과 현장답사를 진행하였습니다!</p>
            <img src="/images/blog/post55/post55_2.png">
            <img src="/images/blog/post55/post55_3.png">
            <p>미국에서 처음 먹은 식사는 Dogfish예요!!</p>
            <p>이전 미국 출장팀이 다녀갔던 Dogfish!</p>
            <p>저희 블로그에도 소개가 되어 있답니다~</p>
            <p>※링크: <a href="https://bit.ly/3KzgJbd">link: https://bit.ly/3KzgJbd</a></p>
            <img src="/images/blog/post55/post55_4.png">
            <p>첫 번째 일정은 대표님과 우 박사님의 워크숍이었어요. </p>
            <p>오전 시간 오후 시간 나누어서 진행되었고 이번 강의를 통해서 경영진 분들이 미국에 어떻게 오게 되셨는지, 어떤 일을 겪었고 어떤 생각을 갖고 계시는지를 엿볼 수 있었어요.</p>
            <p>마치 TED 강의를 듣는 듯 도전정신도 생기고 시야도 넓어진 것 같았어요~</p>
            <p>개인적으로 기억에 남았던 건 대표님의 첫 미국 출장 경험 이야기였는데 그때 새로운 경험을 많이 하고 이런 세상도 있다는 걸 많이 생각하셨다고 합니다!</p>
            <p>그러면서 나도 어떻게 이 세계에 들어올 수 있을까 고민을 많이 하셨고 그 뒤로 정말 노력을 많이 하신 것 같았어요.</p>
            <p>같은 맥락으로 본인이 느꼈던 경험을 저희에게 전달하기 위해서 이번 미국 출장을 기획하셨다고 하시는데 들으면서 도전이 많이 되더라고요.</p>
            <p>저는 영어 공부에 대한 도전이 또 생겼거든요!!!</p>
            <p>막간 어필을 하자면, 저희 회사의 복지 중엔 영어 공부와 관련된 보너스도 있답니다~!</p>
            <img src="/images/blog/post55/post55_5.png">
            <img src="/images/blog/post55/post55_6.png">
            <p>이 날은 대표님의 이야기를 듣고, 우 박사님의 강의 시작 전에, 점심으로는 중식을 먹었어요!</p> 
            <p>여러분! 미국에서 먹는 중식은 또 다른 맛인 거 아시나요???</p>
            <p>마파두부에서 마라 맛이 느껴져요.</p>
            <p>마라가 호불호가 나뉘긴 하지만 저는 너무 맛있게 먹었습니다!</p>
            <img src="/images/blog/post55/post55_7.png">
            <img src="/images/blog/post55/post55_8.png">
            <p>이색적인 거리의 풍경들~</p>
            <p>마침 날씨도 화창했어요.</p>
            <p>중간에 스타벅스에 들려서 커피타임도 가졌어요!</p>
            <p>허세 샷 참을 수 없지!!!</p>
           <img src="/images/blog/post55/post55_9.png">
            <p>다음날, 미국 메릴랜드주 볼티모어에 있는 맥헨리 요새에 방문했어요.</p>
            <p>방문하기 전 이곳이 어떤 곳인지 우 박사님께서 간단하게 설명해주셨어요~!</p>
            <p>박정현이 부른 미국국가 영상을 틀어주셨는데요. </p>
            <p>미국 국가 가사에 “ ~널찍한 띠와 빛나는 별들이 새겨진 저 깃발이 치열한 전장 넘어 우리가 사수한 성벽 위에서 당당히 나부끼고 있는 것이 ~ “ 라는 가사가 나옵니다.</p>
            <p>이 가사는 프랜시스 스콧 키의 &lt;맥헨리 요새의 방어 &gt;라는 시에서 따온 가사예요.</p>
            <p>프랜시스 스콧 키는 영국 해군과 포로협상을 하러 바다로 나갔는데요.</p>
            <p>국 해군의 함대가 볼티모어 항구를 공격해 왔고, 무수히 쏟아지는 포화를 보고 조국이 공격당하는 걸 바라볼 수밖에 없었어요.</p>
            <p>그러나 다음날 전혀 손상되지 않고 굳건히 펄럭이는 깃발을 보고 영감을 받아 썼다고 합니다.</p>
           <img src="/images/blog/post55/post55_10.png">
            <p>방문자 센터에는 미니 박물관이 있었어요!</p>
            <p>날씨가 많이 흐렸는데, 오히려 흐린 날씨가 이곳에는 더 어울렸던 것 같아요!</p>
            <img src="/images/blog/post55/post55_11.png">
            <img src="/images/blog/post55/post55_12.png">
            <img src="/images/blog/post55/post55_13.png">
            <img src="/images/blog/post55/post55_14.png">
            <p>박물관 내부입니다!</p>
            <p>족맥헨리와 국가 탄생 역사를 아담한 공간에 잘 표현해놨어요~!</p>
            <p>공간은 여러 구역으로 나누어진 섹션과 가운데 영상 보는 홀이 있었어요.</p>
            <p>섹션마다 전쟁에 대한 설명과 및 전투기록물, 전쟁에서 쓰였던 군복과 무기 인물에 대한 설명 등이 있었어요.</p>
            <img src="/images/blog/post55/post55_15.png">
            <p>이 박물관에서는 꼭 영상을 보고 나와야 하는데요.</p>
            <p>빗발치는 포격 속에서 살아남아 성공적인 방어를 기념하며 쓴 프랜시스 스콧 키의 시가 노래의 가사가 되어 흘러나오는 것으로 영상이 끝납니다.</p>
            <p>영상이 끝나면서 스크린이 올라가며 보이는 창문 밖에는 어딘가 굳건한 요새와 미국 국기가 휘날리고 있어요.</p>
            <p>저도 모르게 왼쪽 가슴에 손을 얹을 뻔했습니다! 이곳은 미국인들에게 꽤 큰 의미가 있는 곳일 수밖에 없더라구요.</p>
            <img src="/images/blog/post55/post55_16.png">
            <img src="/images/blog/post55/post55_17.png">
            <p>강을 따라 이어진 요새 성곽이 5각형으로 둘려져 있습니다.</p>
            <p>우중충한 날씨가 전쟁의 쓸쓸했던 현장을 한 번 더 재연해준 느낌이었어요.</p>
            <p>사실 그냥 방문했으면 예쁜 공원이구나~ 했을 텐데, 영상을 보고 전쟁의 흐름을 보고 나니 당시 치열했을 전투가 씁쓸해지더라고요.</p>
            <p>그래도 기념으로 사진 촬영 한 컷 해 봅니다!</p>
            <img src="/images/blog/post55/post55_18.png">
            <img src="/images/blog/post55/post55_19.png">
            <p>요새 안으로 들어오면 미국 국기가 휘날리고 있고요, 건물들에 입장할 수 있어요.</p>
            <p>건물 안에서도 다양한 전시품들을 볼 수 있었어요.</p>
            <p>방공호도 직접 들어가볼 수 있었구요!</p>
            <img src="/images/blog/post55/post55_20.png">
            <img src="/images/blog/post55/post55_21.png">
            <p>대포가 정말 어마어마하게 컸습니다.</p>
            <p>저 포탄 크기 보이시나요…?</p>
            <p>맥헨리 요새의 하이라이트는 국립공원 요원들의 대포 재연 쇼라고 하는데, 저희는 아쉽게도 못 보고 왔어요.</p>
            <p>공원을 다 둘러보는 데 걸리는 소요 시간은 넉넉히 한 시간 반 정도 걸렸던 것 같아요.</p>
            <p>요새 관람을 마친 뒤에는 차로 10분 거리에 이너 하버가 있어요.</p>
            <p>저희도 이너 하버까지 본 후 하루를 마무리하였습니다!</p>
            <img src="/images/blog/post55/post55_22.png">
            <img src="/images/blog/post55/post55_23.png">
            <p>다음날은 펜실베이니아주의 게티즈버그로 이동하였습니다.</p>
            <p>원래 이곳은 일정 짤 때 못 갈 뻔했던 곳이었어요.</p>
            <p>그런데 메릴랜드에서 필라델피아로 이동하는 길에 있어서 다행히 방문할 수 있었답니다.</p>
            <p>결론적으로는 제일 좋았던 곳입니다!</p>
            <p>제가 안내를 맡았던 곳이기도 했구요.</p>
            <p>100을 알아야 10을 겨우 말할 수 있는 저는… 이곳을 소개하기 위해 얼마나 많은 역사 자료를 봤는지 몰라요.</p>
            <p>다행히 팀원분들이 재밌게 들어주셨어요~</p>
            <p>게티즈버그에 대한 소개는 박물관을 먼저 소개하고 자세히 말씀드릴게요!</p>
            <p>박물관은 입장료는 사진을 참고하시면 됩니다.</p>
            <p>저희는 필름, 사이클로라마 &amp; 박물관 입장 티켓(13.75$)을 샀어요.</p>
            <img src="/images/blog/post55/post55_24.png">
            <p>보면 버스 가이드 투어가 있는데, 이날 일정에서 저희에게 주어진 시간이 딱 2시간이었어요.</p>
            <p>그래서 팀을 나눠서 박물관팀 / 배틀필드 투어 팀 이렇게 관람하려고 하다가 그냥 다 같이 움직이자 라고 정해서 박물관 1시간 -&gt; 배틀필드 투어 30분 이렇게 진행하였어요.</p>
            <p>결론적으로는 꼭 박물관을 들렀다가 배틀필드로 나가는 게 좋을 것 같아요!</p>
            <p>배틀필드는 어디에 무슨 기념비가 있는 정도의 차이고 공간은 모두 비슷비슷하기 때문에, 로버트 리의 동상이 있는 버지니아 메모리얼 지역만 갔습니다.</p>
            <p>자주 언급되고 중요한 장소인 리틀 라운드 탑(Little Round Top)은 closed라고 안내가 되어있더라고요.</p>
            <img src="/images/blog/post55/post55_25.png">
            <p>약 20분 정도 길이의 영화가 진행됩니다!</p>
            <p>김 파트장님이 이 영화가 가장 기억에 남았다 그러길래 이유를 물으니 자신이 좋아하는 배우인 모건 프리먼(Morgan Freeman)이 내레이션을 한 것 같다고 하셨는데 맞았어요!</p>
            <p>영화는 역사 다큐멘터리처럼 여러 이미지, 기록물들을 보여주면서 모건 프리먼의 내레이션으로 진행되었어요.</p>
            <p>인상이 깊었던 건 수많은 전쟁 사상자의 사진을 볼 때였어요.</p>
            <p>수습되지 못하고 땅에 그대로 쌓여 있는 시신들 사진이 나오는데, 우리도 전쟁의 아픈 역사가 있다 보니 더 이입되었어요.</p>
            <p>영화를 보고 여운이 가시기 전에 사이클로라마 전시관으로 이동하게 되는데요~</p>
            <img src="/images/blog/post55/post55_26.png">
            <img src="/images/blog/post55/post55_27.png">
            <p>이곳은 파노라마 형식으로 전투가 그려져 있는 곳이었어요.</p>
            <p>조명과 사운드를 통해서 제가 전투의 현장에 직접 들어온 것 같은 연출을 했더라고요.</p>
            <p>그리고 소리로만 들으면 놓칠 수 있는데 중간중간에 모니터가 있어서 어떤 대사들이 나오는지 눈으로확인할 수 있게 되어있었어요!</p>
            <img src="/images/blog/post55/post55_28.png">
            <p>도대체 게티즈버그 전투가 뭔데 이렇게 중요하게 다루고 있는 건지 궁금하시죠!!!</p>
            <p>간단하게 설명해 드리자면 에이브러햄 링컨을 한번 짚고 넘어가야합니다.</p>
            <p>Government of the people, by the people, for the people, shall not perish from the earth.</p>
            <p>국민의, 국민에 의한, 국민을 위한 정부라는 연설이 바로, 이 전투에서 나오게 되었는데요.</p>
            <p>미국은 북-산업화 진행 / 남- 노예제도의 대규모 농장 자본주의로 경제사회가 나뉘게 됩니다.</p>
            <p>그러던 와중 노예해방에 대한 문제가 사회적인 문제로 떠오르면서 남과 북의 갈등이 고조되는데요.</p>
            <p>이는 민주당과 공화당의 정치적인 문제가 됩니다.</p>
            <p>남측은 노예라는 존재가 자신들 경제사회의 근원이기 때문에 쉽게 포기할 수 없었습니다.</p>
            <p>그러던 찰나에 노예해방의 찬성에 가까운 링컨이 대통령으로 당선되자, 남쪽에 주들이 연방 탈퇴 즉 독립을 선언합니다.</p>
            <p>그러면서 남북전쟁이 시작되게 되는데요.</p>
            <p>산업화가 진행되고 있어 기술적으로 우세했던 북군이 유리했던 이 전쟁에서, 무승부만 되어도 독립을 할 수 있기에 버티기만 하면 되는 입장이었던 남군이 생각보다 우세하게 됩니다.</p>
            <p>생각과는 다른 상황에 전쟁은 길어지게 되고 게티즈버그 전투에서 북군이 다시 승리의 깃발을 잡으며 수많은 사상자와 함께 해당 전투가 끝이 납니다.</p>
            <p>전쟁의 사상자를 기렸던 링컨의 연설이 지금까지도 최고의 연설로 손에 꼽히고 있는 거죠!</p>
            <img src="/images/blog/post55/post55_29.png">
            <img src="/images/blog/post55/post55_30.png">
            <p>링컨에 대한 여러 기념품과 동상들이 있었어요.</p>
            <p>박물관 앞에 있는 링컨 동상과 함께 사진도 남겨봅니다.</p>
            <img src="/images/blog/post55/post55_31.png">
            <p>인포메이션에서 종이 프린트와 링컨 타투 스티커 같은 걸 나눠주더라고요.</p>
            <p>저는 처음에 뭔지 몰랐는데, 어 링컨이네 ? 이러고 다른 분들이 알아보시더라고요…ㅋㅋㅋ</p>
            <p>이렇게 박물관에서 게티즈버그 전투에 대해서 한번 배우고 사이클로라마를 통해 간접경험을 한 뒤 배틀필드 오토투어를 돌아봅니다.</p>
            <p>오토투어는 본인의 자동차로 코스를 돌아보면 되는데요…</p>
            <p>표지판이 잘 되어있다고 하긴 하던데 저희는 좀 헷갈려서 그냥 내비게이션을 사용하며 다녔어요.</p>
            <img src="/images/blog/post55/post55_32.png">
            <img src="/images/blog/post55/post55_33.png">
            <p>로버트 리 동상 앞에서 사진 한번 찍어봅니다.</p>
            <p>길어져서 생략했지만 로버트 리도 아주 중요한 인물 중에 한 분이에요… 현재 논란도… 있어서 중요한 분이죠…</p>
            <img src="/images/blog/post55/post55_34.png">
            <p>현장을 돌아보며 느낀 건, 박물관에서 봤던 그림과 영상이 눈앞에 펼쳐져 있는 기분이었어요.</p>
            <p>꼭 박물관에 가서 현장에 대한 설명을 듣고 현장 투어 시작하는 걸 추천해 드려요.</p>
            <p>다가오는 느낌이 아주 다르더라고요.</p>
            <p>들판만 봤으면 느끼지 못했을 것 같아요.</p>
            <p>이런 것이 다 연출과 기획의 힘이겠죠..???</p>
            <p>한 수 배워갑니다.</p></br>
            <p>이상 아이지아이 코리아의 미국 메타버스 기획 현장 포스팅 1편이었습니다~!</p>
            <p>역사적인 내용이 많아서 좀 지루하셨을지도 모르겠네요..</p>
            <p>너무 감명받아버려서 그만….</p>
            <p>저희 프로젝트에서 해당 내용들을 어떻게 표현할지 기대가 되네요!</p>
            <p>이렇게 직접 보았으니 열심히 할 거예요!!</p>
        `,
    createDate: 'Posted on Feb 24, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.741Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '54',
    title: 'DMZ 사라진 마을 메타버스 인터뷰 촬영 [KBS 남북의 창, 아리랑TV]',
    content: `
              <img src="/images/blog/post54/post54_10.png">
              <p>안녕하세요! 저희 아이지아이 코리아에 큰 경사가 있었답니다!!</p>
              <p>바로 ‘KBS’, ‘아리랑TV’ 와 같은 대형 방송사에서 저희 사라진 마을 메타버스와 관련하여 인터뷰 요청이 왔답니다! 짝짝짝!!</p>
              <p>사라진 마을 메타버스가 뭐냐고요~?</p>
              <p>사라진 마을 메타버스 프로젝트는 통일부 주관하에 저희 아이지아이 코리아가 수주하여 작업한 프로젝트입니다!</p>
              <p>DMZ 내에, 이전에는 존재했지만 지금은 대부분 사라져버린 마을들을 메타버스로 구현한 프로젝트입니다.</p>
              <p>저희가 맡은 프로젝트가 TV에 나오다니!! 정말 뜻깊은 날이네요~!</p>
              <p>그럼, 지금부터 인터뷰 촬영 현장 소개해 드릴게요! 퐐로퐐로 미~</p>
              <img src="/images/blog/post54/post54_01.png">
              <p>인터뷰 촬영 현장을 설명 드리기에 앞서, 사라진마을 메타버스를 간단히 소개해 드리겠습니다!</p>
              <p>본 프로젝트는 지난 12월 28일 6.25전쟁 전까지 비무장지대에 존재했던 마을을 메타버스로 구현했습니다.</p>
              <img src="/images/blog/post54/post54_02.png">
              <img src="/images/blog/post54/post54_03.png">
              <img src="/images/blog/post54/post54_04.png">
              <img src="/images/blog/post54/post54_05.png">
              <p>해당 마을과 관련된 문헌과 고증 자료들을 분석하여 당시 마을의 주요 지물과 생활상을 확인하고, 당시 마을에 실제로 거주하셨던 어르신들의 구술자료를 바탕으로 미션과 공간 등을 제작하였습니다.</p>
              <p>또한 DMZ 실태조사와 같은 현지 조사를 통해 이를 확인하고 구현했답니다~</p>
              <p>비무장지대에는 400여 개의 마을이 존재한 것으로 파악되는데, 그 중 철원군 관전리, 철원군 사요리, 연천군 고랑포리, 양구군 문등리, 고성군 대강리까지 총 5가지 공간과 공간별 3가지 미션으로 구성되어 총 15개의 미션으로 구축된 메타버스를 확인할 수 있습니다!</p>
              <p>당시의 고증자료와 구술자료를 바탕으로 제작된 메타버스는 온 국민을 대상으로 제작되어 누구나 쉽게 웹으로 접속 가능합니다~</p>
              <p>아래 링크를 클릭하면 사라진 마을을 볼 수 있어요!!</p>
              <p>※사라진 마을 메타버스 링크: <a href="https://universe.go.kr/main">link: https://universe.go.kr/main</a></p>
              <img src="/images/blog/post54/post54_06.png">
              <p>다시 본론으로 와서 먼저 ‘KBS 남북의 창’ 인터뷰 촬영현장으로 가볼까요?</p>
              <p>방송 관련자 분들과 본 프로젝트의 주무관님이 저희 회사에 방문해 주셨습니다!</p>
              <img src="/images/blog/post54/post54_07.png">
              <p>(사진 촬영은 허가받고 진행하였습니다.)</p>
              <p>방송 관계자분, 통일부 주무관님, 본 프로젝트의 PM을 맡으신 윤파트장님께서 촬영 전 사전 회의하는 모습입니다. 다들 매우 진지한 모습이네요!😊</p>
              <p>배경으로 사라진마을 메타버스 영상도 틀고, 방송에 잘 나올 수 있도록 공간도 깔끔하게 정리해 두었답니다.</p>
              <img src="/images/blog/post54/post54_08.png"><img src="/images/blog/post54/post54_09.png">
              <p>먼저 주무관님부터 인터뷰를 진행하였습니다.</p>
              <p>사라진 마을 메타버스가 어떻게 시작되었고, 앞으로 어떤 모습으로 발전하게 될지 등 주무관님께서 차분히 답변해 주셨습니다!^^</p>
              <p>카메라 감독님도 풀샷, 타이트 샷 등의 다양한 앵글로 컷을 살리기 위해 애쓰셨습니다!!</p>
              <img src="/images/blog/post54/post54_10.png">
              <img src="/images/blog/post54/post54_11.png">
              <p>다음은 우리 윤파트장님께서 인터뷰해주셨습니다.</p>
              <p>이런 인터뷰 녹화가 처음이신 윤파트장님께서 긴장하셨는지 얼굴이 발그레 *^^* 해지셨지만 차분히 인터뷰 잘해주셨습니다!</p>
              <p>사라진 마을 메타버스가 어떻게 제작되었는지, 그 안에 미션들은 어떻게 만들어지게 되었는지 등 메타버스를 직접 시연하시면서 리포터분께 친절하게 설명해드리고 있네요~</p>
              <img src="/images/blog/post54/post54_12.png">
              <img src="/images/blog/post54/post54_13.png">
              <p>그리고 마지막으로 리포터분이 인터뷰 마무리해 주시면서 촬영이 끝났습니다~</p>
              <p>인터뷰 촬영은 총 1시간 정도 진행했고, 어떻게 방영이 될지 굉장히 궁금했어요! (기대기대..)</p>
              <img src="/images/blog/post54/post54_14.png">
              <img src="/images/blog/post54/post54_15.png">
              <img src="/images/blog/post54/post54_16.png">
              <img src="/images/blog/post54/post54_17.png">
              <p>※방송 링크: <a href="https://news.kbs.co.kr/news/view.do?ncd=7591925">link: https://news.kbs.co.kr/news/view.do?ncd=7591925</a></p>
              <p>KBS 남북의 창에서 1월 28일 드디어 방영되었습니다!!</p>
              <p>어르신분들 인터뷰와 저희 메타버스가 함께 방영되었답니다!</p>
              <p>어르신분들의 말씀을 방송을 통해 들을 수 있었는데 마음이 먹먹하더라고요...</p>
              <p>사라진 마을 메타버스를 통해 조금이나마 어르신분들에게 위로가 되고 기억을 되살릴 수 있는 기회가 될 수 있길 바랍니다!</p>
              <p>저희가 진행했던 프로젝트가 이렇게 방영되고 누군가에게는 위로와 회상의 의미로 남는다는 것이 굉장히 뜻깊었습니다.</p>
              <img src="/images/blog/post54/post54_18.png">
              <img src="/images/blog/post54/post54_19.png">
              <p>다음은 아리랑TV 촬영으로 가볼까요?</p>
              <p>아침 일찍 오전부터 인터뷰가 진행된 터라, 미리 전날 인터뷰를 위해 세팅해 두었답니다.</p>
              <p>오전에 촬영팀이 도착하자마자 바로 인터뷰에 들어갔습니다!</p>
              <img src="/images/blog/post54/post54_20.png">
              <p>먼저 메타버스 시연을 진행하면서 사라진 마을 메타버스가 어떤 미션들로 구성되어 있고, 어르신들의 구술자료에서는 어느 부분을 어떻게 반영했는지 등, 아리랑TV PD분들의 폭격 질문에도 차분하게 대답하시는 윤파트장님의 모습입니다. (크 윤파트장님 믓지다..)</p>
              <p>지난번 경험을 토대로 유연한 진행이 이루어졌답니다!</p>
              <p>확실히 두 번째 촬영이라 그런지 더 여유가 생기셨더라고요~~</p>
              <img src="/images/blog/post54/post54_21.png">
              <img src="/images/blog/post54/post54_22.png">
              <p>다음은 윤파트장님 자리에서 사라진 마을 메타버스의 초기 기획이 어떻게 작업 되었고, 어떤 과정을 거쳐 사라진 마을 메타버스가 제작되었는지, 또 어떤 작업을 하고 계시는지 등에 대해 인터뷰가 이루어졌습니다!</p>
              <p>모니터에 작업화면도 함께 확인하며 사라진마을 메타버스 구축과정에 열정적으로 설명해 주셨습니다~</p>
              <p>또 긴 촬영에도, 우리 아이지아이 코리아 직원분들이 인터뷰 촬영에 잘 협조해주셔서 일정대로 인터뷰를 진행할 수 있었네요..!</p>
              <img src="/images/blog/post54/post54_23.png">
              <img src="/images/blog/post54/post54_24.png">
              <p>아리랑TV 인터뷰 촬영은 총 1시간 반 정도 소요되었습니다.</p>
              <p>사라진 마을에 대한 간단한 질문들을 끝으로 촬영은 마무리되었습니다.</p>
              <p>아리랑TV PD분들도 인터뷰 진행이 잘 이루어질 수 있도록 편안한 분위기를 조성해 주셨답니다!</p>
              <p>아리랑 촬영팀과 아이지아이 코리아 직원분들 다들 고생 많으셨습니다~~</p>
              <img src="/images/blog/post54/post54_25.png">
              <img src="/images/blog/post54/post54_26.png">
              <img src="/images/blog/post54/post54_27.png">
              <img src="/images/blog/post54/post54_28.png">
              <p>※방송 링크: <a href="https://youtu.be/Rhg9EvuamJ4">link: https://youtu.be/Rhg9EvuamJ4</a></p>
              <p>아리랑 TV [Peace & Prosperity]는 2월 7일 방영 되었어요!</p>
              <p>아리랑 TV도 마찬가지로 저희 메타버스 이야기와 어르신 분들의 이야기가 함께 나오니 더욱 뭉클하고 사라진 마을의 의미가 잘 전달되는 것 같아요..</p>
              <p>저희 아이지아이 코리아가 맡은 사라진 마을 프로젝트가 이렇게 방송에 나온다는 게 신기할 따름이네요!!</p>
              <p>여러분도 사라진 마을 메타버스 안 곳곳을 돌아다니면서 1940년대 마을의 분위기도 느끼고, 당시 에피소드를 바탕으로 구성된 재밌는 미션도 체험해 보시는 건 어떨까요?</p>
              <p>이상 아이지아이 첫 인터뷰 촬영 현장 포스팅이었습니다~!</p>
            `,
    createDate: 'Posted on Feb 9, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.773Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '53',
    title: 'IGI Korea 소개',
    content: `
              <img src="/images/blog/post53/post53_1.png">
              <p>안녕하세요. ㈜아이지아이 코리아 대표 최상수입니다.</p>
              <p>저희 회사에 대한 정보가 많지 않아서 제가 면접 때마다 면접자들에게 저희 회사에 대해 자세히 소개하는 내용들이 있는데,</p>
              <p>저희 회사 지원자들에게 미리 그 내용들을 공유하면 좋을 것 같아서 이렇게 글을 남깁니다.</p>
              </br>
              <b>매출</b>
              <p>저희는 2019년에 OnTwins 디지털 트윈 솔루션을, 2021년에 OnTwins 메타버스 솔루션을, 2022년에 OnTwins 가상 전시 솔루션을 상용화했습니다.</p>
              <img src="/images/blog/post53/post53_2.png">
              <p>매출은 2019년 7,100만원, 2020년 3억 9,300만원, 2021년 24억 400만원, 2022년 32억 7000만원이 발생했습니다.</p>
              <p>2023년 매출 목표는 50억입니다. 영업이익은 2021년 25%, 2022년 20% 정도 됩니다.</p>
              <p>저희는 OnTwins라는 솔루션 판매만으로 회사를 안정적으로 운영할 수 있는 상태이고, 2021년에 투자 받은 20억을 거의 사용하지 않고 있는 상황입니다.</p>
              <p>2023년에는 미국 법인 설립을 시작으로 본격적으로 글로벌 진출을 앞두고 있고, 2025년 IPO를 목표로 하고 있습니다.</p>
              </br>
              <b>구성원</b>
              <p>저희는 수시로 여러 직군에서 채용을 진행하고 있습니다.</p>
              <p>현재 전체 인원은 37명이며, 대표이사를 포함하여 초기 창립 멤버인 C레벨 3명은 모두 관련 분야 박사학위를 가지고 있으며, 국내∙외 대기업, 유수 연구소, 대학 등에서 평균 20년 정도 경력을 갖고 있습니다. 우리는 2018년 2명, 2019년 3명, 2021년 12명, 2022년 12명, 2023년 현재 5명을 신규 채용했습니다.</p>
              <img src="/images/blog/post53/post53_3.png">
              <p>C레벨 3명을 제외한 34명 중 개발자는 19명이고, 디자이너는 3D 7명, 2D 5명입니다.</p>
              <p>그리고 프로젝트 관리 2명, 총무회계 1명으로 구성되어 있습니다.</p>
              <p>C레벨 3명도 소프트웨어 개발자 출신입니다. 현재까지 퇴사율은 20% 정도 되고, 이 중 많은 분들은 수습 기간 초반에 적응하지 못하고 퇴사하셨습니다. 협업에 어려움을 느끼는 분들은 수습 통과가 어려운 경향이 있습니다.</p>
              <p>수습을 통과하시더라도 혼자 일하는 것에 익숙하신 분들은 회사 생활에 어려움을 느끼는 것 같습니다.</p>
              </br>
              <p><b>채용</b>
              <p>저희는 경력보다는 신입을 우대합니다.</p>
              <p>신입은 주로 가능성에 중점을 두고 채용하고, 경력자의 경우 명확한 실력을 요구합니다.</p>
              <p>또한, 1년 미만의 경력이 2회 이상 있는 경우 서류를 통과하기는 어렵습니다. 1년 미만의 경력이 1회 있는 분들 또한 면접 시 퇴사의 타당한 명분을 항상 확인합니다.</p>
              <p>면접은 100% 온라인으로 진행되고, 업무별로 테스트를 진행하기 때문에 면접자에게는 부담이 조금 있을 수 있습니다.</p>
              </br>
              <b>연봉</b>
              <p>저희는 기본연봉, 성장지원금, PS로 구분해서 급여를 지급합니다.</p>
              <p>기본연봉은 1/12로 매월 받습니다. 성장지원금은 본인 성장을 위해 쓸 수 있도록 100만원 복지 카드를 드리고, 업무와 어학에 미션을 달성하면 각각 100만원의 보너스를 드리는 300만원 패키지입니다.</p>
              <p>PS는 회사의 실적과 본인의 기여에 따라 기본연봉의 50%까지 책정됩니다.</p>
              <p>저희 회사 대졸 신입 연봉을 2021년, 2022년 기준으로 살펴보면 중견기업 대졸 초봉 3424만원(<a href="https://www.edaily.co.kr/news/read?newsId=02302566629278192&amp;mediaCodeNo=257">https://www.edaily.co.kr/news/read?newsId=02302566629278192&amp;mediaCodeNo=257</a>)보다는 많습니다.</p>
              <p>저희는 IPO 전 100대 기업 평균 5,356만원(<a href="https://www.etoday.co.kr/news/view/2094977">https://www.etoday.co.kr/news/view/2094977</a>) 수준으로 연봉을 인상하는 것을 목표로 하고 있습니다.</p>
              <p>마지막으로 저희 회사는 3개월 수습 기간이 있으며 이 기간 동안에도 100%의 급여가 지급됩니다.</p>
              </br>
              <p><b>복지</b>
              <p>저희 회사의 복지에 대해 말씀드리면 기본적인 4대 보험과 퇴직연금을 지원하고 있습니다.</p>
              <p>2023년부터 전체 직원 종합건강검진 또한 지원합니다.</p>
              <p>점심 식사를 기본적으로 제공하며, 야근 시 저녁 식사도 제공하나 야근을 지양하고 있습니다.</p>
              <p>인재를 추천할 시 100만원의 인센티브를 드리며, 연구개발을 매우 중요하게 생각하고 있기 때문에 논문, 특허에 대한 별도 인센티브가 있습니다.</p>
              <p>만 5년을 근속하시면 1개월 유급휴가를 제공하는 안식월 제도가 있고, 위에서 설명한 대로 최대 연봉의 50%까지 제공하는 PS 제도가 있습니다.</p>
              <p>2021년, 2022년 일정 비율의 PS가 지급되었습니다. 1년 근속 시 방송통신대 학비를 지원하고 있으며, 2년 이상 근속자 중 평가에 의해 대학원 학비를 지원합니다.</p>
              <p>1년 이상 근속한 직원에 한해 직전년도 평가를 기준으로 최대 2,000만원의 주택자금대출제도를 시행하고 있으며, 생일은 휴가이고, 선물로 현금 10만원을 드립니다.</p><br>
              <p>임직원에게 일정 비율 스톡옵션을 기여에 따라 제공할 예정입니다.</p>
              <p>마지막으로 글로벌 진출을 위해 매년 리더급 직원들의 미국 출장을 적극 지원하고 있습니다.</p>
              <p>작년 4명의 리더가 미국 메릴랜드에 다녀왔으며, 올해는 설연휴가 끝나는 1월말 경영진과 10명의 선별된 직원들이 미국 B2C 서비스 준비를 위해 미국에서 워크샵을 진행할 예정입니다.</p>
              </br>
              <p>앞으로도 ㈜아이지아이 코리아에 많은 관심 부탁드리고, 저희가 세계 최고의 회사로 성장해 나가는 과정을 지지해 주시고, 응원해주시길 부탁드리겠습니다.</p>
              <p>감사합니다.</p>

          `,
    createDate: 'Posted on Jan 18, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.805Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '52',
    title: '2023 신년 인사',
    content: `
              <img src="/images/blog/post52/post52_2.png">
              <p >(주)아이지아이 코리아와 관계된 고객님들, 주주님들, 그리고 임직원 여러분 모두 2022년 한 해 정말 감사했습니다.</p>
              <p>2023 계묘년 새해 복 많이 받으시고, 건강하시고, 계획하시는 모든 일을 이루시길 기원합니다.</p>
              </br></br>
              <p>저희 회사는 2021년부터 매해 10명 이상 직원들을 채용하고 있으며 매출과 영업이익도 급성장하고 있습니다.</p>
              <p>여러분들의 성원에 힘입어 2023년 디지털트윈, 메타버스, 가상전시 분야에서 글로벌 B2C기업으로 발돋움할 계획입니다.</p>
              </br></br>
              <p>앞으로도 많은 관심과 응원 부탁드립니다.</p>
              <p>감사합니다.</p>
              </br></br>
              <p >대표이사 최상수</p>
          `,
    createDate: 'Posted on Jan 11, 2023',
    lastModifiedDate: '2024-07-04T06:19:39.701Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '51',
    title: "통일부, 비무장지대 '사라진마을' 메타버스로 재현",
    content: `
            <img src="/images/blog/post50/post50_2.png">
            <p ><strong>[연합뉴스]</strong></p></br>
            <p >'통일부는 6·25전쟁 이전 존재했으나 현재는 사라진 비무장지대 마을을 조사해 일부를 메타버스로 재현했다고 28일 밝혔다. 
            해당지역에 관한 문헌 분석으로 마을 위치와 특성을 확인하고, 생존 주민과의 면담, 현지 방문을 통해 마을의 존재를 구체적으로 확인했다.
             조사 결과는 '디엠지(DMZ) 사라진마을 이야기' 책자 및 영상, 'DMZ 사라진마을 메타버스', DMZ 메타버스(universe.go.kr)에서 확인할 수 있다.'</p></br> 
            <p >자세한 내용은 아래 링크를 통해 확인해보세요.</p>
            <a href="https://bit.ly/3WU3NzX" target="_blank">link: https://bit.ly/3WU3NzX</a>
            `,
    createDate: 'Posted on Dec 29, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.856Z',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '50',
    title: "행안부, '오픈 소스' 개발 기반 표준프레임워크 최신판 공개",
    content: `
              <img src="/images/blog/post50/post50_1.png">
              <p ><strong>[연합뉴스]</strong></p> </br>
              <p >'행정안전부는 7일 '2022 표준프레임워크 신규버전 발표회'를 열고 공공정보화사업 개발 기반인 표준프레임워크 최신판을 공개한다고 6일 밝혔다. 이날 발표회에서는 행정·공공기관의 전자정부 표준프레임워크 우수 활용사례 시상식도 열린다. '건강보험심사평가원(HIRA) 디지털 플랫폼'이 대상을, '통일부 비무장지대(DMZ) 통합시스템 가상세계(메타버스) 플랫폼'은 최우수상을 받는다.'</p></br>
              <p >자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3FYaE4p" target="_blank">link: https://bit.ly/3FYaE4p</a>
            `,
    createDate: 'Posted on Dec 29, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.848Z',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '49',
    title: '2022년 표준프레임워크 활용 구축 최우수상 수상(DMZ Universe)',
    content: `<img src="/images/blog/post49/post49_5.png">
              <p>안녕하세요!</p>
              <p>날씨가 조금은 풀린 2022년 12월 7일 한국지능정보사회진흥원 서울사무소에서 전자정부 표준프레임워크 발표회가 있었습니다.</p>
              <img src="/images/blog/post49/post49_1.png"><img src="/images/blog/post49/post49_2.png">
              <p>전자정부 표준프레임워크가 뭘까? 라고 생각하시는 분들도 계시겠죠?</p>
              <p>개발자가 아니라면 단어가 조금은 생소하게 느껴지실 것 같은데요~</p>
              <p>전자정부 표준프레임워크란 공공부문 정보화 사업 시 플랫폼별 표준화된 개발 프레임워크를 이야기해요!</p>
              <p>표준프레임워크가 생기기 전 과거에는 ‘JAVA’ 기반의 정보화 사업 구축 시 수행 업체에 따라 유지보수 등 여러 가지 문제가 발생하기도 하였답니다.</p>
              <p>전자정부 표준프레임워크가 출시된 이후 공공사업에 적용되는 개발프레임워크의 표준 정립으로 응용 SW 표준화, 품질 및 재사용성이 향상되었었을 뿐만 아니라 이로 인해 대기업이나 중소기업 모두가 동일한 개발 기반 위에서 공정하게 경쟁할 수 있게 되었어요!</p>
              <img src="/images/blog/post49/post49_3.png">
              <p>전자정부 표준프레임워크는 매년 신규 버전을 발표하는데요</p>
              <p>올해는 표준프레임워크 4.1신규버전 발표회를 진행했어요!</p>
              <p>새로운 4.1 신규버전을 간단하게 소개해 드리면 공통컴포넌트가 253종으로 늘어나서 구현도구, 실행환경등이 개선되었어요!</p>
              <p>특히 모바일 신분증 연계가 추가 되고, Spring tool 4.15.1을 지원하게 된 것이 가장 크답니다~!</p>
              <p>또한 GitHub 컨트리뷰션을 통하여 개선사항을 반영하기도 했답니다!</p>
              <p>표준프레임워크는 벌써 10년이 훌쩍 넘었는데요~</p>
              <p>이번 신규버전 발표회에는 표준프레임워크로 개발된 모든 사이트 중에서 우수한 사례를 뽑아 시상을 진행하고, 컨트리뷰션에 참가한 분들중 가장 기여도가 큰 분들에게도 시상을 진행했어요.</p>
              <img src="/images/blog/post49/post49_4.png"><img src="/images/blog/post49/post49_5.png">
              <p>DMZ Universe는 올해 2022년 표준프레임워크 활용 사례에서 무려 최우수상을 수상하게 되었답니다!!!</p>
              <img src="/images/blog/post49/post49_6.png"><img src="/images/blog/post49/post49_7.png">
              <p>DMZ Universe 는 표준프레임워크 사용하여 개발된 사이트 중에서도 메타버스를 주제로하는 독보적인 사이트에요!</p>
              <p>국내 최초로 공공기관에서 3D를 이용하여 표준프레임워크로 개발되었기 때문이랍니다.</p>
              <p>표준프레임워크를 적용하였기 때문에 엑티브엑스나 그 어떤 설치 프로그램 없이 이용이 가능하다는 장점이 있어요~</p>
              <p>표준프레임워크 소개 이후에 우수사례 발표순서가 되자 저희 과제를 담당하신 주무관님께서 멋지게 발표를 진행해 주셨어요~!!</p>
              <p>발표전에 갑자기 프로젝터가 안되는 작은 사고가 있었는데도 긴장하시지 않고 정말 프로페셔널하게 발표를 진행해 주셔서 감동이었어요.</p>
              <img src="/images/blog/post49/post49_8.png"><img src="/images/blog/post49/post49_9.png">
              <p>짧은 영상을 통해 올 한해 DMZ Universe에서 진행했던 행사와 더불어 내년도 진행할 행사 그리고 계획까지 발표를 해주셨어요.</p>
              <p>마지막으로 발표가 끝나고 최우수상 시상패를 받고 한컷!!</p>
              <p>우리 2023년 DMZ Universe PM님이신 안 파트장님도 사진을 한장 찍었어요.</p>
              <img src="/images/blog/post49/post49_10.png"><img src="/images/blog/post49/post49_11.png">
              <p>작년 그리고 올해 개발하느라 정말 고생했는데 이렇게 상까지 받게 되니 그간 고생했던 것들이 보상받는 것 같아서 뿌듯했답니다.</p>
              <p>국내 최초 공공서비스 메타버스인 DMZ Universe 앞으로 더 많은 분이 찾아 주셨으면 하는 소망을 담아 시상식 후기 마치칠게요.</p>
            `,
    createDate: 'Posted on Dec 29, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.764Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '48',
    title: 'PTV days(Technology & Vision Day) 행사 - 2일차',
    content: `
              <img src="/images/blog/post48/post48_1.png">
              <p>PTV days 행사 두 번째 날입니다!</p>
              <p>첫 번째 날은 직원분들 각자 한 해 동안의 프로젝트를 발표하는 시간이었다면 두 번째 날은 초청 인사분들의 기술 강연, 2023년 아이지아이 코리아의 비전을 듣는 시간입니다!</p>
              <p>초청 인사분은 총 다섯 분을 모셨습니다. 전문가 초청 인사분들의 기술 강연을 들을 수 있다니.. 굉장히 기대됐어요!</p>
              <img src="/images/blog/post48/post48_2.png">
              <img src="/images/blog/post48/post48_3.png">
              <p>귀한 손님분들을 모시니 오시기 전에 미리 자리 세팅을 해두어야겠죠~~?</p>
              <p>초청 인사분들은 등받이가 있는 편한 의자로 준비해두고 기념품으로 드릴 텀블러와 에코백도 의자 위에 가지런히 올려두었어요!</p>
              <p>강의 듣다가 당 땡길 것을 대비해서 다과도 책상에서 바로 주워 먹을 수 있게 해놨답니다^^</p>
              <img src="/images/blog/post48/post48_4.png">
              <img src="/images/blog/post48/post48_5.png">
              <p>행사 리플렛은 입구에 있어서 바로 들고 들어갈 수 있어요!</p>
              <p>리플렛 안에는 행사 일정이 적혀있답니다~!</p>
              <p>9시가 되고 바로 첫 번째 초청 인사분의 강연이 시작되었습니다!!</p>
              <p>담소를 나누던 우리 직원분들 모두 담소를 멈추고 집중 모드로 돌입!</p>
              <img src="/images/blog/post48/post48_6.png">
              <p>첫 강연은!!! ‘제조 디지털 트윈 모델 개발 사례’입니다.</p>
              <p>디지털 트윈에 대한 연구를 자세히 들을 수 있었는데요~</p>
              <p>중소기업의 설비를 대상으로 AAS 디지털 트윈 표현 모델을 어떻게 설계하는지, AAS 모델을 어떻게 생성하고 사용하는지 심도 있는 연구를 들을 수 있었답니다!</p>
              <img src="/images/blog/post48/post48_7.png">
              <p>두 번째 강연은 아이지아이 코리아의 파트너 회사인 ‘이삭엔지니어링’의 이사님께서 발표해 주셨습니다.</p>
              <p>‘이삭엔지니어링’은 산업별 프로세스 노하우와 엔지니어링 역량을 바탕으로 사업을 구축하고 확장하고 있었습니다!</p>
              <p>이 기회를 통해 우리 파트너 회사에서는 무슨 일을 하고 어떻게 사업을 구축하는지 들을 수 있는 좋은 기회였어요!</p>
              <img src="/images/blog/post48/post48_8.png">
              <p>30분 정도 휴식 시간을 가진 후 세 번째 강연이 시작되었습니다!</p>
              <p>세 번째 강연은 ‘Exploring the Knowledge Structure of Digital Twin & Metaverse’ 입니다.</p><p>디지털 트윈과 메타버스의 지식 구조 탐색을 통해 이 두 개념의 관계를 연구하셨습니다.</p>
              <p>디지털 트윈과 메타버스, 서로 비슷하면서도 다른 개념이죠~? 이 두 개념의 지식구조상의 관계를 들을 수 있는 흥미로운 강연이었어요!</p>
              <img src="/images/blog/post48/post48_9.png">
              <p>네 번째 강연은 ‘Digital Twin in Hospital Comprehensive nursing care service ward’입니다.</p>
              <p>병원 관제 현황을 조사하여 보호자 없는 병동 이슈를 파악하고 디지털 트윈 접근 방안을 연구하셨습니다.</p><p>병원 시설에 디지털 트윈 환경을 구축한다는 주제가 참신하고 흥미로웠어요~</p>
              <p>강연을 들으며 실제로 병원 전체 시설에 트윈이 도입된다면 정말 편리하겠다는 생각이 들었어요!</p>
              <img src="/images/blog/post48/post48_10.png">
              <p>마지막으로 'Management Consulting 101'입니다!</p>
              <p>디지털 트윈 시장을 분석하고 다양한 기업의 전략 조사하여 강연해 주셨습니다.</p>
              <p>내용을 말씀드릴 수는 없으나 어디에서도 들을 수 없는 좋은 강연이었습니다~!</p>
              <img src="/images/blog/post48/post48_11.png">
              <p>눈에 불을 켜고 강연을 듣고 있는 우리 직원분들..!!!</p>
              <p>정말 유익하고 흥미로운 강연에 모두 몰입해서 강연을 들었습니다!!</p>
              <p>바쁘실 텐데 시간 내어 좋은 강연해주신 초청 인사 분들께 진심으로 감사드립니다~~</p>
              <img src="/images/blog/post48/post48_12.png">
              <img src="/images/blog/post48/post48_13.png">
              <p>12시 초청 인사분들의 강연이 끝나고 점심 먹고 티타임을 가졌어요~</p>
              <p>잠깐이지만 모여서 이야기하고 따뜻한 차 마시니 나른하고 힐링 되었어요~!</p>
              <img src="/images/blog/post48/post48_14.png">
              <p>그리고.. 지치신 분들은 이렇게 뻗으셨답니다..ㅎㅎ</p>
              <p>직원 분들 바로 옆에서 수다 떨어도 절대 안 깨시더라고요..</p>
              <img src="/images/blog/post48/post48_15.png">
              <img src="/images/blog/post48/post48_16.png">
              <p>그리고 대표님과 전무님께서 2023년의 아이지아이 코리아 비전과 조직도 개편을 말씀해 주실 차례입니다!</p>
              <p>대표님의 발표는 지난 2022년을 되돌아보고 23년의 새로운 목표와 비전, 그리고 바라는 점이 준비되어있네요!</p>
              <p>앞으로 저희가 나아가야 할 방향을 본다는 생각에 기대도 되고 떨리는 마음이었습니다..!</p>
              <img src="/images/blog/post48/post48_17.png">
              <p>저희 회사는 지속해서 성장하고 있습니다!</p>
              <p>직원분들 수가 증가하는 것만 봐도 얼마나 회사가 성장하고 있는지 직감할 수 있죠~</p>
              <p>2023년에는 강력한 솔루션과 컨텐츠 기반의 글로벌 B2C 기업으로 성장을 목표로 하고 있다고 합니다!</p>
              <img src="/images/blog/post48/post48_18.png">
              <p>2023년의 조직도 개편된 부분도 확인하였습니다.</p>
              <p>이전보다 훨씬 조직이 세분화된 것을 볼 수 있었습니다!</p>
              <p>회사의 큰 비전과 목표를 설명 들으니 앞으로 더 열심히 해야겠다는 생각이 드네요~!</p>
              <img src="/images/blog/post48/post48_19.png">
              <p>다음은 전무님께서 직원분들에게 전하고 싶은 메시지를 발표해주셨습니다!</p>
              <img src="/images/blog/post48/post48_20.png">
              <img src="/images/blog/post48/post48_21.png">
              <p>전무님께서 우리의 현재 업무 방식과 앞으로의 업무 방향성을 말씀해주셨습니다!</p>
              <p>딱딱할 수 있는 이야기를 그림과 함께 비유적으로 풀어서 설명해 주셔서 몰입되었던 것 같네요~</p>
              <p>또한 각종 규정과 직급에 대한 요구사항을 자세히 설명해주셨어요!</p>
              <p>전무님의 말씀에서 공감이 되고 와 닿는 부분도 있었고 어떤 자세로 일해야 하는지 생각하게 된 것 같습니다!</p>
              <img src="/images/blog/post48/post48_22.png">
              <img src="/images/blog/post48/post48_23.png">
              <p>대표님과 전무님의 회사 비전과 조직도 개편에 대한 발표를 마치고 마지막 차례만 남았는데요..!</p>
              <p>그것은 바로!! 올해의 직원 수상입니다!!!</p>
              <p>올해의 직원은 올 한해 가장 훌륭했던 직원 한 분으로 상금 70만원, 휴가 1일이 주어집니다!! 올해의 직원은 저희 직원분들 무기명 투표로 선정됩니다.</p>
              <p>과연 누가 되었을지 궁금하고 기대되는데요~!</p>
              <p>두구두구..</p>
              <img src="/images/blog/post48/post48_24.png">
              <img src="/images/blog/post48/post48_25.png">
              <p>바로~~ 김00 연구원님이 되셨습니다!!</p>
              <p>김00연구원님은 올해 고생하시기도 했고 항상 열정을 다하시며 일하십니다!ㅠㅠ</p>
              <p>또한 직원분들도 굉장히 잘 챙겨주시고요!</p>
              <p>연구원님이 아쉽게도 2일차 행사에는 참석하지 못하셔서 직접 단상 위에 서지 못하셨습니다..</p>
              <p>그래서 1일차 발표에서 ‘김00 연구원님 사랑합니다’를 외친 정00님이 대신 수상하셨습니다!</p>
              <p>마지막 수상까지 하고 이렇게 행사가 마무리되었습니다!</p>
              <p>행사.. 이렇게 끝내고 다들 해산하기 너무 아쉽잖아요? 행사의 진짜 마무리인 회식하러 갑니다~~!</p>
              <img src="/images/blog/post48/post48_26.png">
              <p>회식은 흑돼지전문 흑돈가 삼성직영점에서 했습니다!</p>
              <p>다들 고기 먹을 생각에 기쁘고 설레는 마음으로 갔답니다~!</p>
              <img src="/images/blog/post48/post48_28.png">
              <img src="/images/blog/post48/post48_29.png">
              <p>숯불 등장!!!!!</p>
              <p>숯불에 고기 최고 아닌가요오?</p>
              <p>멜젓도 숯불에 데우고 있어요~ 고기야 빨리 익어라..!!!</p>
              <p>역시 고기가 실망 시킬 일이 없죠ㅠㅠ 먹자마자 크... 말안해도 아시죠..?</p>
              <p>한 입 먹자마자 안 드신 직원 분들께 빨리 드셔보시라고 너무 맛있다고 제촉했답니다!ㅎㅎ</p>
              <img src="/images/blog/post48/post48_30.png">
              <p>냉면, 계란찜, 육회도 뚝딱 했는데 먹느라 바빠서 사진을 못찍었네요..ㅠ</p>
              <p>술 드시고 싶으신 분들은 맥주도 한잔씩하며 즐거운 연말 회식 분위기를 즐겼답니다 ^^//</p>
              <p>(술 강요는 절.대. 없습니다!!)</p>
              <p>행사가 끝나고 회식까지 이렇게 마무리되었습니다~</p>
              <img src="/images/blog/post48/post48_31.png">
              <p>PTVdays를 마치며..</p>
              <p>행사를 통해 1년 동안 내가 했던 일들 그리고 앞으로의 방향과 비전에 대한 이야기를 나누었어요~</p>
              <p>나의 이야기를 들려주고 다른 분들의 이야기도 들을 수 있는 기회가 있다는 것 자체가 의미가 있는 것 같아요!</p>
              <p>이 글을 보시는 분들 모두 연말 마무리 잘 하시고 내년에도 좋은 일들만 가득하길 바라요~!</p>
            `,
    createDate: 'Posted on Dec 28, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.780Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '47',
    title: 'PTV days(Project Day) 행사 - 1일차',
    content: `
              <img src="/images/blog/post47/post47_0.png">
              <p>안녕하세요~ 이번에 저희 IGI KOREA에서 PTV days 행사를 개최했습니다!</p>
              <p>PTV 행사 장소는 구로를 떠나 바로 강남에서!</p>
              <p>평소보다 일찍 모인 첫 번째 날, 직원분들 각자 한 해 동안의 프로젝트를 발표하는 시간을 가졌습니다.</p>
              <img src="/images/blog/post47/post47_1.png">
              <p>이 분은 바로 우리 회사 아기 호랑이입니다!🐯🐯</p>
              <p>이번 행사를 위해 많은 시간과 노력을 써 주셔서 두 번째 사진으로 당첨!</p>
              <img src="/images/blog/post47/post47_2.png">
              <p>이렇게 멋진 포스터도 만들어 주셨습니다. 색이 너무 예쁘지 않나요?</p>
              <p>포스터는 PTV 장소 곳곳에 붙여두었습니다.</p>
              <img src="/images/blog/post47/post47_3.png">
              <p>직원분들의 발표 전 대표님이 개회사를 맡아주셨습니다.</p>
              <p>자 이제부터 개회사와 함께 시작합니다!</p>
              <img src="/images/blog/post47/post47_4.png"><img src="/images/blog/post47/post47_5.png">
              <p>첫 번째 발표는 우리 회사 분위기 메이커 조 파트장님께서 맡아 주셨습니다.</p>
              <p>회사에서 쓰는 프로그램과 그 외 프로그램들을 소개해 주셨는데</p>
              <p>뛰어난 발표 실력 덕분에 다른 그룹임에도 호기심을 마구 자극했습니다.</p>
              <img src="/images/blog/post47/post47_6.png">
              <p>두 번째 발표는 여름에 통일 걷기를 통해 GPS 달고 데이터를 수집 해오신 김 프로님의 발표!</p>
              <p>실제로 수집 해오신 데이터를 기반으로 프로젝트에 적용했었기 때문에 작업했던 기억이 새록새록~</p>
              <img src="/images/blog/post47/post47_7.png"><img src="/images/blog/post47/post47_8.png">
              <p>이렇게 초반에는 디자인기획그룹의 발표가 이어져 나갔습니다.</p>
              <p>그 와중에 올해 에이스로 폭풍 성장하신 파트장님의 발표가 진행되었습니다!</p>
              <p>그동안의 회사에서 있었던 자신의 스토리를 차근차근 발표해 주셨는데,</p>
              <p>실패를 겪고 다시 성공을 거둔 하나의 이야기가 모든 직원들의 가슴을 울려버렸습니다.</p>
              <p>이날 사실 베스트 프레젠터가 선정될 예정이었는데 이 발표를 듣고 선정되실 분이 암묵적으로 정해진 느낌이었습니다.</p>
              <img src="/images/blog/post47/post47_9.png">
              <p>중간에 미국에 계셔서 참석하지 못한 저희 IGI의 전무님이신 송 박사님을 화상 미팅으로나마 인사를 나누게 되었습니다.</p>
              <p>조언과 격려를 받으며 다시 발표 Start!</p>
              <img src="/images/blog/post47/post47_12.png"><img src="/images/blog/post47/post47_13.png">
              <p>디자인 파트의 발표가 끝나고 기획 파트의 차례!</p>
              <p>기획 파트는 여러 프로젝트를 처음부터 끝까지 보조해주시는 분들인데 그분들의 업무와 고충을 잠깐이나마 알 수 있는 좋은 자리였습니다.</p>
              <img src="/images/blog/post47/post47_10.png">
              <p>뒤쪽에서는 바쁘게 움직이고 있는 아기 호랑이와 같이 행사 기획 팀 남 프로님!</p>
              <p>학교 가면 꼭 있는 방송부 친구들 같네요.ㅋㅋ</p>
              <p>이번 PTV 행사를 위해 많이 신경 써주셔서 감사합니다~!!</p>
              <img src="/images/blog/post47/post47_11.png">
              <p>오전 발표가 끝나고 다들 점심식사를 하고 휴식시간을 가졌어요!</p>
              <p>점심 먹고 올라오니 저희 OnTwins 자체라 할 수 있는 분 이사님이 반갑게 맞이해주셨어요!</p>
              <img src="/images/blog/post47/post47_14.png">
              <p>오후 발표를 시작하기 전 남 프로님의 외부 발표 연습을 잠깐 볼 수 있었습니다.</p>
              <p>저희 회사에는 많은 능력자분들을 있네요~!</p>
              <img src="/images/blog/post47/post47_15.png"><img src="/images/blog/post47/post47_16.png">
              <p>남 프로님의 멋있는 발표가 끝나고 오후 개발 그룹의 발표가 시작되었습니다~!</p>
              <p>IT 회사답게 펼쳐진 심오한 얘기와 개발의 장들~</p>
              <p>개발자분들의 발표를 보니 한 해 동안 고생 많이 하시고 성장하신 것을 느낄 수 있었어요!</p>
              <img src="/images/blog/post47/post47_18.png">
              <p>이날은 신입사원분들도 함께 자리를 했었는데, 신입사원분들의 간단한 자기소개를 들었습니다!</p>
              <p>벌써부터 기대되는 신입분들..!</p>
              <img src="/images/blog/post47/post47_19.png">
              <p>신입사원분들을 보시면 흐뭇해하는 대표님의 모습입니다.</p>
              <img src="/images/blog/post47/post47_20.png">
              <p>관객들 호응도도 아주 좋습니다!</p>
              <img src="/images/blog/post47/post47_21.png">
              <p>마지막 순서!</p>
              <p>두근두근 베스트 프레젠터를 발표를 합니다...!</p>
              <p>베스트 프레젠터는 30만원 상당의 상금이 주어지게 됩니다.</p>
              <p>다같이 두구두구두구두구두구두구두구</p>
              <img src="/images/blog/post47/post47_23.png"><img src="/images/blog/post47/post47_24.png">
              <p>베스트 프레젠터는 바로 그 디자인기획그룹 에이스와 개발그룹의 에이스!!</p>
              <p>그룹마다 한 분씩 받게 되었습니다.</p>
              <p>축하합니다!!! 짝짝!!</p>
              <img src="/images/blog/post47/post47_25.png">
              <p>이렇게 PTV 1일차는 막을 내렸습니다.</p>
              <p>오늘 행사를 통해 올 한 해를 리뷰하며 공유함으로써 동료들을 이해할 수 있게 되어 이번 PTV 행사는 정말 대성공이었습니다!</p>
              <p>모두들 이런 자리가 종종 있어도 괜찮을 것 같다는 생각을 하며 다 같이 맛있는 저녁을 위해 굶주린 배를 잡고 행사장을 나섰습니다.</p>
              <img src="/images/blog/post47/post47_26.png"><img src="/images/blog/post47/post47_27.png"><img src="/images/blog/post47/post47_28.png"><img src="/images/blog/post47/post47_29.png">
              <p>저녁은 바로 보노보노!!</p>
              <p>평소에는 가기 힘든 가격!</p>
              <p>보노보노는 해산물 뷔페라고 소개되어 있지만 해산물 외 여러 고퀄리티 음식들이 준비되어 있었습니다.</p>
              <p>진짜 너무 맛있어서 모두들 정신 없이 먹느라 시간이 가버렸어요~</p>
              <p>빵빵해진 배를 잡고 모두들 퇴근 완료!</p>
              <p>1일차가 끝난 건 아쉽지만 아직 2일차가 남았기 때문에 다음 날로 Go~</p>
            `,
    createDate: 'Posted on Dec 28, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.776Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '46',
    title: '제3회 산타즈아 도덕산 등산기',
    content: `
              <img src="/images/blog/post46/post46_1.png">
              <p>안녕하세요~ 오랜만에 산타즈아에서 등산을 다녀왔어요!</p>
              <p>이번에 등반할 산은 바로 광명에 있는 도덕산이에요~</p>
              <p>도덕산 하니 뭔가 굉장히 철학적인 느낌이 들지만 그런것과는 전혀 상관없는 공원처럼 잘 꾸며져 있는 산이었어요!</p>
              <img src="/images/blog/post46/post46_2.png">
              <img src="/images/blog/post46/post46_3.png">
              <p>날이 많이 추워졌지만, 햇볕이 강할 때 등산은 힘들기 때문에 이날 오전 10시에 도덕산 앞에 모였어요.</p>
              <p>오늘 등반멤버는 5명으로 구성!</p>
              <p>사실 객원 멤버들이 함께하기로 했었는데 전화해보니 쿨쿨 꿈나라라 연락이 닿지 않았어요!ㅠㅠ</p>
              <img src="/images/blog/post46/post46_4.png">
              <img src="/images/blog/post46/post46_5.png">
              <p>도덕산은 아파트 숲 안쪽에 위치한 산으로 공원을 지나 잘 꾸며져 있는 등산로를 따라 올라가면 산책하듯 등산을 즐길 수 있어 저 같은 초보 등산러에 딱 맞는 산이에요!</p>
              <p>(물론 고수분들은 등반 내내 호흡 하나 흐트러지지 않고 올랐다는…)</p>
              <img src="/images/blog/post46/post46_6.png">
              <img src="/images/blog/post46/post46_7.png">
              <p>약 10분 정도 올라가면 폭포를 만날 수 있다고 해요~</p>
              <p>단순히 등산로만 예쁜 산이 아니라 볼거리도 많아서 더 좋았답니다!</p>
              <img src="/images/blog/post46/post46_8.png">
              <img src="/images/blog/post46/post46_9.png">
              <p>짠~! 인공폭포 도착!!</p>
              <p>도덕산 인공폭포는 절벽 위에서 시원한 물줄기가 뿜어져 나오고 있었어요!</p>
              <p>거센 물줄기 때문에 비록 물은 흙탕물이었지만 보는 것만으로 가슴이 뻥 뚫리는 느낌이에요!</p>
              <p>이런 곳에서 사진 한 장 안 찍을 수 없겠죠?ㅎ</p>
              <img src="/images/blog/post46/post46_1.png">
              <p>인공폭포 위로는 출렁다리가 보이네요~</p>
              <p>얼른 출렁다리가 있는 곳 까지 올라가봅시다!</p>
              <img src="/images/blog/post46/post46_10.png">
              <p>출렁다리 앞에서 턱걸이 한 개 성공하신 본부장님..</p>
              <p>본부장님: 한개(한계)야</p>
              <img src="/images/blog/post46/post46_11.png">
              <img src="/images/blog/post46/post46_12.png">
              <p>출렁다리는 특이하게 세 개가 모여 있는 구조에요!</p>
              <p>아래서 보는 인공폭포도 멋있지만, 출렁다리 위에서 보는 인공폭포도 인상 깊었어요~</p>
              <p>바닥이 뚫려있어서 살짝 아찔했답니다..(핸드폰 사수!)</p>
              <img src="/images/blog/post46/post46_13.png">
              <p>출렁다리는 지나 약 10분 정도 더 산을 오르면 정상에 정자가 있어요!</p>
              <p>원래 맑은 날은 광명시가 눈에 펼쳐진다고 하는데 이날은 미세먼지가 가득해서 뿌연 도심만 볼 수밖에.. 아쉬웠어요..ㅠ</p>
              <img src="/images/blog/post46/post46_14.png">
              <p>열심히 등산도 했겠다 등산 후에는 단백질을 보충해 줘야겠죠?</p>
              <p>그래서 찾아간 곳은 광명 샤브향~</p>
              <p>저희가 일찍 와서 그런지 손님이 별로 없어서 좀 걱정했는데 그런 걱정이 무색하게 정말 만족스러운 식사를 할 수 있었어요!</p>
              <p>주문한 메뉴는 월남쌈 샤브샤브세트로 소고기, 해산물, 라이스페이퍼, 야채, 죽까지 풀 코스로 준비해주는 메뉴에요~!</p>
              <img src="/images/blog/post46/post46_15.png">
              <p>샤브향이 좋았던 이유 중에 하나는 고기와 해산물을 제외하고 나머지 음식들은 셀프로 리필 해서 부족함 없이 먹을 수 있었어요!</p>
              <p>물론 리필을 안 해도 될 정도로 양이 많긴 해요~</p>
              <p>아! 또 신기한 점이 있었는데 로봇이 서빙해주고 있어서 자꾸만 눈이 가더라구요~</p>
              <img src="/images/blog/post46/post46_16.png">
              <img src="/images/blog/post46/post46_17.png">
              <img src="/images/blog/post46/post46_18.png">
              <img src="/images/blog/post46/post46_19.png">
              <p>열심히 샤브샤브와 월남쌈을 먹고, 디저트로 준비한 것은 칼국수~</p>
              <p>칼국수 면이 알록달록 이뻐서 눈으로 보는 재미까지 있답니다! (이러고도 죽까지 먹은 건 안 비밀)</p>
              <p>고기와 해산물, 야채가 우려진 샤브샤브 국물에 죽까지 먹으니 진짜 더 이상 들어갈 배가 없을 정도였답니다..</p>
              <p>다 먹을때쯤 주위를 둘러보니 어느새 넓은 매장에 사람이 가득 차 있더라구요~</p>
              <p>역시 이곳은 맛집이었나봐요~</p>
              <br>
              <p>가을 느낌 물씬 가볍게 오를 수 있는 도덕산 등산 후에 샤브샤브 점심까지 완벽한 산타즈아 3번째 등산이었습니다!</p>
            `,
    createDate: 'Posted on Oct 27, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.756Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '45',
    title: '아이지아이코리아 제주도 워크샵 - 3일차',
    content: `
              <img src="/images/blog/post45/post45_13.png">
              <p>제주도 워크샵 3일 차의 아침이 밝았습니다~!</p>
              <img src="/images/blog/post45/post45_01.png">
              <p>어제저녁에 너무 재밌었다며 이야기꽃을 피우는 버스와 함께 3일 차 첫 번째 관광지인 한담해변으로 이동합니다!!</p>
              <img src="/images/blog/post45/post45_02.png">
              
              <img src="/images/blog/post45/post45_03.png">
              <p>이런 아름다운 스팟에 사진을 빼놓을 수 없죠?</p>
              <p>아름다운 풍경과 그에 맞는 모델분을 모셨습니다..!</p>
              <img src="/images/blog/post45/post45_04.png">
              <img src="/images/blog/post45/post45_05.png">
              <p>그대의 인생샷만 건질 수 있다면 우린 괜찮아....</p>
              <p>결국 프사 건지셨다는..v^.^v</p>
              <img src="/images/blog/post45/post45_06.png">
              <p>이렇게 풍경에 취해 해변 길을 따라 걷고있는 와중에!</p>
              <p>....????</p>
              <img src="/images/blog/post45/post45_07.png">
              <img src="/images/blog/post45/post45_08.png">
              <p>크...여러분은 보그IGI 10월호에 실릴 탑스타 강프로님을 보고 계십니다.</p>
              <img src="/images/blog/post45/post45_09.png">
              <img src="/images/blog/post45/post45_10.png">
              <p>덥고 목이말라 카페에서 커피한잔 할까하고 카페에 들어섰습니다!</p>
              <p>카페 앞으로 내다보이는 바다가 보기만 해도 너무 시원하죠??</p>
              <img src="/images/blog/post45/post45_11.png">
              <p>계단에 올라서니 해맑게 웃고 계시는 우리 직원분들이 보이네요!</p>
              <p>웃음의 근원지가 어딘가하고 고개를 돌려보니..          </p>
              <img src="/images/blog/post45/post45_12.png">
              <img src="/images/blog/post45/post45_13.png">
              <p>..이분들 왜 이러는 걸까요?</p>
              <img src="/images/blog/post45/post45_14.png">
              <img src="/images/blog/post45/post45_15.png">
              <p>IGI의 공식 MC이자 DJ이자 래퍼이자 개그맨이신 이프로님의 공연이 계속 이어지길래 저도 팝콘 하나 들고 자리 잡았습니다^0^ </p>
              <img src="/images/blog/post45/post45_16.png">
              <img src="/images/blog/post45/post45_17.png">
              <p>옆자리에서는 아름다운 귤꽃이 애교를 한껏 뽐내고 있네요...!</p>
              <p>[부제: IGI 재간둥이 박모군]</p>
              <p>군데군데 직원분들이 있으신 곳에는 재미없는 곳이 없더군요! 정말 즐거웠습니다!!</p>
              <p>자 이제 두 번째 스팟으로 이동해볼까요~?</p>
              <img src="/images/blog/post45/post45_18.png">
              <p>두 번째 관광지는 바로바로 "노형수퍼마켙" 입니다!!</p>
              <p>이 "노형수퍼마켙"은 5가지 컨셉의 몽환적인 미디어 아트를 체험할 수 있는 공간이었는데요~</p>
              <p>우리 직원분들이 아이들처럼 뛰며 감탄하고 수없이 많은 사진을 남긴 곳이기도 합니다~!</p>
              <p>바로 확인하러 가볼까요??</p>
              <p>규모가 꽤 커 보이는 노형수퍼마켙 전시관의 모습입니다.</p>
              <img src="/images/blog/post45/post45_19.png">
              <img src="/images/blog/post45/post45_20.png">
              <p>노형수퍼마켙 간판이 보이네요</p>
              <p>조용하고 고요한 진입구를 지나니...               </p>
              <img src="/images/blog/post45/post45_21.png">
              <img src="/images/blog/post45/post45_22.png">
              <img src="/images/blog/post45/post45_23.png">
              <img src="/images/blog/post45/post45_24.png">
              <p>수퍼마켓 입구를 통해 내부로 이동하니 흑백사진 속 80년대의 수퍼마켓 내부를 보는 듯했습니다!</p>
              <img src="/images/blog/post45/post45_25.png">
              <img src="/images/blog/post45/post45_26.png">
              <p>제주 미디어 아트 노형수퍼마켙의 컨셉은 신기하고 재밌었는데요!</p>
              <p>1981년 어느 날 이 슈퍼에 묘한 문이 열리며 제주의 색을 쭉쭉 빨아들이기 시작했답니다.</p>
              <p>제주는 색을 잃어 모든 것이 흑백이 되고 말았죠.</p>
              <p>아 ~ 그래서 이 수퍼마켓은 색을 읽어 흑과 백으로만 이뤄져 있었던 거군요!!</p>
              <p>조용한 분위기 속에서 열심히 사진을 찍던 중 갑자기 문 쪽에 있던 오래된 텔레비전이 켜졌습니다!</p>
              <img src="/images/blog/post45/post45_27.png">
              <img src="/images/blog/post45/post45_28.png">
              <p>자판기인 줄 알았던 문이 열리며 새로운 세계가 열렸습니다...! </p><p>흑백의 수퍼마켙과는 다르게 오색찬란한 공간이 보이는데요..</p>
              <p>저곳이 제주의 색을 빨아들이고 있는 곳일까요?</p>
              <img src="/images/blog/post45/post45_29.png">
              <img src="/images/blog/post45/post45_30.png">
              <p>저곳은 어떤 세상일지 한번 떠나보겠습니다~~ </p>
              <p>아바타에 나올 것만 같은 눈부신 통로를 따라 어디론가 이동하는 듯하네요..</p>
              <p>통로를 거쳐 도착한 곳은 바로바로.. </p>
              <img src="/images/blog/post45/post45_31.png">
              <p>짠 이곳입니다~~~~!!!</p>
              <img src="/images/blog/post45/post45_32.png">
              <img src="/images/blog/post45/post45_33.png">
              <p > 매우 거대한 전시홀이 저희를 반겨주었어요!</p>
              <p>몽환적이고 시각적으로 너무 다채로운 영상들이 모든 공간을 감각적으로 채우고 있었는데요~</p>
              <img src="/images/blog/post45/post45_34.png">
              <img src="/images/blog/post45/post45_35.png">
              <p>눈과 영상미를 모두 빼앗은 영상미에 놀랐던 순간순간들이었답니다~~</p>
              <img src="/images/blog/post45/post45_36.png">
              <img src="/images/blog/post45/post45_37.png">
              <p>마치 판타지 영화 속의 주인공이 된 것처럼 이곳을 즐기는 직원분들이 너무 귀엽지 않나요?</p> 
              <p>특히나 디자인본부 직원분들의 눈빛이 특히나 빛났는데요..</p> 
              <p>저희도 나중엔 이러한 전시 공간 기대해도 되는 걸까요?</p>
              <img src="/images/blog/post45/post45_38.png">
              <p>아름다운 아트를 뒤로한 이분은 누굴까요? **맞추시는 분께 커피 (선착순 1명)** </p>
              <img src="/images/blog/post45/post45_39.png">
              <img src="/images/blog/post45/post45_40.png">
              <p>이 전시홀 내부는 원형 공간의 3층으로 이뤄져 있고, 모든 공간을 자유롭게 돌아다닐 수 있어요.</p> 
              <p>돌아다니며 사진 찍으시는 분들도 있고 이렇게 앉아서 즐기시는 분들도 있었어요!</p>
              <p>군데군데 포토 스팟도 자리하고 있었습니다!</p>
              <img src="/images/blog/post45/post45_41.png">
              <img src="/images/blog/post45/post45_58.png">
              <p>예술적 감각에 취하고 멋진 그대들에게 취하고...</p>
              <p>끝나고 보니 약 1시간 정도를 시간 가는 줄 모르게 감상했더군요.. 기대 이상으로 재밌고 새로운 체험이었습니다!</p>
              <p>네에!! 이렇게 노형수퍼마켙에서 관람을 마쳤습니다.</p>
              <img src="/images/blog/post45/post45_42.png">
              <p>다음으로는 점심을 먹으러 이동했는데요!!</p>
              <p>이곳의 갈치조림이 동료분들의 밥 두공기를 해치웠습니다. 정~말 맛있었어요..(쓰읍)</p>
              <img src="/images/blog/post45/post45_43.png">
              <img src="/images/blog/post45/post45_44.png">
              <p>사진을 보니 다시 배가 고파지네요.. 저 빨간 조림 양념이 저희 테이블의 대화를 사라지게 만들었습니다..</p>
              <p>짭짤한 갈치와 매콤한 양념의 조화가 밥도둑이 되었거든요~~!</p>
              <p>제주도의 갈치 조림집을 찾으신다면,, "옛촌" 제가 강력하게 추천합니다!!!!</p>
              <img src="/images/blog/post45/post45_45.png">
              <p>든든히 배를 채우고 바로 다음장소로 이동했습니다!!</p>
              <p>이제 3일차의 마지막이자 IGI 제주 워크샵의 마지막 일정이었던  &lt;제주 동문 시장&gt; 입니다~~</p>
              <img src="/images/blog/post45/post45_46.png">
              <img src="/images/blog/post45/post45_47.png">
              <img src="/images/blog/post45/post45_48.png">
              <img src="/images/blog/post45/post45_49.png">
              <p>들어서자마자 정겨운 시장분위기가 물씬 풍기더라구요!</p>
              <p>역시 제주도하면 귤이죠~</p>
              <p>맛있는 귤을 보니 가족과 친한 지인분들이 생각나셨는지 곳곳에서 귤한박스씩 배송시키는 모습들을 볼 수 있었습니다. (따듯하고 스윗해...!)</p>
              <img src="/images/blog/post45/post45_50.png">
              <p>분명 30분전 점심을 먹었지만 저는 딱새우와 눈이 마주쳐서 몇몇 직원분들과 바로 딱새우를 먹으러 갔답니다!</p>
              <p>제주도 와서 딱새우 안먹고 가기 너무 아쉽잖아요??</p>
              <p>딱새우 배 꾹 눌러서 야무지게 먹었답니다,, JMT,,</p>
              <img src="/images/blog/post45/post45_51.png">
              <p>딱새우만 먹기 또 아쉽죠..?</p>
              <p>먹는 거 하면 아이지아이니까..</p>
              <p>고등어회 꼬소~~하니 진짜 맛있었어요! 최고ㅠㅠㅠ!</p>
              <img src="/images/blog/post45/post45_52.png">
              <p>같은 시간 동문시장을 핫하게 밝힌 아이돌분들이 계셨는데요!</p>
              <p>코디분들의 신나는 표정을 보니 오늘의 코디가 즐겁고 만족스러우셨나 봅니다.ㅎㅎ</p>
              <p>동문 야시장이 또 인기라고 하는데요~! 저희는 이른 오후에 가서 야시장을 직접 느끼진 못했지만</p>
              <p>다음번엔 대만 야시장을 그대로 가져다 놓았다는 동문야시장을 꼭 방문해보고 싶네요!</p>
              <img src="/images/blog/post45/post45_53.png">
              <p>3일동안 고생하신 버스기사님께 감사인사를 드리고 직원분들의 이동수단이었던 버스앞에서 마지막 한컷!!!!!</p>
              <img src="/images/blog/post45/post45_54.png">
              <img src="/images/blog/post45/post45_55.png">
              <p>제주공항은 오늘도 역시나 바쁘게 여행자들을 반기고, 또 배웅하고 있었습니다.</p>
              <p>즐거웠던 제주도를 떠나는 길이었지만 비행기를 타러가는 길은 항상 설레네요!!</p>
              <img src="/images/blog/post45/post45_56.png">
              <p>재밌고 행복한 추억 가득 안고 다시 일상으로 돌아갈 시간이 되었습니다.</p>
              <p>비행기에서 내려다보는 하늘이 저를 몹시 센치하게 만드려는 순간!!!!!!</p>
              <p>기절했습니다. 다시 정신차리고 눈뜨니 착륙한다더군요..</p>
              <img src="/images/blog/post45/post45_57.png">
              <p>2박3일간의 제주도 워크샵! 언제 가나 기다렸는데 벌써 다녀와서 블로그를 작성하고 있네요.</p>
              <p>3일간 찍었던 사진들을 넘겨보며 저도 모르게 미소짓고 있을 정도로 재밌던 순간들이 많았나봅니다.</p>
              <p>다음에 또 떠나길 기대할게요 그럼 안녕~~~~~</p>
            `,
    createDate: 'Posted on Oct 21, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.903Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '44',
    title: '아이지아이코리아 제주도 워크샵 - 2일차',
    content: `
              <img src="/images/blog/post44/post44_01.png">
              <p>제주도 2일 차가 되었어요!</p>
              <p>조식 먹고 8시 반에 모여서 출발했어요. 2일 차 첫 번째 장소는 안돌오름입니다!</p>
              <p>오름이란 “작은 산”을 뜻하는 제주도 방언으로 각자의 분화구가 있는 소화산을 말합니다!</p>
              <img src="/images/blog/post44/post44_43.png">
              <p>오름 가는 길에서 창문을 보면? 기린 뛰어다닐 것 같은 풍경을 볼 수 있어요!!</p>
              <p>이게 제주죠..! 창문만 봐도 힐링이 될 수 있답니다~~</p>
              <img src="/images/blog/post44/post44_37.png">
              <p>1일 차 때 부터 차에서 이동하는 동안 다들 스티커 붙였어요~~ 손등에도 마스크에도!</p>
              <p>다들 귀여움 장착 하고 안돌오름으로 입장!!</p>
              <img src="/images/blog/post44/post44_02.png">
              <img src="/images/blog/post44/post44_03.png">
              <p>안돌오름에 오르니 특유의 상쾌함과 장엄한 나무들… 감성에 젖더군요. (작성자 mbti f)</p>
              <p>안돌오름에 도착하니 입구에는 자그마한 카페와 지도가 있었어요.</p>
              <p>지도 아기자기 귀엽지 않나요?? 민트색 카페도 너무 내 취향!!</p>
              <p>이 카페와 같이 사진을 찍으면 더 예쁘게 나온답니다!!^0^</p>
              <img src="/images/blog/post44/post44_04.png">
              <img src="/images/blog/post44/post44_05.png">
              <p>키야아아아~~ 나무들이 길쭉길쭉하니, 너무 멋있고 진짜 숲속에 온 것 같지 않나요?</p>
              <p>안돌오름 진짜 최고예요!!! 사진 보니 또 가고 싶네요ㅠ</p>
              <p>안돌오름은 편백나무숲의 전경이 이뻐서 웨딩사진 촬영을 위해 많은 사람이 찾는다고 해요.</p>
              <p>숲의 맑은 공기와 푸른 숲 이것이 힐링이죠..!</p>
              <p>풍경이 너무 예뻐서 사진이 잘 찍힐수 밖에 없습니다..</p>
              <p>프사! 인생샷! 건지고 싶으신 분들 안돌오름으로 컴온~~!</p>
              <img src="/images/blog/post44/post44_06.png">
              <img src="/images/blog/post44/post44_07.png">
              <p>짜자잔~~</p>
              <p>사진을 건지기 위한 우리들의 노력…!</p>
              <img src="/images/blog/post44/post44_38.png">
              <img src="/images/blog/post44/post44_39.png">
              <p>다른 쪽에선 화보 촬영 중이네요.</p>
              <p>촬영 작가님들과 배우님의 열정에 감탄이 나왔습니다..!</p>
              <p>큐티한 포즈가 너무 잘 어울리는 박모군&gt;&lt;</p>
              <p>다들 힐링은 물론! 너무나 즐거운 포토타임이었어요!!</p>
              <img src="/images/blog/post44/post44_08.png">
              <p>다음 일정은 녹차 족욕 체험이에요!</p>
              <p>족욕을 하기 위해 녹차 파우치, 녹차 허브 씨솔트, 피부 보습을 위한 녹차유, 수건을 나누어주셨어요~</p>
              <img src="/images/blog/post44/post44_09.png">
              <img src="/images/blog/post44/post44_10.png">
              <p>따뜻한 물을 담아 족욕을 하고 관리를 해주니 온몸 피로가 풀리는 기분이 들었답니다!</p>
              <p>발에 귀한 솔트로 스크럽도 해주고 오일도 발라주었어요!</p>
              <p>제 발이 이렇게 호강하기는 처음이네요~ 아주 매끈매끈 해졌어요~</p>
              <p>족욕 체험 후 만족하시고 패키지를 구매하시는 분들도 꽤 계셨어요!</p>
              <p>직장인들의 피로를 풀어줄 너무 좋은 코스였어요~</p>
              <img src="/images/blog/post44/post44_11.png">
              <img src="/images/blog/post44/post44_40.png">
              <p>뒤쪽으로 나가면 이렇게 넓은 녹차밭이~~!!!!!</p>
              <p>차 한잔 마시면서 녹차밭을 둘러봤어요.</p>
              <p>날씨도 너무 좋고 이런 스팟을 그냥 지나칠 수 없겠죠?</p>
              <p>녹차밭 주인처럼 여유로운 포즈 보이시나요?</p>
              <img src="/images/blog/post44/post44_12.png">
              <p>다른 곳에서도 포토타임이!!</p>
              <p>인생샷 건지기 위해 몸을 아끼지 않는 우리 직원분들..</p>
              <p>사진에 진심인 편..</p>
              <img src="/images/blog/post44/post44_18.png">
              <img src="/images/blog/post44/post44_19.png">
              <p>즐거웠던 족욕체험이 끝나고 점심 식사를 위해 다같이 이동했답니다!</p>
              <p>점심식사는 돈보리라는 식당에서 곤드레밥, 고등어 구이, 돼지고기 볶음 등 알찬 구성으로 식사를 했어요~</p>
              <p>반찬들이 어떻게 전부다 맛있죠? 곤드레밥에 고등어 구이를 얹어 먹으면 진짜 꿀~~~맛!</p>
              <p>물까지 넣어서 야무지게 먹었답니다! 역시 한국인은 밥심!!</p>
              <img src="/images/blog/post44/post44_13.png">
              <p>점심도 먹었겠다~ 배는 부르겠다~ 이제 소화시키러 가볼까요?</p>
              <p>배부른 당신을 위한 유산소코스, 성산일출봉을 방문했습니다.</p>
              <p>모든 분들이 쉽게 쉽게 성산일출봉에게 도전하고 있지만...</p>
              <img src="/images/blog/post44/post44_41.png">
              <p>결국 성산일출봉에 맞고 말았습니다.</p>
              <img src="/images/blog/post44/post44_15.png">
              <img src="/images/blog/post44/post44_16.png">
              <p>성산일출봉, 해맞이가 유명해서 유명한 그림입니다.</p>
              <p>일출이라는 말이 일출을 보고 가는 곳이라는 생각에 유명한 모습인거 같습니다.</p>
              <p>힘들어도 뷰만큼은 정말 예술인 성산일출봉!</p>
              <p>이런 성산일출봉에서 사진 안찍을 수 없겠죠?</p>
              <p>사진은 누구에게도 안 지는 게 아이지아이 아닙니까!</p>
              <p>누구보다 빠르게 남들과는 다르게 사진찍기에 몰입 시작!</p>
              <img src="/images/blog/post44/post44_16.png">
              <p>정상은 정말 속이 뻥 뚫리는 멋있는 풍경이랍니다!</p>
              <p>뷰 맛집, 유산소 맛집!</p>
              <p>눈도 즐겁고 운동도 되는 성산일출봉은 여기서 마무리했답니다!</p>
              <img src="/images/blog/post44/post44_20.png">
              <p>아이지아이 체력훈련 Part. 2 천년의 숲 비자림에 도착했습니다!</p>
              <p>매일 사무실에 앉아서 일하는 아이지아이 여러분을 위한 극강의 체력단련 코스죠.</p>
              <img src="/images/blog/post44/post44_26.png">
              <p>길을 따라 쭉 걸었습니다! 도란도란 이야기꽃을 피우며~~</p>
              <img src="/images/blog/post44/post44_23.png">
              <img src="/images/blog/post44/post44_24.png">
              <p>모두 이야기를 멈추고 주변을 보기 시작했습니다..</p>
              <p>나무들과 풀들이 정말 기괴하지만 아름답지 않나요..?(찐 감상 타임...)</p>
              <img src="/images/blog/post44/post44_22.png">
              <p>30~40분 남짓 걸었는데, 정말정말 힐링이 아주 그냥 미쳤습니다..!</p>
              <p>힐링? 비자림에서 하세요. 다른 곳은 힐링 아닙니다.</p>
              <p>가족, 친구, 연인(있는사람만.) 누구나 같이 가세요. 오늘부터 힐링 전도사 되겠습니다. 아니 비자림 전도사 되겠읍니다..</p>
              <img src="/images/blog/post44/post44_28.png">
              <p>.</p>
              <p>.</p>
              <p>.</p>
              <img src="/images/blog/post44/post44_29.png">
              <p>버스에서 한한한참참참 자다 보니.. 깔끔한 건물에 도착했더라고요.</p>
              <p>자다가 몰랐는데, 벌써 밥 먹을 때가 됐대요. 마린보이에 도착을 했습니다.</p>
              <img src="/images/blog/post44/post44_30.png">
              <p>마린보이는 회 맛집이라고 들었는데.. 밑반찬도 장난 아니네요?</p>
              <p>밑반찬으로 고등어회가 나오는 클라스. 어디 밑반찬 고등어회 주는 곳 나와보라그래!</p>
              <p>아무 데서나 이 정도 클라스 경험 못하거든요~</p>
              <img src="/images/blog/post44/post44_31.png">
              <p>오직 제주도에서만 맛볼 수 있는 북조기 튀김!</p>
              <p>그냥 조기도 맛있는데.. 제주도에서만 난다고 하니 더 맛있는거 있죠?</p>
              <p>이 글을 보시는 여러분들! 꼭 제주도가서 북조기 드셔보세요!</p>
              <img src="/images/blog/post44/post44_32.png">
              <p>오늘의 메.인.디.쉬 참돔회가 나타났습니다. 모두 조용..</p>
              <p>입에서 팔딱팔딱합니다..</p>
              <p>신선함에 한 번 놀라고 맛에 또 놀라고, 같이먹는 소주에 한번 더 놀라고..</p>
              <p>비싼 회는 비싼 값을 합니다..</p>
              <img src="/images/blog/post44/post44_33.png">
              <p>해물 버터구이는 그냥 약 올리려고 올려봤어요. 보시는 분들 배고프라고..ㅎ</p>
              <img src="/images/blog/post44/post44_34.png">
              <img src="/images/blog/post44/post44_35.png">
              <p>저녁 식사 후 2일 차의 마지막 일정이 마무리되었어요.</p>
              <p>시간이 정말 금방 지나갔고 제주도에서의 마지막 밤이라 생각하니 더 하고 싶었던 일, 다녀오고 싶었던 곳들이 생각나 아쉬움이 생겼지만 즐거웠던 오늘 하루</p>
              <p>내일 있을 여행이 그 아쉬움을 달래주었어요.</p>
              <p>아이지아이의 제주도에서 마지막 밤은 오늘의 행복함과 내일의 설렘이 있는 밤이었습니다!</p>
              <p>이제 제주도 마지막 날로 가 봅시다!!</p>
            `,
    createDate: 'Posted on Oct 19, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.900Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '43',
    title: '아이지아이코리아 제주도 워크샵 - 1일차',
    content: `
              <img src="/images/blog/post43/post43_01.png">
              <p>안녕하세요~ 이번에는 저희 IGI KOREA가 BIG 이벤트를 가지고 왔습니다..!</p>
              <p>그거슨.. 바로 IGI 워크샵! 위치는 무.려. 제주도!</p>
              <p>저희 IGI 전 직원이 2박 3일로 제주도에 다녀왔습니다!</p>
              <p>이제부터 IGI의 제주도 이야기를 보러 가시죠~~</p>
              <p>이른 아침부터 힘들게 눈을 떴지만 다들 제시간에 공항에 왔습니다. bb</p>
              <p>다들 몸은 피곤하지만 설레는 마음을 안고 왔어요.</p>
              <p>피곤해도 사진 한 컷은 못 참지~~</p>
              <img src="/images/blog/post43/post43_02.png">
              <img src="/images/blog/post43/post43_03.png">
              <p>비행기를 타는 순간은 언제나 설레는 것 같아요ㅠ</p>
              <p>창가자리에 앉아서 이쁜 하늘을 구경하다가….</p>
              <p>잠깐 눈감았는데 어느새 제주도에 도착했습니다~~!</p>
              <img src="/images/blog/post43/post43_04.png">
              <p>제주도에 도착하자마자 바로 야자수부터 한 컷!</p>
              <p>저는 항상 야자수가 있는 제주도를 보면 한국이 아닌 것 같더라구요.</p>
              <img src="/images/blog/post43/post43_05.png">
              <p>출발 전에, 다들 본부별로 각자 아이템을 맞췄어요!!</p>
              <p> 개발본부는 귤 삔, R&amp;D본부는 귤 선글라스, 디자인본부는 바람개비로~</p>
              <p>저희는 바람개비를 골라서 본부장님께서 하나씩 사주셨답니다!</p>
              <p>환상의 나라 에버.. 아니아니 제주도로 혼저옵서예~~~</p>
              <img src="/images/blog/post43/post43_28.png">
              <p>공항에서 나와서 버스타고 첫 번째 장소로 출발!</p>
              <p>첫번째 장소는 9.81 파크로 무동력 레이싱 테마파크입니다.</p>
              <p>아이지아이 인싸분들은 버스 맨 뒤 자리를 차지했네요!</p>
              <img src="/images/blog/post43/post43_29.png">
              <p>다들 아침도 못먹은 상태…</p>
              <p>아주 배고픈 상태…</p>
              <p>날카로운 상태…</p>
              <p>배고픈 여행자들.. 그들에게 주어지는 오메기떡</p>
              <p>여행사 측에서 즐거운 워크샵 겸 여행을 위해서 오메기떡을 두 알씩 주셨어요!</p>
              <p>개인적인 취향이지만,, 저는 팥 오메기떡보다 크림 오메기떡이 정말 맛있었어요. 대존맛..</p>
              <p>지금도 계속 생각나는 맛이에요.. 크림과 떡의 환상적인 조화ㅜ 미쳤습니다. 정.말.</p>
              <img src="/images/blog/post43/post43_06.png">
              <img src="/images/blog/post43/post43_07.png">
              <p>오메기떡도 먹고 즐겁게 대화하다보니 어느새 981 파크에 도착해 있었어요.</p>
              <p><p>안에서 간단한 설명을 듣고 카트 타러 고고고고!</p>
              <p>9.81파크는 국내최초 무동력 레이싱 테마파크라고 하네요!</p>
              <p>카트를 타기전에 긴장도 되고 사고 날까봐 두려웠지만, 걱정은 잠시뿐! 내 귀에는 바람을 가르는 소리뿐! 트랙위를 날라다녔죠.</p>
              <p>대표님이 깜짝이벤트로 1위 2위 3위를 선발하였는데요!</p>
              <img src="/images/blog/post43/post43_08.png">
              <img src="/images/blog/post43/post43_09.png">
              <p>여기 1등한 사람은 도대체 어떻게 달렸길래 이렇게 빠를까요..? 대단합니다….(라고 1등 한 사람이 블로그를 작성하였습니다.)</p>
              <p>전설 아니고 레전드 황천의 뒤틀린 어깨의 바람개비 부스터가 보이시나요^^</p>
              <p>아이템 빨 좀 받았습니다.</p>
              <img src="/images/blog/post43/post43_10.png">
              <p>신나게 카트를 타고 나니 어느새 배고파졌어요.. 오늘의 점심 메뉴는 고기국수!</p>
              <p>노형삼대국수에 당장 달려갔어요.</p>
              <img src="/images/blog/post43/post43_11.png">
              <p>다들 어깨에 바람개비를 꽂고 있네요...</p>
              <img src="/images/blog/post43/post43_12.png">
              <img src="/images/blog/post43/post43_13.png">
              <p>밑반찬과 흑돼지 수육이 먼저 나오고, 딱 봐도 국물이 깔끔할 것 같은 고기국수가 나왔어요.</p>
              <p>한입 먹는 순간 미.친.맛. 정말 미쳤습니다.. 서울 안 가고 제주도에서 맨날 고기국수만 먹고 싶네요.</p>
              <p>배도 불렸으니 다음 장소로 이동해야겠져?</p>
              <p>다음 장소는 카멜리아 힐이에요!</p>
              <img src="/images/blog/post43/post43_14.png">
              <p>고기국수로 잔뜩 부른 배를 안고 도착한 곳은 카멜리아 힐!</p>
              <p>소화시킬때는 아주 상큼한 포토타임이 최고죠.</p>
              <img src="/images/blog/post43/post43_15.png">
              <p>단체사진도 이렇게 찍었어요!</p>
              <p>얼른 단체 사진 찍고 핑크뮬리가 있는 곳까지 가봅시다!!!</p>
              <img src="/images/blog/post43/post43_16.png">
              <img src="/images/blog/post43/post43_30.png">
              <p>아이지아이 공식 상큼이들의 화려한 포토타임.. 멘트 없이 감상하시죠</p>
              <img src="/images/blog/post43/post43_17.png">
              <p>핑크 뮬리 진짜 예뻤는데 날씨가 흐려서 아쉬웠어요..</p>
              <p>이 사진 보고 추적 60분, 그것이 알고싶다 같은데 사건 현장 사진 찍힌 것 같다고.. 저희 회사 송중기님이(가명)</p>
              <p>날이 흐려도 인생샷 건지겠다고 다들 열심히 찍었답니다!</p>
              <img src="/images/blog/post43/post43_18.png">
              <p>핫한 포토타임을 끝내고 그 마음 그대로 1일차 클라이막스로 갑니다.</p>
              <p>IGI In Yacht</p>
              <img src="/images/blog/post43/post43_19.png">
              <p>요트에 탑승하기전  제주도 인싸템으로 휘감은 감귤요정 v ^___^ v</p>
              <img src="/images/blog/post43/post43_21.png">
              <p>요트에서 영화 한편 봤습니다.</p>
              <img src="/images/blog/post43/post43_22.png">
              <p>그냥 누워있는 것 같지만 진짜 재밌어 하는 중이랍니다..</p>
              <p>저렇게 누워있으면 진짜 꿀잼~~!</p>
              <p>놀이기구 타는 것 같았어요!!</p>
              <img src="/images/blog/post43/post43_31.png">
              <img src="/images/blog/post43/post43_32.png">
              <p>다 같이 낚시도 했어요~</p>
              <p>직원 중 낚시에 성공하신 분들도 있었습니다!</p>
              <p>행복해하시는 표정이 모자이크에 가려서 아쉽네요 ^o^</p>
              <img src="/images/blog/post43/post43_33.png">
              <p>잡은 고기는 바로 회 떠서 먹었는데요!</p>
              <p>입에서 살살 녹더라고요..</p>
              <p>역시 바로 뜬 회라 그런지 진짜 최고!!!</p>
              <p>고기한텐 미안하지만.. 너무 맛있다ㅜ</p>
              <img src="/images/blog/post43/post43_23.png">
              <img src="/images/blog/post43/post43_34.png">
              <p>요트에 탑승할 때 까지만 해도 파도가 그렇게 심하지 않았는데, 포구를 벗어나니 파도가 조금 생기더라구요.</p>
              <p>뱃멀미를 하시는 분들은 조금 힘들어 하셨지만, 뱃멀미에서 자유로운 분들은 신나게 바다를 즐겼습니다.</p>
              <img src="/images/blog/post43/post43_24.png">
              <p>요트투어가 끝나고 단체사진 한 컷!</p>
              <p>요트 투어까지 다 마친 후 다시 또 배에서 밥달라고 난리를 치네요ㅠ</p>
              <img src="/images/blog/post43/post43_25.png">
              <p>바로 흑돼지 먹으러 고고고! 흑돼지로 유명한 진돼지에 왔어요!</p>
              <img src="/images/blog/post43/post43_26.png">
              <img src="/images/blog/post43/post43_27.png">
              <p>가서 제일 먼저 먹었던 메뉴는 두툼한 오겹살! 잘 구워서 한 입에 먹기 좋은 크기로 자른 후 쏙!</p>
              <p>천국, 극락 뭐든 간에.. 너무 맛있었어요ㅠㅠ</p>
              <p>진돼지에서 뒤로 나오면 불빛정원이 나옵니다!</p>
              <p>밤에 가면 불빛이 나와서 너무 예뻐요..!</p>
              <p>고기도 먹고 예쁜 불빛을 보며 제주도 워크샵 1일차를 마무리하네요~</p>
              <p>모두가 즐겁고 햅삐한 하루였어요!!</p>
              <p>2일차에는 어떤게 기다리고 있을까요? 얼른 2일차로 고고고고고!</p>
            `,
    createDate: 'Posted on Oct 14, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.897Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '42',
    title: "[르포]30초마다 공장 손바닥 보듯…불량없는 'LG냉장고' 비결(종합)",
    content: `
              <img src="/images/blog/post42/post42_1.png">
              <p><strong>[아시아경제]</strong></p></br>
              <p>' '디지털 트윈' 기술이 있어 버추얼 팩토리 체계가 돌아간다. 디지털 트윈 기술을 적용하면 매 30초마다 전 공장의 상황을 분석할 수 있다. 라인마다 다른 제품군에도 적시에 적절한 부품을 공급할 수 있게 돕는다. 덕분에 LG스마트파크에선 무슨 일이든, 일어나기 10분 전에 모든 공정을 시뮬레이션하는 '예지보전'을 구현할 수 있다. '</p><br>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3g6Myvb" target="_blank">link: https://bit.ly/3g6Myvb</a>

            `,
    createDate: 'Posted on Oct 13, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.854Z',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '41',
    title: '제1회 르네상스 전시회 관람기',
    content: `
              <img src="/images/blog/post41/post41_1.png">
              <p>제1회 르네상스 동호회가 8월 6일 "팀 버튼 특별전"에 다녀왔습니다.</p>
              <p>무한한 상상과 강렬한 세계관이 잘 담겨 있던 특별전 관람기를 만나보시죠.</p>
              <img src="/images/blog/post41/post41_2.png">
              <p>아침 DDP에 도착하니 비가 억수같이 내렸어요.</p>
              <p>첫 모임인데 날씨 운이 좋지 않다며 우산이 없어 곤란해하고 있으니 "첫 모임이니 제가 커피를 쏘겠습니다!!" 회장님이 말했고</p>
              <p>덕분에 르네상스 멤버들은 비를 피해 비가 그칠 때까지 느긋하게 카페에서 시간을 보낼 수 있었습니다.</p>
              <p>회장님 최고!!</p>
              <img src="/images/blog/post41/post41_3.png">
              <p>각자 취향에 맞는 커피와 소금 빵은 덤~</p>
              <p>주말에 만나니 더 반갑고 할 이야기가 끊이지 않았어요.</p>
              <p>맛있는 커피와 자리가 있으니 모두가 수다쟁이가 되고 별거 아닌 이야기에 웃음이 끊이지 않았어요.</p>
              <p>비가 그치는 것을 확인한 후 팀 버튼 특별전으로 향했습니다.</p>
              <img src="/images/blog/post41/post41_4.png">
              <img src="/images/blog/post41/post41_5.png">
              <p>주말이라 사람들이 꽤 있었지만 관람하는데 불편함은 없었어요.</p>
              <p>사진 촬영이 금지인 건 아쉬웠어요!!</p>
              <p>입장한 르네상스 멤버들은 자신의 속도에 맞춰 관람을 시작했어요.</p>
              <p>관람하다가 서로 만나게 되면 좋아하는 작품과 그림에 대하여 이야기를 나누기도 했어요.</p>
              <p>전시 관람도 하고 서로의 생각도 공유하니 재미있었어요.</p>
              <img src="/images/blog/post41/post41_6.png">
              <img src="/images/blog/post41/post41_7.png">
              <img src="/images/blog/post41/post41_8.png">
              <img src="/images/blog/post41/post41_9.png">
              <p>한 가지의 세계관을 꾸준히 작품화했다는 것에 대한 놀라움과 습작의 위대함을 느끼며 즐겁게 관람을 마무리했어요!</p>
              <p>굿즈샵에 나와서야 마음껏 사진을 찍었어요.</p>
              <img src="/images/blog/post41/post41_10.png">
              <img src="/images/blog/post41/post41_11.png">
              <p>마음에 들었던 작품 엽서도 한 장씩 나눠가 지고 마지막으로 인증샷도 더 남겼어요!</p>
              <p>르네상스 파이팅!!</p>
              <img src="/images/blog/post41/post41_12.png">
              <p>맛집 검색을 돌려 돌려~ 만장일치로 네팔식 커리집으로 고고!!</p>
              <p>생소한데 궁금한~!!</p>
              <p>평점 좋아 바로 찾아갔습니다!</p>
              <img src="/images/blog/post41/post41_13.png">
              <img src="/images/blog/post41/post41_14.png">
              <p>생소한 네팔식 커리이지만 우리가 아는 그 인도 커리맛에 약간의 향신료 맛이 더 나는 아주 입맛에 딱이였습니다!!</p>
              <p>순식간에 커리 완식!!</p>
              <img src="/images/blog/post41/post41_15.png">
              <p>첫 모임이었던 르네상스 멤버들!! 그들은 수다 멤버였습니다.</p>
              <p>앉을 자리와 음식이 있다면 말이 끊이지 않았어요~</p>
              <p>앞으로도 만나게 될 작품을 보고 서로의 이야기 나누기를 기약하며 제1회 르네상스 모임을 마쳤습니다!</p>
              <p>다음에 또 만나기~ 약속!</p>
            `,
    createDate: 'Posted on August 11, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.759Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '40',
    title: '제2회 산타즈아 관악산 등산기',
    content: `
              <img src="/images/blog/post40/post40_1.png">
              <p>저번 1회에 이어 산타즈아에서 이번엔 7월 30일 관악산으로 등산을 다녀왔습니다.</p>
              <p>짧지만 인상깊었던 관악산 등산기 지금 만나보시죠.</p>
              <img src="/images/blog/post40/post40_2.png">
              <p>관악산 등산로 길에도 다양한 입구들이 있지만 저희는 서울대학교 내부의 건설환경종합연구소 앞 정류장에서 하차하여 올라가기 시작했어요.</p>
              <p>주말이다 보니 생각보다 사람들이 많았습니다.</p>
              <img src="/images/blog/post40/post40_3.png">
              <p>등산 중에 한 컷 찍어주고 가실게요~</p>
              <p>1회차와는 다르게 다들 장비들을 꾸리고 있는 모습이 보이시나요?</p>
              <p>가면 갈수록 프로등산인(?) 포스들이 나기 시작하니 앞으로가 기대가 됩니다.^^</p>
              <img src="/images/blog/post40/post40_4.png">
              <p>관악산은 등산로 바로 옆에 강물이 흐르고 있어서 굉장히 운치 있고 좋았는데요.</p>
              <p>날이 많이 더웠던 탓에 강물의 도움을 많이 받았습니다.</p>
              <p>마음 같아서는 물속으로 풍덩 들어가고 싶었는데 잘 참은 자신을 칭찬합니다.</p>
              <img src="/images/blog/post40/post40_5.png">
              <img src="/images/blog/post40/post40_6.png">
              <p>점점 정상에 가까워지면서 아름다운 비경이 펼쳐지기 시작합니다.</p>
              <p>서울 전체가 보인다고 해도 과언이 아닐 정도로 넓게 펼쳐진 도시의 모습이 한눈에 들어왔는데요.</p>
              <p>이 날따라 하늘이 도왔는지 미세먼지도 적어서 더더욱 아름다운 풍경을 눈에 잔뜩 담아두고 왔습니다.</p>
              <img src="/images/blog/post40/post40_7.png">
              <p>이런 풍경에 사진은 참을 수 없습니다. 정상을 얼마 안 남겨두고 또 한 컷!</p>
              <img src="/images/blog/post40/post40_8.png">
              <p>그토록 고대하던 정상에 드디어 도착했습니다!<p>
              <p>사람들이 정말 많았는데요.</p>
              <p>정상 비석의 경우 사람들이 줄을 서가며 사진을 찍더라고요. 그래서 우리는 합리적으로(?) 정상 바위 옆에서 한 컷! 나쁘지 않죠?</p>
              <img src="/images/blog/post40/post40_9.png">
              <p>고생한 그대여. 먹어라!</p>
              <p>고생한 모두를 위해 메뉴 고민 끝에 점심 식사로 닭갈비를 먹기로 했습니다.</p>
              <p>택시를 타고 도착한 곳은 신림에 위치한 춘천집!</p>
              <p>들어가보니 서울 3대 닭갈비라고 홍보하던 것이 기억이 납니다.</p>
              <img src="/images/blog/post40/post40_10.png">
              <p>밑반찬과 메인요리인 닭갈비가 나오고 사진 찰칵!</p>
              <p>셀프바가 따로 있는데 밑반찬도 이것저것 가져오다 보니 상이 꽉 찼습니다.</p>
              <img src="/images/blog/post40/post40_11.png">
              <p>메인 요리인 닭갈비입니다.</p>
              <p>딱 봐도 먹음직스럽게 생기지 않았나요?</p>
              <p>익는 동안 기다리는 이 찰나의 시간이 얼마나 긴지 ㅠㅠ</p>
              <img src="/images/blog/post40/post40_12.png">
              <p>시원한 맥주 한잔과 함께 관악산도 무사히 클리어!</p>
            `,
    createDate: 'Posted on Aug 05, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.753Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '39',
    title: '2022 DMZ 평화의 길 통일걷기 후기',
    content: `
              <img src="/images/blog/post39/post39_1.png">
              <p>이번에는 2022 평화의 길 통일걷기 행사에 직접 다녀왔습니다.</p>
              <p>코스는 강원도 고성에서 출발해서 경기도 파주까지 걷는 대장정이었는데요.</p>
              <p>2022년 6월 27일에서 2022년 7월 9일에 이르기까지 총 13일간의 여정을 무사히 마치고 돌아왔답니다.</p>
              <p>그 일정을 함께 만나 보실까요?</p>
              <img src="/images/blog/post39/post39_2.png">
              <p>여정을 함께 할 통일걷기 운영 버스 앞에 도착했습니다.</p>
              <p>강렬한 빨간색이 굉장히 인상적이었어요.</p>
              <p>되돌아서 생각해보면 기사님께서 코스 길들을 전부 꿰고 계셔서 보다 편하게 일정을 보낼 수 있지 않았나 하는 생각이 듭니다.</p>
              <img src="/images/blog/post39/post39_3.png">
              <p>시작 지점으로 가는 도중 주최 측에서 다양한 물품을 제공해 주셨어요.</p>
              <p>가방이나 모자에 붙일 액세서리와 스탬프 투어를 즐기는 사람을 위한 책자, 그리고 햇빛을 막아줄 모자와 걷기 문구가 들어간 티셔츠까지 받아서 일정 중에 정말 유용하게 사용했답니다.</p>
              <img src="/images/blog/post39/post39_4.png">
              <p>시작은 고성통일전망대에서 통일 Go! Go! Go! 라는 구호와 함께 가볍게 걷기가 시작되었습니다.</p>
              <p>아직까지는 힘들다는 것도 없고 그저 여행하러 온 것처럼 발걸음이 매우 가벼웠던 기억이 나네요.</p>
              <p>비를 뚫으며 발걸음을 한 발자국씩 옮겨봅니다.</p>
              <img src="/images/blog/post39/post39_5.png">
              <p>통일걷기 기간이 딱 장마철의 시작이었기에 비가 걸핏하면 내리곤 했습니다.</p>
              <p>사전에 안내된 우의를 착용해도 신발은 어떻게 할 도리가 없어서 잔뜩 젖으면 숙소에 돌아가서 드라이기로 말리곤 했었던 기억이 지금 되돌아보면 추억으로 남은 것 같습니다.</p>
              <img src="/images/blog/post39/post39_6.png">
              <img src="/images/blog/post39/post39_7.png">
              <p>코스에 민간인 통제구역 안쪽들이 있다 보니 일반적으로 쉽게는 볼 수 없는 절경들을 자주 접했어요.</p>
              <p>굳이 적혀진 랜드마크가 아니더라도 걸으면서 보이는 자연의 아름다움은 걷기의 피곤함을 달래주기에 충분했습니다.</p>
              <p>반대로 보안상 문제로 촬영할 수 없었던 절경들은 눈에 잔뜩 담아왔답니다.</p>
              <img src="/images/blog/post39/post39_8.png">
              <p>다양한 랜드마크들이 일정 중에 가득했지만 가장 기억에 남은 것은 뭐니 뭐니 해도 판문점이었습니다.</p>
              <p>위의 사진은 판문점 근처에 있는 돌아오지 않는 다리라고 합니다.</p>
              <p>넘어가면 북측이기에 다시는 돌아올 수 없다고 하여 그런 이름이 붙었다는 해설 군인의 말을 듣고 '정말 남북 경계선에 왔구나'하는 사실이 실감 났습니다.</p>
              <img src="/images/blog/post39/post39_9.png">
              <p>남북 분단의 상징인 판문점 앞에서 사진을 찍어봤습니다.</p>
              <p>역사적인 공간에 실제로 와보니 뛰는 가슴이 주체 되질 않았어요.</p>
              <p>더불어 삼엄한 군인들의 경비도 든든해서 흔히 말하는 군인들 덕분에 두 발 뻗고 편하게 잔다. 라는 말이 다시 한번 떠올랐습니다.</p>
              <p>북측 건물도 보였지만 북측엔 단 한 사람도 보이지 않아 질문해보니 코로나로 인해 모든 병력들을 철수시켰다고 말씀해 주셨어요.</p>
              <p>규칙상 북측 사람에게 말을 걸거나 손짓을 하는 것조차 금지되어 있지만 그래도 눈에 담아두지 못한 점은 뭔가 아쉽다는 생각이 들었습니다.</p>
              <p>통일이라는 것의 의미를 마음에 새길 수 있는 좋은 경험이었습니다.</p>
              <img src="/images/blog/post39/post39_10.png">
              <p>걷다가 발바닥에 물집이 수없이 잡히고 일정 중반부터는 종아리에 근육통까지 생겨서 매일 파스 스프레이를 걸핏하면 뿌리기 일쑤였지만 마침내 해냈습니다.</p>
              <p>목표를 향해 도전하고 그 결과를 보았을 때 이렇게나 뿌듯하다는 사실을 다시 한번 되새길 수 있었습니다.</p>
              <p>더불어 남북한의 평화적인 통일을 기원하며 통일 Go! Go! Go!</p>
            `,
    createDate: 'Posted on July 21, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.885Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '38',
    title: '연천 답사 - (3)',
    content: `
              <img src="/images/blog/post36/post36_31.png">
              <p>태풍전망대와 고랑포구 역사공원을 방문하고, 점심을 먹기 위해 산머루가든으로 이동했습니다.</p>
              <p>산머루가든은 예약하지 않으면 방문할 수 없다고 해요!!!</p>
              <p>찾아보는 리뷰마다 10년 단골이란 분도 있고 칭찬이 많아서 매우 기대하고 간 곳이에요.</p>
              <img src="/images/blog/post36/post36_32.png">
              <p>처음에 여기가 식당이 맞나? 라고 생각했어요.</p>
              <p>산머루가든이라는 소박한 팻말 하나 있고 가정집에 잘못 들어온 건가? 펜션인가? 고민했는데, 식당이 맞았습니다!</p>
              <img src="/images/blog/post36/post36_33.png">
              <img src="/images/blog/post36/post36_35.png">
              <p>외관 사진은 무성한 잔디밭을 마당으로 할머니 집에 놀러 온 느낌이 난답니다!</p>
              <img src="/images/blog/post36/post36_36.png">
              <p>안으로 들어오면 나무 냄새와 함께 나무로 만들어진 그릇과 수저, 젓가락 등을 판매하고 계세요.</p>
              <p>왜 예약 없이 이용 못 한다는 건지 궁금했는데, 사람을 왕창 받는 식당이 아니라 정말 소수의 사람들이 편안하게 식사하고 갈 수 있는 공간이었어요.</p>
              <img src="/images/blog/post36/post36_37.png">
              <p>진열도 어쩜 이렇게 이쁘게 해놓았는지 공간을 구경하는 재미가 쏠쏠했어요.</p>
              <img src="/images/blog/post36/post36_38.png">
              <p>예약한 인원수에 맞춰서 상이 차려져 있었어요!</p>
              <p>너무 배고팠는데 정갈한 상차림에 기분 좋았어요.</p>
              <img src="/images/blog/post36/post36_39.png">
              <p>무슨 차인지는 모르겠지만 준비해주신 물로 목을 축이고 음식을 기다려요.</p>
              <img src="/images/blog/post36/post36_40.png">
              <p>저희는 다섯 명이서 오리불고기 2개와 메밀막국수 3개, 들깨맛죽 2개를 시켰어요.</p>
              <img src="/images/blog/post36/post36_41.png">
              <p>고기를 기다리면서 소스장을 한번 찍어보았는데요, 쌈장을 개인적으로 먹을 수 있게 주시고 추가로 쌈장 주실 때는 큰 그릇과 공용 수저로 주시더라고요.</p>
              <p>요즘 같은 코로나에 센스있는 상차림!</p> 
              <p>저기 보이는 갈색소스가 진짜 맛있었어요. 사장님의 특제소스인 것 같아요!!</p>
              <p>사진에는 없는데 고기 굽는 젓가락과 식사용 젓가락도 따로 주시더라고요.</p>
              <p>집게로 굽는 게 아니라 불편할줄 알았는데 오히려 더 편했어요!!</p>
              <img src="/images/blog/post36/post36_42.png">
              <img src="/images/blog/post36/post36_43.png">
              <p>고기를 시키면 샐러드와 야채를 내어주시는데, 야채는 계절마다 달라진다고 합니다.</p>
              <p>봄에 오시면 꽃이 함께 나온다고 하네요!!</p>
              <p>샐러드 소스가 너무 맛있어서 저희 직원들 모두 한입 먹자마자 눈 동그래져서 눈으로 대화나눴어요.ㅋㅋㅋㅋㅋ</p>
              <p>고추는 저희가 예약 시간보다 조금 일찍 도착해서 추가로 더 주셨어요. 매운 고추랑 안 매운 고추를 따로 주셨는데요!! 
              <p>고추를 직접 밭에서 새로 따다 주셨는데 진짜 너무 맛있어서... 혼자 8개를 먹었어요.ㅋㅋㅋㅋ</p>
              <p>이러고 옆에 둘러봤더니 한 사람당 3~4개를 먹긴 했더라고요...</p>
              <p>원래 고추 잘 안 드신다던 분도 오늘은 맛있어서 많이 먹었다고...ㅋㅋㅋㅋ</p>
              <img src="/images/blog/post36/post36_45.png">
              <p>고기가 나오면 감자와 함께 구워요.</p>
              <p>사진에서는 고기랑 감자가 섞였지만, 감자를 가장자리에 일렬로 깔아놓고 익은 고기는 그 위에 올리는 식으로 해서 먹었어요.</p>
              <p>먹다 보면 주인아주머니께서 맛있게 먹는 방법을 설명해주신답니다!</p>
              <img src="/images/blog/post36/post36_46.png">
              <p>그다음에 시켰던 죽이 나왔는데요, 고기 먹고 너무 배부른 상태라 간단히 2개만 시킨 거기 때문에 앞접시에 나눠 먹었는데요... 공기밥 하나 정도 양으로 나와요.</p>
              <p>적당히 덜어서 먹었는데, 세상에... 너무 맛있어서 깜짝놀랐어요.</p>
              <img src="/images/blog/post36/post36_47.png">
              <p>마지막 마무리로 메밀막국수가 나왔습니다!</p>
              <img src="/images/blog/post36/post36_48.png">
              <p>고명을 살짝 치워보면 이 안에 양념이 숨어있어요.</p>
              <p>고기 먹고 배불러서 더 이상 못 먹을 것 같았는데, 간이 삼삼하다 그래야 하나? 엄청 감칠맛이 느껴져서 맛있었어요.</p>
              <p>미리 만들어놓는 게 아니라 저희가 주문하면 그때부터 바로 만드신다고 해서 사람을 한 번에 많이 받진 않는 것 같았어요.</p>
              <img src="/images/blog/post36/post36_49.png">
              <img src="/images/blog/post36/post36_50.png">
              <p>음식을 배불리 다 먹은 뒤엔 나무그릇들을 한 번씩 구경하고 산머루가든에서 키우는 검정색 큰 개가 있어요! 
              <p>개 이름은 타이라고 하더라고요.</p>
              <p>개랑 조금 놀다가 왔습니다. 생각해보니 멍멍이 사진을 안 찍었네요!!!</p>
              <p>연천을 갈 일이 있다면, 지인들과 함께 또 한 번 더 오고 싶은 맛집이었답니다.</p>
            `,
    createDate: 'Posted on July 15, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.910Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '37',
    title: '연천 답사 - (2)',
    content: `
              <img src="/images/blog/post36/post36_14.png">
              <p>태풍전망대 다음으로는 고랑포구 역사공원을 방문했습니다.</p>
              <p>태풍전망대에서 고랑포구 역사공원은 약 한 시간 정도 소요되었어요.</p>
              <img src="/images/blog/post36/post36_15.png">
              <p>공간은 01. 만남의 찰나 (안내 장소) &gt; 02. 삶의 찰나(저잣거리 재현 전시실) &gt; 03. 역사와 문화의 찰나 (체험 전시실) &gt; 04. 오감의 찰나 (어린이 체험 전시실)로 이루어져있었는데</p>
              <p>저희가 갔을 때 2층은 출입이 제한되어있었습니다.</p>
              <p>안내소에서 인당 5천 원의 관람료를 내고 나면 문화관광해설사분이 저희를 맞이해주면서 연천의 역사에 대해서 설명을 해주십니다.</p>
              <p>연천은 매우 발전된 상업 도시였는데, 6.25전쟁으로 인해 그 모습을 다 잃어버렸다고 합니다.</p>
              <img src="/images/blog/post36/post36_16.png">
              <p>그 당시 저잣거리를 표현한 전시관이 있었는데요, 이곳에선 어플을 다운받은 뒤 포토존에 서보면 그 가게에 맞는 AR이 나와요.</p>
              <img src="/images/blog/post36/post36_17.png">
              <p>역사공원에서 안내하는 어플을 다운받으면 재밌는 영상이 재생돼요.</p>
              <p>각 장소마다 어떤 장면이 나올지 궁금해서 뭔가 다 찍어보게 되더라구요.</p>
              <p>아이들이랑 오면 정말 재밌게 체험할 수 있을 것 같았어요.</p>
              <img src="/images/blog/post36/post36_18.png">
              <p>관광해설사분께서 너무 재미있게 설명을 해주셨어요.</p>
              <p>마치 할머니한테 옛날이야기 듣듯이 진행되었고요, 예전에 쓰였던 물건들에 대해서도 한 번씩 설명해주셨답니다.</p>
              <p>어릴 적 시골에서 봤던 물건들도 있고, 전래동화에서 봤던 물건, 처음 보는 물건들이 많았었어요.</p>
              <img src="/images/blog/post36/post36_19.png">
              <img src="/images/blog/post36/post36_20.png">
              <p>당시 어떤 음식을 먹었는지도 엿볼 수 있어요.</p>
              <p>지금이야 국밥 가게들이 어디든 있지만, 예전에는 우시장 같은 번화가 같은 곳에서나 볼 수 있었다고 해요.</p>
              <img src="/images/blog/post36/post36_21.png">
              <p>역사 문화 체험전시실로 넘어오면 선사시대부터 지금까지 역사를 볼 수 있어요.</p>
              <p>관광해설사분께서 정말 재미있게 이야기해주셨어요. 이 이후에는 가볍게 한번 둘러보고 여러 가지 체험 및 게임을 해볼 수 있어요.</p>
              <img src="/images/blog/post36/post36_22.png">
              <img src="/images/blog/post36/post36_23.png">
              <img src="/images/blog/post36/post36_24.png">
              <img src="/images/blog/post36/post36_25.png">
              <img src="/images/blog/post36/post36_26.png">
              <p>모두가 도전해보았습니다만, 작전 실패~ㅋㅋㅋ 은근히 어려워요.</p>
              <img src="/images/blog/post36/post36_27.png">
              <p>저는 이게 재미있었어요. 모래를 가지고 높이에 따라 다른 이미지로 바뀌어서 제가 산을 만들 수 있고, 바다를 만들 수 있었어요.</p>
              <img src="/images/blog/post36/post36_28.png">
              <p>연천시 상공을 누빌 수 있는 VR 패러글라이딩도 체험해볼 수 있었어요.</p>
              <p>이외에도 많은 게임이 있었어요. 생각보다 체험할 수 있는 것도 많고 재미있었어요.</p>
              <img src="/images/blog/post36/post36_29.png">
              <img src="/images/blog/post36/post36_30.png">
              <p>생각보다 놀거리가 진짜 많고 역사를 기반으로 한 게임들이 많아서 가족들끼리 오기 좋은 곳이에요.</p>
            `,
    createDate: 'Posted on July 14, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.908Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '36',
    title: '연천 답사 - (1)',
    content: `
              <img src="/images/blog/post36/post36_1.png">
              <p>IGI Korea는 현재 6.25전쟁으로 인해 사라진 마을에 관한 프로젝트를 진행 중인데요, 자료조사를 위해 연천에 가서 태풍전망대와 고랑포구 역사박물관에 다녀왔어요.</p><br>
              <p>먼저 태풍전망대 소개부터 드리자면 남한에서 휴전선상 북한과 가장 가까운 전망대로 유명합니다.</p>
              <p>맑은 날에는 개성의 땅까지 보인다는 최전방 전망대입니다.</p>
              <p>저희가 갔던 날은 사진에 보시다시피 비가 왔다 갔다 하고 안개가 껴서 멀리 보이지는 않았어요.</p>
              <p>하지만 망원경을 통해 건물로 추정되는 것들이 보여서 너무 신기했답니다.</p><br>
              <p>태풍전망대에 출입하기 위해서는 사전 예약을 신청해야 한답니다.</p>
              <p>전망대가 위치한 곳이 민통선에 위치해 있기 때문에 민통선 지역 출입승인 신청서를 내야 해요.</p>
              <p>태풍전망대 주차장으로 네비를 찍고 가다 보면 중간 검문소가 있습니다!</p>
              <p>여기서 미리 신청했던 방문자 기록을 확인하고 민통선과 태풍전망대 출입증을 두 개를 받습니다.</p>
              <p>이때 신분증을 보관해두었다가 이 출입증을 반납해야 신분증을 돌려받을 수 있어요!</p><br>
              <p>군사 지역이기 때문에 사진 찍는 것이 많이 엄격했어요.</p>
              <p>출입자 준수사항에 계속해서 지역 내에서 사진 촬영 금지를 언급하고 있었습니다.</p>
              <p>모든 출입 절차를 마치고 길을 따라 올라갑니다.</p>
              <p>꽤 구불구불한 길을 여러번 올라가다 보면 가는 길에 두루미 생태 관찰대가 있어요.</p>
              <img src="/images/blog/post36/post36_2.png">
              <p>임진강 쪽에는 겨울 철새인 두루미가 자주 찾아오는 곳이라서 두루미 테마파크도 있고 두루미 관련 조형물도 많이 있더라고요.</p>
              <img src="/images/blog/post36/post36_3.png">
              <p>어른용 아이용 눈높이에 맞추어 창을 낸 것 같았어요.</p>
              <p>창이 건너에는 임진강이 흐르고 있답니다. 여름이라 철이 아니라 볼 수 있는 건 없었어요...</p>
              <p>두루미를 보러온 건 아니니 빠르게 지나가 봅니다.</p>
              <p>이다음에는 연강 갤러리와 연천 카페테리아에 가 있었어요.</p>
              <p>입출입 절차가 까다로운 편인데 안에 카페와 갤러리가 있으니 신기했는데 들러보진 못했어요.</p>
              <p>올라가는 길에 중간에 바리케이트가 쳐져 있어서 당황했는데, 전화해보니 그냥 열고 올라오면 된다고 하더라고요.</p>
              <p>가는 길에 계속 지뢰주의 팻말들이 있고 철창이 있고, '이곳은 유엔 관리구역입니다' 안내판들을 보니 새삼 우리나라의 전쟁이 아직 끝나지 않았다는 사실이 다시 한 번 다가왔습니다.</p>
              <img src="/images/blog/post36/post36_4.png">
              <p>드디어 태풍전망대에 도착했습니다.</p>
              <img src="/images/blog/post36/post36_5.png">
              <p>UN사의 비석도 보입니다.</p>
              <img src="/images/blog/post36/post36_6.png">
              <p>가는 길에 돌담이 신기해서 찍어봤어요.</p>
              <p>다른 곳에서 보던 돌담길과는 다른 느낌이지 않나요? 차곡차곡 정리된 돌들...</p>
              <img src="/images/blog/post36/post36_7.png">
              <img src="/images/blog/post36/post36_8.png">
              <p>'태풍전망대는 리뷰도 별로 없고, 이렇게 절차가 까다로운데... 일반인들이 굳이 여길 오려나?' 생각했는데... 생각이 많이 바뀌었습니다.</p>
              <p>태풍전망대는 도착하면 북한 쪽으로 통유리창이 나 있습니다.</p>
              <p>마치 스크린을 바라보는 것처럼 말이죠. 북한 쪽을 바라보고 있기 때문에 이곳은 사진 촬영이 금지되어있었어요.</p>
              <p>근데 경관이 정말 너무 아름다웠어요. 임진강과 높지 않은 산들이 어우러져 있는 한 폭의 그림 그 자체였습니다.</p>
              <p>자리에 다 착석하면 군인이 관련된 역사나 사건들을 설명해줍니다.</p>
              <p>사실 그냥 방치할 줄 알았는데 여러 사건들을 이야기해주시니까 좋았었어요.</p>
              <img src="/images/blog/post36/post36_9.png">
              <p>태풍전망대 맞은편에는 국군들의 종교집회를 위한 교회, 성당, 성모상, 법당, 종각 등이 있고 6.25전쟁 전적비와 6.25참전 소년 전차병 기념비가 세워져 있습니다.</p>
              <img src="/images/blog/post36/post36_10.png">
              <p>북한을 바라볼 수 있는 망원경.</p>
              <img src="/images/blog/post36/post36_11.png">
              <p>이곳에는 불상이 있었는데요, 2층으로 한번 올라가 보았습니다.</p>
              <img src="/images/blog/post36/post36_12.png">
              <p>정말 일반 정자 같은 곳이 아니라 기둥부터 천장까지 빼곡히 다 채워져 있는 그림들을 볼 수 있었어요.</p>
              <img src="/images/blog/post36/post36_13.png">
              <p>새해에 한 번 타종을 했긴 했는데, 그 외에는 북한 측에서 도발로 여길 수 있기 때문에 타종이 금지되어있다고 합니다.</p>
              <p>사실 태풍전망대가 아프리카돼지열병과 코로나로 인해 문을 닫았고, 민간인에게 약 3년 만에 공개가 되었다고 합니다.</p>
              <p>장마철이라 비도 심하게 와서 출입이 통제되었었는데 저희가 딱 운 좋게 들어갈 수 있었어요.</p><br>
              <p>접근이 쉽지 않은 곳이라 조금 자세하게 써보았습니다.</p>
              <p>태풍전망대는 다들 기회가 된다면 대한민국 국민으로서 꼭 방문해야 하는 곳이라고 생각합니다.</p>
            `,
    createDate: 'Posted on July 13, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.906Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '35',
    title: 'IGIKOREA 상조회 첫 기부',
    content: `
              <img src="/images/blog/post35/post35_1.png">
              <p>IGIKOREA에는 사내 상조회를 운영하고 있는데요, 상조회를 통해서 직원들의 경조사비를 챙기거나, 코로나로 인한 재택근무 기간 동안 치킨파티, 사내 동호회 지원 등의 다양한 행사를 진행하곤 했습니다.</p>
              <p>이번 해에는 직원들과 상의하여 상조회비를 의미 있는 곳에 사용하고자 하였고, 기부를 하기로 결정했습니다.</p>
              <p>첫 기부 대상으로 “상록 보육원”을 선정하였습니다!!!</p>
              <p>상록보육원은 약 120여 명의 아이들이 있는 시설이며 저희 회사에서 비교적 가까워서 선정하게 되었습니다.</p>
              <p>기부를 하기 위해서는 상록원 홈페이지에 자세히 설명되어있기 때문에 참고하시면 될 것 같습니다.</p>
              <img src="/images/blog/post35/post35_2.png">
              <p>저희는 물품 후원과 아이들이 먹을 피자를 보내주기로 결정했고, 보육원에 확인 전화를 진행했습니다.</p>
              <p>전화하니 필요한 물품들을 알려주시더라고요, 안내받은 물품들과 파파존스 피자 30판을 보내주었습니다! 아이들이 맛있게 잘 먹었으면 좋겠네요!!</p>
              <p>물품 후원 외에도 다양한 방식의 후원 방법이 많았습니다.</p>
               <img src="/images/blog/post35/post35_3.png">
              <p>물품이 도착하면 보육원 측에서 확인 전화를 줍니다.</p>
              <p>그리고 상록보육원 사이트에 사진이 올라갑니다!</p>
              <p>상조회비로 맛있는 걸 먹고 좋은델 갈 수도 있었지만, 이렇게 사진 올라온걸 보니 내심 뿌듯하고 기분이 좋았습니다.</p>
              <p>앞으로도 상조회의 많은 활약을 기대해주세요!!!!!!!!!!! COMMING SOON!!!</p>
            `,
    createDate: 'Posted on July 6, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.832Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '34',
    title: '제1회 산타즈아 북악산 등산기',
    content: `
              <img src="/images/blog/post34/post34_9.png">
              <p>우리 회사 동호회 제1호 산타즈아가 지난 2022.06.25 등산을 다녀왔습니다!</p>
              <p>원래 파일럿으로 대표님과 함께 몇몇 멤버가 지난번에도 인왕산을 다녀왔지만, 이제 당당히 정식 동호회가 되었답니다!!</p>
              <p>저희의 코스는 다음과 같습니다!</p>
              <p>창의문안내소 - 돌고래쉼터 - 백악쉼터 - 백악마루(산정산) - 청운대쉼터 - 법흥사터 - 삼청안내소</p>
              <p>등산 소요 시간은 10시 40분에 등산해서 12시 30분에 하산했으니 1시간 50분 소요되었습니다.</p>
              <p>사실 1시간 30분 정도 코스인데 이날... 날씨 진짜 더웠습니다. 휴;;;</p>
              <p>등산하고 맛있는 것도 먹어야 하기 때문에, 저희는 일반 등산 코스와 달리 창의문으로 시작했습니다.</p>
              <p>끝나고 종로 쪽 가서 놀아야 하기 때문이죠.</p>
              <img src="/images/blog/post34/post34_2.png">
              <p>창의문안내소는 가는 길이 굉장히 헷갈려요.</p>
              <p>버스 타고 가면 바로 입구 앞에서 내려주지만 택시 타고 오면 뺑뺑 돌게 되니 참고하셔요.</p>
              <p>많은 분들이 모여계시죠??</p>
              <p>저희와 함께 등산을 타실 분들입니다.</p>
              <p>다른 동호회에서 오셨나 봐요.</p>
              <p>* 버스 타고 가면 바로 입구 앞에서 내려주지만 택시 타고 오면 뺑뺑 돌게되니 참고, 동상이 보이는 곳에서 샛길로 빠지면 다음과 같은 안내판이 나옵니다.</p>
              <img src="/images/blog/post34/post34_3.png">
              <p>이렇게 데크길로 올라가다 보면 창의문안내소가 나옵니다.</p>
              <img src="/images/blog/post34/post34_4.png">
              <p>등산 전에 한 컷 찍어주고 올라갑니다!</p>
              <p>일단 저희 멤버는 초보 등산러3, 중간 등산러 1, 고수 등산러2 이렇게 있었습니다.</p>
              <p>초보 등산러인 저는ㅠㅠ 물을 안 챙겨갔는데 다행히 실장님이 물을 챙겨와 주셔서 다행이었습니다.</p>
              <p>사진을 후다닥 찍고 사진 오른쪽에 보이는 계단으로 바로 올라가기 시작합니다.</p>
              <p>저희 코스는 빡세게 30분 등산해서 정상 찍고, 한 시간 동안 산 경치를 보면서 내려오는 것을 목표로 했는데요...</p>
              <p>생각보다 많이 경사가 있더라고요.</p>
              <p>헬스장에서 천국의 계단이라고 하던가요??</p>
              <p>계단이 정말 끝이 없다고 느껴졌어요... </p>
              <p>누가 초보 등산 코스 추천이라고 했나요ㅠㅠ</p>
              <img src="/images/blog/post34/post34_5.png">
              <p>오르면서 중간에 사진을 찍었습니다.</p>
              <p>이날 장마 오기 전이라서 정말 습해서 땀이 비 오듯이 쏟아졌습니다.</p>
              <img src="/images/blog/post34/post34_6.png">
              <p>ㅋㅋㅋ중간에 몇 번을 쉬다 갔는지...</p>
              <p>하지만 몰랐어요. 여기는 시작이었다는 것을... 
              <p>백악쉼터 - 백악마루 여기 경사가 굉장히 높아요. 힘들어서 사진도 못 찍었어요...</p>
              <img src="/images/blog/post34/post34_7.png"><img src="/images/blog/post34/post34_8.png">
              <p>지친 본부장님...</p>
              <p>본부장님 : 둘레길이라며...둘레길이라며...</p>
              <p>실장님 : 이 정도면 껌이죠~</p>
              <p>본부장님 : ...</p>
              <img src="/images/blog/post34/post34_9.png">
              <p>그래도 정상에 도착해서 같이 기념사진을 찍었습니다!!</p>
              <p>여기 울고 있는 표정의 두분은...</p> 
              <p>제가 둘레길이라고 전혀 어렵지 않다고ㅋㅋ</p>
              <p>초보 등산코스라고 해서 약간 속아서 오셨습니다.</p>
              <p>다음에는 장비를 하나둘씩 맞춰서 올 생각이에요~</p>
              <p>등산한 다음에 삼청공원으로 내려오니 너무 이쁜 한옥거리가 눈앞에 펼쳐졌어요.</p>
              <p>15분 정도 걸어서 오설록에 갔습니다.</p>
              <p>(사실 길을 헤메서 25분 정도 걸렸던 것 같아요.)</p>
             <img src="/images/blog/post34/post34_10.png"><img src="/images/blog/post34/post34_11.png">
              <p>요즘 오설록 찾아보기가 어려운데, 오설록이 너무 반가웠어요.</p>
              <p>녹차오프레도는 저희 직원분의 추천으로 처음 먹어 봤는데 너무 맛있더라고요.</p>
              <p>저희가 갈 때는 사람이 별로 없었었는데, 금방 사람이 차더라고요.</p>
              <p>이곳에서 땀을 식힌 후 고기를 먹으러 갔습니다.</p>
              <p>삼청동에서 광장시장까지는 도보 이동 50분이니......그날의 날씨와 컨디션을 보고 결정하시면 될 것 같습니다. 
              <p>저희는 도보로 이동했는데 날씨가 불가마였었어 이번 해 흘릴땀이란 땀은 다 흘린 것 같았어요ㅋㅋ</p>
              <p>같이 찜질방 왔다 생각하고 걷고 걷다 보니 도착한 백제고깃간<p></p>
              </p>
              <img src="/images/blog/post34/post34_12.png">
              <p>전문 등산인 포스가 나오는 우리 실장님ㅋㅋㅋ</p>
              <p>사실 광장시장과는 거리가 좀 됩니다.</p>
              <p>하지만 이곳은 무려 맛집을 꿰고 있는 사람들에게 여러 번 추천받은 고깃집입니다.</p>
              <p>너무 와보고 싶었는데 이번기회에 드디어 와보네요ㅎㅎ</p>
              <img src="/images/blog/post34/post34_13.png">
              <img src="/images/blog/post34/post34_14.png">
              <p>가게 내부는 이런 분위기입니다.</p>
              <p>다행히 단체로 와도 모여서 앉을 수 있습니다.</p>
              </p>
              <img src="/images/blog/post34/post34_15.png">
              <p>메뉴를 어떻게 시켜야 하나 잘 몰랐는데, 세트 메뉴 시키면 됩니다!</p>
              <p>세트 메뉴 시키면 차돌박이랑 등심 육회가 나옵니다.</p>
              <p>이 집은 차돌박이를 육회에 싸 먹는 거로 유명하거든요.</p>
              <p>3인기준 세트 메뉴 시켰을 때 딱 알맞게 먹었어요.</p>  
              <p>첨에 멋모르고 모듬 + 육회 반반 시키려 했는데 가게 직원분이 그렇게 시키면 비싸다고...</p>
              <p>근데 비싸다고만 하고 어떻게 시켜야할지 안 알려주셔서 주문할 때 애를 좀먹었어요.</p>
              <p>하지만 앞으로 이렇게 주문하면 돼요!! 모듬 주세요!!!</p> 
              <img src="/images/blog/post34/post34_16.png">
              <p>밑반찬이 깔리고 곧이어 고기가 육회가 나옵니다.</p>
              <img src="/images/blog/post34/post34_17.png">
              <p>육회는 진짜 맛있었습니다. 사실 특별한 맛이 나는 건 아니고 그냥 육회 맛인데...</p>
              <p>이 집은 차돌박이를 육회에 싸 먹으면서 그 맛이 배가 됩니다.</p>
              <p>뜨거운 고기 안에 차가운 고기, 저희가 나오자마자 너무 정신이 없어서 불판에 올려져 있는 고기 사진은 많이 없습니다...</p>
              <p>직접 가서 한 번 드셔 보세요!</p> 
              <img src="/images/blog/post34/post34_18.png">
              <p>성공적인 등산을 마무리하며 건배!!!</p>      
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.250953806893!2d126.98688555051346!3d37.572707931433854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2dd9fc3a073%3A0x6b3f0a2f2bea6b0f!2z67Cx7KCc6rOg6rmD6rCE!5e0!3m2!1sko!2skr!4v1656918465732!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            `,
    createDate: 'Posted on July 4, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.751Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '33',
    title: '(미국출장)수제 맥주가 유명한, Dogfish Head Alehouse',
    content: `
              <img src="/images/blog/post33/post33_1.png">
              <p>안녕하세요. 수제 맥주 좋아하시나요?</p>
              <p>우리나라에도 요즘은 수제 맥주를 만나볼 수 있는 곳이 많은데요. 미국에도 수제 맥주로 유명한 곳이 있어요.</p>
              <p>바로 Dogfish Head Alehouse라는 곳인데요.</p>
              <p>곱상어라는 뜻의 이곳은 수제 맥주로 유명한 곳으로 이곳 수제 맥주는 liquor store에서도 만나볼 수 있는 곳이에요.</p>
              <p>이곳 현지에 사시다가 한국으로 돌아가신 분들 중에서도 이곳 맥주를 그리워하는 분들이 많다고 하실 정도로 유명한 곳이에요.</p>
              <img src="/images/blog/post33/post33_2.png">
              <img src="/images/blog/post33/post33_3.png">
              <p>이날은 날씨가 너무 좋아서 저희는 테라스 쪽에 자리를 잡았어요.</p>
              <p>Dogfish Head Alehouse는 미국 출장팀한테는 의미 있는 곳이기도 한데요.</p>
              <p>바로 첫날 미국에 도착해서 첫 식사를 했던 곳이 이곳이기도 했어요.</p>
              <p>한국으로 떠나기 전 함께하는 마지막 식사도 이곳이라니 더욱 의미가 있었어요.</p>
              <p>자리에 앉으니 그날의 기억이 새록새록 떠올랐답니다.</p>
              <p>잠시 후에 담당 서버가 자신의 이름을 이야기하며 음료부터 주문을 받았어요. (미국에선 대부분 음료부터 주문을 해요.)</p>
              <p>지난번에 왔을 때 감명받았던 Dogfish head 90 Minute IPA를 먼저 주문했어요.</p>
              <img src="/images/blog/post33/post33_4.png">
              <img src="/images/blog/post33/post33_5.png>
              <p>향긋한 꽃향기가 일품인 이 맥주는 지난번에 처음 마셔보고 감명받았던 맥주기도 해요.</p>
              <p>약간 도수가 있긴 하지만 향이 좋아, 한 번, 맛이 좋아 한 번 더 찾게 되는 맥주인 것 같아요.</p>
              <p>처음에 왔을 때 저는 샘플러를 마셨는데, 이곳에서 만드는 여러 가지 맥주 맛을 보고 싶다면 샘플러도 추천드려요.</p>
              <img src="/images/blog/post33/post33_6.png">
              <img src="/images/blog/post33/post33_7.png>
              <p>샘플러는 하나의 메뉴에 Dogfish Head Alehouse의 여러 가지 다양한 맥주를 맛볼 수 있어요.</p>
              <p>이걸 마셔보고 다음에 마음에 드는 맥주 한 가지를 정하는 것도 방법이랍니다.</p>
              <p>맥주가 나오기 전에 메뉴들을 모두 정했는데요.</p>
              <img src="/images/blog/post33/post33_8.png">
              <img src="/images/blog/post33/post33_9.png">
              <img src="/images/blog/post33/post33_10.png">
              <img src="/images/blog/post33/post33_11.png>
              <p>다양한 메뉴들 중에 어떤 음식을 먹어야 할지 고민하다가, 최후의 만찬을 먹는다는 생각으로 꼭 먹고 싶었던 음식을 시켰어요.</p>
              <img src="/images/blog/post33/post33_12.png">
              <p>처음으로 나온 요리는 Tomato Bisque라는 수프인데요.</p>
              <p>주말 스페셜 메뉴 중에 하나로 토마토의 감칠맛이 일품인 수프 따뜻하게 속을 데워줬어요.</p>
              <img src="/images/blog/post33/post33_13.png">
              <p>피시앤칩스는 영국의 대표 음식으로 유명하기도 하지만 Dogfish Head Alehouse에서도 빼놓을 수 없는 대표 음식이에요.</p>
              <p>저 순살 생선튀김이 고소하고 담백해서 맥주 안주로 딱 맞았답니다.</p>
              <img src="/images/blog/post33/post33_14.png">
              <p>이건 꼭 시켜야 한다며 주문한 잠발라야.</p>
              <p>아프리카 음식이 변형된 음식인데 여러 가지 향신료와 쌀 그리고 닭고기와 해산물이 어우러져 있어요.</p>
              <p>쌀과 면 중에 선택할 수 있는데 저희는 한국인이라서 쌀로 선택했답니다.</p>
              <p>맛은 이국적이면서도 한국인 입맛에 딱이라 손이 멈추지 않는 맛이에요.</p>
              <p>외국에서 밥을 꼭 먹고 싶을 때 한식은 없고 대체 음식이 필요하다면 추천하는 메뉴에요.</p>
              <img src="/images/blog/post33/post33_15.png">
              <img src="/images/blog/post33/post33_16.png>
              <p>여기까지 와서 고기는 하나 뜯어줘야 한다며 주문한 Alehouse Smoked Ribs,</p>
              <p>립도 맛있지만, 미국에 와서 느낀 건 코우슬로가 참 새삼 맛있다는 것을 느껴요.</p>
              <p>느끼한 맛을 싹 잡아줘서 김치처럼 저절로 찾게 된답니다.</p>
              <img src="/images/blog/post33/post33_17.png">
              <p>느끼함을 잡아주는데 꼭 필요한 치킨 샐러드. 이상하게 미국에 와서 자꾸만 샐러드를 찾게 되더라고요.</p>
              <p>치킨 샐러드의 치킨보다 견과류가 정말 맛있었어요.</p>
              <p>도그피시 오시면 한번 꼭 드셔 보세요.</p>
              <img src="/images/blog/post33/post33_18.png">
              <p>메뉴는 인원수만큼 시키는 게 도리라며 주문한 스테이크 샌드위치,</p>
              <p>지난번에 왔을 때는 주문이 안 된다고 해서 아쉬웠는데 이번에는 맛볼 수 있었어요.</p>
              <p>한입에 먹기에는 너무 어마어마하게 크답니다.</p>
              <img src="/images/blog/post33/post33_19.png">
              <p>메인 메뉴를 먹었다면 디저트를 먹어주는 게 당연한 수순이겠죠?</p>
              <p>따뜻한 브라우니에 아이스크림은 진리에요~</p>
              <p>입안 가득한 진한 초콜릿과 아이스크림이 입안에서 어우러져서 달콤함에 정신이 혼미해질 정도예요.</p>
              <p>한 끼의 마무리로 이만한 것도 없겠죠?</p>
              <p>맥주에서 디저트까지 완벽한 도그피쉬</p>
              <p>미국에 정서를 느끼면서 너무 느끼하지 않고 짜지 않은 음식을 먹고 싶다면 도그피시 강추랍니다.</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.442023819608!2d-77.21912708423616!3d39.14192687953367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b62d3d3b2b78af%3A0x7f0377a56ebaea25!2sDogfish%20Head%20Alehouse!5e0!3m2!1sko!2skr!4v1655798589485!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p>대표번호 : +1 301-963-4847</p>
              <p>위치 : 800 W Diamond Ave, Gaithersburg, MD 20878 미국</p> 
              `,
    createDate: 'Posted on June 22, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.726Z',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '32',
    title: "LG전자 창원 'LG 스마트파크', WEF 등대공장 선정",
    content: `<img src="/images/blog/post32/post32_1.png">
              <p><strong>[연합뉴스]</strong></p>
              </br>
              <p>'인공지능(AI)과 빅데이터, 시뮬레이션 기술인 디지털 트윈을 결합해 LG전자가 자체 개발한 시스템으로, '버추얼 팩토리'를 통해 냉장고 생산, 부품 이동과 재고 현황 등 실제 공장의 가동 상황을 한눈에 확인할 수 있다.'</p>
               </br>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3xYV4mh" target="_blank">link: https://bit.ly/3xYV4mh</a>

        `,
    createDate: 'Posted on June 17, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.852Z',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '31',
    title: '(미국출장) Cesium 디너 미팅 참석',
    content: `
              <img src="/images/blog/post31/post31_0.png">
              <p>이번에는 필라델피아에서 열린 Cesium 디너 미팅에 참석했습니다.</p>
              <p>Cesium은 3D 지도 공간을 다루는 오픈소스 기반 소프트웨어 프레임워크입니다.</p>
              <p>이번 디너 미팅의 주제는 메타버스의 주요 구성 요소와 메타버스가 앞으로 나아가야 할 방향이었습니다.</p> 
              <p>발표는 3D 엔지니어링과 에코시스템 분야의 이사로 역임하고 있는 Shehzan Mohammed가 맡았습니다.</p>
              <p>Shehzan은 3D 그래픽 엔지니어로서 Cesium 제품과 런타임 엔진 개발에 참여하는 핵심 구성원입니다.</p>
              <p>또한 Cesium과 같은 지리 공간 어플리케이션에 대한 공개 표준을 발전시키기 위해 OGC(Open Geospatial Consortium)에서 3D 지리 공간 기술에 대한 기고가이자 연사로 크게 활동하고 있습니다.</p>
              <img src="/images/blog/post31/post31_1.png">
              <img src="/images/blog/post31/post31_2.png">
              <p>미팅 장소는 필라델피아 시청이 있는 도시의 중심가였습니다.</p>
              <p>미국에서 좀처럼 보기 힘든 고층 빌딩도 여럿 보이고 주변에는 공원도 있었습니다.</p> 
              <p>비가 온 직후이고 평일이어서 주변에 사람이 그렇게 많지는 않았지만 주말이었다면 관광객들로 엄청 북적이겠다 싶었습니다.</p>
              <img src="/images/blog/post31/post31_3.png">
              <p>그렇게 도착한 곳은 ‘Maggiano’s Little Italy’라는 음식점이었습니다.</p>
              <p>이탈리안-아메리칸 요리를 전문으로 하는 아주 큰 음식점으로, 안쪽에 들어가 보니 별도로 큰 방이 하나 있었습니다.</p>
              <p>이번 디너 미팅처럼 식사하면서 미팅을 하거나 결혼식장 같은 용도로 쓰인다고 합니다.</p>
              <img src="/images/blog/post31/post31_4.png">
              <img src="/images/blog/post31/post31_5.png">
              <p>미팅 장소인 필라델피아는 제가 있던 메릴랜드에서 오기엔 거리가 꽤 멀어서 일찍 출발했더니 제가 첫 번째로 도착한 손님이 되었습니다.</p>
              <p>이렇게 발표를 보면서 각자 자리에 앉아 음식을 먹거나 대화를 나누며 진행되는 미팅이었습니다.</p>
              <p>저도 한 곳에 자리를 잡고 차례차례 도착하는 손님들과 인사를 나누며 발표를 기다렸습니다.</p>
              <p>발표가 진행되면서 다양한 에피타이저나 요리가 나와서 각자 접시에 덜어서 먹으며 발표를 들었습니다.</p>
              <img src="/images/blog/post31/post31_6.png">
              <p>이렇게 한 켠에는 음료를 제공하는 작은 바가 준비되어 있었습니다.</p>
              <p>바텐더에게 요청하면 와인이나 간단한 칵테일, 커피 등을 받을 수 있었습니다.</p>
              <p>각자 음료를 들고 서서 잡담을 나누는 것이 미국의 문화라고 합니다.</p>
              <img src="/images/blog/post31/post31_7.png">
              <p>음식을 먹으며 발표를 듣다 보니 어느덧 발표가 끝났습니다.</p>
              <p>발표는 크게 Cesium에 대해 소개하는 전반부와 메타버스에 대해 이야기하는 후반부로 나뉘었습니다.</p>
              <p>전반부에서는 Cesium을 적용한 여러 데모를 소개했습니다. 웹, 모바일, VR/AR 등 다양한 기기에 적용된 것과 관광지, 산업 현장, 도심지와 같이 다양한 환경을 대상으로 한 예제를 보니 점점 메타버스와 Cesium에 대한 개념이 머릿속에 그려졌습니다.</p>
              <p>후반부에는 전반부에서 간단히 언급한 내용들을 확장하여 메타버스에 대해 심도 있게 논의를 진행했습니다.</p>
              <p>Shehzan은 여러 요소들 중 개방성과 보안을 가장 강조했습니다.</p>
              <p>특히 Cesium이 메타버스의 상호운용성을 위해 어떤 표준을 지지하느냐는 질문에 특정 표준을 선정해서 지지하는 것보다는 어떤 표준이나 기술이던 그것들을 끊김 없이 매끄럽게 서비스할 수 있느냐가 더 중요하다는 답변을 통해 Cesium의 핵심 아이디어를 파악할 수 있었습니다.</p>
              <p>Cesium의 개방성에 대한 인식은 개방형 스펙인 Cesium 3D Tiles에서 잘 드러납니다.</p>
              <p>3D Tiles는 건물, 나무, 포인트 클라우드 및 벡터 데이터를 포함한 3D 콘텐츠를 스트리밍하는 효율적인 방식을 제공합니다.</p>
              <p>이러한 3D 콘텐츠는 다양한 표준 형식을 지원하며, 그 중에는 Khronos에서 개발한 WebGL 런타임 에셋 포맷인 glTF도 포함됩니다.</p>
              <img src="/images/blog/post31/post31_8.png">
              <p>이번 디너 미팅은 Shehzan의 훌륭한 발표를 들을 수 있는 유익한 자리였습니다.</p>
              <p>뿐만 아니라 맛있는 음식을 먹을 수 있었던 점과 디지털 트윈 및 메타버스에 관심 있는 다양한 사람들과 만나볼 수 있었던 점도 매우 만족스러웠습니다.</p>
            `,
    createDate: 'Posted on June 16, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.748Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: 'interview_5',
    title: '직원 인터뷰 - (4)',
    content: `<img src="/images/blog/interview/employee_4.png">
              <p><strong>김재하 팀장</strong></p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>자기 소개를 해주세요.</strong></p>
              <p><br/><p>
              <p>안녕하세요. IGI Korea 솔루션 본부에서 팀장으로 근무 중인 김재하입니다.</p>
              <p>웹디자이너로 일하고 있으며, 솔루션에 관련된 디자인 업무를 맡고 있습니다.</p>
              <p>신입으로 들어와 조금씩 경력이 쌓이며 퍼블리싱의 필요성을 느꼈고, 회사에 다니면서 조금씩 공부했습니다.</p>
              <p>현재는 솔루션에 관한 디자인 &amp; 퍼블리싱 업무를 맡고 있으며, 지금은 개발 분야도 공부하고 있습니다.</p>
              <p>프로젝트 안에서 내가 할 수 있는 부분이 많아지는 것이 재밌어서 계속 공부하며 회사에 다니고 있습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 어떻게 지원하게 되었는지?</strong></p>
              <p><br/><p>
              <p>사실 처음 듣는 회사인데다 에이전시에 들어가고 싶었던 제게 입사하고 싶은 1순위 회사는 분명 아니었습니다.</p>
              <p>공고에 나와 있는 업무를 보고 조금 궁금해져 이 회사에 대해서 찾아봤지만, 회사 정보도 많이 없고, 홈페이지는 모두 영어로 되어 있어 어떤 회사인지 정확히 파악하기 어려웠었던 기억이 납니다.</p>
              <p>다만 평소에 관심이 있었던 대시보드에 관련된 업무들을 할 수 있었다는 점, 솔루션 기업이라면 지금의 내가 이 솔루션에 많은 기여를 할 수 있지 않을까? 라는 생각이 들어 지원해보자는 마음을 먹게 되었습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사를 결심한 이유는?</strong></p>
              <p><br/><p>
              <p>가장 큰 이유는 면접이라고 생각합니다.</p>
              <p>면접은 구직자, 구인자 모두에게 첫인상인데 저는 그 첫 모습이 굉장히 인상적이었습니다.</p>
              <p>보통 면접은 구직자가 회사에 대해 많은 정보를 알기가 쉽지 않은데, 구직자도 이 회사에 대해 파악하고 판단할 수 있도록 충분히 소개를 받았고, 서로 소통하는 면접이었던 것이 기억에 남습니다.</p>
              <p>저는 입사 테스트를 봤었는데, 사실 굉장히 어려웠습니다.</p>
              <p>(😊) 누가 보면 테스트까지 보냐라고 할 수 있겠지만, 이러한 면접 과정들을 통해 이 회사는 채용에 신중을 기한다는 것이 느껴졌고, 이런 곳이라면 사람을 쉽게 생각하는 곳이 아니구나 라는 것을 느껴 입사를 결정하게 되었습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에서 근무하며 좋은 점은?</strong></p>
              <p><br/><p>
              <p>제일 좋은 점을 꼽자면 사람이라고 생각합니다.</p>
              <p>직원들이 많아지며 안 맞는 사람들도 생기기 마련인데 모두가 배려하면서 다 사이좋게 지낸다는 게 쉽지 않다고 생각합니다.</p>
              <p>그 부분이 근무하며 제일 좋았고, 둘째로는 욕심을 낼 수 있는 회사라는 점입니다.</p> 
              <p>스스로 하고자 하는 욕심이 있다면 스스로를 충분히 발전시킬 수 있는 환경을 가진 회사라는 것이 좋았습니다.</p>
              <p>마지막은 소통입니다. 직급에 상관없이 누구나 의견을 개진할 수 있습니다.</p>
              <p>내가 팀장이든 사원이든 서로 자유롭게 피드백하고 소통하는 환경이 좋습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에서 근무하며 아쉬운 점은?</strong></p>
              <p><br/><p>
              <p>스스로를 발전시키기에 정말 좋은 회사입니다.</p>
              <p>다만 때때로 막중한 책임감이 스스로를 짓누를 때가 있습니다.</p>
              <p>누군가에겐 크게 성장할 수 있는 발판이 될 수도 있지만 또 다른 누군가에게는 굉장히 힘든 일이 될 수도 있다는 점이 아쉽습니다.</p>
              <p>하지만 이곳이 아닌 어느 곳도 쉽지 않은 환경일 것이고, 힘든 경우에는 방치하지 않고, 서로 도와 문제를 해결해 나가며 다 같이 성장하고 있습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사하고 싶은 후배들에게 해주고 싶은 말은?</strong></p>
              <p><br/><p>
              <p>보통 회사가 커지는 것이 눈에 보이기 쉽지 않은데, 어느새 눈에 띄도록 쑥쑥 커지는 회사를 보면서 나도 이 회사에 기여를 하고 있구나, 성취감을 느끼며 일하고 있습니다.</p>
              <p>모든 일을 함에 있어서 동기부여라는 것은 참 중요하다고 생각합니다.</p>
              <p>조연이 아닌 주연으로써 회사와 함께 스스로 성장하는 것을 눈으로 직접 확인하실 수 있습니다.</p>
              <p>짧고 굵게 일하는 것이 아닌 길고 굵게 일하고 싶다면 지금 도전하세요.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 이루고 싶은 꿈은?</strong></p>
              <p><br/><p>
              <p>IGI Korea에 입사하기 전 저는 공무원 같은 삶을 꿈꿨습니다.</p>
              <p>그런 제가 몇 가지 욕심이 생겼는데, 그중 하나는 경제적으로 풍요로운 삶이고, 하나는 “디발자” 라고 하죠.</p>
              <p>필요에 따라 디자이너도 될 수 있고, 개발자도 될 수 있는 것이 제가 꿈꾸는 모습입니다.</p>
              <p>내가 배운 디자인만 하면서 적당히 살고 싶었던 제가 개발이라는 것에도 욕심을 가지게 되었습니다.</p>
              <p>내 분야에서 더 전문적인 사람이 되는 것도 하나의 방법이지만, 프로세스 안에서 더 다양한 능력을 발휘할 수 있는 모습이 좀 더 제가 꿈꾸는 모습과 가까운 것 같습니다.</p>      
            </p>
            `,
    createDate: 'Posted on June 15, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.822Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: 'interview_4',
    title: '직원 인터뷰 - (3)',
    content: `<img src="/images/blog/interview/employee_3.png">
              <p><strong>이솔 프로</strong></p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>자기 소개를 해주세요.</strong></p>
              <p><br/><p>
              <p>솔루션 개발본부 이솔 프로입니다.</p>
              <p>현재 입사한 지 7개월이 된 신입 개발자이며, 웹 개발 및 유지보수 업무를 담당하고 있습니다.</p>
              <p>솔루션 개발본부는 개발 단계에서는 디자인 본부에서 3D 디자인과 2D 디자인을 요구사항에 맞추어 완성하여 전달해 주시면 요구사항에 맞는 기능을 구현하며, 유지보수 단계에서는 시스템에서 문제가 발생하면 기술지원을 하는 업무를 하고 있습니다.</p>
              <p>이처럼 2D 개발이 아닌 3D 개발이기 때문에 기존에 생각하지 못하였던 이슈가 발생할 수 있기 때문에 끊임없이 효율적인 구현과 디자인 팀 분들과 지속해서 소통해야 합니다.</p>
              <p>간략하게 저를 소개하자면 원래의 전공은 자동차공학으로 어떻게 보면 웹 개발과는 관련이 없는 전공을 했지만, 웹 개발에 흥미를 느끼게 되었고, IT 분야의 발전성을 고려하여 웹 개발자의 길을 선택하게 되었습니다.</p>
              <p>이러한 길을 택한 것을 IGI Korea 입사하고 업무를 진행하며, 후회없는 선택을 했다고 다시 한번 생각하게 되었습니다.</p>
              <p>아직 스스로 아직 많이 부족한 개발자라고 생각하며 앞으로 입사하실 분들과 함께 발전하며 더 나은 개발자가 되고 싶습니다.</p>
              <p>성격은 남들에게 먼저 다가가는 유연한 성격을 가지고 있다고 생각합니다.</p>
              <p>본사의 구성원으로서 함께 하게 될 분들에게 먼저 다가가 적응할 수 있도록 도움을 드리겠습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 어떻게 지원하게 되었는지?</strong></p>
              <p><br/><p>
              <p>=IGI Korea에 2021년 10월에 입사했고, 아무것도 모르는 주니어 개발자로서 일을 시작했습니다.</p>
              <p>저 같은 경우는 두 번째 도전 만에 IGI Korea의 구성원이 될 수 있었습니다.</p>
              <p>첫 번째 입사 지원했을 때 채용사이트에서 나타난 채용공고를 본 후 회사 홈페이지를 통해 기본적인 정보를 접했고, 기존에 알고 있던 웹 개발과 다르다고 느낀 부분이 존재해 일하고 싶다는 생각이 들어 지원하게 되었습니다.</p>
              <p>첫 번째 면접에서는 준비가 부족하여 떨어지게 되었습니다.</p>
              <p>다시 한번 지원하게 된 이유는 면접 시 대표님께서 직접 소개해주시는 IGI Korea는 어떤 회사이고, 무엇을 하는 회사인가에 대하여 설명해 주실 때 꼭 입사하고 싶다는 생각이 들어 다시 한번 지원하게 되었습니다.</p>
              <p>두 번째 지원하였을 때는 부족했던 부분을 좀 더 보강하여 면접을 통해 IGI Korea의 구성원이 될 수 있었습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사를 결심한 이유는?</strong></p>
              <p><br/><p>
              <p>최종 합격을 받은 후에 고민 없이 입사 결정을 내렸습니다.</p>
              <p>이러한 결정을 내린 것에는 세 가지 이유가 존재하였습니다.</p>
              <p>첫 번째, 면접 시 대표님께서 말씀해주신 IGI Korea라는 회사의 강점과 발전 가능성을 설명해주시고 어떠한 서비스를 제공하는지 알려 주셨을 때 개발자가 되면서 만들고 싶은 막연한 만들어 보고 싶던 웹 서비스와 연관성이 존재하였기 때문입니다.</p>
              <p>두 번째로 웹 개발이라면 단순 2D 사이트를 만드는 것인 줄 알고 있었습니다.</p>
              <p>하지만 IGI Korea는 단순 2D 웹 개발이 아닌 3D 웹 개발을 한다는 것이 색달랐습니다.</p>
              <p>이러한 웹 개발을 한다면 새로운 도전이자 많은 발전을 이룰 기회라는 생각이 들었습니다.</p>
              <p>마지막 세 번째로는 다양한 복지 혜택이 존재하는 것이 마음에 들었습니다.</p>
              <p>복지 혜택이 존재한다는 것은 직원들을 생각하고 회사가 얻은 성과를 직원들과 공유하겠다는 임원진분들의 생각이 있다는 것으로 생각이 들어 입사를 결정하게 된 이유 중 하나였습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 좋은 점은?</strong></p>
              <p><br/><p>
              <p>IGI Korea에서 근무하면서 느낀 IGI Korea만의 장점이 있는데요 가장 좋다는 느낀 부분을 설명해 드리겠습니다.</p>
              <p>첫 번째로는 무엇보다 함께 근무하는 사람들이 좋다는 것입니다.</p>
              <p>IGI Korea의 구성원분들은 인격적으로도 훌륭하지만, 업무적으로 배울 것이 아주 많은 분이라고 느끼고 있습니다.</p>
              <p>어떤 부서나 나태하지 않고 만족하지 않으며, 더 좋은 결과물을 얻어내기 위해 발전하기 위해 노력하고, 끊임없이 문제 해결하기 위해 노력하고 있습니다.</p>
              <p>이러한 부분에서 동기부여가 되어 끊임없이 발전하고 좋은 성과를 지속해서 얻고 싶다는 생각을 하게 되었습니다.</p>
              <p>두 번째로는 임원진분들 직원들을 생각하여 다양한 복지혜택을 만들어 두신 것입니다.</p>
              <p>세 번째로는 개발자로서 새로운 개발을 할 수 있다는 것입니다.</p>
              <p>2D뿐만 아닌 3D 웹 개발을 하며 다양한 개발영역을 접해볼 수 있고 이러한 업무를 통해 스스로 발전해 나가는 것을 느낄 수 있다는 게 느낀 좋은 점 중 하나입니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에서 근무하며 아쉬운 점은?</strong></p>
              <p><br/><p>
              <p>근무하면서 느낀 불편하거나 아쉬운 점이 없이 만족하면서 다니고 있지만, 굳이 꼽자면 두 가지 정도가 있다고 생각합니다.</p>
              <p>첫 번째는 IGI Korea는 짧은 시간에 많은 발전을 이루었고 앞으로도 계속해서 발전해 나갈 것이기 때문에 회사의 업무의 양이 다른 회사의 양보다 많다고 느껴지실 수 있습니다.</p>
              <p>이러한 업무의 강도가 초기에는 힘이 들 수도 있다고 생각합니다.</p>
              <p>하지만 이러한 업무 속에서 짧은 시간이지만 많은 발전을 했다고 생각합니다.</p>
              <p>이러한 시간이 자신을 발전시킬 기회라고 생각하시면 좋을 것 같습니다.</p>
              <p>두 번째로는 아무래도 창립된 지 얼마 되지 않아 중간 역할을 해줄 수 있는 분들이 적은 게 아쉬운 점 중 하나입니다.</p>
              <p>중간 역할은 하실 수 있는 분이 많이 없어 차이가 나는 상사에게 직접 보고해야 하는 경우나 업무에 관하여 문의를 드릴 때 어려움이 있다고 생각합니다.</p>
              <p>이러한 문제는 현재 계신 분들과 함께 성장해 나간다면 중간 역할을 할 수 있는 많은 분이 생기실 거로 생각하기 때문에 시간이 해결해줄 문제라고 생각합니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사하고 싶은 후배들에게 해주고 싶은 말은?</strong></p>
              <p><br/><p>
              <p>많은 개발자분이 백엔드 개발자나 프론트엔드 개발자를 나눠서 생각하시고 있지만 IGI Korea에서는 백엔드와 프론트엔드를 모두 다룰 줄 알아야 하므로 초기에는 두 가지를 하기에는 어려움 있을 수 있다고 생각합니다.</p>
              <p>하지만 이러한 부분은 개발자라는 직업을 선택하였기 때문에 스스로 극복해야 한다고 생각하고 있습니다.</p>
              <p>프론트엔드와 백엔드에 관하여 구분하지 않고 전반적으로 학습하고 함께 발전해 나갔으면 좋겠습니다.</p>
              <p>프로젝트를 진행할 시 다양한 부서의 인원들이 업무를 함께 진행하므로 의사소통이 원활하게 이루어지도록 노력해야 합니다.</p>
              <p>아직 저도 의사소통을 할 때 개발 본부가 아닌 다른 부서의 분들에게 전하고자 하는 말을 정리해도 의미를 전달하기에는 어려움이 있습니다.</p>
              <p>이러한 부분은 프로젝트를 진행해가시면 느낄 수 있으실 텐데 조금씩 개발자가 아닌 분들에게 의미를 전달하는 연습을 함께해 나갔으면 합니다.</p>
              <p>마지막으로 IGI Korea를 함께 발전시키고 이끌어 나갈 동료로 잘 지냈으면 좋겠습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 이루고 싶은 꿈은?</strong></p>
              <p><br/><p>
              <p>IGI Korea에 입사한 후, 예상했던 부분보다 다양한 개발을 할 수 있었습니다.</p>
              <p>초기에는 처음으로 사용하는 기술이나 언어를 이용하여 개발해야 하는 상황이 힘들고 어려웠지만 스스로 학습하고 동료분들의 도움이 있어 성장해 나며 성장을 할 수 있었다고 생각합니다.</p>
              <p>첫 번째 꿈은 이처럼 많은 동료분이 도움을 주신 것만큼 저 또한 새롭게 입사하신 분들의 적응을 도움을 드리면서 회사와 함께 성장하는 것입니다.</p>
              <p>두 번째는 회사와 맞는 서비스를 직접 제안하고 필요한 기술을 정리하여 제안할 수 있도록 개발의 모든 분야에 지식을 확립하는 것입니다.</p>
              <p>이러한 제안을 한다는 것은 회사의 작은 부분까지 파악하고 기술적인 지식 및 개발 능력도 많이 필요하다고 생각합니다.</p>
              <p>많은 발전이 필요하고 지속해서 스스로 많은 부분을 학습해야 한다고 생각합니다.</p>
              <p>마지막 꿈은 IGI Korea는 끊임없이 발전 중인 회사라고 생각합니다.</p>
              <p>전 세계 사람들이 IGI Korea가 어떤 회사인지 알고 있고 함께 일하고 싶은 회사가 되는 날까지 스스로 끊임없이 발전하는 개발자가 되고 싶습니다.</p>`,
    createDate: 'Posted on June 8, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.819Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: 'interview_3',
    title: '직원 인터뷰 - (2)',
    content: `<img src="/images/blog/interview/employee_2.png">
              <p><strong>이세웅 프로</strong></p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>자기 소개를 해주세요.</strong></p>
              <p><br/><p>
              <p>저는 솔루션 개발본부에 신입으로 입사하여 개발본부에서 디지털 트윈 솔루션 개발 업무를 하는 이세웅입니다.</p>
              <p>사실 저는 코딩이나 개발에 전혀 관심도 없었고 지식도 전혀 없었어요.</p>
              <p>기존에 조리학과를 졸업해서 요리와 카페에서 일을 했었는데 적성에 안 맞더라고요.</p>
              <p>그래서 평소 관심이 있던 그래픽 작업을 배워 MD로 오래 일해 왔어요.</p>
              <p>5년 정도 MD 직무를 하며 오프라인과 온라인을 전체적으로 기획 및 관리를 했고, 브랜드와 인사 관리를 겸해왔어요.</p>
              <p>오랜 시간 직무를 수행하며 처음과 다르게 성취감도 줄어들고, 개인과 직무적 성장에 한계를 느꼈고, 코로나를 겪으면서 직업에 대해 많은 생각을 하는 계기가 되었어요.</p>
              <p>그래서 더 늦기 전에 새로운 진로를 정해야겠다고 생각했고 취업 성공 패키지를 통해 개발을 배우게 됐어요.</p>
              <p>개발을 배우게 된 데는 큰 계기는 없었어요.</p>
              <p>고민하며 주변 친구들의 직업에 대해 듣던 중 개발자 친구의 말이 결정적이었어요.</p>
              <p>"개발, 어렵게 생각하지 말고 그냥 외국인이랑 얘기하는 것처럼 말하는 언어를 배우는 거야".</p>
              <p>그 말 하나로 흥미가 생겼고 다행히 재미를 느끼며 지금도 후회 없이 일하고 있어요.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 어떻게 지원하게 되었는지?</strong></p>
              <p><br/><p>
              <p>지원 계기는 채용공고와 홈페이지 때문이었어요.</p>
              <p>처음엔 웹사이트에서 개발자 구인 공고를 찾고 있었는데 IGI Korea 공고를 보게 됐고요.</p>
              <p>우선 깔끔한 구인 공고가 눈에 띄더라고요.</p>
              <p>보통은 딱딱한 느낌의 공고가 대부분인데 깔끔한 디자인으로 정성들여 만든 공고라고 느꼈어요.</p>
              <p>그리고 다양한 복지에도 관심이 생기더군요.</p>
              <p>유연 출퇴근제나 커피데이 등에서 자유로운 분위기일 거라고 생각했고, 올해의 직원이나 PS로 직원들의 성과를 소홀히 하지 않는다는 느낌을 받았어요.</p> 
              <p>그리고 학업적 지원에서 결정적이었죠.</p>
              <p>전문대학 졸인 저는 학업적으로 좀 더 생각이 있었는데 대학 등록금 지원이나 자격증 등의 지원 및 보너스가 엄청 긍정적으로 다가왔고 직원들의 미래도 생각한다는 인상을 받았어요.</p>
              <p>물론 중식 지원에도 상당한 메리트를 느꼈고요.</p>
              <p>홈페이지도 깔끔하지만, 포인트를 살린 디자인과 영문으로 작성되어 외국계 기업 같다는 인상을 주었고요./p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사를 결심한 이유는?</strong></p>
              <p><br/><p>
              <p>면접을 보면서 확신했어요.</p>
              <p>저도 많은 면접을 봤지만 IGI Korea처럼 친절하게 회사 소개를 해주는 곳도 드물었고, 면접 자체에도 굉장히 자세하게 본다고 느꼈어요.</p>
              <p>회사 소개를 해주시며 솔루션과 진행했던 프로젝트를 말씀해주시면서 보여주셨는데, 3D로 만든 건물의 모습과 그 건물을 돌아다니거나 데이터를 보여주는 기능들에 바로 압도되는 느낌이었어요.</p>
              <p>그리고 그런 작업을 제가 할 수도 있다는 생각에 엄청 흥미롭고 재밌을 거 같았어요.</p>
              <p>학원에서나 일반적인 회사에서 만드는 작업이 아닌 유니크한 특성이 굉장히 매력적이었다고 생각해요.</p>
              <p>그리고 제가 게임이나 3D 애니메이션도 좋아하다 보니 더욱 흥미로웠고요.</p>
              <p>이후의 면접에서도 기본적인 개발자로서의 마음가짐을 물어보는 부분도 인상적이었어요.</p>
              <p>개발자로서 좋은 코드가 무엇인지 고민하는가, 또는 협업에 대해서도 질문을 세심하게 주는 면접은 처음이었고요.</p>
              <p>기술 면접에서도 전문적인 질문들로 공부하지 않았다면 몰랐을 질문을 주셨어요.</p>
              <p>질문을 받으면서 어려운 점도 있지만 이런 내용을 다 지나온 개발자들이 모여있다면 많이 배울 기회라고 생각이 들더라고요.</p> 
              <p>그래서 위 내용을 종합하여 입사를 하고 싶다고 확신했어요.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 좋은 점은?</strong></p>
              <p><br/><p>
              <p>여러 가지가 있겠지만 크게 성장과 동료라고 생각되네요.</p>
              <p>우선 성장의 경우 업무적으로 다양한 경험을 지원해 줍니다.</p>
              <p>항상 하는 작업이 아닌 못 해봤던, 그리고 하고 싶다면 얼마든지 기회를 주고 지원해주는 문화로 업무 경험을 쌓게 됩니다.</p>
              <p>저같이 거의 모든 게 신입사원에게는 모든 경험이 다 기회이니까요.</p>
              <p>그리고 학업에 대한 지원도 아끼지 않아 성장지원금 시스템을 통해 개인 성장의 기회도 노릴 수 있고요.</p>
              <p>방통대 등의 지원뿐만 아니라, 성장 미션을 통해서 자격증 등의 공부를 이어 나갈 수 있게 해주는 계기가 되어주었어요.</p> 
              <p>혼자 했더라면 미루고 포기했을 수도 있지만 미션에 대한 동기부여가 확실해서 쉽게 포기할 수가 없더라고요.</p>
              <p>그리고 좋은 동료들과 분위기도 좋아요.</p>
              <p>흔히 말하는 월급루팡이 없는 회사는 처음 보는 것 같고 각자의 업무에 굉장한 책임감을 가지고 임하고 있고요.</p> 
              <p>또한 누군가가 물어봤을 때 하던 일을 멈추고 질문에 정성스럽게 답변해주는 모습도 처음에는 굉장히 놀랐어요.</p>
              <p>게다가 모두 실력 있고 친절하셔서 저도 책임감을 가지도 일하게 되더라고요.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에서 근무하며 아쉬운 점은?</strong></p>
              <p><br/><p>
              <p>저는 너무 만족하며 회사생활을 하고 있어서 아쉽다기보다는 개선 또는 어려워 보일 수 있는 점을 말씀드릴게요.</p>
              <p>우선 회사의 급속 성장으로 일이 늘어났는데 워낙 빠르게 성장하는 탓에 인원을 완벽하게 준비할 시간이 없었죠.</p> 
              <p>그래도 일이 많고 성장세가 빠르다는 건 회사에서도, 저한테도 좋은 소식이어서 다소 행복한 불평이네요.</p>
              <p>물론 인원은 지금도 계속 충원하고 있어서 적절한 업무 분배가 이뤄지고 있고, 다양한 경험을 할 수 있는 기회로 생각되네요.</p>  
              <p>그리고 디지털 트윈이라는 아직은 생소한 솔루션을 진행함에 따라 정보를 찾아보기가 다른 솔루션에 비해 어렵다는 점도 있지만 오히려 블로그나 커뮤니티 게시물에 의존하던 제가 공식문서를 차근차근 볼 수 있는 계기가 되어준 것 같아요.</p>  
              <p>보통 모든 정보는 공식문서에 나와 있고 지원도 기존 기술보다 친절하게 제공해주고 있고요.</p>
              <p>또, 동료들의 조언을 통해서 도움받으며 큰 어려움 없이 업무를 진행 중이에요.</p> 
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사하고 싶은 후배들에게 해주고 싶은 말은?</strong></p>
              <p><br/><p>
              <p>우선 IGI Korea에 흥미가 있고 매력을 느껴서 입사하고 싶은데, 디지털 트윈에 대해 막연한 두려움이 있다면 그러지 않아도 된다고 말해주고 싶어요.</p>
              <p>저도 입사를 하기 전 디지털 트윈에 대해 찾아보고 공부하려고 했는데 자료가 많지 않아서 할 수 있을까 하는 두려움을 가지고 있었는데, 입사 후 그런 걱정은 전혀 할 필요가 없었어요.</p>
              <p>이미 선임분들이 잘 짜둔 코드를 참고하거나, 조언을 구해 차근차근히 해 나갈 수 있도록 시스템이 짜여 있었어요. 또, 업무 전반의 다양한 경험을 할 수 있고, 기회도 주어진다는 점에서 추천해 드리고 싶어요.</p> 
              <p>특히 다양한 분야의 대기업과의 프로젝트 진행은 흔한 기회가 아니어서 기회가 왔을 때 진행을 해보는 게 좋다고 생각되고요.</p>
              <p>그리고 학업에 대한 지원이나, 다양한 복지 혜택에서도 이만큼 직원 개개인을 생각하고 지원해주는 회사는 흔하지 않다고 생각되고 앞으로의 성장에 큰 도움이 될 거예요.</p> 
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 이루고 싶은 꿈은?</strong></p>
              <p><br/><p>
              <p>제가 근무하며 이루고 싶은 건 거창하지는 않지만 크게 두 가지가 있네요.</p>
              <p>가능하다면 학업을 이어가고 싶은 것과 개발자로서의 1인분을 이루고 싶어요.</p>
              <p>회사에서 학업에 대한 지원을 해주는 건 흔하지 않은 기회이고, 전문대학 졸업으로서 학업에 욕심도 있어서 이번 기회에 조금 더 진지하게 공부하고 싶어요.</p> 
              <p>물론 동기부여도 확실하고요.</p>
              <p>우선적인 목표로는 학사학위와 관련 자격증을 취득하려고요.</p>
              <p>더 나아가서는 대학원도 목표하고 있고요.</p>
              <p>그리고 업무와 공부를 진행하며 개발자로서 1인분을 하고 싶네요.</p>
              <p>다양한 경험을 할 수 있게 지원해주고 업무를 바탕으로 성장할 수 있는 시스템이 있는데, 활용하지 않는다면 너무 아까운 기회잖아요.</p>
              <p>신입으로 아직 많은 게 미숙하지만 빨리 학업과 공부를 진행하며 업무의 성장을 해서 동료와 회사에 더 많은 도움이 되고 싶어요.</p>`,
    createDate: 'Posted on May 25, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.814Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: 'interview_2',
    title: '직원 인터뷰 - (1)',
    content: `<img src="/images/blog/interview/employee_1.png">
              <p><strong>정수현 프로</strong></p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>자기 소개를 해주세요.</strong></p>
              <p><br/><p>
              <p>안녕하세요. IGI Korea 디자인기획본부에 소속하여 재직 중인 2D 디자이너 정수현입니다.</p>
              <p>주로 하는 업무는 UI/UX 웹디자인, 퍼블리싱입니다. UI/UX 디자인 이외에도 제안서디자인, ppt 발표자료 디자인, 일러스트레이션, 간단한 영상편집도 진행하고 있습니다.</p>
              <p>여러 고객사 중에서 정부 기관 통일부 관련된 디자인을 주로 하고 있습니다.</p>
              <p>저는 신입으로 입사하였으며 첫 회사입니다.</p>
              <p>대학교에서 2020년 시각디자인학과 졸업 후 웹디자이너를 꿈꾸었습니다.</p>
              <p>졸업 후 전문성 있는 UI/UX 디자이너가 되기 위하여 퍼블리싱을 따로 학원에 다니며 공부했고, 2021년 6월 IGI Korea에 입사하였습니다.</p>
              <p>제가 좋아하는 일들을 직업으로 삼게 되니 열정적으로 일할 수 있고 보람을 느끼고 있습니다.</p>
              <p>향후 더 전문성을 지닌 디자이너로 성장하기 위하여 노력하고자 합니다.</p>
              <p>전문학사를 취득한 상태에서 입사하였고 학사학위 취득을 위해 현재 방송통신대학교에 편입하여 학업을 병행하고 있습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 어떻게 지원하게 되었는지?</strong></p>
              <p><br/><p>
              <p>지원한 경로는 ‘잡코리아’에서 지원하였습니다.</p>
              <p>입사 지원하며 가장 고려했던 부분은 저의 디자인 역량을 키우고 성장할 수 있는 회사였습니다.</p>
              <p>또한 UI/UX 디자인뿐만 아니라 퍼블리싱도 작업하는 회사를 중점으로 지원하고자 했습니다.</p>
              <p>채용공고에서 IGI Korea는 웹 사이트 디자인 및 퍼블리싱을 하는 것이 담당 업무였기에 제가 하고자 하는 방향과 일치했습니다.</p>
              <p>채용공고 디자인도 전체적으로 깔끔했고, ‘OnTwins’ 홈페이지를 방문했을 때 스크롤에 따라 동작하는 3d 디자인을 보고 트렌디하고, 독창적인 디자인을 구현할 수 있는 회사라고 생각하였습니다.</p>
              <p>IGI Korea는 세계적인 수준으로 상용화한 기술 스타트업이라고 소개하고 있고, 대기업의 다양한 고객을 확보했다는 점에서 성장할 수 있는 회사라고 생각하여 지원하였습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사를 결심한 이유는?</strong></p>
              <p><br/><p>
              <p>당시 채용 절차가 서류 전형 &gt; 전화 면접 &gt; 화상 면접 &gt; 임원 면접이었습니다.</p>
              <p>전화 면접에서 기본적인 입사 지원 이유와 디자인 관련 지식을 질문하셨습니다.</p>
              <p>화상 면접에서는 면접 질문 30분과 실기시험 30분을 진행하였습니다.</p>
              <p>체계적이고 꼼꼼하게 평가하여 신중하고 진정성 있는 회사라는 인상을 주었습니다.</p>
              <p>임원 면접을 보기 위해 처음 회사를 방문했을 때 딱딱한 분위기를 예상했으나 임직원분들이 친절하게 맞이해 주셔서 존중받는 느낌이 들었습니다.</p> 
              <p>면접 분위기 또한 편안하고 즐거운 분위기였기에 수평적인 회사라고 느꼈습니다.</p>
              <p>임원면접에서 사업에 대해 대표님께서 ppt를 띄워서 소개해 주셨습니다.</p>
              <p>사업을 정확하게 이해한 것은 아니지만 실시간으로 공장의 상태를 보여준다는 점이 참신하고 유망하다고 느꼈습니다.</p> 
              <p>글로벌하게 크게 사업을 확장하고 있다는 것을 알고 함께 일하고 싶다는 생각이 들었습니다.</p>
              <p>직원 복지에도 많이 신경 써 주려고 하셔서 더 좋게 생각하게 된 것 같습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 좋은 점은?</strong></p>
              <p><br/><p>
              <p>일단 복지가 좋습니다.</p>
              <p>8시와 10시 사이에 출근해서 일찍 온 사람은 일찍 가고 늦게 온 사람은 늦게 가는 출퇴근 제도가 있습니다.</p>
              <p>저는 아침 일찍 출근하는 것이 가장 힘든데 IGI Korea는 출근 시간의 부담이 없어 좋습니다.</p>
              <p>또한 점심 식대를 제공해주어 매일 만원 한정에서 자유롭게 식사할 수 있습니다.</p>
              <p>PS 제도라고 회사 실적에 따라 연봉의 최대 50%까지 받을 수 있는 제도가 있어 열심히 일한 만큼 보상해줍니다.</p>
              <p>수습 통과 시 성장지원금 100만원 지원 등 다양한 복지가 있습니다.</p> 
              <p>사내 분위기도 굉장히 중요하다고 생각하는데 사내 분위기는 독보적으로 좋다고 느낍니다.</p>
              <p>직원분들 전부 유순한 성격이셔서 서로 응원하고 소통도 많이 하는 편입니다.</p>
              <p>상사분들도 다들 잘 챙겨주시고, 배울 점도 많아서 항상 감사함을 느낍니다.</p>
              <p>휴게실에는 빈백과 닌텐도가 있어 점심시간에 게임을 하기도 하고 편하게 쉴 수 있습니다.</p>
              <p>긍정적인 분위기에서 일할 수 있는 것은 큰 장점이라고 생각합니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에서 근무하며 아쉬운 점은?</strong></p>
              <p><br/><p>
              <p>이 부분은 어느 회사나 다 마찬가지라고 생각합니다.</p>
              <p>야근이 많은 것은 아니지만 업무가 가끔 많이 몰려올 때는 야근을 해도 시간이 모자랄 때가 있습니다.</p>
              <p>직원분들이 간혹 휴일에도 근무하시거나 상사분들은 휴가 날에도 업무하시는 것을 볼 때가 있습니다.</p>
              <p>임원진분들께서도 휴일 근무를 되도록 하지 않게 하시며 상사분들께서도 야근 혹은 휴일 근무를 강요하는 일은 절대 없습니다.</p> 
              <p>임원진분께서 업무시간 내에 최대한 끝내고 야근하지 말라고 하십니다.</p>
              <p>다들 자발적으로 업무를 끝내지 못하는 경우 어쩔 수 없이 하는 경우가 생기는데 조금씩 개선되면 좋을 것 같습니다.</p>
              <p>업무량이 몰려오는 시기에는 어쩔 수 없으나 평소에는 최대한 업무 시간에 끝내서 야근을 최소화할 수 있도록 노력할 것입니다.</p>
              <p>이외에 아쉬운 점은 주변이 다 회사인데 순두부찌개 맛집이 없는 게 아쉽습니다.</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 입사하고 싶은 후배들에게 해주고 싶은 말은?</strong></p>
              <p><br/><p>
              <p>IGI Korea의 업무 환경과 어떤 분과 잘 맞을 지 제가 느낀 것을 바탕으로 말씀드리려고 합니다.</p>
              <p>자기 개발과 성장하고 싶은 분들께 최적의 회사라고 생각합니다.</p>
              <p>현재 직원 모두 2022년 상반기 성장 목표를 설정해둔 상태입니다.</p>
              <p>목표 달성 시 회사에서 보너스를 지급해줍니다.</p>
              <p>회사에서 성장을 독려하기 때문에 성장하고 싶은 분들에게 굉장히 좋은 환경이라고 생각합니다.</p>
              <p>그리고 엄청 소극적인 분보다는 소통을 원활하게 하시는 분이 더 잘 맞으리라 생각합니다.</p>
              <p>미팅을 많이 하고 소통도 자주 하는 편입니다.</p>
              <p>처음에는 미팅하는 것조차 어색하고 부담으로 다가올 수 있을 것 같습니다.</p>
              <p>조금만 시간이 흐르면 적응이 되기 때문에 이 부분은 걱정 안 하셔도 될 것 같습니다.</p>
              <p>현재 입사한 지 1년도 안 된 직원분들이 절반이나 되고 신입 분들이 꾸준히 들어오고 계셔서 서로 화이팅하며 일하는 분위기입니다.</p>
              <p>입사하게 되면 직원분들이 친절하게 맞이해 주실 겁니다!</p>
              <p><br/><p>
              <p><br/><p>
              <p><strong>IGI Korea에 근무하며 이루고 싶은 꿈은?</strong></p>
              <p><br/><p>
              <p>저는 기본이 잘 갖추어져야 한다고 생각해서 주 업무 능력 향상이 우선입니다.</p>
              <p>웹 UI/UX 디자이너로서 더욱 전문성을 지니고 싶습니다.</p>
              <p>사용자가 가장 편리하게 이용할 수 있는 UI를 고민해보고 디자인할 것입니다.</p>
              <p>웹을 봤을 때 누가 봐도 예쁘고 이용하고 싶은 마음이 들 수 있도록 구현하고 싶습니다.</p>
              <p>그리고 디자인에 비해 부족한 퍼블리싱 실력을 향상시키고자 합니다.</p>
              <p>저의 코드를 개발자분들이 보고 빠르게 이해하고 적용시켜 보다 원활하게 협업하는 것이 목표입니다.</p>
              <p>이외에 저의 개인적인 막연한 꿈이 있는데 예전부터 해외에서 일해보고 싶다는 생각이 있었습니다.</p>
              <p>회사에서도 글로벌 진출을 위하여 준비하고 있는데 해외에 나가보고 싶습니다.</p>
              <p>해외에서 근무해보는 것도 좋고 첫 직장생활을 하며 번 내 돈으로 제대로 해외여행을 가는 것이 꿈입니다.</p>
            </p>
            
            
            
            `,
    createDate: 'Posted on May 19, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.813Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: 'interview_1',
    title: '대표 인터뷰',
    content: `<img src="/images/blog/interview/ceo.png">
             <p><strong>최상수 대표</strong></p>
             <p><br/><p>
             <p><br/><p>
             <p><strong>자기 소개를 해주세요.</strong></p>
              <p><br/><p>
             <p>안녕하세요 IGI Korea 대표 최상수입니다.</p>
             <p>저는 조선공학으로 학∙석사 학위를, 산업공학으로 박사학위를 받았고, 그래픽스연구원, 지멘스 인더스트리 소프트웨어, 삼성전기, 미국 표준기술연구소 등에 근무하였습니다.</p>
             <p>이런 경험을 바탕으로 2016년 회사를 설립하였습니다.</p>
             <p>저희 회사는 디지털 트윈과 메타버스 분야의 원천기술을 가진 소프트웨어 솔루션 회사로서 글로벌 경쟁력을 가진 스타트업입니다.</p>
             <p><br/><p>
             <p><br/><p>
             <p><strong>어떻게 회사를 설립하게 되었는지?</strong></p>
             <p><br/><p>
             <p>저는 회사 창립 전 15년 정도 국내∙외 기업과 연구소에 근무하며 관련 경력을 쌓았습니다.</p>
             <p>세계적인 소프트웨어 회사들과 경쟁할 수 있는 솔루션 개발을 목표로 회사를 설립하였으며, 박사 학위와 관련 분야 15년 이상 경력을 가진 최고의 전문가들을 경영진으로 모셨습니다.</p>
             <p>저희 솔루션은 웹 기반으로 개발하여 클라우드 환경을 지원하고, 플랫폼으로 개발하여 다양한 컨텐츠와 기능을 편리하게 탑재할 수 있으며, 누구나 쉽게 사용할 수 있도록 지원합니다.</p>
             <p><br/><p>
             <p><br/><p>
             <p><strong>IGI Korea는 어떤 회사인가요?</strong></p>
             <p><br/><p>
             <p>우리는 디지털 트윈과 메타버스 분야의 세계 최고 글로벌 경쟁력을 갖고 있는 소프트웨어 솔루션 회사입니다.</p>
             <p>SI 업무를 지양하고 있습니다.</p> 
             <p>삼성, LG, SK, 한국수자원공사, 통일부 등에서 저희 솔루션, OnTwins를 사용하고 있습니다.</p>
             <p>저희 경영진 모두는 미국 내셔널 랩, 미국 최고의 대학, 글로벌 소프트웨어 회사 등 다양한 근무 경험을 갖고 있습니다.</p>
             <p>이런 글로벌한 경험에서 배운 많은 장점을 선별하여 회사 운영 철학과 문화에 이식하고 있습니다.</p>
             <p>2022년에는 코로나로 인해 연기되었던 글로벌 진출에 박차를 가할 예정이고, 북미(메릴랜드), 유럽(독일), 아시아(싱가포르)에 법인 설립을 준비하고 있습니다.</p>
             <p><br/><p>
             <p><br/><p>
             <p><strong> 회사의 장점은 무엇인가요?</strong></p>
             <p><br/><p>
             <p>저희 회사는 압축 성장하고 있는 회사입니다.</p>
             <p>작년 투자받은 20억을 전혀 사용하지 않을 정도로 자체 매출로 회사 운영이 가능한 상황입니다.</p>
             <p>2021년 기준 24억의 매출, 6억의 영업 이익이 발생했고, 매년 30%이상 성장과 영업 이익을 기대하고 있습니다.</p>
             <p>자체 솔루션으로 돈을 벌고 있는 몇 안 되는 디지털 트윈, 메타버스 기업입니다. 현재 글로벌 진출을 위해 미국에서 개념검증(Proof of Concept, PoC) 프로젝트를 수행하고 있으며, 폭발적인 관심을 받고 있습니다.</p>
             <p>저희 회사는 직원들의 지속적인 성장에 많은 투자를 하고 있습니다.</p>
             <p>매년 임직원의 성장 지원 프로그램이 있습니다.</p>
             <p>100만원은 성장에 필요한 교육, 도서, 물품 등의 구매에 자유롭게 사용할 수 있고, 업무와 어학 목표를 달성할 시 각각 100만원의 추가 인센티브를 지원합니다.</p>
             <p>또한, 학위 취득을 강력하게 독려하고 있고, 등록금을 지원하고 있습니다.</p>
             <p>학사 학위가 없거나 비전공자의 경우 적극적으로 진학을 권고하고 있는데, 이미 6명의 직원이  방송통신대학에서 관련 전공을 공부하고 있습니다.</p>
             <p>내년 대학원 진학을 계획하는 직원들이 다수 있으며, 해외 대학 진학을 준비하는 직원도 있습니다.</p>
             <p>저희 경영진은 임직원들이 회사 일과 병행하며 박사학위까지 받을 수 있도록 적극 지원할 계획입니다.</p>
             <p>그리고 저희 회사는 먹는데 진심입니다.</p> 
             <p>한도 내에서 먹고 싶은 것을 자유롭게 주문해 먹는 점심시간과 커피데이를 임직원들이 좋아하고, 무엇보다 맛집을 탐방하는 회식이 매우 인기가 있습니다.</p>
             <p>비음주자가 다수라 절대 음주를 강요하지 않습니다.</p>
             <p>마지막으로 가끔 발생하는 고객의 갑질로부터 임직원들을 보호하는데 최선을 다합니다.</p>
             <p><br/><p>
             <p><br/><p>
             <p><strong>회사의 단점은 무엇인가요?</strong></p>
             <p><br/><p>
             <p>저희는 특별한 경우가 아니라면 신입 또는 경력이 많지 않은 지원자를 뽑아서 자체적으로 교육시키며 성장시키자는 철학을 갖고 있습니다.</p>
             <p>우리 회사의 철학에 따라 일을 제대로 배워야 협업이 원활하고, 임직원과의 관계도 원만하기 때문입니다.</p>
             <p>저희 회사는 글로벌 스탠다드와 합리적인 업무 방식을 지속적으로 추구하고 있습니다.</p>
             <p>몇몇 협업 경험이 없는 직원들의 경우, 초기엔 이런 방식 적응에 어려움을 겪기도 합니다.</p>
             <p>하지만 대부분 3~6개월 정도 지나면 자연스럽게 팀플레이가 가능하게 되어 회사에서 각자 중요한 역할을 수행합니다.</p>
             <p>저희 회사는 현재 압축 성장하고 있고, 많은 새로운 고객들이 생겨나고 있기 때문에 업무량은 많은 편입니다.</p>
             <p>하나의 업무가 아니라 다양한 일을 경험할 수 있고, 빠르게 회사에 적응하여 기여를 하면 합리적인 보상도 받지만, 아직 전문적인 체계가 부족하다고 느낄 수 있습니다.</p>
             <p>이런 부분들은 대부분의 스타트업들이 겪는 과정이라 저희도 최대한 빨리 안정화하려고 노력하고 있습니다.</p>
             <p>작년엔 15명의 직원이, 올해는 5명의 직원이 새로 합류했고, 앞으로도 10~20명을 추가 채용 예정입니다.</p>
             <p>글로벌 기업으로 성장하기 위한 성장통으로 생각해주시면 좋을 것 같습니다.</p>
             <p><br/><p>
             <p><br/><p>
             <p><strong>IGI Korea에 입사하고 싶은 지원자들에게 해주고 싶은 말이 있을까요?</strong></p>
             <p><br/><p>
             <p>저희 회사가 가장 중요하게 생각하는 것은 건강, 기본, 소통, 열정, 겸손입니다.</p>
             <p>지금 당장의 실력보다는 잠재력이 있는 분들을 선호합니다.</p>
             <p>저희 임직원을 믿고 입사하신다면 글로벌 경쟁력을 갖춘 인재로 성장할 수 있도록 최선을 다해 돕겠습니다.</p>
             <p>저희 회사에 관심 있으신 분들은 적극적인 지원을 부탁드립니다.</p>
             <p><br/><p>
             <p><br/><p>
             <p><strong>앞으로 이루고 싶은 꿈은 무엇인가요?</strong></p>
             <p><br/><p>
             <p>대한민국에서도 SAP, DASSAULT, PTC, AUTODESK, ANSYS, ROBLOX, GATHERTOWN과 같은 기업이 나올 수 있다는 것을 증명하고 싶습니다.</p> 
             <p>임직원들과 신나게 일하며 세계 최고 글로벌 경쟁력을 갖춘 회사로 성장시키고 싶습니다.</p>
             <p>소프트웨어 솔루션 회사로 살아남기 어려운 대한민국에서 모범이 되는 회사로 성장하여 대한민국이 소프트웨어 회사하기 좋은 환경을 만드는 데 일조하고 싶습니다.</p>
            `,
    createDate: 'Posted on May 19, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.807Z',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },

  {
    id: '30',
    title: '(미국출장) 미국에서 한식이 그리울 때, 화개장터',
    content: `<img src="/images/blog/post30/post30_14.png">
              <p>미국 여행을 하다 보면 한식이 그리울 때가 생기는데요.</p>
              <p>이날이 딱 그런 날이었어요!</p>
              <p>그래서 큰마음을 먹고 화개장터라는 한식당을 방문하였어요.</p>
              <p>미국에서 화개장터라니 뭔가 이름부터 정겨운 느낌이 물씬 드는 곳이었어요~!</p>
              <img src="/images/blog/post30/post30_1.png">
              <p>한식당이라서 그런지 문 앞에서부터 한국분들을 많이 만날 수 있었어요!</p>
              <p>거기다 한글이라니 뭔가 뭉클한 느낌이 들었답니다ㅠㅠ!!</p>
              <img src="/images/blog/post30/post30_2.png">
              <img src="/images/blog/post30/post30_3.png">
              <img src="/images/blog/post30/post30_4.png">
              <img src="/images/blog/post30/post30_5.png">
              <p>미국에서 만난 첫 한식당 화개장터는 정말 다양한 메뉴가 있어요.</p>
              <p>정말 한식의 모든 종류가 모여 있는 느낌이라 어떤 메뉴를 골라야 할지 한참 고민해야 했답니다!</p>
              <img src="/images/blog/post30/post30_6.png">
              <p>그리고 한식에서만 느낄 수 있는 이 한 상 반찬!!!</p>
              <p>김치, 무채, 감자조림, 콩나물무침, 사라다, 청포묵, 미역 줄기 까지 참으로 반갑지 않을 수가 없었어요.</p>
              <img src="/images/blog/post30/post30_7.png">
              <img src="/images/blog/post30/post30_8.png">
              <p>저희는 이날 한식을 먹겠다는 마음을 단단히 먹고 왔는데요.</p>
              <p>한국에서는 흔하게 먹을 수 있지만 여기서는 보기 힘든 된장찌개와 소주를 만날 수 있었답니다!</p>
              <p>물론 소주 가격은 한국보다 훨씬 비싸지만 한국의 소주맛을 맛볼 수 있다는 것 자체가 좋았어요!</p>
              <img src="/images/blog/post30/post30_9.png">
              <img src="/images/blog/post30/post30_10.png">
              <p>차돌박이를 시작으로 삼겹살, 곱창볶음 등 먹고 싶었던 한식은 다 시켜서 먹었답니다.</p>
              <img src="/images/blog/post30/post30_11.png">
              <img src="/images/blog/post30/post30_12.png">
              <p>그중에서도 이 주물럭이 정말 맛있었어요.</p>
              <p>주물럭은 주문을 하면 바로 양념해서 현장에서 구워주시는데요.</p>
              <p>서빙하시는 분이 한국분이어서 음식에 대한 설명도 해주시고 즐겁게 식사를 할 수 있었어요!</p>
              <img src="/images/blog/post30/post30_13.png">
              <p>고기를 먹었다면 마무리는 역시 냉면이겠죠?</p>
              <p>고기를 시킨 인분 수만큼 후식 냉면을 시킬 수 있어요.</p>
              <p>후식 냉면까지 고기에 싸 먹고 나니 배에 더 이상 들어갈 공간이 없을 정도로 차버렸답니다..!</p>
              <p>미국 여행 중에 한식이 너무 그립다면 강력 추천하는 화개장터!!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3097.3530629121824!2d-77.11756468418027!3d39.07565497954353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7cdc369d2aaff%3A0x4a57f5ab488e92a1!2z7ZmU6rCc7J6l7YSw!5e0!3m2!1sko!2skr!4v1651451706481!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p>대표번호 : +1 301 340 6880</p>
              <p>위치 : 2104 Veirs Mill Rd, Rockville, MD 20851 미국</p>
              `,
    createDate: 'Posted on May 2, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.733Z',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '29',
    title: '(미국출장) 우즈베키스탄 요리 맛집, Silk Road Choyhona',
    content: `<img src="/images/blog/post29/post29_8.png">
              <p>요즘은 국내에도 다양한 나라의 음식을 맛볼 수 있는데요.</p>
              <p>그래도 보기 어려운 나라 음식을 하는 곳을 소개해 드리려고 해요!</p>
              <p>바로 우즈베키스탄 음식을 맛볼 수 있는 Silk Road Choyhona라는 곳이에요.</p>
              <p>이곳은 메릴랜드 게이더스 버그에 위치한 식당인데요.</p>
              <p>대표님이 한국인 입맛에도 잘 맞고 맛있다고 강력 추천해주신 곳이에요!</p>
              <img src="/images/blog/post29/post29_1.png">
              <img src="/images/blog/post29/post29_2.png">
              <p>왠지 익숙하지 않은 나라 우즈베키스탄의 요리는 어떨지 방문 전부터 엄청난 기대를 하고 도착했답니다!</p>
              <img src="/images/blog/post29/post29_3.png">
              <img src="/images/blog/post29/post29_4.png">
              <p>들어가자마자 보이는 커다란 카페트가 뭔가 동양적이면서도 이국적인 느낌의 인테리어가 마음에 들었답니다!</p>
              <p>점심시간을 피해서 도착해서 그런지 식당 내부는 한산했어요.</p>
              <img src="/images/blog/post29/post29_5.png">
              <img src="/images/blog/post29/post29_6.png">
              <p>미국 음식점에 가면 항상 고민이 되는 것이 메뉴를 고르는 것인에요..</p>
              <p>익숙하지 않은 메뉴 이름을 보면서 들어간 재료가 뭔지 다 파악하면서 어떤 맛이 날지를 파악하는 과정이 힘들었는데</p>
              <p>이곳의 메뉴판은 사진이 있어서 좀 더 쉽게 메뉴를 고르기가 편했어요.</p>
              <p>Silk Road Choyhona 강력 추천 메뉴는 Plov!</p>
              <p>1인 1메뉴씩 시켰답니다~!</p>
              <img src="/images/blog/post29/post29_7.png">
              <p>메인 메뉴가 나오기 전에 시킨 애피타이저 Dolma!</p>
              <p>포도잎을 싼 간 소고기라고 하는데요.</p>
              <p>포도잎 향이 좋아서 소스에 찍어 먹으면 애피타이저로 부담 없이 먹을 수 있어요!</p>
              <p>인원수만큼 시켜서 하나씩 맛보았어요!</p>
              <img src="/images/blog/post29/post29_8.png">
              <p>곧이어 나온 Plov!!</p>
              <p>고기와 함께 야채가 볶아져 나온 볶음밥 같은 비주얼을 가지고 있어요.</p>
              <p>마늘을 통째로 구워 나온 것이 인상적이었어요.</p>
              <p>마늘을 좋아하는 한국인답게 마늘 부터 까먹었답니다.</p>
              <p>역시 마늘은 진리에요.</p>
              <p>맛은 정말 한국인도 좋아할 만한 맛인데 이 맛을 어떻게 표현해야 할지….</p>
              <img src="/images/blog/post29/post29_9.png">
              <p>메뉴 한 가지로는 부족할 거라 생각해서 주문한 케밥 플레이트!!</p>
              <p>다양한 고기로 구성되어 있어 치킨, 양, 소고기를 다양하게 맛 볼 수 있는 점이 좋아요.</p>
              <p>저는 고기도 좋았지만 야채 꼬치가 너무 좋았답니다!</p>
              <p>이국적인 음식을 맛보고 싶지만 모험하기엔 부담이라면 추천하는 Silk Road Choyhona!!</p>
              <p>부담스럽지 않은 가격에 새로운 요리를 즐길 수 있답니다!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.362385490231!2d-77.21965628464234!3d39.14373857953344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b62d186d714601%3A0xb5812bae836d3edd!2sSilk%20Road%20Choyhona!5e0!3m2!1sko!2skr!4v1651220075281!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p>대표번호 : +1 301-330-5262</p>
              <p>위치 : 28 Bureau Dr, Gaithersburg, MD 20878 미국</p>
              `,
    createDate: 'Posted on May 2, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.733Z',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '28',
    title: '(미국출장) 베서스다 맛집 중화요리, Q by Peter Chang',
    content: `<img src="/images/blog/post28/post28_1.png">
              <p>오늘 소개해 드리는 곳은 아주아주 특별한 곳인데요!</p>
              <p>바로 한국이 아니라 태평양 건너에 있는 미국 워싱턴 DC 인근에 있는 Q by Peter Chang 이라는 곳이에요.</p>
              <p>이렇게 미국에 있는 맛집을 소개해 드릴 수 있는 이유는 바로 미국 출장을 왔기 때문이에요~!</p>
              <p>미국 출장 스토리는 다음에 해드릴게요!</p>
              <img src="/images/blog/post28/post28_2.png">
              <img src="/images/blog/post28/post28_3.png">
              <p>이곳은 미국 워싱턴에서도 핫플레이스인 베서스다라는 곳에 있는 고급 중화요리전문점인데요.</p>
              <p>사천식 중국 요리를 선보이고 있는 곳으로 미국에서도 많은 국회의원이 방문하는 곳이라고 해요.</p>
              <img src="/images/blog/post28/post28_4.png">
              <img src="/images/blog/post28/post28_5.png">
              <p>저희는 룸으로 예약을 하고 방문했어요.</p>
              <p>영화 또는 드라마에서 볼 수 있는 회전 식탁이 세팅이 된 상태로 저희를 반겨주고 있었답니다!</p>
              <img src="/images/blog/post28/post28_6.png">
              <p>메뉴를 보니 정말 다양한 메뉴가 있는데 어떤 것을 먹어야 좋을지 한참 고민끝에 엔터런스 메뉴 3가지, 메인 메뉴 3가지, 그리고 북경오리를 주문했어요.</p>
              <p>어디를 가도 먹는 거 하나만큼은 푸짐하게 먹는 IGI Korea 랍니다!</p>
              <img src="/images/blog/post28/post28_7.png">
              <p>가장 먼저 나온 메뉴는 우리나라에서도 자주 볼 수 있는 버블 호떡과 비슷한 비주얼을 가진 팬케이크에요!</p>
              <p>테이블 담당해주신 분께 물어보니 이곳을 방문한 분들 중 10에 9은 이 메뉴를 시킨다고 하더라구요~</p>
              <p>맛은 안에 텅 빈 공갈빵인데 터뜨려 먹는 재미가 있어요.</p>
              <img src="/images/blog/post28/post28_8.png">
              <img src="/images/blog/post28/post28_9.png">
              <p>다음으로 나온 것이 바로 북경오리였는데요~</p>
              <p>이 집은 정말 북경오리가 맛있는 것 같아요!</p>
              <p>껍질은 바삭바삭하고, 고기는 부드러워서 야채와 함께 밀전병에 싸서 소스에 찍어 먹으면 그 맛이 또 일품이랍니다!</p>
              <img src="/images/blog/post28/post28_10.png">
              <p>이 요리는 사천식이라 전체적으로 매콤하지만 한국인인 우리에겐 큰 문제 없어요!</p>
              <p>메뉴를 추천해주신 분은 조금 매울 수 있다고 이야기해주셨지만 그냥 살짝 매콤한 맛?이었어요.</p>
              <img src="/images/blog/post28/post28_11.png">
              <p>따뜻한 국물 요리도 하나 시켰는데 안에 면이 들어 있어요.</p>
              <p>너무 순식간에 면이 불어서 국물이 금방 사라져서 빨리 먹어야 했답니다!</p>
              <p>고수가 올려져 있어서 고수를 좋아하지 않는 분들은 고수를 빼달라고 하시는 게 좋아요..!</p>
              <p>이렇게 먹어도 많은데 한 번 더 북경오리를 시키고 탕수육이 먹고 싶다는 한 분의 의견으로 탕수육과 비슷한 메뉴도 추가 했답니다!</p>
              <img src="/images/blog/post28/post28_12.png">
              <p>이 요리는 탕수육 느낌이 나서 맛있게 먹었답니다! 탕수육보다는 좀 더 달고 진득한 맛이에요.</p>
              <p>여기서 끝내면 안 되겠죠?</p>
              <p>정말 마지막으로 익숙한 메뉴를 하나 더 시켰어요!</p>
              <img src="/images/blog/post28/post28_13.png">
              <p>바로 샤롱바오~</p>
              <p>디저트로 샤롱바오까지 먹고 멋진 저녁 식사를 끝냈답니다!</p>
              <p>워싱턴에서 격식 있는 음식점을 찾으시거나 맛있는 사천식 중화요리가 먹고 싶다면</p>
              <p>강력하게 추천해 드리는 Q by Peter Chang!!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12405.35649179915!2d-77.091161!3d38.984756!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6fa16d80baaf97fa!2sQ%20by%20Peter%20Chang!5e0!3m2!1sko!2skr!4v1651218592114!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <p>대표번호 : +1 240-800-3722</p>
            <p>위치 : 4500 East-West Hwy #100, Bethesda, MD 20814 미국</p>
            
              `,
    createDate: 'Posted on May 2, 2022',
    lastModifiedDate: '2024-07-04T06:19:39.733Z',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '27',
    title: '경복궁 근처 된장찌개 찐맛집, 대복집!',
    content: `<img src="/images/blog/post27/post27_1.jpg">
              <p>오늘은 수요 미식회에서도 소개되었던 경북궁역 근처 대복집을 소개해드릴게요!</p>  
              <p>모든 음식이 맛있는 고기+된장찌개 맛집 대복집이에요!</p>
              <img src="/images/blog/post27/post27_2.jpg">
              <p>외관은 고전적인 분위기를 풍기는 기와로 되어있어요! 종로의 분위기와 잘 맞는 건물인 것 같네요.</p>
              <img src="/images/blog/post27/post27_3.jpg">
              <img src="/images/blog/post27/post27_4.jpg">
              <p>메인메뉴와 사이드 메뉴입니다!</p>
              <p>메인메뉴에서 고기류인 등심, 차돌박이, 생삼겹, 삼겹이 있어요!</p>
              <p>식사메뉴에는 된장찌개와 해물 소면이 있는데 가격은 따로 표시 안되어 있습니다.</p>
              <img src="/images/blog/post27/post27_5.jpg">
              <img src="/images/blog/post27/post27_6.jpg">
              <p>매장 구조는 크게 ‘ㅁ’자 구성이고 가운데는 테이블로 이루어져 있고 외곽은 룸으로 구성되어 있어요!</p>
              <p>저희는 룸에서 먹었는데 저희가 있었던 룸은 일반 가정집처럼 꾸며져 있어서 집에서 먹는 편안한 기분을 느낄 수가 있었어요!</p>
              <img src="/images/blog/post27/post27_7.jpg">
              <p>반찬은 콩나물, 부추, 오이무침, 마늘, 파채 무침이 나와요.</p>
              <p>이곳의 밑반찬은 모두 맛있었지만 그 중 오이무침과 파무침이 엄청 맛있어요!</p>
              <p>파무침은 일반 시중 소스를 쓰는 것이 아닌 직접 양념을 하셔서 만드시는 것 같았어요.</p>
              <img src="/images/blog/post27/post27_8.jpg">
              <img src="/images/blog/post27/post27_9.jpg">    
              <p>수요 미식회에서 된장찌개 맛집으로 출연하였는데 먹어보니 TV에 나온 이유를 알겠더라고요..</p>
              <p>미나리의 그윽한 향과 두부, 고기가 들어있어 건강함과 맛을 같이 잡은 느낌이었어요.</p>
              <p>저희는 한 방울도 안 남기고 싹싹 먹었어요!</p>              
              <img src="/images/blog/post27/post27_10.jpg">
              <img src="/images/blog/post27/post27_11.jpg">   
              <p>메인 메뉴였던 소고기 등심이에요! 입에서 살살 녹아 정말 맛있었어요!</p>
              <p>점심시간에 줄 서서 먹는다는 맛집 종로 대복집에 대하여 리뷰해 보았는데요!</p>
              <p>저희는 평일 저녁시간에 예약을 하고 가서 안 기다리고 음식을 바로 먹을 수 있었지만 점심시간에는 줄을 서서 먹는 집이라고 하네요.</p>
              <p>한식이 엄청 먹고 싶은 날 대복집을 한 번 방문해보시는 걸 강력 추천 드립니다!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50610.18020076695!2d126.95201055769843!3d37.55185329584021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2956c622579%3A0x7a40444ae52bae10!2z64yA67O17KeR!5e0!3m2!1sko!2skr!4v1650510660195!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <p>영업시간 : 평일 12:00 - 22:00 (유동적 운영/주말 휴무)</p>
              <p>대표번호 : 02-736-8029</p>
              <p>위치 : 서울 종로구 사직로 137-9</p>
              `,
    createDate: 'Posted on April 21, 2022',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '26',
    title: '구디 중화요리 맛집, 량',
    content: `<img src="/images/blog/post26/post26_1.jpg">
              <p>저희 회사 회식할 때 자주 갔던 중식집을 소개해드리려고 합니다.</p>
              <p>구로디지털단지에 위치한 ‘량’입니다!</p>
              <p>짜장면, 짬뽕도 맛있지만 이 집은 요리가 정말 맛있어요!</p>
              <img src="/images/blog/post26/post26_2.jpg">
              <img src="/images/blog/post26/post26_3.jpg">
              <p>량은 벽산디지털밸리3차 지하 2층에 있습니다.</p>
              <p>지하 2층이지만 밖이 다 보입니다. 왼쪽 사진 계단으로 내려가면 바로 찾을 수 있습니다!</p>
              <p>외부도 깔끔하지만 내부도 깔끔하게 되어있어요!</p>
              <img src="/images/blog/post26/post26_4.jpg">
              <p>이곳에 오면 꼭 먹는 술이 있습니다. 바로 연태고량주입니다! 향이 굉장히 좋아요!</p>
              <p>저희 직원분들은 술을 못드시는 분이 많아서 다들 한 두잔 정도만 마신답니다..!</p>
              <img src="/images/blog/post26/post26_5.jpg">
              <p>첫 번째로 나온 메뉴는 탕수육! 저희 직원분들이 가장 좋아하는 메뉴이기도 합니다.</p>
              <p>탕수육이 엄청 바삭바삭하고 소스도 맛있어요..ㅠ</p>
              <p>저는 개인적으로 찍먹파라 부먹일 때의 눅눅한 탕수육을 안 좋아하는데 이 탕수육은 부먹이어도 엄청 바삭합니다!</p> 
              <p>이곳에 방문하면 탕수육은 꼭 드셔보시는 걸 추천드려요!</p> 
              <img src="/images/blog/post26/post26_6.jpg">
              <p>다음 요리는 팔보채입니다! 보기만해도 먹음직스러워 보이지 않나요?</p>
              <p>해물을 좋아하시는 분들이라면 싫어하실 수가 없는 요리죠.</p> 
              <p>해물도 싱싱한 느낌이고 맛있습니다!</p>
              <img src="/images/blog/post26/post26_7.jpg">
              <p>마지막으로 칠리새우입니다~! 저희 직원들 사이에서 탕수육 다음으로 인기가 많은 메뉴입니다!</p>
              <p>새우가 크고 오동통해서 씹히는 맛이 너무 좋아요!</p>
              <p>달달한 맛이고 너무 맵지 않아서 더 좋습니다.</p>
              <img src="/images/blog/post26/post26_1.jpg">           
              <p>이런 요리 먹을 때 짬뽕 국물도 빠지면 섭섭하죠~</p>
              <p>짬뽕은 매콤한 편이고 누구나 무난하고 맛있게 먹을 수 있는 맛입니다.</p>
              <p>중화요리가 땡길 때 '량' 방문해보세요!</p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d75938.63726432691!2d126.89021295100993!3d37.53084760354633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e2395d86671%3A0x75596e2f8fb8b8b9!2z65-JIOykke2ZlOyalOumrOyghOusuOygkA!5e0!3m2!1sko!2skr!4v1650523607608!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <p>영업시간 : 평일 11:00 - 21:30(15:00 - 17:00 브레이크타임)</p>
            <p>대표번호 : 02-863-3277</p>
            <p>위치 : 서울 구로구 디지털로 271</p>
            `,
    createDate: 'Posted on April 21, 2022',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '25',
    title: "'DMZ 가상으로 즐겨요'…통일부, 첫 메타버스 체험 서비스",
    content: `<img src="/images/blog/post25/post25_1.png">
              <p><strong>[연합뉴스]</strong></p>
              <br/> 
              <p>'통일부는 31일부터 정부 부처 가운데 최초로 메타버스 플랫폼을 활용한 DMZ 관련 정보 대국민 서비스를 제공한다고 밝혔다.</p>
              <p>해당 서비스에 접속하면 첫 화면에 '평화섬' 공간지도가 뜨고 ▲ DMZ 실태조사 ▲ DMZ 가상체험 ▲ 만남의 공간 ▲ 자료실 등 지도 곳곳에 표시된 코너명을 클릭하면 DMZ와 관련한 다양한 정보들을 입체적으로 접할 수 있다.'</p>
              <br/>  
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3NCzqdt" target="_blank">link: https://bit.ly/3NCzqdt</a>`,
    createDate: 'Posted on March 31, 2022',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '24',
    title: '구로디지털단지역 근처 회식 고민될 땐 한우마당!',
    content: `<img src="/images/blog/post24/post24_1.png">
              <p >저희 직원들이 직접 다녀온 곳 중에서 제일 반응이 좋았던 곳을 소개해보려 합니다.</p>
              <p>바로 한우마당이라는 곳인데요 한우마당은 구로에만 4개 지점이 있어요.</p>
              <p>그중에서 소개해드릴 곳은 한우마당 늘푸른점입니다!</p>
              <img src="/images/blog/post24/post24_2.png">
              <p>한우마당은 2층에 있습니다.</p>
              <p>저녁식사로 가서 밝은 모습은 담지 못했네요..</p>
              <p>입구는 부동산 옆에 있습니다!</p>
              <p>구로에 있는 여러 지점 중에서도 저는 늘푸른점이 제일 깨끗한 것 같아요!!</p>
              <p>회식으로 한우마당을 선택했지만, 수많은 지점 중 어디 지점을 갈지 고민이시라면 저는 개인적으로 늘푸른점을 추천드려요.</p> 
              <img src="/images/blog/post24/post24_3.png">
              <p>한우마당의 메뉴판입니다.</p>
              <p>한우 전문점답게 딱 필요한 메뉴들로만 구성되어 있음을 확인할 수 있습니다.</p>
              <p>메뉴판은 지점마다 조금씩 다른 것 같아요. </p>
              <p>고기is뭔들이지만, 뭘 시켜야 할지 고민이 된다면 생등심MIX를 처음에 시켜서 드시고 입맛에 맞는 부위를 추가 주문하시면 됩니다.</p> 
              <p>고민될 때는 제일 비싼 거로 시켜 드시면 실패할 일은 없습니다!</p>
              <img src="/images/blog/post24/post24_4.png">
              <p>매장구조는 많이 넓지는 않습니다.</p>
              <p>요일마다 차이는 있겠지만, 목요일은 금세 만석이 되어버렸어요!ㅠㅠ</p>
              <p>인원이 4인 이상이면 꼭 예약하시고 가는 게 좋아요!</p>
              <p>저희는 이날 6명이 방문했는데,  6인용 테이블로 배정해 주셨어요.</p>
              <img src="/images/blog/post24/post24_5.png">
              <img src="/images/blog/post24/post24_6.png">
              <p>반찬은 셀프 바에서 원하는 만큼! 부담 없이! 가져올 수 있답니다.</p>
              <p>3인분씩 나눠 주셔서 여섯 명이 한 테이블에서 먹을 수 있었어요!</p>
              <img src="/images/blog/post24/post24_7.png">
              <img src="/images/blog/post24/post24_8.png">
              <p>한우마당을 회식 장소로 추천한 이유!</p>
              <p>바로바로 직원분께서 고기를 직접 구워 주신답니다!!!!!</p>
              <p>자고로 고기란 굽는 사람의 기술이 아주아주 중요한 법이죠.</p> 
              <p>직원분께서 직접 구워 주시니, 맛있는 고기를 맛없게 굽는 대참사를 미연에 방지할 수 있습니다.</p>
              <img src="/images/blog/post24/post24_9.png">
              <img src="/images/blog/post24/post24_10.png">
              <p>사진의 양은 1.5인분 정도입니다.</p>
              <p>소고기가 제일 맛있는 지점! 미디움레어로 구워주셨어요!!</p>
              <p>취향에 따라 더 익혀 먹거나, 바로 드시면 됩니다!</p>
              <p>Tip) 고추냉이를 따로 요청하시면 주시니까 고추냉이와 같이 드시는 것도 추천해 드려요..!</p>
              <img src="/images/blog/post24/post24_11.png">     
              <p>한우마당에서는 육회를 꼭!!!!! 드셔야 해요.</p> 
              <p>사진은 대자를 2개로 나눈 양이에요. 약 250g인데요.</p>
              <p>자세한 설명은 생략하고 한마디로 정리하겠습니다.</p>
              <p>저희는 이 대자를 총 3번을 더 시켜 먹었어요. 육회만 1.5kg 먹었습니다.</p>
              <img src="/images/blog/post24/post24_12.png">
              <img src="/images/blog/post24/post24_13.png">
              <p>입가심으로 차돌라면까지 먹었어요. 라면도 너무 맛있어요!!</p>
              <p>고기 먹고 라면 안 먹으면 서운하지 않나요?</p> 
              <p>화룡점정으로 라면으로 마무리해 줍니다.</p>
              <img src="/images/blog/post24/post24_14.png">
              <p>마무리를 한 줄 알았는데… 사장님이 서비스를 주셨어요!</p> 
              <p>소고기를 얼큰한 라면으로 마무리한 후 감칠맛 도는 묵사발로 최종 마무리하였습니다.</p>
              <p>구로디지털단지 근처에서 회식을 어디로 갈까 고민하시는 분은 한우마당 추천드리며 포스팅을 마무리하도록 하겠습니다!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5908.242159925468!2d126.89501762982916!3d37.480814190919816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f83a50f5069%3A0x2f6f40696ceba72d!2z7ZWc7Jqw66eI64u5IOuKmO2RuOuluOygkA!5e0!3m2!1sko!2skr!4v1646283677325!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p>영업시간 : 11:30 ~ 22:30 (브레이크타임 14:30~16:00)</p>
              <p>대표번호 : 02-830-3233</p>
              <p>위치 : 서울 구로구 디지털로 26길 98</p>
              `,
    createDate: 'Posted on March 03, 2022',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '23',
    title: '역삼역 근처 유명 횟집, 보물섬!',
    content: `<img src="/images/blog/post23/post23_1.png">
              <p>오늘은 역삼역 근처 횟집 맛집 보물섬을 소개하겠습니다!</p>
              <p>역 부근이라 내려가서 도보 10분안에 바로 도착할 수 있답니다!</p>
              <img src="/images/blog/post23/post23_2.png">
              <img src="/images/blog/post23/post23_3.png">   
              <p>보시다시피 보물섬은 건물 사이 골목길로 약간만 들어가시면 바로 찾을 수 있습니다!</p>
              <p>코로나로 인해 영업시간은 오후 1시부터 9시까지이고(2022년 2월 17일 기준) 전용 주차장이 없기 때문에 자차를 이용하시는 분들은 인근 다른 주차장을 이용하셔야 하는 점 참고해주세요!</p>
              <img src="/images/blog/post23/post23_4.png">
              <p>식당에 입장하자마자 보이는 풍경이에요!</p>
              <p>물고기들이 활발하게 움직이고 있는데, 너무 싱싱해 보이지 않나요<?/p>
              <p>/p>어떤 생선을 사용하는지 바로 볼 수 있어 더욱 믿음이 간답니다!</p>
              <img src="/images/blog/post23/post23_5.png">
              <p>보물섬의 메뉴판입니다!</p>
              <p>여름에는 민어회, 겨울에는 대방어가 주요 히트 상품인데, 시세에 따라 가격이 다른 점 참고해주세요!</p>
              <img src="/images/blog/post23/post23_6.png">
              <p>회를 시키자마자 푸짐하게 반찬을 세팅해주셨어요! 너무 맛있어 보이지 않나요?</p>
              <p>초장, 간장, 막장 중 원하는 양념에 맞게 음식을 드시면 더 맛있게 먹을 수 있어요!</p>
              <p>저희는 대방어 세트를 주문해서 기다리고 있었답니다!!</p>
              <img src="/images/blog/post23/post23_7.png">
              <p>드디어 메인 메뉴가 나왔어요.</p>
              <p>진짜 저의 최애 메뉴가 되었어요 추천합니다!</p>
              <p>겉바속촉이 여기도 있을 줄이야.. 살짝 익힌 겉면이 식감을 더 살려주는 거 아시죠...?</p>
              <p>생선인데 스테이크 맛이 날 수가 있다는 걸 처음 알았네요. 한입 먹을 때마다 감탄하면서 먹었답니다!</p>
              <p>6명이 방문하면 충분히 먹을 수 있는 양이고, 기호에 따라 다양한 방법으로 먹을 수 있어요!</p>
              <img src="/images/blog/post23/post23_8.png">
              <p>싱싱한 해산물들이 모듬으로 나오는데, 가장 신선함을 맛볼 수 있는 메뉴였습니다!</p>
              <p>한 입씩 먹을 때마다 꼬들함이 느껴졌고, 초장과 함께 먹지 않아도 괜찮을 만큼 비린 맛이 덜했어요!<p></p>
              돌 멍게를 먹은 후 소주와 함께 먹으면 맛있다고 하셨는데, 일행분들 말씀으로는 적당히 입맛을 돋우기에 괜찮다고 하셨어요!</p>
              <img src="/images/blog/post23/post23_9.png">
              <p>방어 김치찜을 추가로 주문했는데, 술안주로 너무나도 최적이었어요!</p>
              <p>짜지도 싱겁지도 않은 적당한 맛에, 속이 촉촉해서 함께 먹기에는 딱이랍니다!</p>
              <p>김치만 먹어도 전혀 부담스럽지 않은 맛이었고, 매운 음식을 못 드시는 분들도 충분히 먹을 수 있는 정도였어요!</p>
              <p>게다가 주방 이모님께서 어떤 부분이 제일 맛있다고 설명해주시고 직접 잘라주시니 더 맛있는 것 같은 느낌이 들었답니다..!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d320.27314172991794!2d127.0345821293106!3d37.49916042970723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca156081f9353%3A0xe3dace61d5c0bd95!2z6rCV64KoIOuztOusvOyErA!5e0!3m2!1sko!2skr!4v1645593230241!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p>영업시간 : 13:00~21:00 (2022년 2월 17일 기준)</p>
              <p>대표번호 : 02-566-3563</p>
              <p>위치 : 서울 강남구 테헤란로20길 11-1</p>
              `,
    createDate: 'Posted on February 23, 2022',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '22',
    title: 'Asset Administration Shell',
    content: `<img src="/images/blog/post22/post22_1.png">
              <img src="/images/blog/post22/post22_2.png">    
              <p>In April 2015, Platform Industry 4.0 in Germany released the Industrie 4.0 
              reference model, 'RAMI4.0 (Reference Architecture Model Industrie 4.0)', 
              and proposed the Asset Administration Shell (AAS) to implement physical  
              assets as the I4.0 Component in Industrie 4.0. AAS is metadata for modeling 
              all information of assets corresponding to entities in the physical world  
              in the information world, and it can be said to be a core concept and technology 
              to implement a digital twin in Industrie 4.0.  
              </p>
              <img src="/images/blog/post22/post22_3.png"> 
              <p>It is necessary to figure out the concepts of ‘submodel’ and ‘property’ for practically understanding and implementing AAS.</p>
              <p>The submodel is the most basic unit representing the characteristics of AAS, and is a collection of properties in which various properties are classified according to certain criteria.</p>
              <p>Submodels can be mainly classified into general submodels and asset-specific submodels.</p> 
              <p>The general submodel is a basic and common submodel applied to various assets, and all AAS recommends including the Identification, Technical Data, Operational Data, and Document submodels to represent essential information about the asset.</p>
              <p>The asset-specific submodel is a defined for various assets to perform specialized functions.</p>
              <p>Considering the diversity and complexity of functions, submodels for each asset may vary significantly.</p> 
              <p>The property is a fundamental element for representing properties in the physical world of an asset as technical data in the information world, and it becomes possible to identify when a name or term is given so that the property is defined using specific properties.</p>
              <br><p>All AAS must be individually identified and distinguished from others in the information world, and should be capable of interfacing and exchanging data with each other.</p>
              <p>To do this, all assets in the physical world, AAS reflecting assets in the information world, and properties to express their characteristics require their own unique identifiers.</p>
              <p>An identifier refers to information of a certain format in which an object can only be identified, and the I4.0 Component may use various types of identifiers.</p> 
              <p>When creating AAS models, identifiers defined from internationally recognized data dictionaries such as eCl@ss or CDD (Common Data Dictionary) are referenced.</p>
              <img src="/images/blog/post22/post22_4.png"> 
              <p>The establishment of the AAS standard is currently in progress (as of January 2022), and additional definitions and support for the AAS technical specification are continuously provided. For details on the AAS technical specifications, it is recommended to refer to the IEC63278 standard currently being enacted.</p>
              <br/>
              <p>2015년 4월, 독일의 Plattform Industrie 4.0은 Industrie 4.0의 참조모형 ‘RAMI4.0 (Reference Architecture Model Industrie 4.0)’을 공개하고, Industrie 4.0 내 물리적 자산 (Asset)을 I4.0 Component로 구현하기 위한 개념으로 Asset Administration Shell (이하 AAS)을 제안하였다.</p>
              <p>즉, AAS는 물리적 세계의 개체에 해당하는 자산의 모든 정보를 정보 세계에 모델링 하기 위한 메타 데이터로 Industrie 4.0에서 디지털트윈을 구현하는 핵심 개념이자 기술이라 할 수 있다.</p>
              <br/>
              <p>AAS의 이해와 실제적인 구현을 위해서는 기본적으로 ‘서브모델 (Submodel)’과 ‘프로퍼티 (Property)’의 개념을 이해해야 한다.</p>
              <p>서브모델은 AAS의 특성을 표현하는 가장 기본적인 단위로서, 다양한 프로퍼티들을 일정한 기준에 따라 분류한 프로퍼티의 모음이다.</p>
              <p>서브모델은 크게 일반 서브모델과 자산 별 서브모델로 분류할 수 있다. 일반 서브모델은 다양한 자산에 적용되는 기본적이고 공통적인 서브모델로서, 모든 AAS는 자산에 대한 필수 정보를 나타내기 위해 Identification, Technical Data, Operational Data 및 Document 서브모델을 포함하기를 권장하고 있다. 자산 별 서브모델은 다양한 자산들이 특화된 기능을 수행하기 위해 정의된 서브모델로서, 기능의 다양성과 복잡성을 고려할 때 자산 별 서브모델의 성격과 개수는 크게 다를 수 있다.</p>
              <p>프로퍼티는 자산의 물리 세계에서의 특성들을 정보 세계의 기술적인 데이터로 표현하기 위한 기본요소로서, 자산이 특정 프로퍼티들을 사용하여 그 특성이 규정되도록 명칭 또는 용어가 부여될 때 식별이 가능해진다.</p>
              <br/>
              <p>모든 AAS는 정보 세계에서 개별적으로 식별되어 다른 AAS와 구분되어야 하며, 상호 데이터 교환 및 인터페이스가 가능하여야 한다.</p>
              <p>이를 위해서는 물리 세계에서의 모든 자산, 정보 세계에서 자산을 반영하는 AAS, 그리고 그 특성을 표현하기 위한 프로퍼티 모두 각각 고유의 식별자 (Identifier)를 필요로 한다.</p>
              <p>식별자는 개체를 유일하게 식별할 수 있는 일정한 형식의 정보를 의미하며, I4.0 Component는 다양한 유형의 식별자를 사용할 수 있다.</p>
              <p>현재 AAS 모델 생성 시 eCl@ss 또는 CDD (Common Data Dictionary)와 같이 국제적으로 공인된 데이터 사전으로부터 정의된 식별자를 참조하여 활용하고 있다.</p>
              <br/>
              <p>AAS 표준 제정은 현재 (2022년 1월 기준) 진행 중이고 AAS 기술 사양에 대한 추가적인 정의와 지원이 지속적으로 제공되고 있다.</p>
              <p>AAS 기술 규격에 대한 구체적인 사항은 현재 제정 중인 IEC63278 표준안을 참고하기를 권장한다.</p>
              <br/>
              <p>Written by Prof. Juyeon Lee</p>`,
    createDate: 'Posted on Feb 9, 2022',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.TECH,
    imgUrls: [],
  },
  {
    id: '21',
    title: '한정애 환경부 장관, 광역상수도 정수장 방역상황 점검',
    content: `<img src="/images/blog/post21/post21_1.jpg">  
            <p><strong>[뉴시스]</strong></p>
            <br/>  
            <p>'한정애 환경부 장관이 16일 오후 경기도 화성시 매송면 한국수자원공사 화성정수장을 방문, 코로나19 확산에 대비, 광역상수도 정수장의 방역상황 및 대응방안을 점검하고 국내 최초로 도입한 광역상수도 스마트(AI) 정수장 자율운영을 시연하고 있다.'</p> 
            <br/> 
            <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
            <a href="https://bit.ly/3FPCHSi" target="_blank">link: https://bit.ly/3FPCHSi</a>`,
    createDate: 'Posted on December 16, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '20',
    title: 'CJ대한통운, 2023년까지 물류현장에 ‘디지털 트윈’ 적용한다',
    content: `<img src="/images/blog/post20/post20_1.jpg">  
            <p><strong>[물류신문]</strong></p>
            <br/> 
            <p>'CJ대한통운은 AI·알고리즘을 적용한 ‘디지털 트윈’ 기술을 2023년까지 단계적으로 물류현장에 적용한다고 21일 밝혔다.</p>
            <p>디지털트윈은 가상세계에 현실과 동일한 공간을 구축한 뒤 시뮬레이션을 통해 최적의 운영방식을 찾아내는 첨단기술로 전자, 자동차, 에너지 등 다양한 산업군에서 활발하게 도입되고 있다.'</p>
            <br/>   
            <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>       
            <a href="https://bit.ly/3o7Ev2D" target="_blank">link: https://bit.ly/3o7Ev2D</a>`,
    createDate: 'Posted on november 22, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '19',
    title: '이삭엔지니어링, 쉬운 디지털트윈 솔루션 모듈 제공',
    content: `<img src="/images/blog/post19/post19_1.jpg">  
              <p><strong>[서울경제]</strong></p>
              <br/> 
              <p>'4차 산업 혁명 시대의 도래로 제조 산업의 스마트팩토리 환경 구축은 더 이상 선택이 아닌 필수요소가 됐다.</p>
              <p>글로벌 기업이나 대기업은 미래 변화에 대응하기 위해 스마트 제조 환경을 구축해 제조 경쟁력 우위를 지속 선점하고 있으며, 
              중소기업의 경우에도 기업의 상황과 환경에 따라 전략적 스마트팩토리 구축을 시도하고 있다.'</p>
              <br/>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3o98B5O" target="_blank">link: https://bit.ly/3o98B5O</a>`,
    createDate: 'Posted on October 26, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '18',
    title: '아이지아이 코리아의 새로운 사무실!',
    content: `<img src="/images/blog/post18/post18_1.png">  
              <p>안녕하세요, 오늘은 직원들이 계속 늘어나면서 기존 사무실 바로 옆에 새로운 사무실을 얻게 되었다는 좋은 소식을 들고 왔는데요!</p>
              <p> 그동안 사무실로 새로운 가구들이 오고, 짐정리도 하고, 전체적으로 꾸미느라 소식이 조금 늦었어요..!</p>
              <img src="/images/blog/post18/post18_2.png">  
              <img src="/images/blog/post18/post18_3.png">  
              <p>입구를 지나면 가장 먼저 보이는 소회의실인데요! 소회의실은 주로 직원들 교육을 진행하거나, 소규모 회의를 할 때 이용하고 있어요!</p>
              <p>빔프로젝터도 있어서 큰 화면으로 회의를 진행할 경우에도 유용하게 사용하고 있답니다!</p>
              <img src="/images/blog/post18/post18_4.png">  
              <img src="/images/blog/post18/post18_5.png">  
              <img src="/images/blog/post18/post18_6.png">  
              <img src="/images/blog/post18/post18_7.png">                
              <p>소회의실을 지나 쭉 앞으로 가면 우리 회사의 가장 인기공간인 대회의실이 위치하고 있어요!!</p>
              <p>문을 열면 바로 옆에 회사 로고와 함께 큰 현수막이 자리잡고 있구요! 저희가 진행한 프로젝트들도 액자로 벽에 걸어서 꾸며놓았어요!</p>
              <p>이 공간은 직원들 모두가 모여서 회의를 할 때나, 내부 행사가 있을때 주로 이용을 하고있는데요!</p>
              <p>다른 스케줄이 없을 땐 직원들이 편하게 쉴 수 있는 공간으로도 사용하고 있어요!</p>
              <img src="/images/blog/post18/post18_8.png">  
              <img src="/images/blog/post18/post18_9.png">  
              <p>쉬면서 이렇게 닌텐도 스위치도 하고있어요! 요즘은 마리오카트를 가장 즐겨한답니다!</p>
              <img src="/images/blog/post18/post18_10.png">  
              <p>다들 빈백에 앉아서 휴식을 취하는데, 너무 편해서 한 번 앉으면 일어나기 너무 힘들다고 하시더라구요(웃음)</p>
              <p>새로 얻은 이 사무실은 기존 사무실과 조금 다른 컨셉으로 좀 더 편안한 느낌으로 가려고 하고 있는데, 하나씩 꾸며가는 재미가 있었어요!</p>
              <p>지금은 어수선해보이지만, 점점 더 꾸미고 채워서 다음에는 더 좋은 공간으로 찾아올게요!</p>`,
    createDate: 'Posted on September 10, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '17',
    title: '구디역 카페, 파라노이드!',
    content: `<img src="/images/blog/post17/post17_1.jpg">
              <p>안녕하세요, 2021년 6월에 OPEN한 파라노이드 구로직영점에서 저희 (주)아이지아이 코리아와 제휴를 진행하게 되었어요!</p>
              <p>위치는 삼성IT밸리 건물이고, 이전에 국수나무가 있었던 자리인데요.</p>
              <p>외관이 아주 파랗게 되어있어서, 멀리서도 한눈에 찾을 수 있답니다! 사진으로만 봐도 눈에 참 잘 띄지 않나요?</p>
              <p>파라노이드 카페는 ROASTERS &amp; BAKERS 카페로 깨끗하고 청량한 분위기의 카페랍니다!</p>
              <img src="/images/blog/post17/post17_2.png">
              <img src="/images/blog/post17/post17_3.png">
              <img src="/images/blog/post17/post17_4.png">                              
              <img src="/images/blog/post17/post17_5.png">              
              <p>내부는 이렇게 되어있는데요.</p>
              <p>좌석 간 공간도 널찍하게 되어있어서 음료와 베이커리를 여유롭게 드실 수 있는 공간이 충분히 마련되어 있어요!</p>
              <img src="/images/blog/post17/post17_6.png">       
              <p>메뉴도 다양한데요. 커피부터 티, 에이드까지 모두를 만족시킬 수 있도록 준비되어 있어요!</p>
              <img src="/images/blog/post17/post17_7.jpg">    
              <img src="/images/blog/post17/post17_8.jpg">                  
              <p>쿠키와 파운드 케이크, 크로플, 빙수까지 디저트 메뉴도 빈틈없이 준비되어 있답니다!</p>
              <p>쿠키는 초코 청크, 초코 쿠키, 그레인 쿠키 세가지가 준비되어 있는데요.</p>
              <p>먹어보니 정말정말 맛있으니 꼭 드셔보시길 추천드려요!</p>
              <img src="/images/blog/post17/post17_9.png"> 
              <p>저희는 이렇게 직원들 다같이 모여 파라노이드 커피를 자주 마시는데요!</p>
              <p>시그니쳐 메뉴인 파라노이드 라떼, 돌체 파 니엔떼와 로투스밀크를 가장 많이들 드시니 참고하시면 좋을것 같아요!ㄴ</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.9284180996588!2d126.8924727156464!3d37.48601553649249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f432f861fef%3A0x5c171ccb6ed57cfe!2z7YyM652864W47J2065OcIOq1rOuhnCDsubTtjpg!5e0!3m2!1sko!2skr!4v1630285595228!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p><strong>파라노이드 구로카페</strong></p>
              <p>영업시간 : </p>
              <p>월~금 07:00 ~ 20:00</p>
              <p>토,일 11:00 ~ 20:00</p>
              <p>대표번호 : 02-6953-7909</p>
              <p>위치 : 서울 구로구 디지털로33길 27 삼성IT밸리 1층 106호</p>
              `,
    createDate: 'Posted on August 30, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '16',
    title: '강원도 고성, 여행기 - (2)',
    content: `<img src="/images/blog/post16/post16_1.png">
              <img src="/images/blog/post16/post16_2.png">
              <img src="/images/blog/post16/post16_3.png">
              <p>저희가 예약한 숙소는 바로 앞에 바다가 있는 "에이트 호텔&amp;펜션" 인데요.</p>
              <p>성수기라 자리가 없을까 노심초사하며 열심히 서치한 결과 찾아낸 숙소에요.</p>
              <p>급하게 예약해 걱정했던것과 달리 숙소도 깔끔하고, 바로 앞에 바다도 있어서 정말 좋았답니다!</p>
              <img src="/images/blog/post16/post16_4.png">
              <img src="/images/blog/post16/post16_5.png">
              <p>호텔 뒷편에 주차장도 널찍하게 있어서 차를 못댈 걱정은 하지 않아도 될정도였어요!</p>
              <p>(사진 외에 옆쪽에 자리가 더 있어서 20대정도는 주차가 가능해보였어요!)</p>
              <img src="/images/blog/post16/post16_6.png">
              <img src="/images/blog/post16/post16_7.png">
              <p>오늘 일정은 기차시간전에 밥을 먹기로 결정!</p>
              <p>모두 짐을 챙겨 차에 싣고 출발!! 목적지는 "최일순 짬뽕 순두부" 인데요.</p>
              <p>제가 정말 좋아하는 순두부집이에요!</p>
              <img src="/images/blog/post16/post16_8.png">
              <img src="/images/blog/post16/post16_9.png">
              <img src="/images/blog/post16/post16_10.png">
              <img src="/images/blog/post16/post16_11.png">              
              <p>자리도 넉넉하고, 식물로 가득해서 뭔가 숲속에서 밥먹는 기분이 든답니다!</p>
              <p>앞접시, 국자, 물수건, 앞치마 등은 셀프로 가져다 쓰시면 되구요!</p>
              <p>처음에 세팅해주시는 밑반찬 외에는 추가로 가져다 드시면 돼요!</p>
              <img src="/images/blog/post16/post16_12.png">
              <img src="/images/blog/post16/post16_13.png">
              <img src="/images/blog/post16/post16_14.png">
              <img src="/images/blog/post16/post16_15.png"> 
              <p>밑반찬은 푸짐하고 가짓수도 많아요!</p>
              <p>여러가지 메뉴 중 저는 늘 기본 짬뽕순두부만 먹다가 처음으로 치즈얼큰이순두부에 도전했고 나머지 두분은 기본 짬뽕순두부를 시켰어요.</p>
              <p>정말 푸짐하지 않나요? 양이 너무 많아서 배가 가득찼지만 그래도 맛있어서 싹싹 비웠답니다!</p>
              <img src="/images/blog/post16/post16_16.png">
              <img src="/images/blog/post16/post16_17.png">
              <img src="/images/blog/post16/post16_18.png">
              <img src="/images/blog/post16/post16_19.png"> 
              <p>밥을 다 먹고 기차시간까지 시간이 조금 남아서, 후식먹고 출발하기로 급 결정!</p>
              <p>역에서 멀지 않은 곳인 "순두부젤라또" 에 방문하였어요! 저희는 역이랑 더 가까운 1호점(본점)으로 갔어요!</p>
              <p>(안목해변쪽에 2호점도 있으니 참고하시면 될 것 같아요!)</p>
              <p>어떤 맛을 먹을까 하다 저희는 모두 기본 순두부젤라또를 선택했는데요.</p>
              <p>다른맛도 궁금했지만 역시 처음은 기본맛이라는 생각으로 골랐는데 역시 탁월한 선택이었던것 같아요!</p>
              <img src="/images/blog/post16/post16_20.png">
              <img src="/images/blog/post16/post16_21.png"> 
              <p>밥도 맛있게 먹고 후식으로 마무리 후에 차량도 반납하고 강릉역으로 돌아왔습니다!</p>
              <p>이제 다시 돌아가야 할 시간이네요....가기 전 강릉역 표지판 앞에서 아쉬움을 담아 한 컷 찍고 이제 기차에 올라탑니다..!</p>
              <p>이번 고성 여행기는 이것으로 끝이지만, 다음은 이번보다 더 재밌는 이야기 들고 올게요! 기대해주세요!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.374225602034!2d128.9012268160014!3d37.80470261820881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3561e5ba4633f45d%3A0xfc1481f7d4bd57a1!2z7LWc7J287Iic7Kes672V7Iic65GQ67aA!5e0!3m2!1sko!2skr!4v1629676560822!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p><strong>최일쑨짬뽕순두부</strong></p>
              <p>영업시간 : 매일 06:30 ~ 21:00</p>
              <p>대표번호 : 033-644-2662</p>
              <p>위치 : 강원 강릉시 안현동 해안로 517</p><br><br>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.9314677930115!2d128.91329591600118!3d37.79164581896032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3561e70373dd029b%3A0xed146764e86b5af9!2z7Iic65GQ67aA7KCk652865iQIOuzuOygkA!5e0!3m2!1sko!2skr!4v1629676660593!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p><strong>순두부젤라또</strong></p>
              <p>영업시간 : </p>
              <p>월,수,목,금,토,일 10:00 ~ 19:30</p>
              <p>월,수,목,금,토,일 브레이크타임 15:30 ~ 16:40</p>
              <p>대표번호 : 010-7523-4487</p>
              <p>위치 : 강원 강릉시 초당순두부길 95-5</p>
              `,
    createDate: 'Posted on August 23, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '15',
    title: '강원도 고성, 여행기 - (1)',
    content: `<img src="/images/blog/post15/post15_1.png">
              <p>통일전망타워, 6.25 전쟁체험 전시관, DMZ박물관까지 모든 일정을 마치고 드디어 첫 바다를 보러 갔어요!</p>
              <img src="/images/blog/post15/post15_2.png">
              <img src="/images/blog/post15/post15_3.png">
              <img src="/images/blog/post15/post15_4.png">             
              <img src="/images/blog/post15/post15_5.png">           
              <p>해수욕을 하는 사람들, 바다에서 사진 찍는 사람들이 참 많았는데요.</p>
              <p>저희도 맑은 바다를 보면서 발도 담가보고, 사진도 찍고 하다보니 피곤이 싹 날아가는 기분이었답니다!</p>
              <p>(모래사장에 회사이름이랑 솔루션이름도 한 번 적어보았어요!)</p>
              <img src="/images/blog/post15/post15_6.png">
              <img src="/images/blog/post15/post15_7.png">
              <img src="/images/blog/post15/post15_8.png">  
              <p>실장님께서 추천해주신 "카페 노메드"!</p>
              <p>입구에 도착하자마자 이국적인 인테리어에 저희팀 모두 감탄을....</p>
              <p>마치 발리에 온 것 같다며 다들 신이나서 입장!</p>
              <img src="/images/blog/post15/post15_9.png">
              <img src="/images/blog/post15/post15_10.png">
              <img src="/images/blog/post15/post15_11.png">             
              <img src="/images/blog/post15/post15_12.png">  
              <p> 입구에 들어서자마자 키오스크가 한 대 서있고 옆에 모바일이 한 대 배치되어있어요.</p>
              <p>여기서 QR코드로 방문자 등록을 해주시고 주문까지 완료하면 되구요.</p>
              <p>내부는 넓진 않지만 자리가 효율적으로 배치되어있었어요.</p>
              <p>카운터부터 전체적으로 인테리어가 발리 느낌이 물씬 나서 마치 동남아에 온듯한 기분을 느낄 수 있었답니다!</p>
              <img src="/images/blog/post15/post15_13.png">             
              <img src="/images/blog/post15/post15_14.png">  
              <p>한 쪽에는 시럽, 티슈, 물이 준비되어있고 신기하게 생긴 거울도 있네요.</p>
              <p>벽면에는 이렇게 동남아 느낌이 나는 거울 및 소품들도 붙어있구요!</p>
              <img src="/images/blog/post15/post15_15.png">             
              <img src="/images/blog/post15/post15_16.png">
              <p>한참 구경하다보니 저희가 시킨 메뉴가 나왔는데요. 고민하다 날이 너무 더워 세잔 모두 에이드로 시켰는데 탁월한 선택이었던 것 같아요.</p>
              <p>저희가 고른 메뉴는 청포도, 자몽, 레몬에이드! 플레이팅이 너무 예쁘게 나와서 사진도 많이 찍고 맛있게 먹었답니다!</p>
              <img src="/images/blog/post15/post15_17.png"> 
              <p>카페에서 조금 쉬었더니 다들 갑자기 배가 고파져서 밥을 먹으러 가기로 했어요. 장소는 "봉포한우숯불구이" 인데요!</p>
              <p>오전에 다들 땡볕에 돌아다니느라 지쳐있어서 고기를 먹고 기운을 내자! 해서 든든하게 고기를 먹으러 왔습니다!</p>
              <img src="/images/blog/post15/post15_18.png">
              <img src="/images/blog/post15/post15_19.png">
              <img src="/images/blog/post15/post15_20.png">             
              <img src="/images/blog/post15/post15_21.png">  
              <p>봉포한우 숯불구이의 메뉴는 굉장히 다양한데요. 저희는 양념모둠, 불판모둠을 시켰어요.</p>
              <p>밑반찬은 꺳잎, 묵, 샐러드, 김치, 콩나물파절임 등 너무 많지도, 부족하지도 않게 나오네요!</p>
              <img src="/images/blog/post15/post15_22.png">             
              <img src="/images/blog/post15/post15_23.png">  
              <p>처음으로 불판모둠을 시켰는데요! 정말 맛있어 보이죠?</p>
              <p>사진에는 없지만 여기에 양념모둠과 냉면까지 배부르게 먹었답니다!</p>
              <p>다들 배부르게 먹고 이제 드디어 숙소로 향할 시간인데요.</p>
              <p>그냥 가기 아쉬워서 바로 앞 편의점에서 간식까지 든든하게 사서 숙소로 출발!</p>
              <img src="/images/blog/post15/post15_24.png">             
              <img src="/images/blog/post15/post15_25.png">  
              <p>숙소에 도착해서 짐을 풀고 디자인팀 워크샵을 진행했어요. 오기전에 사온 간식, 와인 등을 세팅하고 워크샵 시작!</p>
              <p>이번 워크샵에서는 "2021년 디자인 트렌드"와 "브랜드 가이드 리뷰", "디자인실 공통 업무 교육" 까지 진행했는데요!</p>
              <p>각자 발표를 하고 맛있는 간식과 함께 얘기나누다보니 팀 내 결속력이 더 올라간 기분이 드는거 있죠!</p>
              <p>그렇게 저희팀은 워크샵을 잘 마치고 하루를 잘 마무리했답니다..!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d808329.537563634!2d127.9660768340946!3d37.683579773637796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x33c3efb83bd169b9!2z64W466mU65Oc!5e0!3m2!1sko!2skr!4v1629281541997!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p><strong>카페 노메드</strong></p>
              <p>영업시간 : 매일 11:00 ~ 19:00</p>
              <p>대표번호 : 010-4761-6266</p>
              <p>위치 : 강원 고성군 토성면 청간정길 43</p>
              <br>
              <p><strong>봉포한우 숯불구이</strong></p>
              <p>영업시간 : 매일 11:30 ~ 23:00</p>
              <p>대표번호 : 033-632-8287</p>
              <p>위치 : 강원 고성군 토성면 토성로 83</p>
              `,
    createDate: 'Posted on August 20, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '14',
    title: '고성, 통일전망타워에 다녀오다! - (3)',
    content: `<img src="/images/blog/post14/post14_1.png">
              <p>통일전망타워 주차장에서 출발해 5분정도 차를 몰고가다보면 이렇게 DMZ박물관이 나오는데요!</p>
              <p>박물관이 굉장히 크다보니 주차장도 잘되어있고, 공간이 전체적으로 여유롭더라구요.</p>
              <img src="/images/blog/post14/post14_2.png">
              <img src="/images/blog/post14/post14_3.png">
              <p>박물관을 찾아가실때는 헷갈릴쯔음 이렇게 안내판을 통해 길안내를 계속해주니 걱정없이 찾아가실수 있을것 같구요.</p>
              <p>못보고 지나치기엔 굉장히 큼지막하게 되어있으니 혹여나 길을 잘못들까 걱정하지 않으셔도 될 것 같아요.</p>
              <img src="/images/blog/post14/post14_4.png">
              <img src="/images/blog/post14/post14_5.png">
              <img src="/images/blog/post14/post14_6.png">
              <img src="/images/blog/post14/post14_7.png">
              <p>주차장에 차량을 주차하고 박물관 입구를 향해 가다보면 DMZ박물관에 온게 느껴지는 여러가지 조형물들이 배치되어있구요.</p>
              <p>그 옆에는 북한주민들이 탈북시 사용된 배나, 어떤물품등을 가져오고 사용했는지 외부에 전시되어 있어요.&nbsp;</p>
              <img src="/images/blog/post14/post14_8.png">
              <img src="/images/blog/post14/post14_9.png">
              <img src="/images/blog/post14/post14_10.png">
              <img src="/images/blog/post14/post14_11.png">
              <p>입구에 들어서면 가장 먼저 평창올림픽 마스코트 수호랑과 반다비가 관람객들를 반겨주고 있어요!</p>
              <p>층별로 어떻게 구성되어있는지나 DMZ에 관한 설명들도 잘 요약되어있고 사진에는 없지만 안내데스크도 있으니 궁금하신점이 있다면 미리 물어보시고 들어가면 좋을것 같아요!</p>
              <br/>
              <p>자 그럼 이제 박물관 내부 관람을 시작해볼까요?</p>
              <img src="/images/blog/post14/post14_12.png">
              <img src="/images/blog/post14/post14_13.png">
              <img src="/images/blog/post14/post14_14.png">
              <img src="/images/blog/post14/post14_15.png">
              <p>1zone부터 차례대로 관람하다보면 정말 다양한 사진들과 내용들이 나오는데요.</p>
              <p>전체적으로 공간을 잘 활용해서 구성해놓은것 같았어요.</p>
              <p>개인적으로 글로된 내용보다 사진이나 조형물들이 많이 배치되어 있어서 더 집중도 있었고, 더 잘 와닿았던 것 같아요.</p>
              <img src="/images/blog/post14/post14_16.png">
              <img src="/images/blog/post14/post14_17.png">
              <img src="/images/blog/post14/post14_18.png">
              <img src="/images/blog/post14/post14_19.png">
              <img src="/images/blog/post14/post14_20.png">
              <img src="/images/blog/post14/post14_21.png">
              <p>천장에서 총을 겨누고 있는 모형들이 있거나, 실제철조망에 설치한 다양한 문구의 경고문, 패치 등 각 나라별로 다른 군복 실루엣 등등 굉장히 신경써서 박물관을 구성해놓은게 느껴졌어요.</p>
              <p>단순히 글을 읽고 영상을 보고 끝이 아니라, 최대한 DMZ에 있는 것 같은 느낌을 주려고 한 점이 굉장히 인상깊었어요.</p>
              <img src="/images/blog/post14/post14_22.png">
              <img src="/images/blog/post14/post14_23.png">
              <p>가장 마지막 Zone에는 통일의 염원을 담은 메시지를 통일 나무에 달아서 소원을 비는 공간이 따로 마련되어 있어요.</p>
              <p>쌓여있는 개수만 보아도 얼마나 많은 사람들이 다녀갔는지 알 수 있겠죠?</p>
              <img src="/images/blog/post14/post14_24.png">
              <img src="/images/blog/post14/post14_25.png">
              <img src="/images/blog/post14/post14_26.png">
              <img src="/images/blog/post14/post14_27.png">
              <p>끝에는 기념품샵이 있는데 한국스러움을 잘 나타낼 수 있는 기념품들을 많이 판매하고 있더라구요!</p>
              <p>기념으로 하나씩 구매하시는것도 좋은 추억이 될 수 있을것 같아요!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3119.1481755834902!2d128.38034911601312!3d38.5764354734028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5fd856d0636721d3%3A0x24a8d73a5cb08f8a!2zRE1aIOuwleusvOq0gA!5e0!3m2!1sko!2skr!4v1629263588795!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p>영업시간 :</p>
              <p>11월1일~2월28일 09:00 ~ 17:00(입장마감 16:10, 관람마감 16:50)</p>
              <p>11월1일~2월28일 09:00 ~ 18:00(입장시간 : 관람시간 1시간 전까지)</p>
              <p>매주 월요일, 1월1일 휴무 </p>
              <p>대표번호 : 033-681-0625</p>
              <p>위치 : 강원 고성군 현내면 통일전망대로 369</p>
              `,
    createDate: 'Posted on August 19, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '13',
    title: '고성, 통일전망타워에 다녀오다! - (2)',
    content: `<img src="/images/blog/post13/post13_1.png">
              <img src="/images/blog/post13/post13_2.png">
              <img src="/images/blog/post13/post13_3.png">
              <p>처음 딱 들어오게 되면 정면에는 통일전망타워 외부로 나갈 수 있는 문이 있고, 위로 올라가는 계단들도 중간중간 있는데 지금은 코로나 때문에 1층과 외부만 관람할 수 있더라구요..</p>
              <p>좌측으로는 이렇게 특산물들도 소개 및 판매하고 있어요!</p>
              <img src="/images/blog/post13/post13_4.png">
              <img src="/images/blog/post13/post13_5.png">
              <p>특산물 외에 이렇게 평화의 소망나무라는 것도 설치되어 있는데요.</p>
              <p>지금은 코로나로 인해 운영을 잠시 중단했지만 그동안 많은 사람들이 남겨둔 글들이 쌓여있어요!</p>
              <p>통일을 염원하는 마음으로 한반도 모양의 판에 글을 써서 걸어둘 수 있어요!</p>
              <img src="/images/blog/post13/post13_6.png">
              <img src="/images/blog/post13/post13_7.png">
              <img src="/images/blog/post13/post13_8.png">
              <img src="/images/blog/post13/post13_9.png">
              <p> 외부로 나가면 우리가 갈 수 없는 북한의 모습을 조금이라도 가깝게 볼 수 있도록 이렇게 망원경도 배치되어 있구요.</p>
              <p>여러대의 망원경이 배치되어 있는데도 빈자리가 나지 않을만큼 많은 사람들이 DMZ공간을 궁금해하더라구요!</p>
              <p>카메라를 잔뜩 확대시켜 찍은 금강산 전망대도 보이구요.</p>
              <img src="/images/blog/post13/post13_10.png">
              <img src="/images/blog/post13/post13_11.png">
              <p>외부로 나가는 문 입구에 이렇게 DMZ를 바라보았을 때 어디가 어디인지 표시해주는 지도도 큼지막하게 배치되어 있어요!</p>
              <p>모르고 그냥 보는것보다 이렇게 뭐가 어디에 있는지 알고본다면 더 재밌게 볼 수 있겠죠?</p>         
              <img src="/images/blog/post13/post13_12.png">
              <img src="/images/blog/post13/post13_13.png">
              <p>통일전망타워 외부로 나가 망원경 없이 바라봤을때의 전경이에요.</p>
              <p>너무 아름답지 않나요? 우측으로는 해금강이, 좌측으로는 금강산이 위치한 DMZ는 맑은 날씨에 보니 정말정말 아름다운 곳이더라구요.</p>
              <p>언젠가 저 공간에 발을 들일 수 있는날이 왔으면 좋겠어요.</p>
              <img src="/images/blog/post13/post13_14.png">
              <p>통일전망타워를 모두 구경하고, 이번에는 중간길로 올라올때와 반대로 약간 돌아서 바다를 보며 내려갔는데요!</p>
              <p>중간에 이렇게 사람들이 평화의 메세지를 팔찌와 함께 끈으로 매달아 두었더라구요.</p>
              <p>이렇게 통일을 염원하는 마음들이 여기저기 보이는게 참 좋았답니다!</p>
              <img src="/images/blog/post13/post13_15.png">
              <img src="/images/blog/post13/post13_16.png">
              <img src="/images/blog/post13/post13_17.png">
              <img src="/images/blog/post13/post13_18.png">
              <p> 아래로 내려와 다시 주차장쪽으로 오게되면 한쪽에 이렇게 6.25 전쟁체험 전시관이 있는데요!</p>
              <p>이 곳은 병영체험실, 6.25전쟁자료실, 기획전시실, 국군홍보실, 전사자유해발굴실, 영상체험실, 유엔참전국실 등으로 구성되어 있어요.</p>
              <p>관람하기전 6.25전쟁의 역사에 대해 짧게 살펴볼 수 있도록 되어있구요!</p>
              <img src="/images/blog/post13/post13_19.png">
              <img src="/images/blog/post13/post13_20.png">
              <img src="/images/blog/post13/post13_21.png">
              <img src="/images/blog/post13/post13_22.png">
              <p>예전과 오늘의 병영을 비교해둔 곳도 있고, 전쟁을 체험을 위해 마련된 공간도 있어요.</p>
              <p>이런것들을 둘러보면서 그 당시 전쟁을 겪었던 분들이 얼마나 힘들었을지 다시 한 번 느껴볼 수 있었어요.</p>
              <img src="/images/blog/post13/post13_23.png">
              <img src="/images/blog/post13/post13_24.png">
              <img src="/images/blog/post13/post13_25.png">
              <p>전사자 유해와 더불어 그 밖에 신발, 칫솔, 수통, 벨트 등등 수많은 물건들도 볼 수 있었고 다양한 종류의 지뢰들이 있다는 것도 알 수 있었어요.</p>
              <p>이번에 6.25 전쟁체험 전시관을 관람하면서 정말 다양한 나라의 많은 분들이 우리나라를 위해 힘써 주셨다는것과 분단의 아픔을 잊지 말아야 한다는 것을 느꼈어요.</p>
              <p><br/><p>
              <p>다음은 전시관을 나와 조금은 엄숙해진 기분으로 DMZ박물관으로 출발..!</p>
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3118.8352754418356!2d128.37068261601326!3d38.58364097298085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5fd85137bc21b7cd%3A0x89f99cd74c63c783!2z6rOg7ISx7Ya17J287KCE66ed7YOA7JuM!5e0!3m2!1sko!2skr!4v1629165386483!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
             <p>영업시간 :</p>
             <p>3.1 ~ 7.14 / 8.21 ~ 10.31 / 09:00 ~ 16:50</p>
             <p>7.15 ~ 8.20 / 09:00 ~ 17:50</p>
             <p>11.1 ~ 2.28 / 09:00 ~ 15:50</p>
             <p>대표번호 : 033-682-0088</p>
             <p>위치 : 강원도 고성군 현내면 금강산로 481</p>
             `,
    createDate: 'Posted on August 18, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '12',
    title: '고성, 통일전망타워에 다녀오다! - (1)',
    content: `<img src="/images/blog/post12/post12_1.png">
              <p>안녕하세요~~~~~~이번에 저희 디자인팀에서 강원도 고성에 간다면 꼭 한 번 가봐야 한다는 통일전망타워를 다녀왔습니다!</p>
              <p>DMZ공간도 볼 수 있고 6.25 전쟁체험 전시관, DMZ박물관도 가까워 세가지를 같이 볼 수 있는 곳이랍니다!&nbsp;일석삼조!</p>
              <p>다만 꼭 차량이 있어야 갈 수 있는 곳이니 참고해주세요!</p>
              <p>차를 타고 가실때는 “통일전망대 주차장” 또는 “통일전망대 출입신고소”를 검색하고 가시면 되구요!</p>
              <p>도착하시게 되면 이렇게 차량을 대기시켜 놓는 곳이 있는데요~ 안내해주시는분들이 있으시니까 지시에 잘 따라주시면 된답니다!</p>
              <img src="/images/blog/post12/post12_2.png">
              <img src="/images/blog/post12/post12_3.png">
              <p>차량을 이렇게 대기시켜놓는 이유는 좌측 사진을 보시면 "통일전망대 출입신고 하는곳" 이라고 적혀있는데요~</p>
              <p>이렇게 따로 신고를 하고, 정해진 시간에 맞춰 대기중인 차량들이 동시에 같이 이동한답니다!</p>
              <img src="/images/blog/post12/post12_4.png">
              <img src="/images/blog/post12/post12_5.png">
              <p>차량을 대기시켜놓고 주변에 있는 안내표지판들을 따라가다보면 우측 사진처럼 발열체크하는 곳이 나오고, 이곳에서 간단하게 체온측정 후 입장하게 된답니다!<br>
              <p>발열 체크 후에 DMZ팔찌를 채워주시는데, 뒤쪽에서 살짝 다시 얘기할게요!</p>
              <img src="/images/blog/post12/post12_6.png">
              <img src="/images/blog/post12/post12_7.png">
              <p>입장 후 안으로 쭉 걸어들어가보면 이렇게 통일전망대 신청서 배부/접수하는 곳이 나오는데요!</p>
              <p>좌측에서 먼저 신청서를 배부받고 정면에 보이는 안내사항들, 주의사항들 꼭 읽어보신 후에! 신청서를 작성하시고 접수해주시면 돼요!</p>
              <img src="/images/blog/post12/post12_8.png">
              <img src="/images/blog/post12/post12_9.png">
              <p>신청서는 대표자 성명 및 생년월일, 방문인원, 주소, 차량번호, 차종, 전화번호, 동승자 명단까지 작성해주시면 되구요.</p>
              <p>작성하는 곳에 견본이 배치되어 있으니 헷갈리시는 분들은 참고하셔서 작성해주세요~</p>
              <img src="/images/blog/post12/post12_10.png">
              <img src="/images/blog/post12/post12_11.png">
              <p>이제 신청서까지 다 접수하신분들은 출발! 하면 좋겠지만, 통일전망대를 들어가기 전에 간단한 안보교육을 진행하고 있어요~</p>
              <p>30분마다 8분길이의 영상을 시청하게 되는데, 현재는 코로나로 인해 안보교육은 생략했고, 신청서 접수 시 직원분이 안내해주세요!</p>
              <p>아 그리고 출발하기전 발열체크를 하면서 이렇게 팔찌를 채워주는데요! 이 팔찌는 절대 잃어버리시면 안되니까 주의해주세요!</p>
              <p>자 그럼, 이제 진짜 준비완료 했으니 통일전망대로 출발해볼까요?</p>
              <img src="/images/blog/post12/post12_12.png">
              <img src="/images/blog/post12/post12_13.png">
              <p>지정된 시간에 맞춰서 차량들이 다같이 출발을 하는데, 생각보다 많이 들어가야해요!</p>
              <p>혹시라도 어떻게 가는지 모르실 때는 같이 출발한 차들을 따라가는게 제일 좋은 방법이지만, 중간중간 위쪽에 방향을 알려주는 표지판도 있으니 만약 길이 헷갈리신다면 표지판을 참고하는것도 좋은 방법이에요!</p>
              <p>통일전망타워를 향해 쭉 달리다보면 중간에 검문소가 나와요! 검문소를 지나고나면 사진촬영은 가능하지만, 영상촬영은 금지되어있으니 절대 찍으시면 안돼요!</p>
              <p>검문소에서 아까 작성한 신고서와 팔찌를 확인하고, 차량 내부에 신고서에 없는 사람이 있는지 확인 후에 오른쪽 사진과 같이 노란 출입증을 주는데, 이걸 차량 앞쪽에 꼭 배치하고 다녀야해요.&nbsp;군용차량을 추월하거나 하는등의 규칙도 있으니 주의해서 다녀야 한답니다!</p>
              <img src="/images/blog/post12/post12_14.png">
              <img src="/images/blog/post12/post12_15.png">
              <p>쭉 달리고 달리면, 넓은 주차장이 나오는데요, 여기서 먹을거리도 살 수 있고, 각종 기념품도 판매하고 있답니다.</p>
              <p>그리고 저 멀리 통일전망타워가 보이는데, 차량을 주차하고 저기까지 걸어올라가야 해요.</p>
              <img src="/images/blog/post12/post12_16.png">
              <img src="/images/blog/post12/post12_17.png">
              <img src="/images/blog/post12/post12_18.png">
              <img src="/images/blog/post12/post12_19.png">
              <p>이렇게 우거진 숲길 사이를 쭉 올라가다보면....</p>
              <img src="/images/blog/post12/post12_20.png">
              <p>드디어 통일전망타워에 도착했어요!</p>
              <img src="/images/blog/post12/post12_21.png">
              <img src="/images/blog/post12/post12_22.png">
              <p>대부분이 아래 주차장에 주차하고 걸어올라오시는데, 위쪽에도 이렇게 작게 주차장이 있더라구요.</p>
              <p>다만 위에 자리가 없다면 다시 내려가서 주차하고 오셔야 하기 때문에 그냥 아래에 대는게 마음이 편할수도..</p>
              <p>(하지만 저희는 빈자리가 있었어서 조금 후회했어요..)</p>
              <img src="/images/blog/post12/post12_23.png">
              <img src="/images/blog/post12/post12_24.png">
              <img src="/images/blog/post12/post12_25.png">
              <img src="/images/blog/post12/post12_26.png">
              <p>외부에는 직접 보낼 수 있는 우체통도 있었는데 지금은 잠시 운영을 중단한것 같았어요.</p>
              <p>이외에도 DMZ 평화의길 A코스 집결지, 공군351고지 전투지원작전기념비, 고성먹거리 관광지, 국토종주 자전거길 안내도 등 여러가지 정보 및 볼거리들이 있었답니다!</p>
              <br/>
              <p>자 그러면 이제 통일전망타워 내부로 들어가볼까요?</p>
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3118.8352754418356!2d128.37068261601326!3d38.58364097298085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5fd85137bc21b7cd%3A0x89f99cd74c63c783!2z6rOg7ISx7Ya17J287KCE66ed7YOA7JuM!5e0!3m2!1sko!2skr!4v1629165386483!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
             <p>영업시간 :</p>
             <p>3월1일 ~ 7월14일, 8월21일 ~ 10월31일 - 09:00 ~ 16:50</p>
             <p>7월15일 ~ 8월20일 - 09:00 ~ 17:50</p>
             <p>11월1일 ~ 2월28일 - 09:00 ~ 15:50</p>
             <p>대표번호 : 033-682-0088</p>
             <p>위치 : 강원도 고성군 현내면 금강산로 481</p>
             `,
    createDate: 'Posted on August 17, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '11',
    title: '구디역 맛집, 이태리부대찌개',
    content: `<img src="/images/blog/post11/post11_1.jpg">
              <p>오늘은 직장인 맛집이라 불리는 구로디지털단지역 근처 이태리부대찌개를 소개해드릴게요!</p>
              <p>전체적으로 깔끔한 느낌으로 가성비 갑! 인 부대찌개 집이에요!!</p>
              <img src="/images/blog/post11/post11_2.jpg">
              <img src="/images/blog/post11/post11_3.jpg">
              <p>외관은 이렇게 모던한 디자인으로 되어있구요~ 이마트 건너편에 위치해 있으며  지플러스 코오롱 디지털타워 건물 2층이에요!</p>
              <p>매장 구조는 크게 ‘ㄱ’자로 생각보다 넓어요! 내부도 깔끔해서 좋아요~</p>
              <p>그리고 또 좋은 점이 있다면 카운터에서 QR코드를 찍거나 명부작성을 하는</p>
              <p>번거로움 없이 제자리에서 스마트폰으로 편리하게 코드를 찍어 처리할 수 있어요!</p>    
              <img src="/images/blog/post11/post11_4.jpg">
              <img src="/images/blog/post11/post11_5.jpg">
              <p>메인메뉴와 사이드 메뉴입니다!</p>
              <p>메인메뉴에서 부대찌개와 부대전골이 있는데 차이는 부대찌개보다 전골이 천원 더 비싸고 그만큼 햄이 더 푸짐하다고 해요~</p>
              <p>사리를 더 추가하고 싶으시면 사이드메뉴에서 다양한 사리를 추가할 수 있어요!</p>
              <img src="/images/blog/post11/post11_6.jpg">
              <img src="/images/blog/post11/post11_7.jpg">
              <p>기본 반찬은 제공되고 모자라는 반찬은 셀프코너에서 먹을 만큼 담아 올 수 있어요~~</p>
              <p>그리고 정말 좋은 점은 밥과 라면 사리가 무제한이라는 점이에요!! (요즘 물가에선 상상도 못 하는…!)</p>
              <img src="/images/blog/post11/post11_8.jpg">
              <img src="/images/blog/post11/post11_9.jpg">
              <p>반찬은 콩나물, 배추김치, 어묵이 나와요. 그리고 이곳은 버터를 제공해줍니다~</p>
              <p>밥을 버터에 비벼 먹으면 고소하고 부드러워요..! 버터는 기호에 맞게 넣어 드시면 좋을 것 같아요!</p>
              <img src="/images/blog/post11/post11_10.jpg"> 
              <p>저희는 부대전골을 주문했어요!! 사진을 보니 또 군침이..ㅎㅎ</p>
              <p>다양한 햄과 소시지, 신선한 채소들로 푸짐해요~~ 기름이 많지 않아 먹고 나서도 깔끔하답니다! 부대찌개 1인분 8,000원, 부대전골은 9,000원인데 밥과 라면 사리 무한리필… 가성비 최고인 것 같아요!</p>
              <p>코로나가 잠잠해지면 저희 직원분들과 다 같이 또 가고 싶네요!ㅠㅠ</p>
              <p>평일 점심, 저녁 시간에는 북적일 수 있으니 여유롭게 드시고 싶으시면 그 시간 피해서 혹은 주말에 오시면 좋을 것 같아요!</p>
              <br/>
              <p>+밥 먹고 후식으로 ‘우리동네구로커피집’을 갔습니다. 부대찌개 집에서 정말 가까워요!</p>
              <img src="/images/blog/post11/post11_11.jpg">
              <img src="/images/blog/post11/post11_12.jpg">
              <p>대표 음료로 딸기우유가 있는데 수년간 개발한 수제 딸기청이라고 하네요!</p>
              <p>부대찌개로 엄청 배불렀는데도 맛있게 먹었답니다ㅎㅎ</p>
              <p>아메리카노도 맛집이라고 하니 음료 드시고 싶으시면 '우리동네구로커피집’에서 시원하게 음료 마시는 것도 추천해 드려요!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50657.45602072549!2d126.86245499322852!3d37.482178860031624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f6f4bc073e5%3A0x86211a9025ab6d44!2z7J207YOc66as67aA64yA7LCM6rCcIOq1rOuhnOygkA!5e0!3m2!1sko!2skr!4v1627934139332!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>     
              <p><strong>이태리부대찌개 구로점</strong></p>  
              <p>영업시간 : 매일 11:00~22:00 명절휴무</p>   
              <p>대표번호 : 02-6969-7180</p>
              <p>위치 : 서울 구로구 디지털로26길 123</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.703300417171!2d126.89539663119069!3d37.48218404604371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e25acd48139%3A0x33abd0806a0da2e2!2z7Jqw66as64-Z64Sk6rWs66Gc7Luk7ZS87KeR!5e0!3m2!1sko!2skr!4v1627934086412!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              <p><strong>우리동네구로커피집</strong></p> 
              <p>영업시간 : 평일 07:30~20:00</p> 
              <p>대표번호 : 070-4245-2988</p> 
              <p>위치 : 서울 구로구 디지털로26길 110</p> 
              `,
    createDate: 'Posted on August 02, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '10',
    title: '대림동 맛집, 공주칼국수!',
    content: `<img src="/images/blog/post10/post10_1.png">
              <p>오늘은 대림역 근처에 있는 공주칼국수를 소개해드리도록 할게요!</p>
              <p>보통 칼국수집은 일반적으로 맑은칼국수가 메인메뉴인데, 여기는 얼큰칼국수가 메인이에요!</p>
              <img src="/images/blog/post10/post10_2.png">
              <img src="/images/blog/post10/post10_3.png">
              <p>외관은 이렇게 길쭉하게 가로로 되어있고,  1층짜리 식당이에요. 입구에는 앉아서 기다릴 수 있는 의자도 있어요!</p>
              <p>점심때 가면 사람이 바글바글해서, 저희는 아예 일찍가거나, 아예 늦게 간답니다.</p>
              <img src="/images/blog/post10/post10_4.png">
              <p>이전에 비해 훨씬 깔끔해진 공주칼국수의 메뉴판이에요!</p>
              <p>예전에는 칼국수, 콩국수, 만두 이 세가지 뿐이었는데, 지금은 겨울메뉴도 생겼고, 콩물이랑 생면도 따로 판매하고 있답니다!</p>         
              <img src="/images/blog/post10/post10_5.png">
              <p>내부는 4인석 테이블 7개정도로 되어있고, 입구쪽은 카운터와 주방이 있어요!</p>
              <p>그리고 사진에 나온것처럼 생활의달인에도 나온 칼국수집입니다! (그래서 더 믿음이 가는...)</p>
              <img src="/images/blog/post10/post10_6.png">
              <img src="/images/blog/post10/post10_7.png">
              <p>매운 정도를 선택할 수 없는 대신, 살짝 더 맵게 먹고 싶은 사람들을 위한 고추장아찌와 콩국수에 깊은맛을 더해줄 히말라야 핑크소금까지 테이블마다  배치되어 있어요!</p>
              <img src="/images/blog/post10/post10_8.png">
              <img src="/images/blog/post10/post10_9.png">
              <p>겉절이, 단무지, 쑥갓이 기본적으로 나오는데, 이 겉절이가 진짜 맛있답니다! 올 때마다 늘 싹싹 비우게 되는 맛이에요...!</p>
              <p>곁들임으로 만두도 추가 주문했어요! 칼국수 먹는데 만두가 빠지면 섭하잖아요..?</p>
              <img src="/images/blog/post10/post10_10.png">
              <img src="/images/blog/post10/post10_11.png">            
              <p>드디어 나온 메인메뉴들! 쑥갓을 좋아하시는분은 기호에 따라 칼국수에 추가로 넣어서 드시면 되고, 콩국수는 국물이 다른곳에 비해 엄청 걸쭉하고, 진해요! 그래서 콩국수를 별로 좋아하지 않는 직원분들도 여기선 맛있게 드시더라구요!</p>
              <p>양도 여성분들은 다 못드시는 경우도 있을만큼 푸짐하고 많아요!</p>
              <p>이번에는 한여름이다보니, 칼국수와 콩국수밖에 못먹었지만 겨울에는 꼬막비빔칼국수도 꼭 먹어봐야겠어요!</p>
              <p>칼국수, 콩국수를 좋아한다면 대림동 공주칼국수 집에서 꼭 한 번 드셔봤으면 좋겠어요!</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25325.756387605095!2d126.87887253955077!3d37.490944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e47cceee8e1%3A0x7ae80bf3f648284c!2z6rO17KO87Lm86rWt7IiY!5e0!3m2!1sko!2skr!4v1626657392366!5m2!1sko!2skr" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
    createDate: 'Posted on July 23, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.ETC,
    imgUrls: [],
  },
  {
    id: '9',
    title: '아이지아이 코리아, 이사하다!',
    content: `<img src="/images/blog/post9/post9_1.png">  
              <p>드디어 아이지아이 코리아 사무실 이사를 완료했어요~~~~~~~~~~!</p>
              <p>일찍부터 나와서 짐 나르고 정리하느라 힘드셨을 텐데 다들 고생하셨습니다!</p>
              <p>이전 사무실은 하나를 반으로 나눠서 두 회사가 같이 쓰는 형식이라 조금 좁았었는데 온전히 하나의 사무실을 쓰게 되니 다들 너무 좋아하시더라구요!!</p>
              <p>두 배는 넓어진 사무실을 보니 뿌듯하고, 바닥도 새로 해서 깨끗한 사무실을 보니 더욱더 맘에 들었습니다..!</p>
              <img src="/images/blog/post9/post9_2.png">  
              <img src="/images/blog/post9/post9_3.png">
              <p>우선 사무실을 소개해드리자면, 입구에는 신발장과 다 같이 사용하는 넓은 테이블이 가장 먼저 배치되어 있어요!</p>
              <p>저희는 사무실 내에서 각자 슬리퍼를 따로 신고 있어서, 출근하면 신발을 가장 먼저 갈아신구요.</p>
              <p>옆에 있는 넓은 테이블에서는 간단한 회의를 하기도 하고, 직원들끼리 커피 마시면서 가볍게 수다도 떨 수 있는 공간이 있어요.</p>
              <br/>
              <p>아무래도 사무실이 두 배나 커지다 보니 이사하면서 엄청 많은 것들을 구매했는데, 그중 하나가 이전에 없었던 파티션이에요.</p>
              <p>정말 고심에 고심해서 골랐는데, 사무실 느낌과 참 잘 어울리는 게 완벽한 선택이었던 것 같아요.</p>
              <img src="/images/blog/post9/post9_4.png">  
              <img src="/images/blog/post9/post9_5.png">
              <p>그리고 이번에 이사하면서 대표님께서 직원분들의 PC를 아이맥으로 교체해주셨어요!!</p>
              <p>허리가 안 좋은 직원들에게는 스탠딩 데스크까지 마련해주신 대표님...감사합니다..!</p>
              <img src="/images/blog/post9/post9_6.png">  
              <img src="/images/blog/post9/post9_7.png">
              <p>탕비실에는 간식들이 다양하게 채워져 있는데요!</p>
              <p>직원들 배고프지 말라고 대표님이 늘 빈틈없이 주문해주십니다..!</p>
              <p>(제가 육개장을 좋아한다고 말씀드렸더니, 다음날 바로 육개장이..)</p>
              <br/>
              <p>아직 사무실이 다 꾸며진 건 아니지만 필요한 것들을 조금씩 채워갈 예정입니다!</p>
              <p>다양한 것들을 해보려고 시도 중이니 기대해주세요!</p>`,
    createDate: 'Posted on July 9, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.EVENT,
    imgUrls: [],
  },
  {
    id: '8',
    title: "제조혁신 가속화 위한 '디지털 트윈 기술'이 뜨고 있다",
    content: `<img src="/images/blog/post8/post8_1.jpg">  
              <p><strong>[경남매일]</strong></p>
              <br/>
              <p>'최상수 아이지아이코리아 대표와 정기욱 박사(경남매일 전략자문)가 제조혁신 가속화를 위한 디지털 기술에 대한 소개와 국내의 중소기업이 이를 활용하기 위한 방안 제시를 정리해 싣는다.</p>
              <p>'디지털 트윈 구축을 고려하고 있는 기업들은 다음과 같은 세 가지 사항을 필수적으로 고려해야 한다.'</p>
              <br/>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3IbickH" target="_blank">link: https://bit.ly/3IbickH</a>`,
    createDate: '2021-07-08 12:48',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '7',
    title: 'Digital Twin',
    content: `<img src="/images/blog/post7/post7_1.png">  
              <p>The digital twin is one of the key feature technologies in the era of the 4th industrial revolution.</p>
              <p>General Electric (GE) defined a digital twin as “a technology that predicts results in advance by creating twins of objects in the real world on a computer and simulating situations that may occur in real life”.</p>
              <p> Cases of building digital twins are being actively reported mainly by developed countries and global manufacturing companies.</p>
              <p>However, in order to establish  a digital twin, various information and communication technologies (ICT) must be connected and integrated, and it is practically impossible to establish an ideal digital twin in a short period of time.</p>
              <p>Therefore, it is necessary to strategically build a digital twin according to the realistic situation of each manufacturing company step by step.</p>
              <img src="/images/blog/post7/post7_2.svg">  
              <p>Schleich et al [1] divided terms and concepts into Digital Model (DM), Digital Shadow (DS), and Digital Twin (DT) depending on whether the data flow between the digital object and the physical object is automatic or manual.</p>
              <p>As shown in Fig. 1, a form in which data flow between physical and digital objects is passive in both directions is defined as DM. DS automatically links data from physical objects to digital objects, but it manually links data from digital objects to physical objects.</p>
              <p>DT is a bidirectional automated form of data flow between digital and physical objects.</p>
              <p>Also, as a result of examining existing papers, it was reported that studies on DT, which are currently the highest level, are very rare, and most of them are focused on DM and DS.</p>
              <br/>
              <p>The results of research and analyzing domestic studies related to digital twin [2] are similar to those of the study of Schleich et al [1]. Cases of the highest level of DT are very rare, and most are concentrated in DS. The fact that there are not many cases of DM was identified as a different point.</p>
              <p>Most of the studies are still at a very early stage, and the level of application is also narrow that most have been identified as being applied as test beds.</p>
              <br/>
              <p>Ontwins has DM, DS, and DT applications in many companies.</p>
              <p>In the future, various digital twin cases will be introduced.</p>
              <br/>
              <p>디지털 트윈은 4차 산업혁명 시대의 핵심 미래 기술 중 하나이다.</p>
              <p>GE (General Electric)는 디지털 트윈을 “컴퓨터에 현실 속 사물의 쌍둥이를 만들고, 현실에서 발생할 수 있는 상황을 컴퓨터로 시뮬레이션 함으로써 결과를 미리 예측하는 기술”이라고 정의했다.</p>
              <p>선진국과 글로벌 제조기업을 중심으로 디지털 트윈 구축 사례들이 활발히 발표되고 있다.</p>
              <p>하지만 디지털 트윈 구축을 위해서는 다양한 ICT (Information and Communication Technology)들이 연결되어 통합되어야 하고, 단기간에 이상적인 형태의 디지털 트윈이 구축되기는 현실적으로 불가능하다.</p>
              <p>따라서 단계적으로 각 제조기업의 현실적인 상황에 맞게 전략적으로 디지털 트윈을 구축해 나가야 한다.</p>
              <br/>
              <p>Schleich et al [1]는 디지털 트윈 사이의 물리적 개체와 디지털 개체 간 데이터 흐름의 자동 혹은 수동 여부에 따라 DM (Digital Model), DS (Digital Shadow) 및 DT (Digital Twin)로 용어와 개념을 구분하였다.</p>
              <p>Fig. 1과 같이 물리적 개체와 디지털 개체 간 데이터 흐름이 양방향 모두 수동인 형태를 DM으로 정의했다.</p>
              <p>DS는 물리적 개체에서 디지털 개체로는 데이터가 자동으로 연결되지만 디지털 개체에서 물리적 개체로 연결되는 데이터는 수동인 방식이다.</p>
              <p>DT는 디지털 개체와 물리적 개체 간 데이터 흐름이 양방향 자동화된 형태이다.</p>
              <p>또한 기존 논문들을 조사한 결과 현재 가장 높은 수준인 DT에 관한 연구들은 매우 드물고, 대부분이 DM과 DS에 집중되고 있다고 보고했다.</p>
              <br/>
              <p>디지털 트윈 관련 국내 연구들을 조사 및 분석한 결과[2] Schleich et al [1]의 연구와 유사한 결과를 보였다.</p>
              <p>가장 높은 수준인 DT에 대한 사례는 매우 드물고, 대부분이 DS에 집중되어 있었다. DM의 사례가 많지 않다는 것이 다른 점으로 파악되었다.</p> 
              <p>대부분의 연구들이 아직은 매우 초기 수준들이고, 적용 수준 또한 국소적이며, 테스트베드 정도로 적용된 것으로 파악되었다.</p>
              <br/>
              <p>OnTwins는 많은 기업들에서의 DM, DS, DT 적용 사례들을 보유하고 있다.</p>
              <p>앞으로 다양한 디지털 트윈 사례들이 소개할 예정이다.</p>
              <br/>
              <p><strong>[References]</strong></p>
              <br/>
              <p>[1] Kritzinger, W., Karner, M., Traar, G., Henjes, J. and Sihn, W., Digital Twin in manufacturing: A categorical literature review and classification, IFAC-PapersOnLine, 51(11), 2018, 1016-1022.</p>
              <p>[2] Choi, S., Woo, J., Kim, J. Choi, W., Kim, J., and Lee, J.,
               A Survey and Analysis of Research on Digital Twin in Korea, Korean Journal of 
               Computational Design and Engineering, 26(1), 2021, 59-69.</p>
              <br/>
              <p>Written by Dr. SangSu Choi</p>`,
    createDate: 'Posted on July 2, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.TECH,
    imgUrls: [],
  },
  {
    id: '6',
    title: 'Reference Architecture',
    content: `<img src="/images/blog/post6/post6_1.jpg">
              <p>Smart manufacturing is realized through combination of cutting-edge information technology (IT) and operation technology (OT) such as IoT, big data, cloud computing, artificial intelligence, modeling &amp; simulation, VR/AR/ additive manufacturing, CNC machine tools, and collaborative robots.</p>
              <p>It is most important to secure interoperability for these heterogeneous technologies to be fused and integrated.</p>
              <p>Reference models and architectures have recently been developed to solve the problems of integration and interoperability for smart manufacturing.</p>
              <img src="/images/blog/post6/post6_2.jpg">
              <p>RAMI 4.0 was developed by several German-based industry, academia, and research partners [1].</p>
              <p>RAMI 4.0 solves interoperability by proposing a series of abstract layers for the manufacturing industry as shown in [Figure 1].</p>
              <p>The description of each layer is as follows [2].</p>
              <p>① Business Layer: This layer consists of business models and information about business components such as services and products they provide.</p>
              <p>② Functional Layer: The functions of the architectural model are characterized, detailed, and their relationships established.</p>
              <p>③ Information Layer: This layer controls and manages the data and information used in the architecture.</p>
              <p>④ Communication Layer: Communication between layers, systems, and all running components can be interoperable at the same time.</p>
              <p>⑤ Integration Layer: This layer connects all layers and physical components of this architecture. It also covers network and software integration.</p>
              <p>⑥ Asset Layer: It includes physical objects such as people and equipment that exist in the real world.</p> 
              <br/>
              <p>RAMI 4.0 model has been developed by studying existing approaches and integrating them into the interoperability stack.</p>
              <p>The interoperability stack has been designed and built using existing standards such as OPC-UA, AutomationML [3] and ProSTEP.</p>
              <p>In addition, the architecture incorporates IEC 62890 [4] functions for lifecycle improvement and value stream mapping.</p>
              <img src="/images/blog/post6/post6_3.jpg">
              <p>IBM introduced an Industry 4.0 architecture based on a reference model consisting of two layers with distributed functions as shown in [Figure 2] [5].</p>
              <p>The Edge layer is responsible for connecting legacy systems and equipment and integrating with the protocols they follow.</p>
              <p>The plant and enterprise layers are hybrid clouds or platforms that perform data-driven analytics for overall decision making.</p>
              <p>IBM’s architecture incorporates the OPC-UA communication standard and is designed with the ISA-95 specification in mind.</p>
              <img src="/images/blog/post6/post6_4.jpg">    
              <p>National Institute of Standards and Technology (NIST) of the United States proposed a service-oriented architecture aiming at smart manufacturing by connecting OT and IT as shown in [Figure 3] [6].</p>
              <p>It also provides Business Intelligence (BI) services so that all stakeholders can be contacted.</p>
              <p>IT domain services consist of all IT operational elements from the system level to the enterprise level.</p>
              It handles processes and tasks specified at the physical level.</p>
              <p>The virtual domain of the digital factory area that performs modeling &amp; simulation services is also included in the architecture.</p>
              <br/>
              <p>NIST also developed a reference model for smart factory design and analysis, such as Factory Design and Improvement (FDI) for smart manufacturing [7].</p>
              <p>FDI was developed based on global manufacturing company processes related to factory, manufacturing line, process, and equipment design operations.</p>
              <img src="/images/blog/post6/post6_5.jpg">
              <img src="/images/blog/post6/post6_6.jpg">
              <p><strong>[Semiconductor Factory]</strong></p>
              <img src="/images/blog/post6/post6_7.jpg">
              <img src="/images/blog/post6/post6_8.jpg">
              <p><strong>[Shipyard]</strong></p>
              <img src="/images/blog/post6/post6_9.jpg">
              <img src="/images/blog/post6/post6_10.jpg">
              <p><strong>[Chemical Factory]</strong></p>
              <br/>
              <p>It consists of 4 activities and 28 tasks. After FDI was modeled with IDEF0, it was developed as a data model in XML and JSON format.</p>
              <p>[Figure 5] shows examples of application of the cloud-based digital twin system developed based on the FDI reference model.</p>
              <p>It is connected through MES and IIOT platforms and web services to visualize key performance indicators (KPIs) and data in realistic 3D based on digital twins, and is connected with big data analysis, artificial intelligence, and modeling &amp; simulation solutions in real time to perform the analysis.</p>
              <img src="/images/blog/post6/post6_11.jpg">
              <p>Japan launched Industrial Value Chaing Initiative: IVI in order to support the establishment of business scenarios and use cases for connected manufacturing by various companies, and also to provide and manage a loosely defined standard repository that can be continuously changed in the future.</p>
              <p>[Figure 6] shows a reference architecture developed with a loosely defined standard [8].  IVI aims to link technologies through a bottom-up approach based on the needs of the manufacturing industry.</p>
              <p>The industrial value chain reference architecture offers two creative concepts.  One is the Smart Manufacturing Unit (SMU), which presents an autonomous unit of smart manufacturing.</p> 
              <p>SMU is a system that dramatically improves productivity and efficiency through autonomous communication and linkage of manufacturing organizations meeting diversity and individuality in industrial demand. Each SMU consists of three axes: assets, activities, and management.</p>
              <p>The other is a generic functional block, which is used to model the entire smart manufacturing based on engineering flow, supply and demand flow, and level of organizational stratification.</p>   
              <br/>
              <p>스마트 제조는 사물인터넷, 빅데이터, 클라우드컴퓨팅, 인공지능, 모델링&amp;시뮬레이션, VR/AR, 적층 가공, CNC 공작 기계, 및 협업 로봇과 같은 최첨단 정보 기술(IT)과 운영 기술(OT)의 융합을 통해 실현된다[2].</p>   
              <p>이러한 이기종 기술들이 융합되고, 통합되기 위한 상호운용성 확보가 무엇보다 중요하다. 최근 스마트 제조를 위한 통합 및 상호운용성 문제를 해결하기 위해 레퍼런스 모델과 아키텍처가 개발되었다.</p> 
              <br/>
              <p>RAMI 4.0은 독일에 기반을 둔 여러 산·학·연 파트너들에 의해 개발되었다 [1]. RAMI 4.0은 [그림 1]과 같이 제조 산업을 위한 일련의 추상 계층들을 제안하여 상호운용성을 해결한다. 각 레이어에 대한 설명은 다음과 같다.</p>   
              <p>① 비즈니스 계층(Business Layer): 이 계층은 서비스 및 제공하는 제품과 같은 비즈니스 구성 요소에 대한 비즈니스 모델과 정보로 구성된다.</p>   
              <p>② 기능 계층(Functional Layer): 아키텍처 모델의 기능들이 특성화되고, 상세화 되며, 그 관계들이 설정된다.</p>
              <p>③ 정보 계층(Information Layer): 이 계층은 아키텍처에서 사용되는 데이터와 정보를 제어하고 관리한다.</p>
              <p>④ 통신 계층(Communication Layer): 계층, 시스템, 및 실행되는 모든 구성 요소 간의 통신은 동시에 상호 운용이 가능하다.</p>
              <p>⑤ 통합 계층(Integration Layer): 이 계층은 이 아키텍처의 모든 계층과 물리적 구성 요소들을 연결한다. 또한 네트워크 및 소프트웨어 통합에 대해서도 다룬다.</p>
              <p>⑥ 자산 계층(Asset Layer): 현실 세계에 존재하는 사람, 설비 등과 같은 물리적인 객체들을 포함한다.</p>
              <br/>
              <p>RAMI 4.0 모델은 기존 접근 방식들을 연구하고, 이를 상호운용성 스택에 통합하면서 개발되었다. OPC-UA, AutomationML [3] 및 ProSTEP 등과 같은 기존 표준들을 활용하여 상호운용성 스택을 설계하고 구축하였다.</p>
              <p>또한, 이 아키텍처에는 수명주기 개선 및 가치 흐름 매핑을 위해 IEC 62890 [4] 기능들이 통합되었다.</p>
              <br/>
              <p>IBM은 [그림 2]와 같이 기능이 분산된 두 개의 계층으로 구성된 레퍼런스 모델 기반의 인더스트리 4.0 아키텍처를 소개했다 [5].
              <p>엣지(Edge) 계층은 레거시 시스템 및 장비들을 연결하고, 이들이 따르는 프로토콜과 통합하는 역할을 담당한다. 플랜트 및 엔터프라이즈 계층은 하이브리드 클라우드 또는 플랫폼으로 전반적인 의사결정을 위한 데이터 기반 분석을 수행한다.</p>
              <p>IBM의 아키텍처는 OPC-UA 통신 표준을 통합하고, ISA-95 사양을 고려하여 설계되었다.</p><br>
              <p>미국표준기술연구소는 [그림 3]과 같이 OT와 IT를 연결하여 스마트 제조를 목표로 하는 서비스 지향 아키텍처를 제안했다 [6].</p>
              <p>모든 이해 관계자가 연락할 수 있도록 BI (Business Intelligence) 서비스 또한 제공한다.</p>
              <p>IT 도메인 서비스는 시스템 수준에서 엔터프라이즈 수준에 이르는 모든 IT 운영 요소들로 구성된다.</p>
              <p>OT 도메인 서비스는 작업 현장의 구성 요소인 물리적 레벨에 지정된 프로세스와 태스크를 처리한다.</p>
              <p>모델링&amp;시뮬레이션 서비스를 수행하는 디지털 팩토리 영역의 가상 도메인도 아키텍처에 포함되어 있다.</p><br>
              <p>미국표준기술연구소는 스마트 제조를 위한 FDI(Factory Design and Improvement) [7]와 같은 스마트 공장 설계·분석을 위한 레퍼런스 모델도 개발하였다.</p>
              <p>FDI는 공장, 제조 라인, 프로세스 및 장비 설계 운영과 관련한 글로벌 제조 기업 프로세스를 기반으로 개발되었다.</p><br>
              <p>4개의 액티비티와 28 개의 태스크로 구성되어 있다. FDI는 IDEF0로 모델링 된 후 XML과 JSON 형태의 데이터 모델로 개발되었다.</p>
              <p>[그림 5]은 FDI 레퍼런스 모델을 기반으로 개발된 클라우드 기반 디지털 트윈 시스템의 적용 사례들을 보여준다.</p>
              <p>MES, IIOT 플랫폼과 웹서비스를 통해 연결되어 핵심 성능 지표(KPI)들과 데이터들을 디지털 트윈을 기반으로 실감형 3D로 가시화하며, 빅데이터 분석, 인공지능, 및 모델링&amp;시뮬레이션 솔루션들과 연결되어 실시간 분석을 수행한다.</p><br>
              <p>일본은 다양한 기업의 커넥티드 제조에 대한 사업 시나리오 및 이용사례의 구축을 지원하고, 향후 지속적으로 변경될 수 있도록 느슨하게 정의된 표준 저장소를 제공하고 관리할 수 있도록 하기 위한 산업 가치사슬 사업을 (Industrial Value Chain Initiative: IVI) 발족하였다.</p>
              <p>[그림 6]은 느슨하게 정의된 표준으로 개발된 참조 아키텍처를 보여준다 [8].</p>
              <p>IVI는 제조 산업의 요구들을 기반으로 상향식 접근 방식을 통해 기술들을 연결하는 것을 목표로 한다.</p>
              <p>산업 가치사슬 레퍼런스 아키텍처는 두 가지 창의적인 개념을 제공한다.</p>
              <p>하나는 스마트 제조의 자율 단위를 제시하는 스마트 제조 유닛(Smart Manufacturing Unit: SMU)이다.</p>
              <p>SMU은 산업수요의 다양성과 개성에 맞닥뜨려 제조조직의 자율단위 상호소통과 연계를 통해 생산성과 효율성을 획기적으로 향상시키는 시스템이다.</p>
              <p>개별 SMU는 자산, 활동, 관리의 세 축으로 구성된다.</p>
              <p>또 다른 하나는 일반적인 기능 블록으로 엔지니어링 흐름, 수요와 공급 흐름, 조직 계층화 수준을 기반으로 전체 스마트 제조를 모델링하는 데 활용된다.</p> 
              <br/>
              <p><strong>[References]</strong></p>
              <br/>
              <p>[1] Hankel, M., Rexroth, B., “The reference architectural model industrie 4.0 (rami 4.0)”, ZVEI, 2015, 410.</p>
              <p>[2] Zeid, A., Sundaram, S., Moghaddam, M., Kamarthi, S., Marion, T., "Interoperability in smart manufacturing: research challenges", Machines, 7(2), 2019, 21.</p>
              <p>[3] Henßen, R., Schleipen, M., “Interoperability between OPC UA and AutomationML”, Procedia Cirp, 25, 2014, 297-304.</p>
              <p>[4] Pethig, F., Niggemann, O., Walter, A., “Towards Industrie 4.0 compliant configuration of condition monitoring services”, In IEEE 15th International Conference on Industrial Informatics (INDIN), 2017, 271-276.</p>
              <p>[5] Rouse,W.B., Sage, A.P., “Handbook of Systems Engineering and Management”, JohnWiley &amp; Sons: Hoboken, NJ, USA, 2009; ISBN 9780470083536</p>
              <p>[6] Lu, Y., Riddick, F., Ivezic, N., “The Paradigm Shift in Smart Manufacturing System Architecture”, In IFIP International Conference on Advances in Production Management Systems, 2016, 767-776,</p>
              <p>[7] Jung, K., Choi, S., Kulvatunyou, B., Cho, H., Morris, K. C., “A reference activity model for smart factory design and improvement”, Production planning &amp; control, 28(2), 2017, 108-122.</p>
              <p>[8] Takahashi, K., Ogata, Y., Nonaka, Y., “A proposal of unified reference model for smart manufacturing”, In 13th ieee conference on automation science and engineering, 2017, 964-969.</p>
              <br/>
              <p>Written by Dr. SangSu Choi</p>`,
    createDate: 'Posted on May 3, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.TECH,
    imgUrls: [],
  },
  {
    id: '5',
    title: 'Smart Factory',
    content: `<img src="/images/blog/post5/post5_1.jpg" alt="" />
              <p>The end result of smart manufacturing is a smart factory.</p>
              <p>Smart factory best practice examples are being introduced around global companies. Siemens, a German company, announced that they are maintaining their Amberg factory in optimal condition by detecting machine abnormalities by attaching 1,000 sensors to facilities and parts in the factory and analyzing tens of millions of process data every day.</p>
              <p>In the case of Amazon, an unmanned grocery store ‘Amazon Go’ has been open in Seattle, and artificial intelligence technology is used to make payments automatically without the need for customers to pay in person.</p>
              <p>n South Korea, POSCO collects 1 terabyte of data every day by attaching sensors and cameras to the plate factory of the Gwangyang Submission Center and analyzes the data to prevent and improve quality defects.</p>
              <p>S Electric has introduced an automated system to all processes such as parts supply, assembly, and packaging at the Cheongju plant to enable real-time process control and integrated plant operation and collect big data with camera robots for quality control.</p>
              <p>Hyundai & Kia Motor is striving to realize a zero-defect rate by developing a “Smart Tag” that controls automobile processes in real time and automatically controls the process through real-time wireless communication.</p>
              <p><br></p>
              <p>As such, large corporations are realizing smart factories through the latest Information and Communication Technology (ICT) and their own internal experts, but small and medium enterprises (SMEs) are still experiencing difficulties. </p>
              <p>According to the Smart Manufacturing innovation promotion team, the levels of the smart factory are classified into five: the level of ICT not applied at level 0, the level 1 (basic 1) of identified & checked level 2 (basic 2) of measured & monitored, level 3 (intermediate 1) of analyzed & controlled, level 4 (intermediate 2) of optimized & integrated, and the most idea level 5 of advanced & autonomy.</p>
              <p>The government has announced that they have completed the supply of 12,660 smart factories from 2014 to 2019 with the goal of building 30,000 smart factories by 2022.</p>
              <p>82% of the participating companies are mostly within level 1 and level 2, and only 17.9% were identified as level 3 and level 4.</p>
              <p><br></p>
              <p>In the case of SMEs, unlike large corporations, there are many difficulties due to the lack of internal manpower and budget for the building of smart factories.</p>
              <p>Smart factories should be built according to plans and strategies that are embodied around the internal manpower to suit the unique environment of the company.</p>
              <p>It means that the best practices of large corporations do not guarantee the success of SMEs.</p>
              <p><br></p>    
              <p>If ICT technology is quickly introduced without internal preparation, problems may arise after the introduction. In order to build a smart factory, internal personnel must understand the related technology, clarify key KPIs within the company, and clary define the data required to derive the KPIs.</p>
              <p>After that, ICT technologies and systems must be introduced after an internal agreement between the stakeholders within the company is reached to maximize the effect.</p>
              <p>The initiative and excessive intervention external experts, external organizations, ICT vendors can sometimes be negative or disoriented in the long term.</p>
              <p>There are some companies that express their dissatisfaction after promoting the establishment of a smart factory by relying excessively on external experts.</p>
              <p>It is necessary to build a smart factory with the help of external experts, but internal personnel must be the center. It is highly recommended that you develop a strategy and roadmap taking into account the characteristics of your business, budge, and return of investment (ROI).</p>
              <p><br></p>
              <p>스마트 제조의 최종 결과물은 스마트 공장이다. 글로벌 기업들을 중심으로 스마트 공장 베스트 프랙티스 사례들이 소개되고 있다. 독일 지멘스는 암베르크 공장 내 설비와 부품에 1,000여개의 센서들을 부착해 기계 이상을 감지하고 매일 수천만 건의 공정 데이터를 분석해 공장을 최적의 상태로 유지하고 있다고 발표했다.</p>
              <p>아마존의 경우 시애틀에 무인 식료품점 ‘아마존고’를 설치하고, 인공지능 기술을 활용해 고객이 계산을 하지 않아도 자동 결제되도록 했다.</p>
              <p>한국의 경우 포스코는 광양제출소 후판 공장에 센서와 카메라를 부착해 매일 1 테라바이트 데이터를 수집하고, 그 데이터들을 분석해 품질결함 예방과 개선을 하고 있다.</p>
              <p>LS 일렉트릭은 청주공장에 부품공급, 조립, 포장 등 전 공정에 자동화된 시스템을 도입하여 실시간 공정 제어 및 공장 통합 운영이 가능하도록 하였고, 카메라 로봇으로 빅데이터를 수집해 품질 관리를 하고 있다.</p>
              <p>현대 · 기아차는 자동차 공정을 실시간 컨트롤하는 ‘스마트 태그 (Smart Tag)’를 개발해 공정 과정을 실시간 무선통신으로 자동 제어를 통해 불량률 제로를 실현하고자 노력하고 있다.</p>
              <p><br></p>
              <p>이처럼 대기업들은 최신 ICT (Information and Communication Technology)와 내부 전문 인력들을 통해 스마트 공장을 실현해가고 있지만 중소기업들의 경우 여전히 어려움을 겪고 있다.</p>
              <p>스마트제조혁신추진단에 따르면 스마트 공장 수준은 레벨 0의 ICT 미적용 단계, 레벨 1 (기초 1)인 식별과 점검 (Identified & Checked), 레벨 2 (기초 2)인 측정과 확인 (Measured & Monitored), 레벨 3 (중간1)인 분석과 제어 (Analyzed & Controlled), 레벨 4 (중간 2)인 최적화과 통합 (Optimized & Integrated), 가장 이상적인 단계인 레벨 5 (고도화) 맞춤과 자율 (Customized & Autonomy)로 구분된다.</p>
              <p>정부는 2022년까지 스마트 공장 3만개 구축을 목표로 하여 2014년부터 2019년까지 12,660개의 스마트 공장 보급을 완료했다고 발표했다.</p>
              <p>82%의 참여기업이 대부분 레벨 1과 레벨 2의 수준이며, 17.9%만이 레벨 3와 레벨 4로 파악되었다.</p>
              <p><br></p>
              <p>중소기업의 경우 대기업과는 달리 스마트 공장 구축을 위한 내부적인 인력 부족, 예산 부족 등으로 인해 많은 어려움이 따른다.</p>
              <p>스마트 공장은 기업 고유의 환경에 맞게 내부 인력 중심으로 구체화된 계획과 전략에 따라 구축되어야 한다.</p>
              <p>대기업의 베스트 프랙티스들이 중소기업의 성공을 보장하지 않는다.</p>
              <p><br></p>
              <p>내부적으로 준비되지 않은 상황에서 ICT 기술을 서둘러 도입하면 도입 이후 문제들이 발생할 수도 있다.</p>
              <p>스마트 공장을 구축하기 위해서는 내부 인력들이 관련 기술에 대해 이해하고 있어야 하며, 회사 내 주요 KPI들을 명확히 하고, 그 KPI들을 도출하기 위해 필요한 데이터에 대해 명확히 정의해야 한다.</p>
              <p>이후 회사 내 이해당사자들 간 내부적 합의가 이루어진 뒤에 ICT 기술과 시스템들이 도입되어야 그 효과를 극대화할 수 있다.</p>
              <p><br></p>
              <p>외부 전문가, 외부 기관, ICT 밴더사 등의 주도와 과도한 개입은 때때로 장기적인 관점에서 마이너스가 되거나 방향성을 잃을 수도 있다.</p>
              <p>외부 인력들에 과도하게 의존하여 스마트 공장 구축을 추진했다가 불만을 표출하는 기업들도 더러 있다.</p>
              <p> 외부 전문가들의 도움을 받되 내부 인력들이 중심이 되어 스마트 공장을 구축해 나가야 한다.</p>
              <p>자신들의 업의 특성, 예산, ROI (Return of Investment) 등을 고려하여 전략과 로드맵을 개발하시길 강력히 추천한다.</p>
              <p><br></p>
              <p>Written by Dr. SangSu Choi</p>`,
    createDate: 'Posted on May 14, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: 'https://source.unsplash.com/8xznAGy4HcY/800x400',
    category: BlogCategory.TECH,
    imgUrls: [],
  },
  {
    id: '4',
    title: 'Smart Manufacturing',
    content: `<img src="/images/blog/post4/post4_1.png" alt="" />
              <p>With the advent of the fourth industry revolution, global manufacturing companies are developing smart manufacturing systems based on Information and Communication Technology (ICT) and reporting various best practices.'</p>
              <p><br></p>
              <p>The competition is fierce for preoccupying the manufacturing advantage.</p>
              <p>In Germany, the manufacturing powerhouse, the government is leading the Public Private Partnerships (PPP) project called Industry 4.0.</p>
              <p>Through connected factories, it is making a leading attempt to create value and business models that have not existed until now by converging Internet of Things (IoT) and Internet of Services (IoS) with the manufacturing industry.</p>
              <p>The final result of Industry 4.0 is implemented as a smart factory.</p>
              <p>In the United States, the Advanced Manufacturing Partnership (AMP) has been initiated with recommendations of the President’s Council of Advisors on Science and Technology (PCAST).</p>
              <p>Smart Manufacturing Leadership Coalition (SMLC) and National Institute of Standards and Technology (NIST) are conducting active research on smart manufacturing.</p>
              <p><br></p>
              <p>The Japanese government has announced Connected Industries as a new concept framework to achieve Society 5.0.</p>
              <p>The main policies in the manufacturing sector are composed of four types: smart factory pilot project, Industrial Internet of Things (IIoT) platform connection, startup factory construction support, and test bed pilot project.</p>
              <p>China aims to advance its economic competitiveness by advancing China’s position in the global manufacturing value chain through three phases over next thirty years with a broad umbrella industrial plan called Made in China 2025 (MIC 2025).</p>
              <p>In Korea, the Manufacturing Industry Innovation 3.0 strategy was introduced, and the Korean government is promoting business of developing core technology for smart manufacturing and expending smart factories.</p>
              <p><br></p>
              <p>The SMLC definition states, “smart manufacturing is the intensified application of advanced intelligence system to enable rapid manufacturing of new product, dynamic response to product demand, and real-time optimization of manufacturing production and supply chain network.”</p>
              <p>NIST defines smart manufacturing as “fully-integrated, collaborative manufacturing systems that respond in real time to meet changing demands and conditions in the factory, in the supply network, and in customer needs.”</p>
              <img src="/images/blog/post4/post4_2.svg" alt="" />
              <p><strong>Choi, S., Kang, G., Jung, K., Kulvatunyou, B., Morris, K. C., "Applications of the factory design and improvement reference activity model", In IFIP International Conference on Advances in Production Management Systems, 2016, 697-704.</strong></p>
              <p><br></p>
              <p>The smart manufacturing system is built on the latest ICT technologies and standards.</p>
              <p>Figure 1 shows the concept of smart manufacturing system that all manufacturing company functions including raw material management, production, logistics, services, and products are connected to the network via an integrated system, and production is controlled through the Cyber-Physical Product System (CPPS).</p>
              <p>CPPS consists of a virtual factory model connected to a real production site that continuously exchanges information with enterprise software through the Internet of Service (IoS), distributed devices, and equipment (IoT).</p>
              <p>The analysis function of CPPS can generate dynamic plans to optimally control the entire production process.</p>
              <p><br></p>
              <p>Many standards for smart manufacturing system are being developed around IoS and IoT.</p>
              <p><br></p>
              <p>In the IoS sector, standard development is in progress centering on representative systems used by manufacturing companies, and the maturity of related standards is high because the utilization is already very high in manufacturing companies.</p>
              <p>STEP 242 is a representative standard for Product and Enterprise Resource Planning (ERP), and ISA-95 is representative for Manufacturing Execution System (MES).</p>
              <p><br></p>
              <p>Table 1 summarizes the representative functions of PLM, ERP, SCM, and MES related to IoS.</p>
              <img src="/images/blog/post4/post4_3.svg" alt="" />
              <p><strong>Choi, S., Jung, K., Kulvatunyou, B., Morris, K. C., “An analysis of technologies and standards for designing smart manufacturing systems”, J Res Natl Inst Stan, 121, 2016, 422-433.</strong></p>
              <a href="https://www.nist.gov/publications/analysis-technologies-and-standards-designing-smart-manufacturing-systems">link : https://www.nist.gov/publications/analysis-technologies-and-standards-designing-smart-manufacturing-systems</a>
              <p><br></p>
              <p>In the IoT sector, there is ISO/IEC 30141, which defines the IoT reference structure proposed by China, and IEEE 802.24 is also a representative standard activity.</p>
              <p>The “IEC SG8 Industry 4.0- Smart Manufacturing” strategic group compose mainly of European countries and the United States is actively promoting international standardization activities for the Internet of Things (IoT) architecture framework IEEE P2413-Standard for an Architectural Framework for the Internet of Things (IoT) and mutual cooperation.</p>
              <p>oneM2M has been formed to improve the reality that products are not compatible internationally due to the jagged M2M standards by region.</p>
              <p>At Supervisory Control And Data Acquisition (SCADA) and device level, standards such as OPC UA, MTConnect, PackML, and BatchML are being used.</p>
              <p><br></p>
              <p>IEC 62264 is an international standard for enterprise control system integration that is based on ISA 95.</p>
              <p>IEC 62264 defines an activity model, a functional model, and an object model belonging to the Manufacturing Operations Management (MOM) domain.</p>
              <p>ISO 22400 defines key performance indicators (KPIs) used in manufacturing management.</p>
              <p><br></p>
              <p>4차 산업혁명 시대의 도래로 글로벌 기업들은 최신 정보통신기술(Information and Communication Technology: ICT)을 기반으로 스마트 제조 시스템을 개발하고 있으며, 다양한 베스트 프랙티스들을 발표하고 있다.</p>
              <p>제조 우위를 선점하기 위한 국가의 경쟁 또한 치열하다.</p>
              <p>제조 강국 독일에서는 인더스트리 4.0이라 불리는 민-관-학 프로젝트를 정부주도로 추진하고 있다.</p>
              <p>연결된 공장을 통해 사물 인터넷과 인터넷 서비스를 제조업과 융합하여 지금까지는 없었던 가치와 비즈니스 모델을 창출하기 위한 선도적 시도를 하고 있다.</p>
              <p>인더스트리 4.0의 최종 결과물은 스마트 팩토리 (Smart Factory)로 구현된다.</p>
              <p>미국은 대통령 과학기술자문위원회(President’s Council of Advisors on Science and Technology: PCAST)의 권고로 첨단 제조 파트너십 프로그램을 발족시켰다.</p>
              <p>스마트 제조 리더십 연합체(Smart Manufacturing Leadership Coalition: SMLC)와 미국표준기술연구소(National Institute of Standards and Technology: NIST)를 중심으로 스마트 제조에 대한 활발한 연구를 진행하고 있다.</p>
              <p>일본은 소사이어티 5.0, 커넥티드 인더스트리즈 (Connected Industries) 정책을 발표하였다.</p>
              <p>제조 분야의 주요 정책은 스마트 팩토리 시범사업, IIoT(Industrial Internet of Things) 플랫폼 연계, 스타트업 팩토리 구축 지원, 테스트베드 시범사업 등 4가지로 구성된다.</p>
              <p>중국은 제조업 활성화와 제조 강국 건설을 위하여 향후 30년간 3단계 제조업 혁신을 통해 세계 제조업 선도국가 지위 확립하는 것이 목표로 제조 2025를 추진하고 있다.</p>
              <p>한국도 제조 3.0을 추진하며 정부 주도로 스마트 제조 원천 기술 개발, 스마트 팩토리 보급 확산 사업을 추진하고 있다.</p>
              <p><br></p>
              <p>SMLC는 스마트 제조를 ‘신제품의 신속한 제조, 제품 수요의 적극적 대응, 생산 및 공급사슬망의 실시간 최적화를 가능하게 하는 첨단 지능형 시스템들의 심화 적용 (intensified application)’이라 정의했고, NIST는 ‘공장, 공급 네트워크 및 고객 요구의 변화하는 요구와 조건을 충족하기 위해 실시간으로 대응하는 완전 통합되어 있는 협업적인 제조 시스템’ 이라고 정의했다.</p>
              <p>스마트 제조 시스템은 최신의 ICT 기술과 표준들을 기반으로 구축된다.</p>
              <p>[그림 1]은 원자재 관리, 생산, 물류, 서비스 및 제품을 포함한 모든 제조 기업 기능이 통합 시스템으로 네트워크에 연결되고, 생산이 사이버-물리 생산 시스템(Cyber-Physical Production System: CPPS)을 통해 제어되는 스마트 제조 시스템의 개념을 보여준다.</p>
              <p>CPPS는 분산 소프트웨어 서비스 (Internet of Service: IoS) 및 분산 장치 및 장비 (IoT)를 통해 엔터프라이즈 소프트웨어와 지속적으로 정보를 교환하는 실제 생산 현장과 연결된 가상의 팩토리 모델로 구성된다.</p>
              <p>CPPS의 분석 기능은 전체 생산 프로세스를 최적으로 제어하기 위한 동적 계획을 생성할 수 있다.</p>
              <p><br></p>
              <p>스마트 제조 시스템을 위한 많은 표준들이 IoS와 IoT를 중심으로 개발되고 있다.</p>
              <p><br></p>
              <p>IoS 영역에서는 제조기업에서 활용되는 대표 시스템들을 중심으로 표준 개발이 진행되고 있으며, 이미 제조 기업에서 그 활용도가 매우 높기에 관련 표준의 성숙도 또한 높은 편이다.</p>
              <p>PLM(Product Lifecycle Management)은 STEP 242이 대표적 표준이고, SCM(Supply Chain Management), ERP(Enterprise Resource Planning)와 관련해서는 OAGIS, SCOR가 있으며, MES(Manufacturing Execution System)와 관련해서는 ISA-95가 대표적이다.</p>
              <p><br></p>
              <p>[표 1]은 IoS와 관련한 PLM, ERP, SCM, MES에 대한 대표적인 기능들을 정리한 것이다.</p>
              <p><br></p>
              <p>IoT 영역에서는 중국에서 제안한 사물인터넷 참조구조를 정의하는 ISO/IEC 30141가 있으며, IEEE 802.24도 대표적인 표준 활동이다.</p>
              <p>유럽과 미국을 중심으로 구성된 “IEC SG8 Industry 4.0-Smart Manufacturing” 전략그룹은 현재 활발하게 사물인터넷(IoT) 아키텍처 프레임워크 국제 표준화 활동을 추진하고 있는 IEEE P2413- Standard for an Architectural Framework for the Internet of Things(IoT)와 상호협력을 추진하고 있다.</p>
              <p>oneM2M은 지역별로 들쭉날쭉한 M2M 표준 때문에 국제적으로 제품을 호환할 수 없는 현실을 개선하기 위해 결성했다.</p>
              <p>SCADA(Supervisory Control And Data Acquisition) 및 장치 레벨에서는 OPC UA, MTConnect, PackML 및 BatchML과 같은 표준들이 활용되고 있다.</p>
              <p><br></p>
              <p>IEC 62264는 기업 제어 시스템 통합을 위한 국제 표준이며 ISA 95를 토대로 한다.</p>
              <p>IEC 62264는 MOM(Manufacturing Operations Management) 도메인에 속한 활동 모델과 기능 모델, 객체 모델을 정의한다.</p>
              <p>ISO 22400 제조업 관리에 사용되는 핵심 성능 지표(KPI)를 정의하고 있다.</p>
              <p><br></p>
              <p>Written by Dr. SangSu Choi</p>`,

    createDate: 'Posted on May 3, 2021',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: 'https://source.unsplash.com/8xznAGy4HcY/800x400',
    category: BlogCategory.TECH,
    imgUrls: [],
  },
  {
    id: '3',
    title:
      '포스트 코로나 시대 스마트 제조 구현 "서로 다른 기술간의 융합에 달렸다"',
    content: `<img src="/images/blog/post3/post3_1.jpg" alt="" />
              <p><strong>[e4ds news]</strong></p>
              <p><br></p>
              <p>'최상수 아이지아이 코리아 대표이사는 ‘정보통신기획평가원(IITP) 주간기술동향 1954호’에 ‘이기종 기술기반 스마트 제조 시스템 표준 및 상호운용성 동향’이란 제목의 글을 기고하며 한국의 제조업체들이 스마트 제조 시스템을 개발하기 위해 숙지해야 할 관련 기술 표준들과 레퍼런스 아키텍처들에 관해 설명했다.'</p>
              <p><br></p>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/31kCKGb" target="_blank">link : https://bit.ly/31kCKGb</a>`,
    createDate: 'Posted on July 21, 2020',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '2',
    title:
      '표준, 계측 및 품질 전문 교육 센터와 IGI Korea Company의 스마트 제조 분야 협력 체결식',
    content: `<img src="/images/blog/post2/post2_1.png" alt="" />
              <p><strong>[TCVN]</strong></p>
              <p><br></p>
              <p>'6월 6일 오후 표준, 계측 및 품질 일반 부서에서 표준, 계측 및 품질 전문 교육 센터(GSO)와 IGI Korea Company 간의 스마트 제조 분야 협력 서명식이 있었습니다.</p>
              <p>평가에 따르면, 4.0 기술 혁명은 삶의 모든 측면에서 상당한 개혁의 물결을 불러일으키고 있으며, 생산 방식을 전통적 방식에서 더 스마트한 방식으로 변화시키고 있습니다.'</p>
              <p><br></p>
              <p>'Chiều 6/6, tại Tổng cục Tiêu chuẩn Đo lường Chất lượng đã diễn ra Lễ ký kết hợp tác về lĩnh vực sản xuất thông minh giữa Trung tâm Đào tạo Nghiệp vụ Tiêu chuẩn Đo lường Chất lượng (TCĐLCL) và Công ty IGI Hàn Quốc.</p>
              <p>Theo nhận định, cuộc cách mạng công nghệ 4.0 đang tạo ra một làn sóng cải cách đáng kể trong mọi khía cạnh của cuộc sống, thay đổi cách thức sản xuất từ truyền thống sang hướng thông minh hơn.'</p>
              <p><br></p>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://tcvn.gov.vn/le-ky-ket-hop-tac-ve-linh-vuc-san-xuat-thong-minh-giua-trung-tam-dao-tao-chat-luong-va-cong-ty-igi-han-quoc/06/06/2019/" target="_blank">link : https://tcvn.gov.vn/le-ky-ket-hop-tac-ve-linh-vuc-san-xuat-thong-minh-giua-trung-tam-dao-tao-chat-luong-va-cong-ty-igi-han-quoc/06/06/2019/</a>`,
    createDate: 'Posted on June 6, 2019',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '1',
    title:
      '산업 혁명 4.0(Industry 4.0) 시대에 스마트 생산은 피할 수 없는 추세이며, 표준, 계측 및 품질 총무부는 이 분야를 개척하기로 결정',
    content: `<img src="/images/blog/post1/post1_1.png" alt="" />
              <p><strong>[VietQ]</strong></p>
              <p><br></p>
              <p>'11월 30일 오후 Ha Minh Hiep 표준, 계측 및 품질 부국장과 관련 부서 대표들이 아시아생산성기구(APO) 전문가인 최상수 박사와 문제에 대한 작업 세션을 가졌습니다.'</p>
              <p><br></p>
              <p>'Chiều ngày 30/11, Phó Tổng cục trưởng Tổng cục Tiêu chuẩn Đo lường Chất lượng Hà Minh Hiệp cùng đại diện các đơn vị liên quan đã có buổi làm việc với chuyên gia của Tổ chức Năng suất Châu á (APO) - Tiến sỹ Sangsu CHOI về những vấn đề liên quan đến sản xuất thông minh (SXTM) cho các doanh nghiệp của Việt Nam.'</p>
              <p><br></p>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3yMbyia" target="_blank">link : https://bit.ly/3yMbyia</a>`,
    createDate: 'Posted on November 30, 2017',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
  {
    id: '0',
    title: '<한 뼘 책보기> ‘스마트 제조 시스템’ - 최상수',
    content: `<img src="/images/blog/post0/post0_1.jpg" alt="" />
              <p><strong>[경남매일]</strong></p>
              <p><br></p>
              <p>'국내외 IT 기업, 제조기업, 연구소 등에서 근무하며, 제조와 IT기술의 융합을 위해 많은 노력을 해온 저자는, 회사에 적합한 스마트 제조 시스템을 도입하기 위해서는 먼저 현재 스마트시스템 준비 수준을 분석해보고, 이를 기반으로 상호 운용성, 통합을 고려하여 연구개발 로드맵을 작성하고 기술과 시스템들을 도입해야 한다고 주장한다.'</p>
              <p><br></p>
              <p>자세한 내용은 아래 링크를 통해 확인해보세요.</p>
              <a href="https://bit.ly/3IaIIdW" target="_blank">link : https://bit.ly/3IaIIdW</a>`,
    createDate: 'Posted on July 27, 2016',
    lastModifiedDate: '2024-07-03 12:00',
    thumbnailUrl: null,
    category: BlogCategory.PRESS,
    imgUrls: [],
  },
];
